import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddResourceUserInput,
  ResourceAccessLevel,
  ResourcePreviewWithUsersFragment,
  ResourceType,
  SortDirection,
  useAddResourceUsersMutation,
  useResourceAddUsersQuery,
  UserPreviewSmallFragment,
  UsersSortByField,
} from "api/generated/graphql";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { ResourceLabel } from "components/label/Label";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { Role } from "components/modals/ResourceIndividualRoleModal";
import {
  Banner,
  ButtonV3,
  Divider,
  EntityIcon,
  Icon,
  Input,
  Select,
} from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import {
  resourceHasOnlyOneRole,
  resourceRequiresAtLeastOneRole,
  serviceTypeHasMaxOneRole,
} from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";
import { getUserAvatarIcon } from "views/users/utils";

import * as styles from "./ResourceAddUsersView.css";

type UserWithExpirationandRoles = {
  user: UserPreviewSmallFragment;
  expiration: ExpirationValue;
  roles: Role[];
};
interface UserItemRow {
  id: string;
  [UsersSortByField.FirstName]: string;
  [UsersSortByField.Email]: string;
  item: UserPreviewSmallFragment;
}
const columns: Header<UserItemRow>[] = [
  {
    id: UsersSortByField.FirstName,
    label: "Name",
    sortable: true,
    customCellRenderer: (row) => {
      let userFullname = row.item?.fullName;
      let icon = row.item ? getUserAvatarIcon(row.item) : null;
      if (!userFullname) {
        return <></>;
      }
      return (
        <div
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            gap: "sm",
          })}
        >
          {icon && <Icon data={icon} />}
          <ResourceLabel
            text={userFullname}
            bold={true}
            pointerCursor={true}
            maxChars="auto"
          />
        </div>
      );
    },
    width: 230,
  },
  {
    id: UsersSortByField.Email,
    label: "Email",
    sortable: true,
    customCellRenderer: (row) => {
      let userEmail = row.item.email;
      if (!userEmail) {
        return <></>;
      }
      return (
        <ResourceLabel
          text={userEmail}
          bold={false}
          pointerCursor={true}
          maxChars="auto"
        />
      );
    },
  },
];

const ResourceAddUsersView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { resourceId } = useParams<{ resourceId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [usersToAddByUserId, setUsersToAddByUserId] = useState<
    Record<string, UserWithExpirationandRoles>
  >({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [addUsersErrorMessage, setAddUsersErrorMessage] = useState("");

  type SortValue = {
    field: UsersSortByField;
    direction: SortDirection;
  };
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: UsersSortByField.FirstName,
    direction: SortDirection.Asc,
  });
  function isSortableField(str: string): str is UsersSortByField {
    return Object.values<string>(UsersSortByField).includes(str);
  }
  const [
    addResourceUsers,
    { loading: addUsersLoading },
  ] = useAddResourceUsersMutation();
  const {
    data,
    previousData,
    loading,
    error,
    fetchMore,
  } = useResourceAddUsersQuery({
    variables: {
      id: resourceId,
      searchQuery: debouncedSearchQuery,
      sortBy: sortBy,
      maxNumEntries: 100,
    },
  });
  let resource: ResourcePreviewWithUsersFragment | undefined;
  if (previousData?.resource.__typename === "ResourceResult") {
    resource = previousData.resource.resource;
  }
  if (data?.resource.__typename === "ResourceResult") {
    resource = data.resource.resource;
  }
  const cursor = data?.users?.cursor || undefined;
  const totalNumUsers = data?.users?.totalNumUsers || 0;
  const allUsers = data?.users.users ?? previousData?.users.users ?? [];
  const roles: ResourceAccessLevel[] =
    resource?.accessLevels?.filter((role) => role.accessLevelName !== "") ?? [];

  if (loading && !(data || previousData)) {
    return <FullscreenSkeleton />;
  }
  if (!resource?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (!resource || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const handleClose = () => {
    transitionBack(`/resources/${resourceId}#users`);
  };

  const resourceRequiresRole = resourceRequiresAtLeastOneRole(resource);
  const directRolesByUserId: Record<string, ResourceAccessLevel[]> = {};
  const numDirectAccessPointsByUserId: Record<string, number> = {};
  resource.resourceUsers.forEach((resourceUser) => {
    if (resourceUser.access?.directAccessPoint) {
      if (!directRolesByUserId[resourceUser.userId]) {
        directRolesByUserId[resourceUser.userId] = [];
      }
      directRolesByUserId[resourceUser.userId] = _.uniqBy(
        [...directRolesByUserId[resourceUser.userId], resourceUser.accessLevel],
        "accessLevelRemoteId"
      );

      if (!numDirectAccessPointsByUserId[resourceUser.userId]) {
        numDirectAccessPointsByUserId[resourceUser.userId] = 0;
      }
      numDirectAccessPointsByUserId[resourceUser.userId] += 1;
    }
  });

  let resourceHasOnlyOneAccessType =
    resource && resourceHasOnlyOneRole(resource);

  if (resource.resourceType === ResourceType.OktaApp) {
    resourceHasOnlyOneAccessType = true;
  }

  // Filter out users who have full direct access already
  const users = allUsers.filter((user) => {
    const directRoleCount = numDirectAccessPointsByUserId[user.id] || 0;
    // Resource does not have access levels
    if (resourceHasOnlyOneAccessType && directRoleCount !== 0) {
      return false;
    }

    // Resource has access levels
    const rolesForResource = resource?.accessLevels ?? [];
    if (
      rolesForResource.length !== 0 &&
      directRoleCount === rolesForResource.length
    ) {
      return false;
    }

    return true;
  });

  const rows: UserItemRow[] = users.map((user) => {
    return {
      id: user.id,
      [UsersSortByField.FirstName]: user.firstName || "--",
      [UsersSortByField.Email]: user.email || "--",
      item: user,
    };
  });

  const title = (
    <>
      Add Users:
      <div className={sprinkles({ display: "flex", alignItems: "center" })}>
        <EntityIcon type={resource.resourceType} size="lg" />
      </div>
      {resource.name}
    </>
  );
  const loadMoreRows = async () => {
    if (!cursor) {
      return;
    }
    await fetchMore({
      variables: {
        searchQuery: debouncedSearchQuery,
        id: resourceId,
        cursor: cursor,
        sortBy: sortBy,
      },
    });
  };

  const numUsersToAdd = Object.keys(usersToAddByUserId).length;

  const handleAddUsers = async () => {
    if (resourceRequiresRole) {
      for (let { roles } of Object.values(usersToAddByUserId)) {
        if (
          roles.length === 0 ||
          (roles.length === 1 && roles[0].accessLevelName === "")
        ) {
          setAddUsersErrorMessage(
            "Must select at least one role for each user"
          );
          return;
        }
      }
    }

    logEvent({
      name: "apps_add_user",
      properties: {
        type: "resource",
        numUsers: Object.entries(usersToAddByUserId).length,
      },
    });
    try {
      const resourceUsersToAdd: AddResourceUserInput[] = [];
      for (const [userId, UserWithExpirationandRoles] of Object.entries(
        usersToAddByUserId
      )) {
        const expirationVal =
          usersToAddByUserId[userId].expiration || ExpirationValue.Indefinite;
        const accessDurationInMinutes = expirationValueToDurationInMinutes(
          expirationVal
        )?.asMinutes();
        UserWithExpirationandRoles.roles.forEach((role) => {
          resourceUsersToAdd.push({
            resourceId: resource?.id ?? "",
            userId: userId,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
            durationInMinutes: accessDurationInMinutes,
          });
        });
      }

      const { data } = await addResourceUsers({
        variables: {
          input: {
            resourceUsers: resourceUsersToAdd,
          },
        },
        refetchQueries: ["ResourceDetailColumn"],
      });
      switch (data?.addResourceUsers.__typename) {
        case "AddResourceUsersResult":
          startPushTaskPoll(data.addResourceUsers.taskId);
          handleClose();
          break;
        case "CannotAddSystemUserToResourceError":
          logError(new Error(data.addResourceUsers.message));
          setAddUsersErrorMessage(data.addResourceUsers.message);
          break;
        case "OpalGlobalImpersonationResourceDirectAddNotAllowedError":
          logError(new Error(data.addResourceUsers.message));
          setAddUsersErrorMessage(data.addResourceUsers.message);
          break;
        case "CallToWebhookFailedError":
          setAddUsersErrorMessage(data.addResourceUsers.message);
          break;
        case "ResourceUserAlreadyExists":
          setAddUsersErrorMessage(data.addResourceUsers.message);
          break;
        default:
          logError(new Error(`failed to update resource user access`));
          setAddUsersErrorMessage(
            "Error: failed to update resource user access"
          );
      }
    } catch (error) {
      logError(error, "failed to update resource user access");
      setAddUsersErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to update resource user access",
          error
        )
      );
    }
  };

  return (
    <FullscreenView
      title={title}
      onCancel={handleClose}
      onPrimaryButtonClick={handleAddUsers}
      primaryButtonLabel={`Add ${
        numUsersToAdd > 0 ? numUsersToAdd : ""
      } ${pluralize("user", numUsersToAdd)}`}
      primaryButtonDisabled={numUsersToAdd === 0}
      primaryButtonLoading={addUsersLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select users to add to the resource:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Filter by name or email"
            />
          </div>
          <Divider />
          {loading ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              columns={columns}
              rows={rows}
              totalNumRows={totalNumUsers ?? 0}
              getRowId={(user) => user.id}
              onLoadMoreRows={loadMoreRows}
              checkedRowIds={new Set(Object.keys(usersToAddByUserId))}
              onCheckedRowsChange={(checkedRowIds, checked) => {
                if (checked) {
                  const newUsersToAddByUserId = {
                    ...usersToAddByUserId,
                  };
                  for (const userId of checkedRowIds) {
                    if (!(userId in usersToAddByUserId)) {
                      const user = users.find((user) => user.id === userId);
                      if (user) {
                        const UserWithExpirationandRoles: UserWithExpirationandRoles = {
                          user: user,
                          expiration: ExpirationValue.Indefinite,
                          roles: [
                            {
                              accessLevelName: "",
                              accessLevelRemoteId: "",
                            },
                          ],
                        };
                        newUsersToAddByUserId[
                          userId
                        ] = UserWithExpirationandRoles;
                      }
                    }
                  }
                  setUsersToAddByUserId(newUsersToAddByUserId);
                } else {
                  const newUsersToAddByUserId = {
                    ...usersToAddByUserId,
                  };
                  for (const userId of checkedRowIds) {
                    delete newUsersToAddByUserId[userId];
                  }
                  setUsersToAddByUserId(newUsersToAddByUserId);
                }
              }}
              onRowClick={(user) => {
                if (user.id in usersToAddByUserId) {
                  const newUsersToAddByUserId = {
                    ...usersToAddByUserId,
                  };
                  delete newUsersToAddByUserId[user.id];
                  setUsersToAddByUserId(newUsersToAddByUserId);
                } else {
                  const newUsersToAddByUserId = {
                    ...usersToAddByUserId,
                  };
                  const UserWithExpirationandRoles: UserWithExpirationandRoles = {
                    user: user.item,
                    expiration: ExpirationValue.Indefinite,
                    roles: [
                      {
                        accessLevelName: "",
                        accessLevelRemoteId: "",
                      },
                    ],
                  };
                  newUsersToAddByUserId[user.id] = UserWithExpirationandRoles;
                  setUsersToAddByUserId(newUsersToAddByUserId);
                }
              }}
              manualSortDirection={
                sortBy && {
                  sortBy: sortBy.field,
                  sortDirection: sortBy.direction,
                }
              }
              handleManualSort={(sortBy, sortDirection) => {
                // Investigate if there is more elegant solution
                if (!sortDirection) {
                  setSortBy(undefined);
                  return;
                }
                if (!isSortableField(sortBy)) {
                  return;
                }
                const direction: SortDirection =
                  sortDirection === "DESC"
                    ? SortDirection.Desc
                    : SortDirection.Asc;
                setSortBy({
                  field: sortBy,
                  direction,
                });
              }}
            />
          )}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addUsersErrorMessage && (
          <Banner
            message={addUsersErrorMessage}
            type="error"
            marginBottom="lg"
          />
        )}
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "lg",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textLg",
              fontWeight: "medium",
              marginBottom: "lg",
            })}
          >
            Adding {numUsersToAdd} {pluralize("User", numUsersToAdd)}
          </div>

          {numUsersToAdd > 0 && (
            <ButtonV3
              leftIconName="x"
              label="Clear all"
              size="xs"
              type="dangerBorderless"
              onClick={() => setUsersToAddByUserId({})}
            />
          )}
        </div>
        {Object.keys(usersToAddByUserId).map((userId) => {
          const user = usersToAddByUserId[userId].user;

          if (!user) {
            return null;
          }
          const expiration = usersToAddByUserId[userId].expiration;

          return (
            <div key={userId} className={styles.userCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                  marginBottom: "lg",
                })}
              >
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon data={getUserAvatarIcon(user)} />
                </div>
                <div className={styles.userInfoSection}>
                  <div className={styles.userCardHeader}>{user.fullName}</div>
                  <div className={styles.userCardSubtitle}>{user.email}</div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      const newUsersToAddByUserId = {
                        ...usersToAddByUserId,
                      };
                      delete newUsersToAddByUserId[user.id];
                      setUsersToAddByUserId(newUsersToAddByUserId);
                    }}
                  />
                </div>
              </div>
              <Select
                key={userId}
                options={Object.values(ExpirationValue)}
                value={expiration}
                onChange={(val) => {
                  if (val) {
                    const newUsersToAddByUserId = {
                      ...usersToAddByUserId,
                    };
                    newUsersToAddByUserId[user.id].expiration = val;
                    setUsersToAddByUserId(newUsersToAddByUserId);
                  }
                }}
                disableBuiltInFiltering
                getOptionLabel={(expirationVal) =>
                  expirationVal === ExpirationValue.Indefinite
                    ? "Indefinite access"
                    : `Access for ${expirationVal}`
                }
              />
              {(roles.length > 0 || resourceRequiresRole) && (
                <div className={sprinkles({ marginTop: "md" })}>
                  <Select
                    key={userId}
                    options={roles.filter((role) => {
                      // Only include roles that the user does not already have
                      // or are currently selected to be added
                      if (
                        directRolesByUserId[user.id]
                          ?.map((r) => r.accessLevelRemoteId)
                          .includes(role.accessLevelRemoteId)
                      ) {
                        return false;
                      }
                      if (
                        usersToAddByUserId[user.id].roles
                          .map((role) => role.accessLevelRemoteId)
                          .includes(role.accessLevelRemoteId)
                      ) {
                        return false;
                      }
                      return true;
                    })}
                    selectOnly
                    placeholder="Select role"
                    onChange={(val) => {
                      if (!val) {
                        return;
                      }
                      const selectedAccessLevel = {
                        accessLevelName: val.accessLevelName,
                        accessLevelRemoteId: val.accessLevelRemoteId,
                      };
                      const newRolesByUserIdToAdd = { ...usersToAddByUserId };
                      if (
                        serviceTypeHasMaxOneRole(resource?.serviceType) ||
                        (usersToAddByUserId[user.id].roles.length === 1 &&
                          usersToAddByUserId[user.id].roles[0]
                            .accessLevelName === "")
                      ) {
                        usersToAddByUserId[user.id].roles = [
                          selectedAccessLevel,
                        ];
                      } else {
                        usersToAddByUserId[user.id].roles = [
                          ...usersToAddByUserId[user.id].roles,
                          selectedAccessLevel,
                        ];
                      }
                      setUsersToAddByUserId(newRolesByUserIdToAdd);
                    }}
                    getOptionLabel={(role) => role.accessLevelName}
                  />
                  {usersToAddByUserId[user.id].roles.map((role) => {
                    if (role.accessLevelName === "") {
                      return null;
                    }
                    return (
                      <div
                        className={sprinkles({
                          paddingX: "sm",
                          marginTop: "sm",
                          fontSize: "textSm",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {role.accessLevelName}
                        <Icon
                          name="x"
                          size="xs"
                          onClick={() => {
                            const newUsersToAddByUserId = {
                              ...usersToAddByUserId,
                            };
                            newUsersToAddByUserId[
                              user.id
                            ].roles = newUsersToAddByUserId[
                              user.id
                            ].roles.filter(
                              (r) =>
                                r.accessLevelRemoteId !==
                                role.accessLevelRemoteId
                            );
                            setUsersToAddByUserId(newUsersToAddByUserId);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default ResourceAddUsersView;
