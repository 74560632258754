import { TagPreviewLargeFragment, useTagsQuery } from "api/generated/graphql";
import { Select } from "components/ui";
import { useState } from "react";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";

interface TagDropdownProps {
  value?: TagPreviewLargeFragment | undefined;
  onChange: (tag: TagPreviewLargeFragment | undefined) => void;
  placeholder?: string;
}
const PAGE_SIZE = 25;

const TagDropdown = (props: TagDropdownProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 200);
  let tagSearchCursor: string | null | undefined;

  const {
    data: tagSearchData,
    error: tagSearchError,
    fetchMore: tagSearchFetchMore,
  } = useTagsQuery({
    variables: {
      input: {
        searchQuery: debouncedSearchQuery,
        limit: PAGE_SIZE,
      },
    },
  });

  if (tagSearchError) {
    logError(tagSearchError, "failed to list tags");
  }

  const cursor = tagSearchData?.tags.cursor;

  const loadMoreRows = cursor
    ? async () => {
        await tagSearchFetchMore({
          variables: {
            input: {
              searchQuery: debouncedSearchQuery,
              limit: PAGE_SIZE,
              cursor: tagSearchCursor,
            },
          },
        });
      }
    : undefined;

  let tags: TagPreviewLargeFragment[] = [];
  switch (tagSearchData?.tags.__typename) {
    case "TagsResult":
      tags = tagSearchData.tags.tags;
      break;
    default:
      break;
  }

  const getTagLabel = (tag: TagPreviewLargeFragment) => {
    return tag.key + ":" + tag.value;
  };

  const onChange = (tag: TagPreviewLargeFragment | undefined) => {
    setSearchQuery("");
    props.onChange(tag);
  };

  return (
    <Select
      value={props.value}
      options={tags}
      getOptionLabel={(tag) => {
        return getTagLabel(tag);
      }}
      onChange={onChange}
      onInputChange={setSearchQuery}
      alwaysShowPlaceholder={true}
      placeholder={props.placeholder}
      onScrollToBottom={() => {
        if (loadMoreRows) loadMoreRows();
      }}
    />
  );
};

export default TagDropdown;
