import {
  ConnectionType,
  EntityType,
  SortDirection,
} from "api/generated/graphql";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import MuiVirtualTable, {
  Header,
} from "components/tables/material_table/MuiVirtualTable";
import { Banner, Modal } from "components/ui";
import { Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { plural } from "pluralize";
import React from "react";
import { Link } from "react-router-dom";
import { SortDirection as TableSortDirection } from "react-virtualized";
import { getResourceUrlNew } from "utils/common";
import * as styles from "views/access_reviews/settings/scope/AccessReviewItemPreviewModal.css";

interface AccessReviewItem {
  id: string;
  value: string;
  rendered: React.ReactElement;
  connection?: {
    name: string;
    id: string;
    connectionType: ConnectionType;
  };
  admin?: {
    id: string;
    name: string;
  };
}

interface AccessReviewItemPreviewModalProps {
  itemType: "resource" | "group" | "app";
  onClose: () => void;
  items: AccessReviewItem[];
  loadMoreRows: (cursor: string) => Promise<void>;
  onSortDirectionChange: (direction: SortDirection) => void;
  cursor?: string;
  loading: boolean;
}

interface TableRow {
  name: string;
  app: string;
  admin: string;
}

export const AccessReviewItemPreviewModal: React.FC<AccessReviewItemPreviewModalProps> = (
  props
) => {
  const pluralItemType = plural(props.itemType);
  const title = `Access Review ${_.capitalize(pluralItemType)}`;
  const headers: Header<TableRow>[] = [
    {
      id: "name",
      label: `${_.capitalize(props.itemType)}`,
      width: 300,
      minWidth: 250,
    },
  ];
  if (props.itemType !== "app") {
    headers.push({
      id: "app",
      label: `App`,
      width: 300,
      minWidth: 250,
      sortable: false,
    });
  }
  headers.push({
    id: "admin",
    label: `Admin`,
    width: 300,
    minWidth: 250,
    sortable: false,
  });

  const onSortChange = (dir: keyof typeof TableSortDirection) => {
    props.onSortDirectionChange(
      dir === TableSortDirection.ASC ? SortDirection.Asc : SortDirection.Desc
    );
  };

  const rows = props.items.map((x) => {
    return {
      id: x.id,
      data: {
        name: {
          value: x.value,
          element: x.rendered,
        },
        app: {
          value: x.connection?.name ?? "",
          element: x.connection ? (
            <ConnectionLabel
              text={x.connection.name}
              connectionId={x.connection.id}
              connectionType={x.connection.connectionType}
              target="_blank"
            />
          ) : (
            <></>
          ),
        },
        admin: {
          value: x.admin?.name ?? "",
          element: x.admin ? (
            <Link
              to={getResourceUrlNew({
                entityId: x.admin.id,
                entityType: EntityType.Owner,
              })}
              target="_blank"
            >
              {x.admin.name}
            </Link>
          ) : (
            <></>
          ),
        },
      },
    };
  });

  const loadMoreRows = () => {
    if (!props.cursor) return Promise.resolve();
    return props.loadMoreRows(props.cursor);
  };

  return (
    <Modal title={title} isOpen={true} onClose={props.onClose} maxWidth={false}>
      <Modal.Body>
        {!props.loading && rows.length === 0 ? (
          <Banner
            type="warning"
            message={`The access review will not include any ${pluralItemType}.`}
          />
        ) : (
          <div className={styles.table}>
            <MuiVirtualTable
              columns={headers}
              rows={rows}
              defaultSortBy={"name"}
              defaultSortDirection={TableSortDirection.ASC}
              onSortOptionsChanged={(_, dir) => onSortChange(dir)}
              loadMoreRows={loadMoreRows}
              allRowsLoaded={props.cursor === undefined}
            />
            {props.loading && <SelectorLoading />}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Close"
        onPrimaryButtonClick={props.onClose}
      />
    </Modal>
  );
};

const SelectorLoading = () => {
  return (
    <div className={styles.loading}>
      {_.range(12).map(() => (
        <div className={sprinkles({ paddingY: "sm", paddingX: "lg" })}>
          <Skeleton />
        </div>
      ))}
    </div>
  );
};
