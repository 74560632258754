import * as Sentry from "@sentry/react";
import { Extras, SeverityLevel } from "@sentry/types";
import {
  isAuthError,
  isNetworkFetchError,
  isUnexpectedHTMLError,
  isValidationError,
} from "api/ApiContext";

const SEVERITY_LEVEL_WARNING: SeverityLevel = "warning";

/**
 * Log an error to Sentry, unless `error` is of a special type that should be
 * logged as a warning.
 *
 * @param error The error to log.
 * @param prefix A prefix to add to the error message for easier identification.
 * @param context Extra context to add to the error, like debug IDs.
 */
export function logError(error: unknown, prefix?: string, context?: Extras) {
  const errorIsAuthError = isAuthError(error);
  const errorIsValidationError = isValidationError(error);
  const errorIsNetworkFetchError = isNetworkFetchError(error);
  const errorIsUnexpectedHTMLError = isUnexpectedHTMLError(error);

  if (prefix) {
    error = new Error(
      prefix + ": " + (error instanceof Error ? error.message : error)
    );
  }

  if (
    errorIsAuthError ||
    errorIsValidationError ||
    errorIsNetworkFetchError ||
    errorIsUnexpectedHTMLError
  ) {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.warn("SENTRY WARNING:", error);
    }
    Sentry.captureException(error, {
      level: SEVERITY_LEVEL_WARNING,
      extra: context,
      tags: {
        errorIsAuthError,
        errorIsValidationError,
        errorIsNetworkFetchError,
        errorIsUnexpectedHTMLError,
      },
    });
  } else {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.error("SENTRY ERROR:", error);
    }
    Sentry.captureException(error, { extra: context });
  }
}

/**
 * Log a warning to Sentry.
 *
 * @param error The error to log.
 * @param prefix A prefix to add to the error message for easier identification.
 * @param context Extra context to add to the error, like debug IDs.
 */
export function logWarning(error: Error, prefix?: string, context?: Extras) {
  if (prefix) {
    error = new Error(prefix + ": " + error.message);
  }

  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.warn("SENTRY logWarning:", error);
  }

  Sentry.captureException(error, {
    level: SEVERITY_LEVEL_WARNING,
    extra: context,
  });
}

export function logMessage(message: string) {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.info("SENTRY logMessage:", message);
  }

  Sentry.captureMessage(message);
}
