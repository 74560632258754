import {
  ConnectionPreviewLargeFragment,
  ConnectionType,
  ConnectionVisibilityGroupFragment,
  EntityType,
  ImportSetting,
  useConnectionPreviewQuery,
  useUpdateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ConnectionConfig } from "components/forms/common";
import ChildrenDefaultConfigTemplateRowV3 from "components/forms/rows/ChildrenDefaultConfigTemplateRowV3";
import ConnectionAutoImportGroupResourcesRow from "components/forms/rows/ConnectionAutoImportGroupResourcesRow";
import ConnectionImportNotificationRowV3 from "components/forms/rows/ConnectionImportNotificationRowV3";
import ConnectionImportSettingRow from "components/forms/rows/ConnectionImportSettingRow";
import ConnectionTicketProviderPropagationRow, {
  TicketProviderFields,
} from "components/forms/rows/ConnectionTicketProviderPropagationRow";
import ConnectionWebhookToggleRow from "components/forms/rows/ConnectionWebhookToggleRow";
import VisibilityRow from "components/forms/rows/VisibilityRow";
import {
  getConnectionConfigChangedFields,
  makeConfigForConnection,
  makeUpdateInputForConnection,
  validateConnectionConfig,
} from "components/forms/utils";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { useUnsavedChangesModal } from "components/modals/update/UnsavedChangesModal";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Banner, Divider, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { hasRemoteGroupResources } from "utils/directory/connections";
import { isNativeConnectionType } from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import {
  ForbiddenPage,
  NotFoundPage,
  UnexpectedErrorPage,
} from "views/error/ErrorCodePage";

import AppIcon from "./AppIcon";

type ConnectionPreview = ConnectionPreviewLargeFragment & {
  importVisibilityGroups: ConnectionVisibilityGroupFragment[];
} & {
  visibilityGroups: ConnectionVisibilityGroupFragment[];
};

const AppEditView = () => {
  const { connectionId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);

  const { data, error, loading } = useConnectionPreviewQuery({
    variables: {
      connectionId,
    },
  });
  let connection: ConnectionPreview | undefined;
  if (data?.connection.__typename === "ConnectionResult") {
    connection = data.connection.connection;
  }

  if (!authState.user?.isAdmin) {
    return <ForbiddenPage />;
  }
  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!connection) {
    return <NotFoundPage entity="App" />;
  }
  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  return <AppEdit connection={connection} />;
};

interface Props {
  connection: ConnectionPreview;
}

const AppEdit = ({ connection }: Props) => {
  const logEvent = useLogEvent();
  const { connectionId } = useParams<Record<string, string>>();
  const { displaySuccessToast } = useToast();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasConfigTemplates = useFeatureFlag(FeatureFlag.ConfigurationTemplates);
  const hasAutoImportConfigTemplate = useFeatureFlag(
    FeatureFlag.ConnectionAutoImportConfigTemplate
  );
  const hasWebhookToggleFF = useFeatureFlag(
    FeatureFlag.ConnectionWebhookToggle
  );

  const initialConfig = makeConfigForConnection(connection);
  const [config, setConfig] = useState<Partial<ConnectionConfig>>(
    initialConfig
  );

  const {
    handleClose,
    maybeRenderUnsavedChangesModal,
  } = useUnsavedChangesModal(`/apps/${connectionId}`);

  const [errors, setErrors] = useState<string[]>([]);

  const [updateConnection, { loading }] = useUpdateConnectionMutation();

  const handleChange = (key: keyof ConnectionConfig) => (
    val: ConnectionConfig[keyof ConnectionConfig]
  ) => {
    setConfig({
      ...config,
      [key]: val,
    });
  };

  const handleChangeShouldNotify = (val: boolean) => {
    let importNotification = {
      shouldNotify: val,
      recipientOwnerId: config.importNotification?.recipientOwnerId,
      recipientOwnerName: config.importNotification?.recipientOwnerName,
    };

    setConfig({
      ...config,
      importNotification: importNotification,
    });
  };

  const handleChangeImportRecipientOwner = (recipient?: {
    name: string;
    id: string;
  }) => {
    let importNotification = {
      shouldNotify: config.importNotification
        ? config.importNotification.shouldNotify
        : false,
      recipientOwnerId: recipient?.id,
      recipientOwnerName: recipient?.name,
    };

    setConfig({
      ...config,
      importNotification: importNotification,
    });
  };

  const handleChangeTicketProvider = ({
    ticketProviderEnabled,
    ticketProjectId,
    ticketProjectName,
    ticketProvider,
  }: TicketProviderFields) => {
    setConfig({
      ...config,
      ticketProviderEnabled,
      ticketProjectId,
      ticketProjectName,
      ticketProvider,
    });
  };

  const handleChangeOwner = (owner?: { name: string; id: string }) => {
    setConfig({
      ...config,
      adminOwnerId: owner?.id,
      adminOwnerName: owner?.name,
    });
  };

  const handleSave = async () => {
    if (connection === null || !initialConfig) {
      return null;
    }

    const errors = validateConnectionConfig(config);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    logEvent({
      name: "apps_app_edit_click",
      properties: {
        connectionType: connection.connectionType,
        visibilityChange:
          config.visibility !== connection.visibility ||
          !_.isEqual(
            config.visibilityGroupIds,
            connection.visibilityGroups.map((g) => g.visibilityGroupId)
          ),
      },
    });

    try {
      setErrors([]);

      const changedConfig = getConnectionConfigChangedFields(
        initialConfig,
        config
      );
      const inputFields = makeUpdateInputForConnection(
        changedConfig,
        connection
      );

      const { data } = await updateConnection({
        variables: {
          input: inputFields,
        },
        refetchQueries: [
          "ConnectionOverview",
          "AppDetailColumn",
          "ConnectionPreview",
        ],
      });

      switch (data?.updateConnection.__typename) {
        case "UpdateConnectionResult":
          setErrors([]);
          displaySuccessToast("Success: app settings updated");
          handleClose(false);
          break;
        case "UserFacingError":
        case "ConnectionNotFoundError":
        case "ConnectionBadMetadataError":
        case "ConnectionVisibilityGroupNotFoundError":
          setErrors([data.updateConnection.message]);
          logError(new Error(data.updateConnection.message));
          break;
        default:
          logError(new Error("Failed to update connection"));
          setErrors(["Failed to update connection."]);
      }
    } catch (err) {
      setErrors(["Failed to update connection."]);
    }
  };

  const title = (
    <>
      Edit:
      <div className={sprinkles({ display: "flex" })}>
        <AppIcon
          app={{
            __typename: "ConnectionApp",
            connectionType: connection.connectionType,
            connectionIconUrl: connection.iconUrl,
          }}
        />
      </div>
      {connection.name}
    </>
  );

  const importFormRows = [];
  if (!isNativeConnectionType(connection.connectionType)) {
    const awsMetadata =
      (connection.metadata?.__typename === "AWSSSOConnectionMetadata" &&
        connection.metadata) ||
      null;

    importFormRows.push(
      <ConnectionImportSettingRow
        key="importSetting"
        connectionType={connection.connectionType}
        mode="edit"
        importSetting={config.importSetting ?? ImportSetting.None}
        onChange={handleChange("importSetting")}
        awsIdentityCenterImportSetting={config.awsIdentityCenterImportSetting}
        onChangeAwsIdentityCenterImportSetting={handleChange(
          "awsIdentityCenterImportSetting"
        )}
        awsOrganizationSetImportSetting={config.awsOrganizationImportSetting}
        onChangeAwsOrganizationImportSetting={handleChange(
          "awsOrganizationImportSetting"
        )}
        hideAwsOrganizationImportSetting={
          awsMetadata !== null && !awsMetadata.awsOrganizationEnabled
        }
        hideAwsIdentityCenterImportSetting={
          awsMetadata !== null && !awsMetadata.awsSsoEnabled
        }
        isV3
      />
    );

    if (
      config.importSetting === ImportSetting.All ||
      config.importSetting === ImportSetting.Tagged
    ) {
      importFormRows.push(
        <ConnectionImportNotificationRowV3
          key="importNotification"
          mode="edit"
          shouldNotify={
            config.importNotification
              ? config.importNotification!.shouldNotify
              : false
          }
          recipientOwner={
            config.importNotification?.recipientOwnerId &&
            config.importNotification?.recipientOwnerName
              ? {
                  id: config.importNotification!.recipientOwnerId,
                  name: config.importNotification!.recipientOwnerName,
                }
              : config.adminOwnerId && config.adminOwnerName
              ? {
                  id: config.adminOwnerId,
                  name: config.adminOwnerName,
                }
              : undefined
          }
          onShouldNotifyChange={handleChangeShouldNotify}
          onRecipientOwnerChange={handleChangeImportRecipientOwner}
        />
      );
    }

    if (
      hasRemoteGroupResources(connection.connectionType) &&
      connection.importSetting !== ImportSetting.All
    ) {
      importFormRows.push(
        <ConnectionAutoImportGroupResourcesRow
          key="autoImportGroupResources"
          mode="edit"
          autoImportGroupResources={Boolean(config.autoImportGroupResources)}
          onChange={handleChange("autoImportGroupResources")}
          isV3
        />
      );
    }
  }
  let tooltipText =
    "Specifies the default visibility setting of items imported into this app.";
  importFormRows.push(
    <FormGroup label="Import visibility" infoTooltip={tooltipText}>
      <VisibilityRow
        entityType={EntityType.Connection}
        key="importVisibility"
        title="Import visibility"
        mode="edit"
        visibility={config.importVisibility}
        visibilityGroups={config.importVisibilityGroups ?? []}
        onChangeVisibilityAndGroups={(val: {
          visibility?: Visibility;
          groupIds?: string[];
        }) => {
          const { visibility, groupIds } = val;
          const visDict = visibility ? { importVisibility: visibility } : {};
          const visGroupsDict = groupIds
            ? { importVisibilityGroups: groupIds }
            : {};
          setConfig({
            ...config,
            ...visDict,
            ...visGroupsDict,
          });
        }}
        contentOnly
        isV3
      />
    </FormGroup>
  );
  if (hasConfigTemplates && hasAutoImportConfigTemplate) {
    importFormRows.push(
      <ChildrenDefaultConfigTemplateRowV3
        key="importedChildrenConfigurationTemplate"
        configurationTemplate={config.childrenDefaultConfigTemplate}
        onChange={handleChange("childrenDefaultConfigTemplate")}
      />
    );
  }

  const hasChanges = !_.isEqual(config, initialConfig);

  // Hide webhook toggle for non-native connections, unless already enabled.
  const showConnectionWebhookToggle =
    isNativeConnectionType(connection.connectionType) ||
    hasWebhookToggleFF ||
    config.webhookEnabled;
  const hasRequiredFields = config.name && config.name.trim().length > 0;
  return (
    <FullscreenView
      title={title}
      onCancel={() => handleClose(hasChanges)}
      onPrimaryButtonClick={handleSave}
      primaryButtonDisabled={!hasChanges || loading || !hasRequiredFields}
    >
      <FullscreenView.Sidebar>
        <FormGroup label="Name">
          <Input
            value={config.name}
            onChange={handleChange("name")}
            placeholder="Enter name"
          />
        </FormGroup>
        <FormGroup label="Description">
          <Input
            value={config.description}
            type="textarea"
            onChange={handleChange("description")}
            placeholder="Enter description"
          />
        </FormGroup>
        <FormGroup label="Admin">
          <OwnerDropdown
            selectedOwnerId={config.adminOwnerId}
            onSelectOwner={handleChangeOwner}
          />
        </FormGroup>
      </FullscreenView.Sidebar>
      <FullscreenView.Content>
        {errors.map((error) => (
          <Banner message={error} type="error" />
        ))}
        {hasAppLevelVisibility && (
          <>
            <div>
              <FormGroup label="Who can view this app">
                <VisibilityRow
                  key="visibility"
                  title="App visibility"
                  mode="edit"
                  entityType={EntityType.Connection}
                  visibility={config.visibility}
                  visibilityGroups={config.visibilityGroupIds ?? []}
                  onChangeVisibilityAndGroups={(val: {
                    visibility?: Visibility;
                    groupIds?: string[];
                  }) => {
                    const { visibility, groupIds } = val;
                    const visDict = visibility ? { visibility } : {};
                    const visGroupsDict = groupIds
                      ? { visibilityGroupIds: groupIds }
                      : {};
                    setConfig({
                      ...config,
                      ...visDict,
                      ...visGroupsDict,
                    });
                  }}
                  additionalWarning={
                    connection.connectionType ===
                      ConnectionType.OktaDirectory &&
                    config.visibility === Visibility.Team
                      ? `The visibility settings of this app do not impact the
                  visibility of Okta Apps or the groups associated with them. To
                  manage their visibility, please utilize the individual item
                  visibility settings. This specific Okta Directory App will remain
                  undiscoverable to members with access unless they possess an
                  Admin role or are part of a designated visibility group.`
                      : undefined
                  }
                  contentOnly
                  isV3
                />
                <Divider margin="lg" />
              </FormGroup>
            </div>
          </>
        )}
        {showConnectionWebhookToggle && (
          <FormGroup label="Sync Details">
            <ConnectionWebhookToggleRow
              mode="edit"
              connectionType={connection.connectionType}
              webhookEnabled={Boolean(config.webhookEnabled)}
              onChange={handleChange("webhookEnabled")}
            />
            {connection.connectionType == ConnectionType.Custom ? (
              <ConnectionTicketProviderPropagationRow
                mode="edit"
                ticketProviderEnabled={Boolean(config.ticketProviderEnabled)}
                ticketProjectId={config.ticketProjectId}
                ticketProjectName={config.ticketProjectName}
                ticketProvider={config.ticketProvider}
                onChange={handleChangeTicketProvider}
              />
            ) : null}
          </FormGroup>
        )}
        {importFormRows}
      </FullscreenView.Content>
      {maybeRenderUnsavedChangesModal()}
    </FullscreenView>
  );
};

export default AppEditView;
