import {
  EntityType,
  Maybe,
  RequestApprovalType,
  RequestFragment,
  RequestReviewerFragment,
  RequestStageFragment,
  RequestStatus,
  ReviewerAction,
  ReviewStageOperator,
} from "api/generated/graphql";
import clsx from "clsx";
import { getOperatorDisplayName } from "components/approvals_editor/utils";
import { ResourceLabel } from "components/label/Label";
import { Banner, Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import * as Icons from "react-feather";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import oldStyles from "views/requests/RequestReviewers.module.scss";

import * as styles from "./RequestReviewers.css";

type RequestReviewersProps = {
  request: RequestFragment;
};

enum StatusLabel {
  Approved = "Approved",
  Pending = "No Action",
  Denied = "Denied",
  Canceled = "Canceled",
}

interface Owner {
  id: string;
  name: string;
}

export const RequestReviewers = (props: RequestReviewersProps) => {
  // Show item headers if bulk request, otherwise show reviewers directly.
  let content;
  if (props.request.stages.length === 1) {
    const sortedStages = [...props.request.stages[0].stages];
    sortedStages.sort((a, b) => a.stage - b.stage);
    content = sortedStages.map((stage) => (
      <RequestReviewersStage stage={stage} key={stage.stage} />
    ));
  } else {
    content = (
      <>
        {props.request.stages.map((requestedItemStages) => (
          <RequestedItemReviewers
            requestedItemName={requestedItemStages.requestedItemName}
            requestedRoleName={
              requestedItemStages.requestedRoleName || undefined
            }
            stages={requestedItemStages.stages}
          />
        ))}
      </>
    );
  }

  return (
    <div className={oldStyles.container}>
      {props.request.reviewersError ? (
        <div className={sprinkles({ margin: "lg" })}>
          <Banner type="error" message={props.request.reviewersError} />
        </div>
      ) : null}
      {content}
    </div>
  );
};

interface RequestedItemReviewersProps {
  requestedItemName: string;
  requestedRoleName?: string;
  stages: RequestStageFragment[];
}

const RequestedItemReviewers = (props: RequestedItemReviewersProps) => {
  const { requestedItemName, requestedRoleName, stages } = props;

  const sortedStages = [...stages];
  sortedStages.sort((a, b) => a.stage - b.stage);

  const approved = sortedStages.every((stage) => {
    if (stage.operator === ReviewStageOperator.And) {
      return stage.reviewers.every(
        (reviewer) => reviewer.reviewerAction === ReviewerAction.Approved
      );
    } else {
      return stage.reviewers.some(
        (reviewer) => reviewer.reviewerAction === ReviewerAction.Approved
      );
    }
  });

  const [expanded, setExpanded] = useState(!approved);

  return (
    <div>
      <div
        className={styles.itemHeader}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <Icon name={expanded ? "chevron-up" : "chevron-down"} />
        {requestedItemName}
        {requestedRoleName ? ` (${requestedRoleName})` : ""}
        {approved && <Icon name="check-circle" color="green600" />}
      </div>
      {expanded &&
        sortedStages.map((stage) => (
          <RequestReviewersStage stage={stage} key={stage.stage} />
        ))}
    </div>
  );
};

type RequestReviewerStageProps = {
  stage: RequestStageFragment;
};

const RequestReviewersStage = (props: RequestReviewerStageProps) => {
  const reviewerOwners: RequestReviewerFragment[] = props.stage.reviewers;

  const {
    uniqueOwners,
    individuals,
    reviewersByOwnerId,
  } = getOwnersAndIndividuals(reviewerOwners);

  const statusByOwnerId: Record<string, RequestStatus> = {};
  reviewerOwners.forEach((reviewer) => {
    if (reviewer.owner) {
      statusByOwnerId[reviewer.owner.id] = getStatusLabelForOwnerId(
        reviewer.owner.id,
        reviewerOwners
      );
    }
  });

  const numReviewers = uniqueOwners.length + individuals.length;
  const sortedOwners = uniqueOwners.sort((a, b) => {
    return a.id.localeCompare(b.id);
  });

  return (
    <div className={oldStyles.container}>
      <div className={oldStyles.section}>
        <span
          className={sprinkles({ fontSize: "bodyLg", fontWeight: "semibold" })}
        >
          Stage {props.stage.stage}
        </span>
        {numReviewers > 1 ? (
          <span className={sprinkles({ marginLeft: "sm", color: "gray500" })}>
            ({getOperatorDisplayName(props.stage.operator)})
          </span>
        ) : null}
      </div>
      {sortedOwners.map(
        (uniqueOwner) =>
          reviewersByOwnerId[uniqueOwner.id].length > 0 && (
            <OwnerRequestReviewersSection
              reviewers={reviewersByOwnerId[uniqueOwner.id]}
              owner={uniqueOwner}
              ownerStatus={statusByOwnerId[uniqueOwner.id]}
            />
          )
      )}
      {individuals.length > 0 && (
        <IndividualsRequestReviewersSection individuals={individuals} />
      )}
    </div>
  );
};

type OwnerRequestReviewersSectionProps = {
  owner: Owner;
  ownerStatus: RequestStatus;
  reviewers: RequestReviewerFragment[];
};

const OwnerRequestReviewersSection = (
  props: OwnerRequestReviewersSectionProps
) => {
  const [showReviewersRow, setShowReviewersRow] = useState(true);

  return (
    <div className={oldStyles.section}>
      <OwnerRequestReviewersHeaders />
      <OwnerRequestReviewersRow
        owner={props.owner}
        ownerStatus={props.ownerStatus}
        showReviewersToggle={() => {
          setShowReviewersRow(!showReviewersRow);
        }}
        showReviewersTable={showReviewersRow}
      />
      <OwnerRequestReviewersIndividualsTable
        reviewers={props.reviewers}
        showReviewersTable={showReviewersRow}
      />
    </div>
  );
};

const OwnerRequestReviewersHeaders = () => {
  return (
    <div
      className={clsx(
        oldStyles.sectionHeaders,
        oldStyles.tr,
        oldStyles.headerText
      )}
    >
      <div className={oldStyles.td}>Owner</div>
      <div className={oldStyles.td}>Status</div>
    </div>
  );
};

type OwnerRequestReviewersRowProps = {
  owner: Owner;
  ownerStatus: RequestStatus;
  showReviewersToggle: () => void;
  showReviewersTable: boolean;
};

const OwnerRequestReviewersRow = (props: OwnerRequestReviewersRowProps) => {
  return (
    <div className={oldStyles.sectionContainer}>
      <div className={clsx(oldStyles.tr, oldStyles.reviewerRow)}>
        <div className={clsx(oldStyles.td, oldStyles.ownerName)}>
          <Link
            to={getResourceUrlNew({
              entityId: props.owner.id,
              entityType: EntityType.Owner,
            })}
          >
            {props.owner.name}
          </Link>
        </div>
        <div className={oldStyles.td}>
          <div className={oldStyles.td}>
            <RequestStatusLabel status={props.ownerStatus} />
          </div>
          <div
            className={clsx(oldStyles.expandReviewers, oldStyles.td)}
            onClick={props.showReviewersToggle}
          >
            <div className={oldStyles.chevron}>
              {props.showReviewersTable ? (
                <Icons.ChevronUp strokeWidth={3} size={17} />
              ) : (
                <Icons.ChevronDown strokeWidth={3} size={17} />
              )}
            </div>
            <div>View Reviewers</div>
          </div>
        </div>
      </div>
    </div>
  );
};

type OwnerRequestReviewersExpandableTableProps = {
  reviewers: RequestReviewerFragment[];
  showReviewersTable: boolean;
};

const OwnerRequestReviewersIndividualsTable = (
  props: OwnerRequestReviewersExpandableTableProps
) => {
  return (
    <div
      className={clsx({
        [oldStyles.sectionContainer]: true,
        [oldStyles.expandableTable]: true,
        [oldStyles.hidden]: !props.showReviewersTable,
      })}
    >
      <div className={clsx(oldStyles.tr, oldStyles.headerText)}>
        <div className={oldStyles.td}>Reviewers</div>
        <div className={oldStyles.td}>Status</div>
      </div>
      {props.reviewers.map((reviewer) => (
        <div
          className={clsx(oldStyles.tr, oldStyles.reviewerRow)}
          key={reviewer.userId}
        >
          <div className={oldStyles.td}>
            <ResourceLabel
              text={reviewer.user?.fullName}
              entityTypeNew={EntityType.User}
              entityId={reviewer.userId}
              avatar={reviewer.user?.avatarUrl}
            />
          </div>
          <div className={oldStyles.td}>
            <ReviewerActionLabel action={reviewer.reviewerAction} />
          </div>
        </div>
      ))}
    </div>
  );
};

type IndividualsRequestReviewersSectionProps = {
  individuals: RequestReviewerFragment[];
};

const IndividualsRequestReviewersSection = (
  props: IndividualsRequestReviewersSectionProps
) => {
  return (
    <div className={oldStyles.section}>
      <IndividualsRequestReviewersHeaders />
      <IndividualsRequestReviewersRow individuals={props.individuals} />
    </div>
  );
};

const IndividualsRequestReviewersHeaders = () => {
  return (
    <div
      className={clsx(
        oldStyles.sectionHeaders,
        oldStyles.tr,
        oldStyles.headerText
      )}
    >
      <div className={oldStyles.td}>Individuals</div>
      <div className={oldStyles.td}>Status</div>
    </div>
  );
};

type IndividualsRequestReviewersRowProps = {
  individuals: RequestReviewerFragment[];
};

const IndividualsRequestReviewersRow = (
  props: IndividualsRequestReviewersRowProps
) => {
  const sortedIndividuals = props.individuals.sort((a, b) =>
    a.userId.localeCompare(b.userId)
  );
  return (
    <div className={oldStyles.sectionContainer}>
      {sortedIndividuals.map((individual) => {
        let labelText = `${individual.user?.fullName}`;
        if (individual.reviewerType === RequestApprovalType.Manager) {
          labelText += " (manager)";
        } else if (
          individual.reviewerType === RequestApprovalType.SkipManager
        ) {
          labelText += " (skip-manager)";
        }

        return (
          <div
            className={clsx(oldStyles.tr, oldStyles.reviewerRow)}
            key={individual.userId}
          >
            <div className={oldStyles.td}>
              <ResourceLabel
                text={labelText}
                entityType={EntityTypeDeprecated.User}
                avatar={individual.user?.avatarUrl}
                entityId={individual.userId}
              />
            </div>
            <div className={oldStyles.td}>
              <ReviewerActionLabel action={individual.reviewerAction} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

type RequestStatusLabelProps = {
  status: RequestStatus;
};

const RequestStatusLabel = (props: RequestStatusLabelProps) => {
  return (
    <div className={oldStyles.statusLabel}>
      <div
        className={clsx({
          [oldStyles.status]: true,
          [oldStyles.statusPending]:
            props.status === RequestStatus.Pending ||
            props.status === RequestStatus.Canceled,
          [oldStyles.statusApproved]: props.status === RequestStatus.Approved,
          [oldStyles.statusDenied]: props.status === RequestStatus.Denied,
        })}
      >
        {props.status === RequestStatus.Denied && <hr />}
      </div>
      <div>{getStatusLabel(props.status)}</div>
    </div>
  );
};

type ReviewerActionLabelProps = {
  action?: Maybe<ReviewerAction>;
};

const ReviewerActionLabel = (props: ReviewerActionLabelProps) => {
  return (
    <div className={oldStyles.statusLabel}>
      <div
        className={clsx({
          [oldStyles.status]: true,
          [oldStyles.statusPending]: !props.action,
          [oldStyles.statusApproved]: props.action === ReviewerAction.Approved,
          [oldStyles.statusDenied]: props.action === ReviewerAction.Denied,
        })}
      >
        {props.action === ReviewerAction.Denied && <hr />}
      </div>
      <div>{getActionLabel(props.action)}</div>
    </div>
  );
};

const getStatusLabelForOwnerId = (
  ownerId: string,
  reviewers: RequestReviewerFragment[]
) => {
  let status = RequestStatus.Pending;
  for (let reviewer of reviewers) {
    if (reviewer.owner?.id === ownerId) {
      if (reviewer.reviewerAction === ReviewerAction.Denied) {
        status = RequestStatus.Denied;
        break;
      }
      if (reviewer.reviewerAction === ReviewerAction.Approved) {
        status = RequestStatus.Approved;
      }
    }
  }
  return status;
};

const getStatusLabel = (status: RequestStatus) => {
  switch (status) {
    case RequestStatus.Approved:
      return StatusLabel.Approved;
    case RequestStatus.Denied:
      return StatusLabel.Denied;
    case RequestStatus.Pending:
      return StatusLabel.Pending;
    case RequestStatus.Canceled:
      return StatusLabel.Canceled;
  }
};

const getActionLabel = (action?: Maybe<ReviewerAction>) => {
  switch (action) {
    case ReviewerAction.Approved:
      return StatusLabel.Approved;
    case ReviewerAction.Denied:
      return StatusLabel.Denied;
    default:
      return StatusLabel.Pending;
  }
};

export const getOwnersAndIndividuals = (
  reviewerOwners: RequestReviewerFragment[]
) => {
  let ownersByOwnerId: Record<string, Owner> = {};
  reviewerOwners.forEach((reviewerOwner) => {
    if (reviewerOwner.owner) {
      ownersByOwnerId[reviewerOwner.owner.id] = reviewerOwner.owner;
    }
  });
  let uniqueOwners: Owner[] = [];
  for (const [, value] of Object.entries(ownersByOwnerId)) {
    uniqueOwners.push(value);
  }

  let individuals: RequestReviewerFragment[] = [];

  let reviewersByOwnerId: Record<string, RequestReviewerFragment[]> = {};
  uniqueOwners.forEach((owner) => {
    reviewersByOwnerId[owner.id] = [];
  });

  reviewerOwners.forEach((reviewer) => {
    if (reviewer.reviewerType === RequestApprovalType.Manager) {
      individuals.push(reviewer);
    } else if (reviewer.reviewerType === RequestApprovalType.SkipManager) {
      individuals.push(reviewer);
    } else if (reviewer.owner && reviewersByOwnerId[reviewer.owner.id]) {
      reviewersByOwnerId[reviewer.owner.id].push(reviewer);
    }
  });

  return { uniqueOwners, individuals, reviewersByOwnerId };
};
