import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  GroupPreviewLargeFragment,
  useRemoveGroupUsersMutation,
  UserGroupFragment,
  UserPreviewSmallFragment,
  useUserGroupsQuery,
} from "api/generated/graphql";
import { ConditionalEditor } from "components/entity_viewer/editor/ConditionalEditor";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { ResourceLabel } from "components/label/Label";
import SelectItemsModal, {
  SelectType,
} from "components/modals/update/SelectItemsModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import React, { useState } from "react";
import { notEmpty } from "utils/common";
import { logError } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { groupUserHasDirectAccess } from "views/Common";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import UserGroupsTable from "views/groups/UserGroupsTable";
import ViewSkeleton from "views/loading/ViewSkeleton";
import UserAddGroupsModal from "views/users/UserAddGroupsModal";

import { Checkbox } from "../../../../components/ui";

type UserGroupsRowProps = {
  userId: string;
};

export const UserGroupsRow = (props: UserGroupsRowProps) => {
  const [showGroupsAddModal, setShowGroupsAddModal] = useState(false);
  const [showGroupsRemoveModal, setShowGroupsRemoveModal] = useState(false);
  const [showUnmanagedGroups, setShowUnmanagedGroups] = useState(false);

  const { data, previousData, loading, error } = useUserGroupsQuery({
    variables: {
      id: props.userId,
    },
  });

  if (loading && !data && !previousData) {
    return <ViewSkeleton />;
  }

  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  let user: UserPreviewSmallFragment | undefined;
  let userGroups: UserGroupFragment[] = [];
  if (previousData?.user.__typename === "UserResult") {
    user = previousData.user.user;
    userGroups = previousData.user.user.userGroups;
  }
  if (data?.user.__typename === "UserResult") {
    user = data.user.user;
    userGroups = data.user.user.userGroups;
  }

  if (!user) {
    return <NotFoundPage entity="User" />;
  }

  const editor = (
    <ConditionalEditor
      menuOptions={[
        {
          label: "Add",
          handler: () => {
            setShowGroupsAddModal(true);
          },
        },
        {
          label: "Remove",
          handler: () => {
            setShowGroupsRemoveModal(true);
          },
        },
      ]}
      disabledInReadOnlyMode
    />
  );

  const userGroupsWithDirectAccess = userGroups.filter((userGroup) =>
    groupUserHasDirectAccess(userGroup)
  );

  const groupsWithDirectAccess = userGroupsWithDirectAccess
    .map((userGroup) => {
      return userGroup.group;
    })
    .filter(notEmpty)
    .filter((group) => group.isManaged);

  const addUserToGroupsModal = (
    <UserAddGroupsModal
      user={user}
      userGroups={userGroups}
      onClose={() => {
        setShowGroupsAddModal(false);
      }}
    />
  );

  const removeUserFromGroupsModal = (
    <RemoveUserFromGroupsModal
      user={user}
      groups={groupsWithDirectAccess}
      showModal={showGroupsRemoveModal}
      setShowModal={setShowGroupsRemoveModal}
    />
  );

  const showUnmanagedGroupsCheckbox = (
    <Checkbox
      size="sm"
      label="Show unmanaged groups"
      checked={showUnmanagedGroups}
      onChange={(value) => {
        setShowUnmanagedGroups(value);
      }}
    />
  );

  return (
    <EntityViewerRow
      title={"Groups"}
      content={
        <EmptyStateContentWrapper
          content={
            <UserGroupsTable
              user={user}
              userGroups={userGroups}
              allRowsLoaded
              showUnmanagedGroups={showUnmanagedGroups}
            />
          }
          entityType={EntityType.Group}
          title={`No groups for user`}
          subtitle={`Grant user access to groups to populate the table`}
          buttonTitle={`Add user to groups`}
          isEmpty={userGroups.length === 0}
          onClickHandler={() => {
            setShowGroupsAddModal(true);
          }}
        />
      }
      editor={editor}
      checkbox={showUnmanagedGroupsCheckbox}
      modals={
        <>
          {showGroupsAddModal && addUserToGroupsModal}
          {showGroupsRemoveModal && removeUserFromGroupsModal}
        </>
      }
      isTable={true}
    />
  );
};

type RemoveUserFromGroupsModalProps = {
  user: UserPreviewSmallFragment;
  groups: GroupPreviewLargeFragment[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const RemoveUserFromGroupsModal = (props: RemoveUserFromGroupsModalProps) => {
  const [idsToRemove, setIdsToRemove] = useState<string[]>([]);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [removeGroupUsers, { loading }] = useRemoveGroupUsersMutation();
  const startPushTaskPoll = usePushTaskLoader();

  const modalReset = () => {
    props.setShowModal(false);
    setIdsToRemove([]);
    setErrorMessage(undefined);
    setSearchQuery("");
  };

  return (
    <SelectItemsModal
      key={"groups_remove"}
      title={"Remove user from groups"}
      selectType={SelectType.Remove}
      itemName={"group"}
      entryInfos={props.groups
        .filter((group) =>
          group.name.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        )
        .map((group) => {
          return {
            entityId: { entityId: group.id, entityType: EntityType.User },
            label: (
              <ResourceLabel
                entityTypeNew={EntityType.Group}
                text={group.name}
              />
            ),
            isBold: false,
            isBreadcrumb: false,
          };
        })}
      idsToUpdate={idsToRemove}
      setIdsToUpdate={setIdsToRemove}
      isModalOpen={props.showModal}
      onClose={modalReset}
      onSubmit={async () => {
        try {
          const { data } = await removeGroupUsers({
            variables: {
              input: {
                groupUsers: idsToRemove.map((groupId) => {
                  return {
                    userId: props.user.id,
                    groupId: groupId,
                  };
                }),
              },
            },
          });
          if (data) {
            switch (data.removeGroupUsers.__typename) {
              case "RemoveGroupUsersResult":
                startPushTaskPoll(data.removeGroupUsers.taskId, {
                  refetchOnComplete: { userId: props.user.id },
                });
                modalReset();
                break;
              default:
                logError(new Error(`failed to remove user from groups`));
                setErrorMessage("Error: failed to remove user from groups");
            }
          }
        } catch (error) {
          logError(error, "failed to remove user from groups");
          setErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to remove user from groups",
              error
            )
          );
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
      submitDisabled={idsToRemove.length === 0}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
};

export default UserGroupsRow;
