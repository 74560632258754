import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeaderV3";
import { TabsV3 } from "components/ui";
import { useHistory } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import ConfigurationTemplatesView from "./ConfigurationTemplatesView";
import RequestTemplatesView from "./RequestTemplatesView";
import TemplateMappingsView from "./TemplateMappingsView";

enum SelectedTab {
  Requests = "requests",
  Configurations = "configurations",
  Mapping = "mapping",
}

const TemplatesColumnV3 = () => {
  const history = useHistory();

  const hasConfigurationTemplates = useFeatureFlag(
    FeatureFlag.ConfigurationTemplates
  );

  let selectedTab: SelectedTab = SelectedTab.Requests;
  if (history.location.pathname.endsWith("/templates/configurations")) {
    selectedTab = SelectedTab.Configurations;
  } else if (history.location.pathname.endsWith("/configurations/mapping")) {
    selectedTab = SelectedTab.Mapping;
  }

  return (
    <>
      <Column isContent maxWidth="none">
        <ColumnHeader
          title="Templates"
          icon={{ type: "name", icon: "template" }}
          includeDefaultActions
        />
        <TabsV3
          tabInfos={[
            {
              title: "Custom Access Requests",
              isSelected: selectedTab === SelectedTab.Requests,
              onClick: () => history.push("/templates/requests"),
            },
            ...(hasConfigurationTemplates
              ? [
                  {
                    title: "Configuration Templates",
                    isSelected: selectedTab === SelectedTab.Configurations,
                    onClick: () => history.push("/templates/configurations"),
                  },
                ]
              : []),
            {
              title: "Template Mapping & Priority",
              isSelected: selectedTab === SelectedTab.Mapping,
              onClick: () => history.push("/templates/configurations/mapping"),
            },
          ]}
        />
        {selectedTab === SelectedTab.Requests && <RequestTemplatesView />}
        {selectedTab === SelectedTab.Configurations && (
          <ConfigurationTemplatesView />
        )}
        {selectedTab === SelectedTab.Mapping && <TemplateMappingsView />}
      </Column>
    </>
  );
};

export default TemplatesColumnV3;
