import { AccessOption } from "api/generated/graphql";
import LayoutToggle from "components/enduser_exp/LayoutToggle";
import { Input, Select, TabsV3 } from "components/ui";
import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import TableHeader from "components/ui/table/TableHeader";
import _ from "lodash";
import useLogEvent from "utils/analytics";
import { useAccessRequestTransition } from "views/access_request/AccessRequestContext";
import { AppItemRow, BundleItemRow } from "views/apps/enduser_exp/types";
import { formatRequestDataForItems } from "views/apps/enduser_exp/utils";
import { useAccessOptionKey } from "views/apps/utils";

import * as tableControlsStyles from "./TableControls.css";

type TableControlsProps<T> = {
  items: AppItemRow[] | BundleItemRow[];
  totalItemsCount: number;
  tabInfos: PropsFor<typeof TabsV3>["tabInfos"];
  searchQuery?: string;
  setSearchQuery: (query: string) => void;
  sortBy?: T;
  setSortBy: (sortValue?: T) => void;
  showSortBy?: boolean;
  sortByOptions: { label: string; value: T }[];
  disableLayoutToggle?: boolean;
  showAccessOptionToggle?: boolean;
  appId?: string;
};

const TableControls = <T,>(props: TableControlsProps<T>) => {
  const transitionToAccessRequest = useAccessRequestTransition();
  const [accessOptionKey, setAccessOptionKey] = useAccessOptionKey();
  const logEvent = useLogEvent();

  const handleChangeAccessMode = (option: AccessOption) => {
    logEvent({
      name: "apps_access_mode_change",
      properties: {
        access_mode: option,
      },
    });
    setAccessOptionKey(option);
  };

  const bulkRightActions: PropsFor<typeof TableHeader>["bulkRightActions"] = [
    {
      label: "Request",
      type: "main",
      onClick: () =>
        transitionToAccessRequest({
          ...formatRequestDataForItems(props.items),
          appId: props.appId,
        }),
    },
  ];

  return (
    <>
      <div className={tableControlsStyles.controlContainer}>
        <div className={tableControlsStyles.searchInput}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            value={props.searchQuery ?? ""}
            onChange={props.setSearchQuery}
            placeholder="Filter by name"
          />
        </div>
        {props.showAccessOptionToggle && (
          <ButtonGroup
            buttons={[
              {
                label: "All",
                onClick: () => handleChangeAccessMode(AccessOption.All),
                selected: accessOptionKey === AccessOption.All,
              },
              {
                label: "My Access",
                onClick: () => handleChangeAccessMode(AccessOption.Mine),
                selected: accessOptionKey === AccessOption.Mine,
              },
            ]}
          />
        )}
        <div className={tableControlsStyles.displayOptions}>
          {props.showSortBy && (
            <div className={tableControlsStyles.sortBySelect}>
              <Select
                value={props.sortByOptions.find((option) =>
                  _.isEqual(option.value, props.sortBy)
                )}
                searchable={false}
                options={props.sortByOptions}
                getOptionLabel={(option) => option.label}
                onChange={(option) => {
                  if (option) props.setSortBy(option.value);
                }}
                size="sm"
              />
            </div>
          )}
          <LayoutToggle disableLayoutToggle={props.disableLayoutToggle} />
        </div>
      </div>
      <div className={tableControlsStyles.tabAndBulkContainer}>
        {props.items.length > 0 ? (
          <div className={tableControlsStyles.tableHeader}>
            <TableHeader
              entityName={"Items"}
              totalNumRows={props.totalItemsCount}
              selectedNumRows={props.items.length}
              bulkRightActions={bulkRightActions}
              size="sm"
            />
          </div>
        ) : (
          <div className={tableControlsStyles.tabs}>
            <TabsV3
              tabInfos={props.tabInfos}
              tabType="round"
              tabStyle={{ wrap: false, fillWidth: false }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TableControls;
