import { NetworkStatus } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import { openInNewTab } from "api/common/common";
import {
  AwsIamFederatedRdsSession,
  ResourceAccessLevel,
  ResourceMetadataFragment,
  ServiceType,
  SessionPreviewFragment,
  useSessionsQuery,
  VaultMongoAtlasSessionFragment,
  VaultMongoSession,
  VaultMySqlMariaDbSessionFragment,
  VaultPostgresSessionFragment,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import styles from "components/modals/SessionDetailsModal.module.scss";
import { Button, Modal } from "components/ui";
import FileSaver from "file-saver";
import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { CheckCircle, ChevronDown, ChevronUp, Copy } from "react-feather";
import { Link } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { DEFAULT_AUTHENTICATION_DB_NAME } from "utils/constants";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";

import { serviceTypeInfoByType } from "../label/ServiceTypeLabel";

type SessionDetailsModalProps = {
  entityType: EntityTypeDeprecated;
  resource: {
    id: string;
    remoteId: string;
    serviceType: ServiceType;
    requireMfaToConnect: boolean;
    metadata?: ResourceMetadataFragment | null;
  };
  session: SessionPreviewFragment;
  serviceName?: string;
  onClose: () => void;
  isLoading?: boolean;
  selectedRole: ResourceAccessLevel | null;
};

export const SessionDetailsModal = (props: SessionDetailsModalProps) => {
  const { onClose } = props;
  const { networkStatus, data, error: getSessionError } = useSessionsQuery({
    variables: {
      input: {
        resourceId: props.resource.id,
      },
    },
    pollInterval: 1000 * 60 * 10,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  let modalTitle;
  let modalContent;
  let footerLabel;

  const sessionExpired = moment(props.session.endTime).isBefore(moment());
  useEffect(() => {
    if (sessionExpired) onClose();
  }, [sessionExpired, onClose]);

  if (props.isLoading || networkStatus === NetworkStatus.loading) {
    // Loading
    modalTitle = "Loading...";
    modalContent = (
      <>
        <p style={{ textAlign: "center" }}>Preparing your session...</p>
        <LinearProgress />
      </>
    );
    footerLabel = "Cancel";
  } else {
    let session: SessionPreviewFragment | undefined;
    switch (data?.sessions.__typename) {
      case "SessionsResult":
        session = data.sessions.sessions.find(
          (sess) => sess.id === props.session.id
        );
    }

    let error: Error | undefined = getSessionError;
    if (!error && session === undefined) {
      if (!sessionExpired) {
        error = new Error("Session is unexpectedly undefined.");
      }
    }
    if (error) {
      logError(error, `failed to retrieve resource session`);
      modalTitle = "Error";
      modalContent = (
        <ModalErrorMessage
          errorMessage={
            "An error occurred while loading your session, please try again later."
          }
        />
      );
      footerLabel = "Close";
    } else if (session !== undefined) {
      let result = renderModalContent(
        props.entityType,
        session!,
        props.resource,
        props.selectedRole
      );
      modalTitle = result.title;
      modalContent = result.content;
      footerLabel = "Okay, understood";
    } else {
      return null;
    }
  }

  return (
    <Modal title={modalTitle} isOpen={true} onClose={onClose}>
      <Modal.Body>{modalContent}</Modal.Body>
      <Modal.Footer
        primaryButtonLabel={footerLabel}
        onPrimaryButtonClick={onClose}
      />
    </Modal>
  );
};

type SessionableResource = {
  id: string;
  remoteId: string;
  serviceType: ServiceType;
  requireMfaToConnect: boolean;
  metadata?: ResourceMetadataFragment | null;
};

export function renderModalContent(
  entityType: EntityTypeDeprecated,
  session: SessionPreviewFragment,
  resource: SessionableResource,
  selectedRole: ResourceAccessLevel | null
) {
  let title = "";
  let content = <div />;

  function appendSessionIdIfNeeded(cliString: string): string {
    if (resource.requireMfaToConnect) {
      return cliString + ` \\\n  --sessionId ${session.id}`;
    }
    return cliString;
  }

  switch (entityType) {
    case EntityTypeDeprecated.Resource:
      if (session.metadata.__typename) {
        switch (session.metadata.__typename) {
          case "AwsIamFederatedRoleSession": {
            title = "AWS IAM Role Session";
            const opalCliString = appendSessionIdIfNeeded(
              `opal iam-roles:start \\\n  --id ${resource.id}`
            );
            content = (
              <div>
                <AwsFederatedSessionSharedContent
                  opalCliString={opalCliString}
                  {...session.metadata}
                />
                <div>
                  <p>
                    This session is configured to use the following IAM role
                    only:
                  </p>
                  {resource.metadata &&
                    resource.metadata.__typename === "AwsRoleMetadata" && (
                      <FieldLabel
                        value={resource.metadata.arn}
                        isDataPrivate={true}
                      />
                    )}
                </div>
              </div>
            );
            break;
          }
          case "AwsIamFederatedSSMSession": {
            title = "AWS EC2 SSH Session";
            const opalCliString = appendSessionIdIfNeeded(
              `opal ssh:start \\\n  --id ${resource.id}`
            );
            content = (
              <div>
                <AwsFederatedSessionSharedContent
                  opalCliString={opalCliString}
                  {...session.metadata}
                />
                <p>
                  You can also SSH using{" "}
                  <a
                    className={styles.link}
                    href={
                      "https://docs.aws.amazon.com/systems-manager/latest/userguide/session-manager-working-with-install-plugin.html#install-plugin-configure-logs"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Amazon Secure Session Manager (SSM)
                  </a>{" "}
                  with the following command:
                </p>
                <FieldLabel
                  value={`aws ssm start-session \\\n    --target ${session.metadata.ec2InstanceId} \\\n    --region ${session.metadata.ec2Region}`}
                  isDataPrivate={true}
                />
              </div>
            );
            break;
          }
          case "AwsIamFederatedRdsSession": {
            title = "AWS RDS Database";
            const sessionId = resource.requireMfaToConnect ? session.id : null;
            content = (
              <div>
                <p>
                  You're connected to the database instance using RDS IAM
                  database authentication.
                </p>
                <p className={styles.modalWarningText}>
                  Please note that this is a one-time credential. Your
                  connection with the database can last up to 12 hours, provided
                  it is actively maintained. If the connection is severed after
                  15 minutes, a new set of credentials will need to be reissued.
                </p>
                <RdsSessionBox
                  awsSessionMetadata={session.metadata}
                  resourceId={resource.id}
                  selectedRole={selectedRole}
                  sessionId={sessionId}
                />
              </div>
            );
            break;
          }
          case "AwsIamFederatedEksSession": {
            title = "AWS EKS Cluster Role";
            const opalCliString = appendSessionIdIfNeeded(
              `opal kube-roles:start \\\n  --id ${resource.id} \\\n  --accessLevelRemoteId "${selectedRole?.accessLevelRemoteId}"`
            );
            content = (
              <div>
                <AwsFederatedSessionSharedContent
                  opalCliString={opalCliString}
                  {...session.metadata}
                />
                <p>Now update your local Kube config using the AWS CLI:</p>
                <FieldLabel
                  value={`aws eks update-kubeconfig \\\n    --name ${session.metadata.clusterName} \\\n    --region ${session.metadata.clusterRegion}`}
                  isDataPrivate={true}
                />
                <p>
                  You should now be able to use <span>kubectl</span> to manage
                  the cluster!
                </p>
              </div>
            );
            break;
          }
          case "VaultMongoSession": {
            title = "MongoDB Database";
            content = (
              <div>
                <p>We have connected you to the database instance.</p>
                <MongoSessionBox
                  mongoSessionMetadata={session.metadata}
                  resourceId={resource.id}
                />
              </div>
            );
            break;
          }
          case "VaultMongoAtlasSession": {
            title = "MongoDB Atlas Database";
            content = (
              <div>
                <p>You've been connected to the database instance.</p>
                <MongoAtlasSessionBox
                  mongoAtlasSessionMetadata={session.metadata}
                  resourceId={resource.id}
                />
              </div>
            );
            break;
          }
          case "VaultMySQLMariaDBSession": {
            title = serviceTypeInfoByType[resource.serviceType].name;
            content = (
              <div>
                <p>We have connected you to the database instance.</p>
                <MySqlMariaDbSessionBox
                  sessionMetadata={session.metadata}
                  resourceId={resource.id}
                  serviceType={resource.serviceType}
                />
              </div>
            );
            break;
          }
          case "VaultPostgresSession": {
            title = serviceTypeInfoByType[resource.serviceType].name;
            content = (
              <div>
                <p>We have connected you to the database instance.</p>
                <PostgresSessionBox
                  sessionMetadata={session.metadata}
                  resourceId={resource.id}
                  remoteId={resource.remoteId}
                />
              </div>
            );
            break;
          }
          case "OpalImpersonationSession": {
            title = "Global Impersonation";
            content = (
              <div>
                <p>
                  You can now navigate to app.opal.dev and select this active
                  global impersonation session from the "Opal Global User
                  Impersonation role" resource:{" "}
                  <a
                    className={styles.link}
                    href={
                      "https://app.opal.dev/resources/93e506b1-4c5a-437d-8f0f-bd8b8811e1b2"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    link to resource
                  </a>{" "}
                </p>
              </div>
            );
          }
        }
      }
      break;
    case EntityTypeDeprecated.Group:
    case EntityTypeDeprecated.Team:
      title = "Group connection";
      content = (
        <div>
          <p className={styles.description}>
            Changes have successfully been propagated to all relevant services
            within this group. You may now access each resource within this
            group.
          </p>
        </div>
      );
      break;
  }

  return {
    title,
    content,
  };
}

type AwsFederatedSessionSharedContentProps = {
  opalCliString: string;
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  awsSessionToken: string;
  awsLoginUrl?: string;
};

const AwsFederatedSessionSharedContent = (
  props: AwsFederatedSessionSharedContentProps
) => {
  const awsSessionString =
    `export AWS_ACCESS_KEY_ID=${props.awsAccessKeyId}\n` +
    `export AWS_SECRET_ACCESS_KEY=${props.awsSecretAccessKey}\n` +
    `export AWS_SESSION_TOKEN=${props.awsSessionToken}`;

  return (
    <div>
      {props.awsLoginUrl ? (
        <div className={styles.awsUrlContainer}>
          <div>You’ve been granted access to a federated AWS session!</div>
          <Button
            label="Access AWS Console"
            type="primary"
            onClick={() => {
              if (props.awsLoginUrl) {
                openInNewTab(props.awsLoginUrl);
              }
            }}
          />
        </div>
      ) : null}
      <p>To launch a session, run the following command:</p>
      <FieldLabel
        value={props.opalCliString}
        isDataPrivate={true}
        includeCLIDocsLink={true}
      />
      <p>
        Alternatively, you can manually run the following command in your
        terminal:
      </p>
      <FieldLabel value={awsSessionString} isDataPrivate={true} />
    </div>
  );
};

type RdsSessionBoxProps = {
  awsSessionMetadata: AwsIamFederatedRdsSession;
  resourceId: string;
  selectedRole: ResourceAccessLevel | null;
  sessionId: string | null;
};

const RdsSessionBox = (props: RdsSessionBoxProps): ReactElement => {
  const { awsSessionMetadata } = props;

  let dbUrl: string;
  let instructions = (
    <>
      <p>Error: Unsupported database. Please contact support.</p>
    </>
  );

  switch (awsSessionMetadata.dbEngine) {
    case "postgres": {
      let opalCliString = `opal postgres-instances:start \\\n  --id ${props.resourceId} \\\n  --accessLevelRemoteId "${props.selectedRole?.accessLevelRemoteId}"`;
      if (props.sessionId) {
        opalCliString += ` \\\n  --sessionId ${props.sessionId}`;
      }
      dbUrl = `postgresql://${awsSessionMetadata.dbUser}:${encodeURIComponent(
        awsSessionMetadata.dbPassword
      )}@${awsSessionMetadata.dbHostname}:${awsSessionMetadata.dbPort}/${
        awsSessionMetadata.dbName
      }`;

      instructions = (
        <>
          <p>Run the following command in your terminal:</p>
          <FieldLabel
            value={opalCliString}
            isDataPrivate={true}
            includeCLIDocsLink={true}
          />
          <p className={styles.label}>
            <b>or</b>
          </p>
          <p className={styles.label}>Copy the connection URL here:</p>
          <FieldLabel value={`psql ${dbUrl}`} isDataPrivate={true} />
          <p className={styles.label}>
            <b>or</b>
          </p>
          <p className={styles.label}>
            If you are using a 3rd party Postgres viewer, like Postico...
            <Button
              label="Open App"
              type="primary"
              onClick={() => {
                openInNewTab(dbUrl);
              }}
            />
          </p>
        </>
      );
      break;
    }

    case "mysql":
      dbUrl = `jdbc:mysql://${awsSessionMetadata.dbUser}:${encodeURIComponent(
        awsSessionMetadata.dbPassword
      )}@${awsSessionMetadata.dbHostname}:${awsSessionMetadata.dbPort}/${
        awsSessionMetadata.dbName
      }`;
      instructions = (
        <>
          <FieldLabel
            label="MySQL Command"
            value={`mysql --host=${awsSessionMetadata.dbHostname} --port=${awsSessionMetadata.dbPort} --enable-cleartext-plugin --ssl-ca=./rds-combined-ca-bundle.pem --ssl-mode=VERIFY_IDENTITY --user=${awsSessionMetadata.dbUser} --password="${awsSessionMetadata.dbPassword}" --database=${awsSessionMetadata.dbName}`}
            isDataPrivate={true}
          />

          <FieldLabel label="JDBC URL" value={dbUrl} isDataPrivate={true} />

          <p>
            To run the above commands, please ensure you've downloaded the{" "}
            <a
              className={styles.link}
              href={
                "https://s3.amazonaws.com/rds-downloads/rds-combined-ca-bundle.pem"
              }
            >
              Amazon RDS Certificate bundle
            </a>
            .
          </p>

          <p>
            Note: Due to RDS limitations, you need to enable the CLEARTEXT
            plugin.
          </p>
        </>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <div className={styles.credContainer}>
        <ModalDetailPanel
          summary={"Automatic instructions"}
          openByDefault={true}
        >
          {instructions}
        </ModalDetailPanel>
        <ModalDetailPanel summary={"Manual instructions"} openByDefault={true}>
          <>
            <FieldLabel
              label="Username"
              value={awsSessionMetadata.dbUser}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Password"
              value={awsSessionMetadata.dbPassword}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Database name"
              value={awsSessionMetadata.dbName}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Hostname"
              value={awsSessionMetadata.dbHostname}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Port"
              value={"" + awsSessionMetadata.dbPort}
              isDataPrivate={true}
            />
          </>
        </ModalDetailPanel>
      </div>
    </>
  );
};

type MongoSessionBoxProps = {
  mongoSessionMetadata: VaultMongoSession;
  resourceId: string;
};

const MongoSessionBox = (props: MongoSessionBoxProps): ReactElement => {
  const { mongoSessionMetadata } = props;

  let authenticationDbName = mongoSessionMetadata.authenticationDbName;
  if (authenticationDbName === "") {
    authenticationDbName = DEFAULT_AUTHENTICATION_DB_NAME;
  }

  let mongoCmd = `mongosh --host ${mongoSessionMetadata.dbHostname}:${mongoSessionMetadata.dbPort} --username ${mongoSessionMetadata.dbUser} -p \\"'${mongoSessionMetadata.dbPassword}'\\" --authenticationDatabase ${authenticationDbName}`;
  if (mongoSessionMetadata.tlsConfig.tlsMode) {
    mongoCmd += ` --tls`;
  }

  const tlsCaCertContent = mongoSessionMetadata.tlsConfig.tlsCaCertContent;
  if (tlsCaCertContent) {
    mongoCmd += ` --tlsCAFile ./tls-ca-cert.pem`;
  }

  const instructions = (
    <>
      {tlsCaCertContent && (
        <p>
          You will first need to download your{" "}
          <Link
            className={styles.link}
            onClick={(e) => {
              e.preventDefault();

              try {
                const blob = new Blob([atob(tlsCaCertContent)], {
                  type: "text/plain;charset=utf-8",
                });
                FileSaver.saveAs(blob, "tls-ca-cert.pem");
              } catch (error) {
                logError(error, `failed to parse and save cert content}`);
              }
            }}
            to={""}
          >
            TLS CA Certificate
          </Link>
          .
        </p>
      )}
      <FieldLabel
        label="MongoDB Command"
        value={mongoCmd}
        isDataPrivate={true}
      />
    </>
  );

  return (
    <>
      <div className={styles.credContainer}>
        <ModalDetailPanel
          summary={"Automatic instructions"}
          openByDefault={true}
        >
          {instructions}
        </ModalDetailPanel>
        <ModalDetailPanel summary={"Manual instructions"} openByDefault={true}>
          <>
            <FieldLabel
              label="Username"
              value={mongoSessionMetadata.dbUser}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Password"
              value={mongoSessionMetadata.dbPassword}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Hostname"
              value={mongoSessionMetadata.dbHostname}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Port"
              value={"" + mongoSessionMetadata.dbPort}
              isDataPrivate={true}
            />
          </>
        </ModalDetailPanel>
      </div>
    </>
  );
};

type MongoAtlasSessionBoxProps = {
  mongoAtlasSessionMetadata: VaultMongoAtlasSessionFragment;
  resourceId: string;
};

const MongoAtlasSessionBox = (
  props: MongoAtlasSessionBoxProps
): ReactElement => {
  const { mongoAtlasSessionMetadata } = props;

  let mongoAtlasCmd = `mongosh "${mongoAtlasSessionMetadata.dbHostname}/${mongoAtlasSessionMetadata.initDbName}" --username ${mongoAtlasSessionMetadata.dbUser} -p \\"'${mongoAtlasSessionMetadata.dbPassword}'\\"`;

  const instructions = (
    <>
      <FieldLabel
        label="MongoDB Atlas Command"
        value={mongoAtlasCmd}
        isDataPrivate={true}
      />
    </>
  );

  return (
    <>
      <div className={styles.credContainer}>
        <ModalDetailPanel
          summary={"Automatic instructions"}
          openByDefault={true}
        >
          {instructions}
        </ModalDetailPanel>
        <ModalDetailPanel summary={"Manual instructions"} openByDefault={true}>
          <>
            <FieldLabel
              label="Username"
              value={mongoAtlasSessionMetadata.dbUser}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Password"
              value={mongoAtlasSessionMetadata.dbPassword}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Hostname with Initial Database Name"
              value={
                mongoAtlasSessionMetadata.dbHostname +
                "/" +
                mongoAtlasSessionMetadata.initDbName
              }
              isDataPrivate={true}
            />
          </>
        </ModalDetailPanel>
      </div>
    </>
  );
};

type MySqlMariaDbSessionBoxProps = {
  sessionMetadata: VaultMySqlMariaDbSessionFragment;
  resourceId: string;
  serviceType: ServiceType;
};

const MySqlMariaDbSessionBox = (
  props: MySqlMariaDbSessionBoxProps
): ReactElement => {
  const { sessionMetadata } = props;

  let mySqlCmd = "",
    dbUrl = "";
  if (props.serviceType === ServiceType.Mysql) {
    mySqlCmd = "mysql";
    dbUrl = `mysql://${sessionMetadata.dbUser}:${encodeURIComponent(
      sessionMetadata.dbPassword
    )}@${sessionMetadata.dbHostname}:${sessionMetadata.dbPort}/`;
  } else if (props.serviceType === ServiceType.Mariadb) {
    mySqlCmd = "mariadb";
    dbUrl = `mariadb://${sessionMetadata.dbHostname}:${
      sessionMetadata.dbPort
    }/?user=${sessionMetadata.dbUser}&password=${encodeURIComponent(
      sessionMetadata.dbPassword
    )}`;
  }

  mySqlCmd += ` --host ${sessionMetadata.dbHostname} --port ${sessionMetadata.dbPort} --user ${sessionMetadata.dbUser} --password=${sessionMetadata.dbPassword}`;
  const tlsCaCertContent = sessionMetadata.tlsConfig.tlsCaCertContent;
  if (sessionMetadata.tlsConfig.tlsMode && tlsCaCertContent) {
    mySqlCmd += ` --ssl-mode=VERIFY_CA --ssl-ca ./tls-ca-cert.pem`;
  }

  const serviceLabel = `${
    serviceTypeInfoByType[props.serviceType].name
  } Command`;

  const instructions = (
    <>
      {tlsCaCertContent && (
        <p>
          You will first need to download your{" "}
          <Link
            className={styles.link}
            onClick={(e) => {
              e.preventDefault();

              try {
                const blob = new Blob([atob(tlsCaCertContent)], {
                  type: "text/plain;charset=utf-8",
                });
                FileSaver.saveAs(blob, "tls-ca-cert.pem");
              } catch (error) {
                logError(error, `failed to parse and save cert content}`);
              }
            }}
            to={""}
          >
            TLS CA Certificate
          </Link>
          .
        </p>
      )}
      <FieldLabel label={serviceLabel} value={mySqlCmd} isDataPrivate={true} />
      <FieldLabel
        label="JDBC URL"
        value={"jdbc:" + dbUrl}
        isDataPrivate={true}
      />
      <p className={styles.label}>
        If you are using a 3rd party{" "}
        {serviceTypeInfoByType[props.serviceType].name} viewer, like Postico...
        <Button
          label="Open App"
          leftIconName="arrow-right"
          onClick={() => {
            openInNewTab(dbUrl);
          }}
        />
      </p>
    </>
  );

  return (
    <>
      <div className={styles.credContainer}>
        <ModalDetailPanel
          summary={"Automatic instructions"}
          openByDefault={true}
        >
          {instructions}
        </ModalDetailPanel>
        <ModalDetailPanel summary={"Manual instructions"} openByDefault={true}>
          <>
            <FieldLabel
              label="Username"
              value={sessionMetadata.dbUser}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Password"
              value={sessionMetadata.dbPassword}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Hostname"
              value={sessionMetadata.dbHostname}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Port"
              value={"" + sessionMetadata.dbPort}
              isDataPrivate={true}
            />
          </>
        </ModalDetailPanel>
      </div>
    </>
  );
};

type PostgresSessionBoxProps = {
  sessionMetadata: VaultPostgresSessionFragment;
  resourceId: string;
  remoteId: string;
};

const PostgresSessionBox = (props: PostgresSessionBoxProps): ReactElement => {
  const { sessionMetadata, remoteId } = props;

  let postgresCmd = `postgres://${sessionMetadata.dbUser}:${sessionMetadata.dbPassword}@${sessionMetadata.dbHostname}:${sessionMetadata.dbPort}/${remoteId}`;
  if (!sessionMetadata.tlsConfig.tlsMode) {
    postgresCmd += `?sslmode=disable`;
  }

  const tlsCaCertContent = sessionMetadata.tlsConfig.tlsCaCertContent;
  if (sessionMetadata.tlsConfig.tlsMode && tlsCaCertContent) {
    postgresCmd += `--ssl-ca ./tls-ca-cert.pem`;
  }
  const serviceName = serviceTypeInfoByType[ServiceType.Postgres].name;
  const serviceLabel = `${serviceName} Command`;

  const instructions = (
    <>
      {tlsCaCertContent && (
        <p>
          You will first need to download your{" "}
          <Link
            className={styles.link}
            onClick={(e) => {
              e.preventDefault();

              try {
                const blob = new Blob([atob(tlsCaCertContent)], {
                  type: "text/plain;charset=utf-8",
                });
                FileSaver.saveAs(blob, "tls-ca-cert.pem");
              } catch (error) {
                logError(error, `failed to parse and save cert content}`);
              }
            }}
            to={""}
          >
            TLS CA Certificate
          </Link>
          .
        </p>
      )}
      <FieldLabel
        label={serviceLabel}
        value={postgresCmd}
        isDataPrivate={true}
      />
      <FieldLabel
        label="JDBC URL"
        value={"jdbc:" + postgresCmd}
        isDataPrivate={true}
      />
      <p className={styles.label}>
        If you are using a 3rd party {serviceName} viewer, like Postico...
        <Button
          label="Open App"
          leftIconName="arrow-right"
          onClick={() => {
            openInNewTab(postgresCmd);
          }}
        />
      </p>
    </>
  );

  return (
    <>
      <div className={styles.credContainer}>
        <ModalDetailPanel
          summary={"Automatic instructions"}
          openByDefault={true}
        >
          {instructions}
        </ModalDetailPanel>
        <ModalDetailPanel summary={"Manual instructions"} openByDefault={true}>
          <>
            <FieldLabel
              label="Username"
              value={sessionMetadata.dbUser}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Password"
              value={sessionMetadata.dbPassword}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Hostname"
              value={sessionMetadata.dbHostname}
              isDataPrivate={true}
            />
            <FieldLabel
              label="Port"
              value={"" + sessionMetadata.dbPort}
              isDataPrivate={true}
            />
          </>
        </ModalDetailPanel>
      </div>
    </>
  );
};

type ModalDetailPanelProps = {
  summary: string;
  openByDefault?: boolean;
  children: React.ReactElement;
};
export const ModalDetailPanel = (props: ModalDetailPanelProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(props.openByDefault || false);
  return (
    <div className={styles.modalDetailPanelContainer}>
      <div
        className={styles.summaryContainer}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className={styles.summaryTitle}>{props.summary}</span>
        <span>{isOpen ? <ChevronUp /> : <ChevronDown />}</span>
      </div>
      {isOpen && (
        <div className={styles.detailContentContainer}>{props.children}</div>
      )}
    </div>
  );
};

type FieldLabelProps = {
  label?: string;
  value: string;
  isDataPrivate?: boolean;
  includeCLIDocsLink?: boolean;
};
export const FieldLabel = (props: FieldLabelProps) => {
  const { label, value, isDataPrivate } = props;
  const [copied, setCopied] = useState(false);
  return (
    <>
      {label && <p className={styles.fieldLabel}>{label}</p>}
      {copied ? (
        <div className={styles.copyContainer}>
          <CheckCircle size={14} />{" "}
          <span className={styles.copyCodeHeader}>Copied!</span>
        </div>
      ) : (
        <CopyToClipboard
          text={value}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
          }}
        >
          <div className={styles.copyContainer}>
            <Copy size={14} />{" "}
            <span className={styles.copyCodeHeader}>Copy</span>
          </div>
        </CopyToClipboard>
      )}
      <SyntaxHighlighter
        language={"bash"}
        customStyle={{
          borderRadius: "0px 0px 5px 5px",
          padding: "0px 16px 16px 16px",
        }}
        style={dark}
        data-private={isDataPrivate ? "redacted" : undefined}
      >
        {value}
      </SyntaxHighlighter>
      {props.includeCLIDocsLink && (
        <p>
          Check out our{" "}
          <a
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href={"https://docs.opal.dev/docs/setting-up-opal-cli"}
          >
            CLI docs
          </a>{" "}
          for more info on using the Opal CLI.
        </p>
      )}
    </>
  );
};

export default SessionDetailsModal;
