export const randomAlphanumericString = (length: Number): string => {
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let result = "";
  for (let i = 0; i < length; i++) {
    result += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return result;
};

export const randomElementInList = <Item>(list: Item[]): Item => {
  const randIndex = Math.floor(Math.random() * list.length);
  return list[randIndex];
};
