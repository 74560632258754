import {
  AccessReviewFragment,
  useAccessReviewsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { Button, ButtonV3, Divider, Icon, Skeleton } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { AuthorizedActionCreateAccessReview } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { getUserAvatarIcon } from "views/users/utils";

import { getDeadlineProgressBarInfo } from "./common/utils";
import CreateAccessReviewColumn from "./create/CreateAccessReviewColumn";

interface AccessReviewData {
  id: string;
  name: string;
  startedBy: {
    fullName: string;
    avatarUrl: string;
  };
  startedOn: string;
  reviewsCompleted: string;
  reviewersFinished: string;
  deadline: {
    diffText: string;
    daysToShow: string;
    dueDate: Date;
  };
}

const ACCESS_REVIEWS_ONGOING_COLUMNS: Header<AccessReviewData>[] = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "startedBy",
    label: "Started By",
    customCellRenderer: (row) => {
      return (
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          <div className={sprinkles({ flexShrink: 0 })}>
            <Icon
              size="sm"
              data={getUserAvatarIcon({ avatarUrl: row.startedBy.avatarUrl })}
            />
          </div>
          <div>{row.startedBy.fullName}</div>
        </div>
      );
    },
  },
  {
    id: "startedOn",
    label: "Started On",
    sortable: true,
    width: 100,
    customCellRenderer: (row) => {
      return <div>{moment(row["startedOn"]).format("YYYY-MM-DD")}</div>;
    },
  },
  {
    id: "reviewsCompleted",
    label: "Reviews Completed",
    sortable: false,
  },
  {
    id: "reviewersFinished",
    label: "Reviewers Finished",
    sortable: false,
  },
  {
    id: "deadline",
    label: "Deadline",
    sortable: true,
    sortingFn: (a, b) => {
      // Descending should show the most overdue to the least overdue
      // e.g., 3 days overdue, 1 day left in review, 24 days left in review
      // Ascending should show the least overdue to the most overdue
      // e.g., 24 days left in review, 1 day left in review, 3 days overdue
      return (
        b.original.deadline.dueDate.getTime() -
        a.original.deadline.dueDate.getTime()
      );
    },
    customCellRenderer: (row) => {
      let sublabelColor: "gray" | "red" = "gray";
      if (row.deadline.diffText.includes("overdue")) {
        sublabelColor = "red";
      } else if (Number(row.deadline.daysToShow) < 3) {
        sublabelColor = "red";
      }
      return (
        <span style={{ color: sublabelColor }}>
          {row.deadline.daysToShow} {row.deadline.diffText}
        </span>
      );
    },
  },
];

const AccessReviewsOngoingColumn = () => {
  const history = useHistory();
  const location = useLocation();
  const { authState } = useContext(AuthContext);
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const transitionTo = useTransitionTo();

  const { data, error, loading } = useAccessReviewsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        ongoingOnly: true,
      },
    },
  });
  let accessReviews: AccessReviewFragment[] | null = null;
  if (data) {
    switch (data.accessReviews.__typename) {
      case "AccessReviewsResult":
        accessReviews = data.accessReviews.accessReviews;
        break;
      default:
        logError(new Error("failed to retrieve ongoing access reviews"));
    }
  } else if (error) {
    logError(error, "failed to retrieve ongoing access reviews");
  }

  if (error || (!loading && !accessReviews)) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const renderRow = (index: number) => {
    if (!accessReviews) return <></>;

    const accessReview = accessReviews[index];
    if (!accessReview) return <></>;

    const { diffText, daysToShow } = getDeadlineProgressBarInfo(accessReview);
    let sublabelColor: "gray" | "red" = "gray";
    if (diffText.includes("overdue")) {
      sublabelColor = "red";
    } else if (Number(daysToShow) < 3) {
      sublabelColor = "red";
    }

    return (
      <ColumnListItem
        key={accessReview.id}
        label={accessReview.name}
        sublabel={`${daysToShow} ${diffText}`}
        sublabelColor={sublabelColor}
        selected={location.pathname.includes(accessReview.id)}
        onClick={() => {
          history.push({
            pathname: "/access-reviews/" + accessReview.id,
            search: "?category=ongoing",
          });
        }}
      />
    );
  };

  const shouldShowCreateAccessReview = authState.user?.organizationAuthorizedActions?.includes(
    AuthorizedActionCreateAccessReview
  );

  const addMenuOptions: PropsFor<typeof ColumnHeader>["addMenuOptions"] = [];
  if (shouldShowCreateAccessReview) {
    addMenuOptions.push({
      label: "Create access review",
      onClick: () => history.push("/access-reviews/new?category=ongoing"),
      icon: { type: "name", icon: "plus" },
    });
  }

  if (hasV3Nav) {
    return (
      <>
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "md",
            marginTop: "xl",
          })}
        >
          <span
            className={sprinkles({
              fontSize: "bodyLg",
              fontWeight: "medium",
            })}
          >
            {loading && !accessReviews ? (
              <Skeleton variant="text" width="100px" />
            ) : (
              `${accessReviews?.length} Ongoing Access Reviews`
            )}
          </span>
          <div
            className={sprinkles({
              display: "flex",
              gap: "sm",
            })}
          >
            {shouldShowCreateAccessReview ? (
              hasV3Nav ? (
                <ButtonV3
                  label="Access Review"
                  type="main"
                  leftIconName="plus"
                  onClick={() =>
                    history.push("/access-reviews/new?category=ongoing")
                  }
                />
              ) : (
                <Button
                  label="Access Review"
                  type="primary"
                  leftIconName="plus"
                  onClick={() =>
                    history.push("/access-reviews/new?category=ongoing")
                  }
                />
              )
            ) : null}
          </div>
        </div>
        {loading && !accessReviews ? (
          <ColumnListItemsSkeleton />
        ) : (
          <Table
            rows={
              accessReviews?.map((ar) => {
                const assignmentReviewsCompleted =
                  ar.stats.numGroupResourceAssignmentsCompleted +
                  ar.stats.numUserAssignmentsCompleted;
                const assignmentsTotal =
                  ar.stats.numUserAssignmentsTotal +
                  ar.stats.numGroupResourceAssignmentsTotal;
                const reviewersFinished =
                  ar.stats.numReviewersTotal - ar.stats.numReviewersIncomplete;
                const reviewersTotal = ar.stats.numReviewersTotal;
                const { diffText, daysToShow } = getDeadlineProgressBarInfo(ar);
                return {
                  id: ar.id,
                  name: ar.name,
                  startedBy: {
                    fullName: ar.startedByUser?.fullName ?? "",
                    avatarUrl: ar.startedByUser?.avatarUrl ?? "",
                  },
                  startedOn: ar.createdAt ?? "",
                  reviewsCompleted: `${assignmentReviewsCompleted}/${assignmentsTotal} reviews completed`,
                  reviewersFinished: `${reviewersFinished}/${reviewersTotal} reviewers finished`,
                  deadline: {
                    diffText,
                    daysToShow,
                    dueDate: new Date(ar.deadline),
                  },
                };
              }) ?? []
            }
            totalNumRows={accessReviews?.length ?? 0}
            getRowId={(ru) => ru.id}
            columns={ACCESS_REVIEWS_ONGOING_COLUMNS}
            defaultSortBy="deadline"
            defaultSortDirection="DESC"
            onRowClick={(row, event) => {
              transitionTo(
                {
                  pathname: "/access-reviews/" + row.id,
                  search: "category=ongoing",
                },
                event
              );
            }}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Column>
        <ColumnHeader
          title="Ongoing"
          icon={{ type: "name", icon: "hourglass" }}
          breadcrumbs={[{ name: "Access Reviews", to: "/access-reviews" }]}
          preserveQuery={false}
          addMenuOptions={addMenuOptions}
        />
        <Divider />
        <ColumnListScroller
          renderRow={renderRow}
          numRows={accessReviews?.length ?? 0}
          loading={loading && !accessReviews}
        />
      </Column>
      {location.pathname.includes("/new") ? <CreateAccessReviewColumn /> : null}
    </>
  );
};

export default AccessReviewsOngoingColumn;
