import {
  BundleDetailFragment,
  BundleItemsSortByField,
  GroupPreviewLargeFragment,
  GroupType,
  ResourcePreviewLargeFragment,
  ResourceType,
  SortDirection,
  useBundleItemsQuery,
  useBundleQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { useToast } from "components/toast/Toast";
import { Divider, Icon } from "components/ui";
import { IconData } from "components/ui/utils";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { logError } from "utils/logging";
import { getResourceSublabel } from "utils/resources";
import { AppsContext } from "views/apps/AppsContext";
import BulkRequestColumn from "views/apps/BulkRequestColumn";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import { BUNDLE_VIEWS } from "./BundleDetailContent";

export const SORT_OPTIONS = [
  {
    label: "Name (A-Z)",
    value: {
      field: BundleItemsSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: BundleItemsSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: BundleItemsSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: BundleItemsSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

const BundleDetailColumn = () => {
  const { bundleId } = useParams<Record<string, string>>();
  const history = useHistory();
  const {
    selectedBundleItems,
    selectBundleItems,
    clearBundleItems,
    clearSelectedItems,
  } = useContext(AppsContext);
  const { authState } = useContext(AuthContext);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);
  const [selectedItemType, setSelectedItemType] = useState<
    string | undefined
  >();

  // Clear selected items when navigating away from this page.
  useEffect(() => {
    return clearSelectedItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { displayErrorToast } = useToast();
  const { data, error, loading } = useBundleQuery({
    variables: {
      input: {
        id: bundleId,
      },
    },
    skip: !bundleId,
  });

  const {
    data: itemsData,
    error: itemsError,
    loading: itemsLoading,
    fetchMore,
  } = useBundleItemsQuery({
    variables: {
      input: {
        bundleId,
        searchQuery,
        sortBy: sortOption.value,
        itemType: selectedItemType,
        maxPageSize: 50,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  if (!bundleId) {
    return null;
  }

  if (error || itemsError) {
    return (
      <Column>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (loading) {
    return (
      <Column>
        <ColumnHeaderSkeleton />
        <Divider />
        <ColumnListItemsSkeleton />
      </Column>
    );
  }

  let bundle: BundleDetailFragment | undefined;
  switch (data?.bundle.__typename) {
    case "BundleResult":
      bundle = data.bundle.bundle;
      break;
    case "BundleNotFoundError":
      return (
        <Column isContent>
          <NotFoundPage />
        </Column>
      );
  }

  if (!bundle) {
    return (
      <Column isContent>
        <NotFoundPage />
      </Column>
    );
  }

  const allItems = itemsData?.bundleItems.items ?? [];
  const cursor = itemsData?.bundleItems.cursor;
  const itemTypes = itemsData?.bundleItems.itemTypes ?? [];
  const itemTypesOptions = itemTypes.map((i) => ({
    label: i.displayText,
    value: i.itemType,
    icon: {
      type: "entity",
      entityType: i.itemType as ResourceType | GroupType,
    } as IconData,
  }));

  const loadMore = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              bundleId,
              searchQuery,
              sortBy: sortOption.value,
              itemType: selectedItemType,
              maxPageSize: 50,
              cursor,
            },
          },
        });
      }
    : undefined;

  const selectAll = async () => {
    let thisCursor = cursor;
    selectBundleItems(allItems);

    while (thisCursor) {
      const { data, error } = await fetchMore({
        variables: {
          input: {
            bundleId,
            searchQuery,
            sortBy: sortOption.value,
            itemType: selectedItemType,
            maxPageSize: 50,
            cursor: thisCursor,
          },
        },
      });
      if (error) {
        logError(error, "failed to select all items.");
        displayErrorToast("failed to select all items.");
        break;
      }

      selectBundleItems(data.bundleItems.items ?? []);
      thisCursor = data?.bundleItems.cursor;
    }
  };

  const clearAll = async () => {
    clearSelectedItems();
  };

  const renderItemRow = (index: number) => {
    const itemWrapper = allItems[index];
    if (!itemWrapper) {
      return <></>;
    }

    let item:
      | ResourcePreviewLargeFragment
      | GroupPreviewLargeFragment
      | undefined;
    const role = itemWrapper.accessLevelName;
    let entityType: ResourceType | GroupType | undefined;
    let hasAccess = false;
    if (itemWrapper.resource) {
      item = itemWrapper.resource;
      entityType = itemWrapper.resource.resourceType;
      hasAccess = item.currentUserAccess.resourceUsers.some((ru) => {
        return (
          ru.accessLevel.accessLevelRemoteId === itemWrapper.accessLevelRemoteId
        );
      });
    } else if (itemWrapper.group) {
      item = itemWrapper.group;
      entityType = itemWrapper.group.groupType;
      let directRoleRemoteId: undefined | string;
      if (item.currentUserAccess.groupUser?.access?.directAccessPoint) {
        directRoleRemoteId =
          item.currentUserAccess.groupUser.access.directAccessPoint.accessLevel
            ?.accessLevelRemoteId ?? "";
      }
      hasAccess = directRoleRemoteId === itemWrapper.accessLevelRemoteId;
    }

    if (!item || !entityType) {
      return <></>;
    }

    const selected = selectedBundleItems
      .map((item) => item.key)
      .includes(itemWrapper.key);

    const toggleItem = () => {
      if (selected) {
        clearBundleItems([itemWrapper]);
      } else {
        selectBundleItems([itemWrapper]);
      }
    };

    let label = item.name;
    if (role) {
      label = `${label} (${role})`;
    }

    return (
      <ColumnListItem
        key={itemWrapper.key}
        label={label}
        sublabel={
          item.__typename === "Resource"
            ? getResourceSublabel(item)
            : getGroupTypeInfo(item.groupType)?.name
        }
        onClick={toggleItem}
        checkbox={{
          checked: selected,
          onChange: toggleItem,
        }}
        disabled={!item.isRequestable}
        type="terminal"
        largeIcon
        icon={{
          type: "entity",
          entityType,
        }}
        rightContent={
          hasAccess ? (
            <Icon name="check-circle" size="xs" color="green600" />
          ) : undefined
        }
      />
    );
  };

  const renderItems = () => {
    return (
      <ColumnListScroller
        numRows={allItems.length}
        renderRow={renderItemRow}
        emptyState={{
          title:
            searchQuery.length > 0
              ? "No items found"
              : "There's nothing in this bundle.",
          subtitle: authState.user?.isAdmin
            ? "Add items from the Resources or Groups tab."
            : undefined,
        }}
        onLoadMore={loadMore}
        loading={itemsLoading}
        hasNextPage={cursor != null}
      />
    );
  };

  const allSelected =
    allItems.length > 0 &&
    allItems.every((item) => {
      return selectedBundleItems.some((i) => i.key === item.key);
    });

  const currentView = BUNDLE_VIEWS.find((view) =>
    location.pathname.endsWith(view.key)
  );

  return (
    <>
      <Column>
        <ColumnHeader
          icon={{ type: "name", icon: "package" }}
          title={bundle.name}
          breadcrumbs={[
            { name: "Apps", to: "/apps" },
            { name: "Bundles", to: "/bundles" },
          ]}
          count={itemsData?.bundleItems.totalNumItems}
        />
        <Divider />
        <ColumnListItem
          label="View Bundle Information"
          onClick={() => history.push(`/bundles/${bundleId}/overview`)}
          selected={Boolean(currentView)}
        />
        <Divider />
        {(bundle.totalNumItems > 0 || loading) && (
          <ColumnSearchAndSort
            placeholder="Search items"
            sortOptions={SORT_OPTIONS}
            sortBy={sortOption}
            setSortBy={setSortOption}
            initialSearchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            filterByOptions={
              itemTypes.length && itemTypes.length > 1
                ? itemTypesOptions
                : undefined
            }
            filterBy={itemTypesOptions.find(
              (i) => i.value === selectedItemType
            )}
            setFilterBy={(value) => setSelectedItemType(value?.value)}
            checkbox={{
              alwaysShow: true,
              checked: allSelected,
              onChange: allSelected ? clearAll : selectAll,
              loading: itemsLoading,
            }}
            trackName="bundle-items"
          />
        )}
        {renderItems()}
      </Column>
      {selectedBundleItems.length > 0 && <BulkRequestColumn />}
    </>
  );
};

export default BundleDetailColumn;
