import { TimeZone } from "@vvo/tzdb";
import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewFilters,
  AccessReviewGroupResourceVisibilityPolicy,
  AccessReviewReviewerAssignmentPolicy,
  Maybe,
  StartAccessReviewInput,
  useStartAccessReviewMutation,
} from "api/generated/graphql";
import {
  DEFAULT_REMINDER_SCHEDULE,
  DEFAULT_REMINDER_TIME_OF_DAY,
} from "components/access_reviews/ReminderNotifications";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { FormGroup, Input, Link, Modal } from "components/ui";
import React, { useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { getDefaultTimezone, getTimeZoneGuess } from "utils/time";
import DeadlineForm from "views/access_reviews/common/DeadlineForm";
import { buildAccessReviewFilter } from "views/access_reviews/create/utils";

interface AccessReviewConfig {
  name: string;
  timeZone?: TimeZone;
  deadline?: string;
  reminderSchedule: number[];
  reminderTimeOfDay: Date;
  reminderIncludeManager: boolean;
  filters: AccessReviewFilters;
  assignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  sendReviewerAssignmentNotifications: boolean;
  selfReviewAllowed: boolean;
  groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
  includeGroupBindings: boolean;
}

const AccessReviewCreateSimpleModal = ({
  filters,
  title,
  onClose,
}: {
  filters: AccessReviewFilters;
  title: string | undefined;
  onClose: () => void;
}) => {
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);
  const [config, setConfig] = useState<AccessReviewConfig>({
    name: "",
    timeZone: getTimeZoneGuess() || getDefaultTimezone(),
    reminderSchedule: DEFAULT_REMINDER_SCHEDULE,
    reminderTimeOfDay: DEFAULT_REMINDER_TIME_OF_DAY,
    reminderIncludeManager: false,
    filters: filters,
    assignmentPolicy: AccessReviewReviewerAssignmentPolicy.Manually,
    sendReviewerAssignmentNotifications: false,
    selfReviewAllowed: true,
    groupResourceVisibilityPolicy:
      AccessReviewGroupResourceVisibilityPolicy.Strict,
    includeGroupBindings: hasGroupBindings, // default to true if feature flag is enabled, when you'll remove the feature flag default to true.
  });
  const [
    createAccessReview,
    { loading: startReviewloading },
  ] = useStartAccessReviewMutation();
  const { displaySuccessToast } = useToast();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const handleCreate = async () => {
    if (config.deadline && config.timeZone) {
      try {
        const input: StartAccessReviewInput = {
          name: config.name,
          deadline: config.deadline,
          reminderSchedule: config.reminderSchedule,
          reminderTimeOfDay: config.reminderTimeOfDay.toISOString(),
          reminderIncludeManager: config.reminderIncludeManager,
          timeZone: config.timeZone.name,
          reviewerAssignmentPolicy: config.assignmentPolicy,
          sendReviewerAssignmentNotification:
            config.sendReviewerAssignmentNotifications,
          selfReviewAllowed: config.selfReviewAllowed,
          filters: buildAccessReviewFilter(config.filters),
          groupResourceVisibilityPolicy: config.groupResourceVisibilityPolicy,
          includeGroupBindings: config.includeGroupBindings,
        };

        const { data } = await createAccessReview({
          variables: { input },
          refetchQueries: ["AccessReviews"],
        });
        switch (data?.startAccessReview.__typename) {
          case "StartAccessReviewResult":
            displaySuccessToast(`Success: access review created`);
            break;
          default:
            logError(new Error(`failed to create access review`));
            setErrorMessage(`Error: failed to create access review`);
        }
      } catch (error) {
        logError(error, "failed to create access review");
        setErrorMessage(
          getModifiedErrorMessage(
            `Error: failed to create access review`,
            error
          )
        );
      }
    }
  };

  const handleNameChange = (newName: string) => {
    setConfig({ ...config, name: newName });
  };

  const handleDeadlineChanges = (newDeadline: string, tz: TimeZone) => {
    if (tz.name != config.timeZone?.name) {
      setConfig({ ...config, timeZone: tz, deadline: newDeadline });
    } else if (newDeadline !== config.deadline) {
      setConfig({ ...config, deadline: newDeadline });
    }
  };

  const canCreate = config.name != "" && config.deadline !== undefined;
  return (
    <Modal isOpen onClose={onClose} title={title ? title : "Create UAR"}>
      <Modal.Body>
        <FormGroup label="UAR Name" required>
          <Input
            value={config.name}
            onChange={handleNameChange}
            maxLength={255}
          />
        </FormGroup>
        <DeadlineForm
          onChange={handleDeadlineChanges}
          label={"Set a deadline"}
          timeZone={config.timeZone}
        />
        <Link color="blue" underline={false} url="/access-reviews/new">
          Advanced Settings
        </Link>
        {errorMessage ? (
          <ModalErrorMessage errorMessage={errorMessage} />
        ) : null}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={"Create"}
        onPrimaryButtonClick={handleCreate}
        primaryButtonLoading={startReviewloading}
        primaryButtonDisabled={!canCreate}
        secondaryButtonLabel={"Cancel"}
        onSecondaryButtonClick={onClose}
      />
    </Modal>
  );
};

export default AccessReviewCreateSimpleModal;
