import { Route, useRouteMatch } from "react-router";

import DashboardColumn from "./DashboardColumn";

const DashboardView = () => {
  const match = useRouteMatch();

  return <Route exact path={`${match.path}`} component={DashboardColumn} />;
};

export default DashboardView;
