import { ColumnContainer } from "components/column/Column";
import { Route } from "react-router-dom";

import NonHumanIdentitiesColumn from "./NonHumanIdentitiesColumn";

const NonHumanIdentities = () => {
  return (
    <Route
      exact
      path={["/non-human-identities"]}
      component={NonHumanIdentitiesComponent}
    />
  );
};

const NonHumanIdentitiesComponent = () => {
  return (
    <ColumnContainer>
      <NonHumanIdentitiesColumn />
    </ColumnContainer>
  );
};

export default NonHumanIdentities;
