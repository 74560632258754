import {
  AccessReviewTemplateFragment,
  useAccessReviewTemplatesQuery,
} from "api/generated/graphql";
import { Months } from "components/access_reviews/AccessReviewMonthPicker";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { Button, ButtonV3, Divider, Skeleton } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { AuthorizedActionCreateAccessReviewTemplate } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import AccessReviewScheduleDetailV3 from "views/access_reviews/AccessReviewScheduleDetailV3";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import AccessReviewScheduleViewer from "./AccessReviewScheduleViewer";

interface ScheduleData {
  id: string;
  name: string;
  monthsToRun: string;
  duration: string;
  resourcesCount: number;
}

const SCHEDULES_COLUMNS: Header<ScheduleData>[] = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "monthsToRun",
    label: "Months to run",
  },
  {
    id: "duration",
    label: "Duration",
  },
  {
    id: "resourcesCount",
    label: "Resources",
  },
];

const AccessReviewSchedulesColumn = () => {
  const history = useHistory();
  const location = useLocation();
  const { accessReviewTemplateId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const transitionTo = useTransitionTo();

  const { data, error, loading } = useAccessReviewTemplatesQuery({
    fetchPolicy: "cache-and-network",
  });
  let accessReviewTemplates: AccessReviewTemplateFragment[] | null = null;
  if (data) {
    switch (data.accessReviewTemplates.__typename) {
      case "AccessReviewTemplatesResult":
        accessReviewTemplates =
          data.accessReviewTemplates.accessReviewTemplates;
        break;
      default:
        logError(new Error(`failed to retrieve access review templates`));
    }
  } else if (error) {
    logError(error, `failed to retrieve access review templates`);
  }

  if (error || (!loading && !accessReviewTemplates)) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const renderRow = (index: number) => {
    if (!accessReviewTemplates) return <></>;

    const template = accessReviewTemplates[index];
    if (!template) return <></>;

    return (
      <ColumnListItem
        key={template.id}
        label={template.name}
        onClick={() => {
          history.push({
            pathname: "/access-reviews/t/" + template.id,
            search: "?category=schedules",
          });
        }}
        selected={location.pathname.includes(template.id)}
      />
    );
  };

  const shouldShowCreateAccessReviewTemplate = authState.user?.organizationAuthorizedActions?.includes(
    AuthorizedActionCreateAccessReviewTemplate
  );

  if (hasV3Nav) {
    if (accessReviewTemplateId) {
      return <AccessReviewScheduleDetailV3 />;
    }
    return (
      <>
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "md",
            marginTop: "xl",
          })}
        >
          <span
            className={sprinkles({
              fontSize: "bodyLg",
              fontWeight: "medium",
            })}
          >
            {loading && !accessReviewTemplates ? (
              <Skeleton variant="text" width="100px" />
            ) : (
              `${accessReviewTemplates?.length} Schedules`
            )}
          </span>
          <div
            className={sprinkles({
              display: "flex",
              gap: "sm",
            })}
          >
            {shouldShowCreateAccessReviewTemplate ? (
              hasV3Nav ? (
                <ButtonV3
                  label="Create Schedule"
                  type="main"
                  leftIconName="plus"
                  onClick={() =>
                    history.push("/access-reviews/t/new?category=schedules")
                  }
                />
              ) : (
                <Button
                  label="Schedule"
                  type="primary"
                  leftIconName="plus"
                  onClick={() =>
                    history.push("/access-reviews/t/new?category=schedules")
                  }
                />
              )
            ) : null}
          </div>
        </div>
        {loading && !accessReviewTemplates ? (
          <ColumnListItemsSkeleton />
        ) : (
          <Table
            rows={
              accessReviewTemplates?.map((t) => {
                return {
                  id: t.id,
                  name: t.name,
                  monthsToRun: t.monthSchedule
                    .map((m) => Months.find((month) => month.id === m)?.name)
                    .join(", "),
                  duration: t.accessReviewDuration + " days",
                  resourcesCount: t.scopeResourcesCount + t.scopeGroupsCount,
                };
              }) ?? []
            }
            totalNumRows={accessReviewTemplates?.length ?? 0}
            getRowId={(ru) => ru.name}
            columns={SCHEDULES_COLUMNS}
            defaultSortBy="name"
            onRowClick={(row, event) => {
              transitionTo(
                {
                  pathname: "/access-reviews/t/" + row.id,
                  search: "category=schedules",
                },
                event
              );
            }}
          />
        )}
      </>
    );
  }

  const addMenuOptions: PropsFor<typeof ColumnHeader>["addMenuOptions"] = [];
  if (shouldShowCreateAccessReviewTemplate) {
    addMenuOptions.push({
      label: "Create access review schedule",
      onClick: () => history.push("/access-reviews/t/new?category=schedules"),
      icon: { type: "name", icon: "plus" },
    });
  }

  return (
    <>
      <Column>
        <ColumnHeader
          title="Schedules"
          icon={{ type: "name", icon: "refresh" }}
          breadcrumbs={[{ name: "Access Reviews", to: "/access-reviews" }]}
          preserveQuery={false}
          addMenuOptions={addMenuOptions}
        />
        <Divider />
        <ColumnListScroller
          renderRow={renderRow}
          numRows={accessReviewTemplates?.length ?? 0}
          loading={loading && !accessReviewTemplates}
        />
      </Column>
      {accessReviewTemplateId ? <AccessReviewScheduleViewer /> : null}
    </>
  );
};

export default AccessReviewSchedulesColumn;
