import {
  GroupType,
  ResourceType,
  useGroupTypesWithCountsQuery,
  useResourceTypesWithCountsQuery,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Select } from "components/ui";
import { logError } from "utils/logging";

interface EntityTypeDropdownProps {
  value?: ResourceType | GroupType | undefined;
  onChange: (resourceType?: ResourceType, groupType?: GroupType) => void;
  placeholder?: string;
}

const EntityTypeDropdown = (props: EntityTypeDropdownProps) => {
  // Fetch all entityTypes
  const entityTypes = new Set<ResourceType | GroupType>();
  const resourceTypes = new Set<ResourceType>();

  const {
    data: groupTypesData,
    error: groupTypesError,
  } = useGroupTypesWithCountsQuery({ variables: { input: {} } });

  if (groupTypesError) {
    logError(groupTypesError, "failed to list group types");
  }
  switch (groupTypesData?.groupTypesWithCounts?.__typename) {
    case "GroupTypesWithCountsResult":
      groupTypesData?.groupTypesWithCounts.groupTypesWithCounts.forEach(
        (groupType) => {
          entityTypes.add(groupType.groupType);
        }
      );
      break;
    default:
      break;
  }

  const {
    data: resourceTypesData,
    error: resourceTypesDataError,
  } = useResourceTypesWithCountsQuery({ variables: { input: {} } });

  if (resourceTypesDataError) {
    logError(resourceTypesDataError, "failed to list resource types");
  }
  switch (resourceTypesData?.resourceTypesWithCounts?.__typename) {
    case "ResourceTypesWithCountsResult":
      resourceTypesData?.resourceTypesWithCounts.resourceTypesWithCounts.forEach(
        (resource) => {
          entityTypes.add(resource.resourceType);
          resourceTypes.add(resource.resourceType);
        }
      );
      break;
    default:
      break;
  }

  const getOptionLabel = (entity: ResourceType | GroupType): string => {
    if (resourceTypes.has(entity as ResourceType)) {
      return getResourceTypeInfo(entity as ResourceType)?.fullName ?? "";
    } else {
      return getGroupTypeInfo(entity as GroupType)?.name ?? "";
    }
  };

  const getIcon = (entity: ResourceType | GroupType) => {
    if (resourceTypes.has(entity as ResourceType)) {
      return getResourceTypeInfo(entity as ResourceType)?.icon;
    } else {
      return getGroupTypeInfo(entity as GroupType)?.icon;
    }
  };

  const onChangeAndCast = (entity: ResourceType | GroupType | undefined) => {
    if (resourceTypes.has(entity as ResourceType)) {
      return props.onChange(entity as ResourceType, undefined);
    } else {
      return props.onChange(undefined, entity as GroupType);
    }
  };

  const sortedEntityTypes = Array.from(entityTypes).sort((a, b) => {
    const labelA = getOptionLabel(a);
    const labelB = getOptionLabel(b);
    return labelA.localeCompare(labelB);
  });

  return (
    <Select
      value={props.value}
      options={sortedEntityTypes}
      placeholder={props.placeholder}
      getOptionLabel={(entityType) => {
        return getOptionLabel(entityType);
      }}
      onChange={onChangeAndCast}
      getIcon={(entityType) => ({
        type: "src",
        icon: getIcon(entityType),
      })}
      alwaysShowPlaceholder={true}
    />
  );
};

export default EntityTypeDropdown;
