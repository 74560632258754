import { Group } from "@visx/group";
import { hierarchy, Tree } from "@visx/hierarchy";
import { HierarchyPointNode } from "@visx/hierarchy/lib/types";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import {
  EntityType,
  GroupResourceEdge,
  GroupType,
  ResourceAccessLevelFragment,
  ResourcePreviewLargeFragment,
  ResourceType,
  UserGroupEdge,
  UserPreviewSmallFragment,
  UserResourceEdge,
  useVisualizationDataQuery,
} from "api/generated/graphql";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import { Icon } from "components/ui";
import {
  useFilterDispatch,
  useFilterState,
} from "components/viz/contexts/FilterContext";
import Link from "components/viz/Link";
import GroupNode from "components/viz/nodes/GroupNode";
import ResourceNode from "components/viz/nodes/ResourceNode";
import UserNode from "components/viz/nodes/UserNode";
import Sidebar from "components/viz/Sidebar";
import sprinkles from "css/sprinkles.css";
import * as React from "react";
import useLogEvent from "utils/analytics";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";

import {
  BASE_NODE_HEIGHT,
  BASE_NODE_WIDTH,
  GroupByNodeData,
  GroupNodeData,
  GROUPS_VERTICAL_OFFSET,
  HEADER_HEIGHT,
  NESTED_NODE_MARGIN,
  NODE_ATTRIBUTE_HEIGHT,
  NODE_GAP_X,
  NODE_PADDING,
  ResourceNodeData,
  ResourceRoleNodeData,
  TreeNodeData,
  UserNodeData,
} from "./common";
import {
  GraphContext,
  graphInitialState,
  graphReducer,
} from "./contexts/GraphContext";
import VizLoader from "./Loader";
import ColumnHeader from "./nodes/ColumnHeader";
import GroupByNode from "./nodes/GroupByNode";
import RoleNode from "./nodes/RoleNode";
import * as styles from "./OrgVisualizationV3.css";
import {
  getLinkHighlightColor,
  getUserGroupByValue,
  makeRolelNodeId,
} from "./utils";
import ZoomingDragPane from "./ZoomingDragPane";

const OrgVisualization = () => {
  const logEvent = useLogEvent();
  const filterState = useFilterState();
  const filterDispatch = useFilterDispatch();
  const [graphState, graphDispatch] = React.useReducer(
    graphReducer,
    graphInitialState
  );
  const hasSelected =
    Boolean(filterState.selection.userIds?.length) ||
    Boolean(filterState.selection.groupIds?.length) ||
    Boolean(filterState.selection.resourceIds?.length) ||
    Boolean(filterState.selection.multiGroupSelections?.length) ||
    Boolean(filterState.selection.multiResourceSelections?.length);

  const shouldApplyUsageFilter = filterState.usage != null;

  const isHidingGroups =
    (filterState.hideGroups ?? false) &&
    graphState.selectedUserIds.length +
      graphState.selectedGroupIds.length +
      graphState.selectedResourceIds.length ===
      0;

  const { loading } = useVisualizationDataQuery({
    variables: {
      input: {
        selection: {
          userIds: filterState.selection.userIds,
          resourceIds: filterState.selection.resourceIds,
          groupIds: filterState.selection.groupIds,
          multiGroupSelections: filterState.selection.multiGroupSelections?.map(
            (gt) => ({
              connectionId: gt.connectionId,
              groupType: gt.groupType as GroupType,
            })
          ),
          multiResourceSelections: filterState.selection.multiResourceSelections?.map(
            (rt) => ({
              connectionId: rt.connectionId,
              parentResourceId: rt.parentResourceId
                ? { parentResourceId: rt.parentResourceId }
                : undefined,
              resourceType: rt.resourceType as ResourceType,
            })
          ),
        },
        showUsage: shouldApplyUsageFilter,
        hideGroups: isHidingGroups,
        includeUnmanagedItems: filterState.showUnmanagedItems,
      },
    },
    fetchPolicy: "no-cache",
    onError: () => {
      graphDispatch({
        type: "SET_ERROR",
        payload: {
          error: "Error: failed to fetch nodes.",
        },
      });
      graphDispatch({
        type: "SET_LOADING",
        payload: {
          loading: false,
        },
      });
    },
    onCompleted: (data) => {
      if (data.visualizationData.__typename === "VisualizationResult") {
        graphDispatch({
          type: "SET_DATA",
          payload: {
            data: data.visualizationData,
          },
        });
      } else if (
        data.visualizationData.__typename === "VisualizationTimeoutError"
      ) {
        logError("viz query timeout", undefined, {
          graphState: JSON.stringify(graphState),
        });
        graphDispatch({
          type: "SET_ERROR",
          payload: {
            error:
              "Query has timed out. We have been notified and will take a look at this performance issue soon. In the meantime, please select a smaller item set.",
          },
        });
      } else {
        logError("unknown");
      }
      graphDispatch({
        type: "SET_LOADING",
        payload: {
          loading: false,
        },
      });
    },
  });

  const data = graphState.data;

  const [sorted, setSorted] = React.useState(false);

  const { topLevelResources, childResourcesByParentId } = React.useMemo(() => {
    const resources = data?.resources ?? [];
    const topLevelResources: ResourcePreviewLargeFragment[] = [];
    const childResourcesByParentId: {
      [parentId: string]: ResourcePreviewLargeFragment[];
    } = {};
    resources.forEach((r) => {
      if (r.parentResourceId) {
        if (r.parentResourceId in childResourcesByParentId) {
          childResourcesByParentId[r.parentResourceId].push(r);
        } else {
          childResourcesByParentId[r.parentResourceId] = [r];
        }
      } else {
        topLevelResources.push(r);
      }
    });
    return {
      topLevelResources,
      childResourcesByParentId,
    };
  }, [data?.resources]);

  const rolesByResourceId = React.useMemo(() => {
    const roles = data?.accessLevels ?? [];
    const rolesByResourceId: {
      [resourceId: string]: ResourceAccessLevelFragment[];
    } = {};
    roles.forEach((roleNode) => {
      if (roleNode.resourceId in rolesByResourceId) {
        rolesByResourceId[roleNode.resourceId].push(roleNode.accessLevel);
      } else {
        rolesByResourceId[roleNode.resourceId] = [roleNode.accessLevel];
      }
    });
    return rolesByResourceId;
  }, [data?.accessLevels]);

  // Start with parent resource nodes expanded
  React.useEffect(() => {
    graphDispatch({
      type: "EXPAND_RESOURCES",
      payload: {
        resourceIds: Object.keys(childResourcesByParentId),
      },
    });
  }, [childResourcesByParentId]);

  const hasHighlightApplied =
    filterState.accessTypes.length > 0 || shouldApplyUsageFilter;
  const shouldFilter = filterState.showHighlightedOnly && hasHighlightApplied;

  const visibleNodeIds = React.useMemo(() => {
    const result = new Set<string>();
    if (!shouldFilter) {
      return result;
    }

    data?.userGroupEdges.forEach((ug) => {
      const highlightColor = getLinkHighlightColor(
        filterState,
        graphState,
        false,
        ug.metadata ?? undefined
      );
      if (highlightColor) {
        result.add(ug.userId);
        result.add(ug.groupId);
      }
    });
    data?.groupResourceEdges.forEach((gr) => {
      const highlightColor = getLinkHighlightColor(
        filterState,
        graphState,
        false,
        gr.metadata ?? undefined
      );
      if (highlightColor) {
        result.add(makeRolelNodeId(gr.resourceId, gr.accessLevel));
        result.add(gr.resourceId);
        result.add(gr.groupId);
      }
    });
    data?.userResourceEdges.forEach((ur) => {
      const highlightColor = getLinkHighlightColor(
        filterState,
        graphState,
        false,
        ur.metadata ?? undefined
      );
      if (highlightColor) {
        result.add(makeRolelNodeId(ur.resourceId, ur.accessLevel));
        result.add(ur.resourceId);
        result.add(ur.userId);
      }
    });
    return result;
  }, [data, filterState, graphState, shouldFilter]);

  const treeData = React.useMemo(() => {
    let users = [...(data?.users ?? [])];
    const userNodeHeight =
      BASE_NODE_HEIGHT +
      Object.values(filterState.attributesShown).filter(Boolean).length *
        NODE_ATTRIBUTE_HEIGHT;

    const userNodes: Array<UserNodeData | GroupByNodeData> = [];

    if (filterState.groupBy && users.length) {
      const groupBy = filterState.groupBy;
      users.sort((userA, userB) => {
        const a = getUserGroupByValue(userA, groupBy);
        const b = getUserGroupByValue(userB, groupBy);
        if (a === b) {
          return 1;
        }
        if (a === "") {
          return 1;
        }
        if (b === "") {
          return -1;
        }
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
      });
    }

    const userByGroupByValue: {
      [groupByValue: string]: UserPreviewSmallFragment[];
    } = {};

    for (let user of users) {
      if (shouldFilter && !visibleNodeIds.has(user.id)) {
        continue;
      }
      if (filterState.groupBy) {
        const groupByValue =
          getUserGroupByValue(user, filterState.groupBy) || "Unknown";
        if (groupByValue in userByGroupByValue) {
          userByGroupByValue[groupByValue].push(user);
        } else {
          userByGroupByValue[groupByValue] = [user];
        }
      } else {
        if ("all" in userByGroupByValue) {
          userByGroupByValue["all"].push(user);
        } else {
          userByGroupByValue["all"] = [user];
        }
      }
    }

    let currentY = 0;
    Object.keys(userByGroupByValue).forEach((groupByValue) => {
      const groupedUsers = userByGroupByValue[groupByValue];
      const groupByIsExpanded =
        filterState.groupBy && graphState.expandedIds.includes(groupByValue);
      const groupByIsCollapsed =
        filterState.groupBy && !graphState.expandedIds.includes(groupByValue);

      if (groupByIsExpanded) {
        userNodes.push({
          kind: "groupBy",
          nodeId: groupByValue,
          count: groupedUsers.length,
          label: groupByValue,
          height: BASE_NODE_HEIGHT,
          y: currentY,
        });
        currentY += BASE_NODE_HEIGHT + NODE_PADDING;
      }

      groupedUsers.forEach((userData) => {
        userNodes.push({
          kind: "user",
          nodeId: userData.id,
          name: userData.fullName,
          email: userData.email,
          team: userData.teamAttr ?? undefined,
          position: userData.position,
          avatarUrl: userData.avatarUrl,
          url: getResourceUrlNew({
            entityId: userData.id,
            entityType: EntityType.User,
          }),
          height: groupByIsCollapsed ? BASE_NODE_HEIGHT : userNodeHeight,
          depth: filterState.groupBy ? 1 : 0,
          y: currentY,
          x: filterState.groupBy ? NESTED_NODE_MARGIN : 0,
        });
        if (!groupByIsCollapsed) {
          currentY += userNodeHeight + NODE_PADDING;
        }
      });

      if (groupByIsCollapsed) {
        userNodes.push({
          kind: "groupBy",
          nodeId: groupByValue,
          count: groupedUsers.length,
          label: groupByValue,
          height: BASE_NODE_HEIGHT,
          y: currentY,
        });
        currentY += BASE_NODE_HEIGHT + NODE_PADDING;
      }
    });

    const groups = data?.groups ?? [];
    const groupNodes: GroupNodeData[] = [];
    let numVisibleGroups = 0;

    const groupBindingSet = new Set<string>();
    groups.forEach((groupData) => {
      // For groups in a group binding, show a link above each
      // group node except the first one in each binding.
      let showLinkAbove = false;
      if (groupData.groupBindingId) {
        showLinkAbove = groupBindingSet.has(groupData.groupBindingId);
        groupBindingSet.add(groupData.groupBindingId);
      }
      if (
        (!shouldFilter || visibleNodeIds.has(groupData.id)) &&
        !isHidingGroups
      ) {
        groupNodes.push({
          kind: "group",
          nodeId: groupData.id,
          name: groupData.name,
          userCount: groupData.numGroupUsers,
          url: getResourceUrlNew({
            entityId: groupData.id,
            entityType: EntityType.Group,
          }),
          groupType: groupData.groupType,
          isManaged: groupData.isManaged,
          isOnCallSynced: groupData.isOnCallSynced,
          groupBinding: groupData.groupBinding ?? undefined,
          showLinkAbove,
          loading: false,
          children: [],
          height: BASE_NODE_HEIGHT,
          y:
            numVisibleGroups * (BASE_NODE_HEIGHT + NODE_PADDING) +
            GROUPS_VERTICAL_OFFSET,
          x: BASE_NODE_WIDTH + NODE_GAP_X,
        });
        numVisibleGroups += 1;
      }
    });

    const resourceNodes: TreeNodeData[] = [];
    let numVisibleResources = 0;
    // Layout resource nodes, handling any child resources and roles.
    // Displays each role as a nested separate node under the resource node it belongs to.
    // Currently, the max depth is 2 - if a child resource has roles.
    topLevelResources.forEach((resourceData) => {
      const isExpanded = graphState.expandedIds.includes(resourceData.id);
      const childResources = childResourcesByParentId[resourceData.id] ?? [];
      // When "Show highlighted only" is on, show any parent resources if any of its child resources are highlighted.
      const isVisible =
        !shouldFilter ||
        visibleNodeIds.has(resourceData.id) ||
        childResources.some((childResource) =>
          visibleNodeIds.has(childResource.id)
        );

      if (!isVisible) {
        return;
      }

      // For collapsed resource nodes, layout any nested nodes behind the collapsed node.
      // This ensures that links to any nested nodes still render, and point to the parent node instead.
      if (!isExpanded) {
        const depth = 0;

        // Add collapsed child resources
        childResources.forEach((childResource) => {
          if (!shouldFilter || visibleNodeIds.has(childResource.id)) {
            resourceNodes.push(
              makeResourceNode(childResource, 0, numVisibleResources)
            );
          }

          // Add collapsed child resource roles
          const roles = rolesByResourceId[childResource.id] ?? [];
          roles.forEach((role) => {
            const nodeId = makeRolelNodeId(childResource.id, role);
            if (!shouldFilter || visibleNodeIds.has(nodeId)) {
              resourceNodes.push(
                makeRoleNode(
                  role,
                  childResource.id,
                  childResource.isManaged,
                  depth,
                  numVisibleResources,
                  isExpanded
                )
              );
            }
          });
        });

        // Add collapsed roles
        const roles = rolesByResourceId[resourceData.id] ?? [];
        roles.forEach((role) => {
          const nodeId = makeRolelNodeId(resourceData.id, role);
          if (!shouldFilter || visibleNodeIds.has(nodeId)) {
            resourceNodes.push(
              makeRoleNode(
                role,
                resourceData.id,
                resourceData.isManaged,
                depth,
                numVisibleResources,
                isExpanded
              )
            );
          }
        });
      }

      // Add the top level resource node
      const resourceNode = makeResourceNode(
        resourceData,
        0,
        numVisibleResources
      );
      resourceNode.expandable =
        resourceData.id in childResourcesByParentId ||
        rolesByResourceId[resourceData.id]?.length > 0;
      resourceNode.numChildResources =
        childResourcesByParentId[resourceData.id]?.length;
      resourceNode.numRoles = rolesByResourceId[resourceData.id]?.length;
      resourceNodes.push(resourceNode);
      numVisibleResources += 1;

      // For expanded resource nodes, layout all child resources and roles
      // as nested nodes under the top level node.
      if (isExpanded) {
        const childResources = childResourcesByParentId[resourceData.id] ?? [];
        childResources.forEach((childResource) => {
          const childNodeIsExpanded = graphState.expandedIds.includes(
            childResource.id
          );

          if (!childNodeIsExpanded) {
            const roles = rolesByResourceId[childResource.id] ?? [];
            roles.forEach((role) => {
              const nodeId = makeRolelNodeId(childResource.id, role);
              if (!shouldFilter || visibleNodeIds.has(nodeId)) {
                resourceNodes.push(
                  makeRoleNode(
                    role,
                    childResource.id,
                    childResource.isManaged,
                    1,
                    numVisibleResources,
                    isExpanded
                  )
                );
              }
            });
          }
          const resourceNode = makeResourceNode(
            childResource,
            1,
            numVisibleResources
          );
          resourceNode.expandable =
            rolesByResourceId[childResource.id]?.length > 0;
          resourceNode.numRoles = rolesByResourceId[childResource.id]?.length;

          if (!shouldFilter || visibleNodeIds.has(childResource.id)) {
            resourceNodes.push(resourceNode);
            numVisibleResources += 1;
          }

          if (childNodeIsExpanded) {
            const roles = rolesByResourceId[childResource.id] ?? [];
            roles.forEach((role) => {
              const nodeId = makeRolelNodeId(childResource.id, role);
              if (!shouldFilter || visibleNodeIds.has(nodeId)) {
                resourceNodes.push(
                  makeRoleNode(
                    role,
                    childResource.id,
                    childResource.isManaged,
                    2,
                    numVisibleResources,
                    isExpanded
                  )
                );
                numVisibleResources += 1;
              }
            });
          }
        });

        const roles = rolesByResourceId[resourceData.id] ?? [];
        roles.forEach((role) => {
          const nodeId = makeRolelNodeId(resourceData.id, role);
          if (!shouldFilter || visibleNodeIds.has(nodeId)) {
            resourceNodes.push(
              makeRoleNode(
                role,
                resourceData.id,
                resourceData.isManaged,
                1,
                numVisibleResources,
                isExpanded
              )
            );
            numVisibleResources += 1;
          }
        });
      }
    });

    const rootNode: TreeNodeData = {
      height: 0,
      kind: "ghost",
      nodeId: "root",
      children: [...userNodes, ...groupNodes, ...resourceNodes],
    };
    return hierarchy(rootNode);
  }, [
    data,
    filterState.attributesShown,
    filterState.groupBy,
    isHidingGroups,
    shouldFilter,
    graphState,
    childResourcesByParentId,
    topLevelResources,
    rolesByResourceId,
    visibleNodeIds,
  ]);

  return (
    <GraphContext.Provider value={{ graphState, graphDispatch }}>
      <ColumnHeaderV3
        title="Explore"
        icon={{ type: "name", icon: "department" }}
        includeDefaultActions
      />
      <div className={styles.corinthianContainer}>
        {graphState.loading || loading ? <VizLoader /> : null}
        {graphState.error && hasSelected ? (
          <div className={styles.error}>
            {graphState.error}
            <Icon
              name="x"
              size="xs"
              onClick={() =>
                graphDispatch({
                  type: "SET_ERROR",
                  payload: {
                    error: undefined,
                  },
                })
              }
            />
          </div>
        ) : null}
        <Sidebar />
        <ParentSize>
          {({ width, height }) => (
            <ZoomingDragPane
              width={width}
              height={height}
              onSort={() => setSorted((prev) => !prev)}
              sorted={sorted}
            >
              <Tree<TreeNodeData> root={treeData}>
                {(tree) => {
                  const { userNodes, groupNodes, resourceNodes } = getNodes(
                    tree.descendants()
                  );
                  const {
                    resourceUserLinks,
                    groupUserLinks,
                    groupResourceLinks,
                  } = getLinks(
                    tree.descendants(),
                    data?.userResourceEdges ?? [],
                    data?.groupResourceEdges ?? [],
                    data?.userGroupEdges ?? []
                  );
                  return (
                    <Group top={160} left={48}>
                      {/* We have to render in this order so that the ends of each link
        will render above their connected nodes, but the link paths won't
        render in front of any nodes that are in the middle. */}
                      {resourceNodes}
                      {userNodes}
                      {resourceUserLinks}
                      {groupNodes}
                      {groupResourceLinks}
                      {groupUserLinks}
                      <ColumnHeader
                        key="users"
                        x={0}
                        y={-(2 * HEADER_HEIGHT)}
                        title="Users"
                        iconName="user"
                        count={data?.users.length}
                      />
                      <ColumnHeader
                        key="groups"
                        x={BASE_NODE_WIDTH + NODE_GAP_X}
                        y={-(2 * HEADER_HEIGHT)}
                        title="Groups"
                        iconName="users"
                        isShowing={!filterState.hideGroups}
                        onShowHideToggle={(show) => {
                          logEvent({
                            name: "viz_hide_groups_click",
                            properties: {
                              isShowing: !filterState.hideGroups,
                            },
                          });
                          filterDispatch({
                            type: "UPDATE_HIDE_GROUPS",
                            payload: { value: !show },
                          });
                        }}
                        count={data?.groups.length}
                      />
                      <ColumnHeader
                        key="resources"
                        x={2 * (BASE_NODE_WIDTH + NODE_GAP_X)}
                        y={-(2 * HEADER_HEIGHT)}
                        title="Resources"
                        iconName="cube"
                        count={data?.resources.length}
                      />
                      {!hasSelected && (
                        <foreignObject height={600} width={1000}>
                          <div className={styles.emptyState}>
                            <Icon
                              name="department"
                              color="gray200"
                              size="xxxl"
                            />
                            <div className={sprinkles({ marginTop: "lg" })}>
                              Add Users, groups or resources
                            </div>
                            <div>on the left to get started</div>
                          </div>
                        </foreignObject>
                      )}
                    </Group>
                  );
                }}
              </Tree>
            </ZoomingDragPane>
          )}
        </ParentSize>
      </div>
    </GraphContext.Provider>
  );
};

const getNodes = (nodes: HierarchyPointNode<TreeNodeData>[]) => {
  const userNodes: JSX.Element[] = [];
  const groupNodes: JSX.Element[] = [];
  const resourceNodes: JSX.Element[] = [];

  const uniqueKeys = new Set();

  nodes.forEach((node) => {
    if (uniqueKeys.has(node.data.nodeId)) return;
    uniqueKeys.add(node.data.nodeId);

    switch (node.data.kind) {
      case "user":
        userNodes.push(
          // Our custom x,y are opposite the default calculated x,y because we are displaying
          // the tree from left to right instead of top to down
          <Group
            className={styles.nodeWithHoverMenu}
            key={node.data.nodeId}
            top={node.data.y ?? node.x}
            left={node.data.x ?? node.y}
          >
            <UserNode key={node.data.nodeId} data={node.data} />
          </Group>
        );
        break;
      case "groupBy":
        userNodes.push(
          <Group key={node.data.nodeId} top={node.data.y} left={node.data.x}>
            <GroupByNode key={node.data.nodeId} data={node.data} />
          </Group>
        );

        break;
      case "group":
        groupNodes.push(
          <Group
            className={styles.nodeWithHoverMenu}
            key={node.data.nodeId}
            top={node.data.y ?? node.x}
            left={node.data.x ?? node.y}
          >
            <GroupNode
              key={node.data.nodeId}
              data={node.data}
              onClick={() => {}}
              onCmdClick={() => {}}
            />
          </Group>
        );
        break;
      case "resource":
        resourceNodes.push(
          <Group
            className={styles.nodeWithHoverMenu}
            key={node.data.nodeId}
            top={node.data.y ?? node.x}
            left={node.data.x ?? node.y}
          >
            <ResourceNode key={node.data.nodeId} data={node.data} />
          </Group>
        );
        break;
      case "resourceRole":
        // skip collapsed roles from rendering so that we don't invisible
        // components into our DOM and hurt performance
        if (!node.data.visible) {
          return;
        }
        resourceNodes.push(
          <Group
            key={node.data.nodeId}
            top={node.data.y ?? node.x}
            left={node.data.x ?? node.y}
          >
            <RoleNode key={node.data.nodeId} data={node.data} />
          </Group>
        );
        break;
      case "ghost":
        break;
      default:
        logError("unknown tree descendant type");
        return;
    }
  });
  return {
    userNodes,
    groupNodes,
    resourceNodes,
  };
};

const getLinks = (
  nodes: HierarchyPointNode<TreeNodeData>[],
  userResourceEdges: UserResourceEdge[],
  groupResourceEdges: GroupResourceEdge[],
  userGroupEdges: UserGroupEdge[]
) => {
  const nodesById: { [nodeId: string]: HierarchyPointNode<TreeNodeData> } = {};
  nodes.forEach((node) => {
    nodesById[node.data.nodeId] = node;
  });

  const resourceUserLinks: JSX.Element[] = [];
  const groupUserLinks: JSX.Element[] = [];
  const groupResourceLinks: JSX.Element[] = [];

  Object.values(userResourceEdges).forEach((userResourceEdge) => {
    const targetNodeId = makeRolelNodeId(
      userResourceEdge.resourceId,
      userResourceEdge.accessLevel
    );
    const source = nodesById[userResourceEdge.userId];
    const target = nodesById[targetNodeId];

    if (source && target) {
      resourceUserLinks.push(
        <Link
          key={`${source.data.nodeId}-${target.data.nodeId}`}
          link={{
            source,
            target,
          }}
          metadata={userResourceEdge.metadata ?? undefined}
        />
      );
    }
  });

  Object.values(groupResourceEdges).forEach((groupResourceEdge) => {
    const targetNodeId = makeRolelNodeId(
      groupResourceEdge.resourceId,
      groupResourceEdge.accessLevel
    );
    const source = nodesById[groupResourceEdge.groupId];
    const target = nodesById[targetNodeId];

    if (source && target) {
      groupResourceLinks.push(
        <Link
          key={`${source.data.nodeId}-${target.data.nodeId}`}
          link={{
            source,
            target,
          }}
          metadata={groupResourceEdge.metadata ?? undefined}
        />
      );
    }
  });

  Object.values(userGroupEdges).forEach((userGroupEdge) => {
    const source = nodesById[userGroupEdge.userId];
    const target = nodesById[userGroupEdge.groupId];
    if (source && target) {
      groupUserLinks.push(
        <Link
          key={`${source.data.nodeId}-${target.data.nodeId}`}
          link={{
            source,
            target,
          }}
          metadata={userGroupEdge.metadata ?? undefined}
        />
      );
    }
  });

  return {
    resourceUserLinks: resourceUserLinks,
    groupUserLinks: groupUserLinks,
    groupResourceLinks: groupResourceLinks,
  };
};

const makeResourceNode = (
  resourceData: ResourcePreviewLargeFragment,
  depth: number,
  numNodesBefore: number
): ResourceNodeData => {
  return {
    kind: "resource",
    nodeId: resourceData.id,
    name: resourceData.name,
    url: getResourceUrlNew({
      entityId: resourceData.id,
      entityType: EntityType.Resource,
    }),
    resourceType: resourceData.resourceType,
    isManaged: resourceData.isManaged,
    depth,
    height: BASE_NODE_HEIGHT,
    y: numNodesBefore * (BASE_NODE_HEIGHT + NODE_PADDING),
    x: 2 * (BASE_NODE_WIDTH + NODE_GAP_X) + depth * NESTED_NODE_MARGIN,
  };
};

const makeRoleNode = (
  role: ResourceAccessLevelFragment,
  resourceId: string,
  isManaged: boolean,
  depth: number,
  numNodesBefore: number,
  visible: boolean
): ResourceRoleNodeData => {
  return {
    kind: "resourceRole",
    nodeId: makeRolelNodeId(resourceId, role),
    roleName: role.accessLevelName,
    resourceId: resourceId,
    isManaged: isManaged,
    roleRemoteId: role.accessLevelRemoteId,
    visible: visible,

    depth,
    height: BASE_NODE_HEIGHT,
    y: numNodesBefore * (BASE_NODE_HEIGHT + NODE_PADDING),
    x: 2 * (BASE_NODE_WIDTH + NODE_GAP_X) + depth * NESTED_NODE_MARGIN,
  };
};

export default OrgVisualization;
