import "./style.css";

import { format } from "date-fns";
import * as React from "react";
import { useState } from "react";
import { DayPicker } from "react-day-picker";
import ReactDOM from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { usePopper } from "react-popper";

import * as styles from "./DatePicker.css";

// https://date-fns.org/v2.30.0/docs/format
type DateFormat =
  | "PPPP" // Friday, April 29th, 1453
  | "PPP"; // April 29th, 1453

interface Props {
  selectedDate?: Date;
  setSelectedDate: (date?: Date) => void;
  dateFormat: DateFormat;
  fromDate?: Date;
}

const DatePicker: React.FC<Props> = (props) => {
  const [showPopover, setShowPopover] = useState(false);

  // As recommended by react-popper, useState instead of useRef
  // https://popper.js.org/react-popper/v2/#example
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement
  );

  const handleDateChange = (date?: Date) => {
    setShowPopover(false);
    props.setSelectedDate(date);
  };

  const body = document.querySelector("body");
  if (!body) return null;

  return (
    <>
      <div
        ref={setReferenceElement}
        className={styles.input({
          hasSelectedDate: Boolean(props.selectedDate),
        })}
        onClick={() => setShowPopover(true)}
      >
        {props.selectedDate
          ? format(props.selectedDate, props.dateFormat)
          : "Select a date"}
      </div>
      {showPopover
        ? ReactDOM.createPortal(
            <OutsideClickHandler onOutsideClick={() => setShowPopover(false)}>
              <div
                ref={setPopperElement}
                className={styles.picker}
                style={popperStyles.popper}
                {...attributes.popper}
              >
                <DayPicker
                  mode="single"
                  selected={props.selectedDate}
                  onSelect={handleDateChange}
                  fromDate={props.fromDate}
                />
              </div>
            </OutsideClickHandler>,
            body
          )
        : null}
    </>
  );
};

export default DatePicker;
