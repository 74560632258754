import { ColumnContainer } from "components/column/Column";
import { Redirect, Route, Switch } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import CreateConfigurationTemplateColumn from "views/templates/configurations/CreateConfigurationTemplateColumn";
import TemplateMapping from "views/templates/configurations/TemplateMapping";
import ConfigurationTemplatesColumn from "views/templates/ConfigurationTemplatesColumn";
import ConfigurationTemplatesContent from "views/templates/ConfigurationTemplatesContent";
import RequestTemplatesColumn from "views/templates/RequestTemplatesColumn";

import ConfigTemplateDetail from "./ConfigTemplateDetail";
import RequestTemplateDetail from "./RequestTemplateDetail";
import RequestTemplatesContent from "./RequestTemplatesContent";
import TemplatesColumn from "./TemplatesColumn";
import TemplatesColumnV3 from "./TemplatesColumnV3";

const Templates = () => {
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  if (hasV3Nav) {
    return (
      <Switch>
        <Route exact path={"/templates"}>
          <Redirect to="/templates/requests" />
        </Route>
        <Route
          exact
          path={[
            "/templates/requests",
            "/templates/configurations",
            "/templates/configurations/mapping",
          ]}
          component={TemplatesComponentV3}
        />
        <Route
          exact
          path={"/templates/configurations/:configurationTemplateId"}
          component={ConfigurationTemplatesComponentV3}
        />
        <Route
          exact
          path={"/templates/requests/:requestTemplateId"}
          component={RequestTemplatesComponentV3}
        />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path={"/templates"} component={TemplatesComponent} />
      <Route
        exact
        path={"/templates/requests"}
        component={RequestTemplatesComponent}
      />
      <Route
        exact
        path={"/templates/configurations"}
        component={ConfigurationTemplatesComponent}
      />
      <Route
        exact
        path={"/templates/configurations/create"}
        component={CreateConfigurationTemplateComponent}
      />
      <Route
        exact
        path={"/templates/configurations/mapping"}
        component={TemplateMappingComponent}
      />
      <Route
        exact
        path={"/templates/configurations/:configurationTemplateId"}
        component={ConfigurationTemplatesComponent}
      />
      <Route
        exact
        path={"/templates/requests/:requestTemplateId"}
        component={RequestTemplatesComponent}
      />
    </Switch>
  );
};

const RequestTemplatesComponent = () => {
  return (
    <ColumnContainer>
      <RequestTemplatesColumn />
      <RequestTemplatesContent />
    </ColumnContainer>
  );
};

const RequestTemplatesComponentV3 = () => {
  return (
    <ColumnContainer>
      <RequestTemplateDetail />
    </ColumnContainer>
  );
};

const CreateConfigurationTemplateComponent = () => {
  return (
    <ColumnContainer>
      <ConfigurationTemplatesColumn />
      <CreateConfigurationTemplateColumn />
    </ColumnContainer>
  );
};

const ConfigurationTemplatesComponent = () => {
  return (
    <ColumnContainer>
      <ConfigurationTemplatesColumn />
      <ConfigurationTemplatesContent />
    </ColumnContainer>
  );
};

const ConfigurationTemplatesComponentV3 = () => {
  return (
    <ColumnContainer>
      <ConfigTemplateDetail />
    </ColumnContainer>
  );
};

const TemplateMappingComponent = () => {
  return (
    <ColumnContainer>
      <ConfigurationTemplatesColumn />
      <TemplateMapping />
    </ColumnContainer>
  );
};

const TemplatesComponent = () => {
  return (
    <ColumnContainer>
      <TemplatesColumn />
    </ColumnContainer>
  );
};

const TemplatesComponentV3 = () => {
  return (
    <ColumnContainer>
      <TemplatesColumnV3 />
    </ColumnContainer>
  );
};

export default Templates;
