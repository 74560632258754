import {
  AuthType,
  AwsIdentityCenterImportSetting,
  ConnectionPreviewLargeFragment,
  ConnectionType,
  ImportSetting,
  Maybe,
  OidcProviderType,
  useOidcProviderQuery,
  useUpdateConnectionMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import ColumnContent from "components/column/ColumnContent";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Button,
  ButtonV3,
  FormRow,
  Input,
  Loader,
  Select,
  Switch,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useContext, useEffect, useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import AppContext from "views/app/AppContext";
import AppHeader from "views/apps/AppHeader";
import { AWSServicePrincipalOption } from "views/requests/utils";

import * as styles from "./ConnectionMetadataAndCredsSetupV3.css";

const SSO_INSTANCE_ARN_REGEX = /^arn:(aws|aws-us-gov|aws-cn|aws-iso|aws-iso-b):sso:::instance\/(sso)?ins-[a-zA-Z0-9-.]{16}$/;
const AWS_ACCOUNT_REGEX = /^\d{12}$/;
const IDENTITY_STORE_ID_REGEX = /^d-[0-9a-f]{10}$|^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
const ACCESS_PORTAL_URL_REGEX = /^https:\/\/.+\.awsapps\.com\/start$/;
const AWS_REGION_REGEX = /[a-z-]*-[a-z]*-[a-z0-9]*/;
const CLOUDTRAIL_SQS_URL_REGEX = /^https:\/\/sqs\.[a-z0-9-]+\.amazonaws\.com\/\d+\/[a-zA-Z0-9_-]+$/;

type AwsConnectionMetadataSetupProps = {
  connection: ConnectionPreviewLargeFragment;
};

export const AwsConnectionMetadataSetup = (
  props: AwsConnectionMetadataSetupProps
) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const hasEDS = useFeatureFlag(FeatureFlag.AwsEds);
  const maybeConnectionMetadata = props.connection.metadata;
  if (maybeConnectionMetadata?.__typename !== "AWSSSOConnectionMetadata") {
    logError(
      new Error(
        `Invalid AWS SSO metadata: ` + maybeConnectionMetadata?.__typename
      )
    );
  }
  const connectionMetadata =
    maybeConnectionMetadata?.__typename === "AWSSSOConnectionMetadata"
      ? maybeConnectionMetadata
      : null;

  const { appState } = useContext(AppContext);
  const { authState } = useContext(AuthContext);
  const orgId = authState.user?.user.organizationId;
  const [credentialsErrorMessage, setCredentialsErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [errors, setErrors] = useState<string[]>([]);
  const { displaySuccessToast } = useToast();
  const hasOnPremRoleChaining = useFeatureFlag(FeatureFlag.OnPremRoleChaining);

  const [managementAccountId, setManagementAccountId] = useState(
    connectionMetadata?.managementAccountId ?? ""
  );
  const [ssoInstanceArn, setSsoInstanceArn] = useState(
    connectionMetadata?.ssoInstanceArn ?? ""
  );
  const [identityStoreId, setIdentityStoreId] = useState(
    connectionMetadata?.identityStoreId ?? ""
  );
  const [accessPortalUrl, setAccessPortalUrl] = useState(
    connectionMetadata?.accessPortalUrl ?? ""
  );
  const [awsRegion, setAwsRegion] = useState(
    connectionMetadata?.awsRegion ?? ""
  );
  const [awsOrganizationEnabled, setAwsOrganizationEnabled] = useState(
    connectionMetadata?.awsOrganizationEnabled ?? false
  );
  const [awsSsoEnabled, setAwsSsoEnabled] = useState(
    connectionMetadata?.awsSsoEnabled ?? false
  );
  const [awsServicePrincipalOption, setAwsServicePrincipalOption] = useState<
    AWSServicePrincipalOption | undefined
  >(
    connectionMetadata?.ec2RoleChainingEnabled
      ? AWSServicePrincipalOption.Ec2InstanceRole
      : connectionMetadata?.podRoleChainingEnabled
      ? AWSServicePrincipalOption.PodRole
      : appState.isOnPrem
      ? AWSServicePrincipalOption.IamUserCredentials
      : undefined
  );

  const [cloudtrailEventsSqsUrl, setCloudTrailEventsSqsUrl] = useState(
    connectionMetadata?.cloudtrailEventsSqsUrl ?? ""
  );

  const [mode, setMode] = useState<"view" | "edit">("view");

  const [accessKeyId, setAccessKeyId] = useState("");
  const [secretAccessKey, setSecretAccessKey] = useState("");
  const defaultVal = "**************";

  const editMode = mode === "edit";

  const [updateConnectionMutation, { loading }] = useUpdateConnectionMutation();
  const metadataDisabled =
    (!awsSsoEnabled && !awsOrganizationEnabled) ||
    managementAccountId === "" ||
    (awsSsoEnabled &&
      [ssoInstanceArn, identityStoreId, accessPortalUrl, awsRegion].some(
        (field) => field === ""
      ));
  // disable credentials if some but not all are filled out
  const credentialsDisabled =
    appState.isOnPrem &&
    [accessKeyId, secretAccessKey].some((field) => field === "") &&
    ![accessKeyId, secretAccessKey].every((field) => field === "");
  const [hasOidcProvider, setHasOidcProvider] = useState<boolean>(false);
  const { data, loading: oidcProviderLoading } = useOidcProviderQuery({
    variables: {
      input: {
        oidcProviderType: OidcProviderType.AwsSession,
      },
    },
  });

  useEffect(() => {
    switch (data?.oidcProvider.__typename) {
      case "OidcProviderResult":
        setHasOidcProvider(true);
        break;
      case "OidcProviderNotFoundError":
        setHasOidcProvider(false);
        setAwsOrganizationEnabled(false); // Disable AWS Org management if no OIDC provider
        break;
      case undefined:
        break;
      default:
        logError(new Error(`Unexpected OIDC provider result`));
    }
  }, [data?.oidcProvider.__typename]);

  const resetConfig = () => {
    setAwsSsoEnabled(connectionMetadata?.awsSsoEnabled ?? false);
    setAwsOrganizationEnabled(
      connectionMetadata?.awsOrganizationEnabled ?? false
    );
    setManagementAccountId(connectionMetadata?.managementAccountId ?? "");
    setSsoInstanceArn(connectionMetadata?.ssoInstanceArn ?? "");
    setIdentityStoreId(connectionMetadata?.identityStoreId ?? "");
    setAccessPortalUrl(connectionMetadata?.accessPortalUrl ?? "");
    setAwsRegion(connectionMetadata?.awsRegion ?? "");
  };

  const credentials =
    !appState.isOnPrem ||
    credentialsDisabled ||
    awsServicePrincipalOption !== AWSServicePrincipalOption.IamUserCredentials
      ? {}
      : {
          credentials: {
            authType: AuthType.AwsSso,
            awsSso: {
              accessKeyId,
              secretAccessKey,
            },
          },
        };

  const handleEditMetadata = async () => {
    const metadataErrors: string[] = [];
    if (!managementAccountId.match(AWS_ACCOUNT_REGEX)) {
      metadataErrors.push("AWS Management Account pattern is invalid");
    }
    if (
      cloudtrailEventsSqsUrl.length > 0 &&
      !cloudtrailEventsSqsUrl.match(CLOUDTRAIL_SQS_URL_REGEX)
    ) {
      metadataErrors.push("SQS URL pattern is invalid");
    }
    if (awsSsoEnabled) {
      if (!ssoInstanceArn.match(SSO_INSTANCE_ARN_REGEX)) {
        metadataErrors.push("SSO Instance ARN pattern is invalid");
      }
      if (!identityStoreId.match(IDENTITY_STORE_ID_REGEX)) {
        metadataErrors.push("Identity Store ID pattern is invalid");
      }
      if (!accessPortalUrl.match(ACCESS_PORTAL_URL_REGEX)) {
        metadataErrors.push(
          "Access portal URL pattern is invalid, should match: https://x.awsapps.com/start"
        );
      }
      if (!awsRegion.match(AWS_REGION_REGEX)) {
        metadataErrors.push("AWS Region pattern is invalid");
      }
    } else {
      setSsoInstanceArn("");
      setIdentityStoreId("");
      setAccessPortalUrl("");
      setAwsRegion("");
    }
    setErrors(metadataErrors);
    if (metadataErrors.length) {
      return;
    }
    try {
      let awsIdentityCenterImportSetting =
        AwsIdentityCenterImportSetting.Provisioned;
      if (
        props.connection.metadata?.__typename === "AWSSSOConnectionMetadata" &&
        !!props.connection.metadata.awsIdentityCenterImportSetting
      ) {
        awsIdentityCenterImportSetting =
          props.connection.metadata.awsIdentityCenterImportSetting;
      }

      let awsOrganizationImportSetting = ImportSetting.Tagged;
      if (
        props.connection.metadata?.__typename === "AWSSSOConnectionMetadata" &&
        !!props.connection.metadata.awsOrganizationImportSetting
      ) {
        awsOrganizationImportSetting =
          props.connection.metadata.awsOrganizationImportSetting;
      }
      try {
        const { data } = await updateConnectionMutation({
          variables: {
            input: {
              id: props.connection.id,
              metadata: {
                connectionType: ConnectionType.AwsSso,
                awsSso: {
                  managementAccountId,
                  ssoInstanceArn,
                  identityStoreId,
                  accessPortalUrl,
                  awsRegion,
                  awsOrganizationEnabled,
                  awsSsoEnabled,
                  awsIdentityCenterImportSetting,
                  awsOrganizationImportSetting,
                  ec2RoleChainingEnabled:
                    awsServicePrincipalOption ===
                    AWSServicePrincipalOption.Ec2InstanceRole,
                  podRoleChainingEnabled:
                    awsServicePrincipalOption ===
                    AWSServicePrincipalOption.PodRole,
                  cloudtrailEventsSqsUrl,
                },
              },
              ...credentials,
            },
          },
        });
        if (data) {
          switch (data.updateConnection.__typename) {
            case "UpdateConnectionResult":
              displaySuccessToast("Success: app setup updated");
              setErrors([]);
              setMode("view");
              break;
            case "ConnectionNotFoundError":
            case "ConnectionBadMetadataError":
            case "UserFacingError":
              logWarning(new Error(data.updateConnection.message));
              setErrors([data.updateConnection.message]);
              break;
            default:
              logError(new Error(`failed to update app setup`));
              setErrors(["Error: failed to update app setup"]);
          }
        }
      } catch (error) {
        logError(error, `failed to update app metadata`);
        setErrors(["Error: failed to update app setup"]);
      }
    } catch (error) {
      logError(error, `failed to update app metadata`);
      setErrors(["Error: failed to update app setup"]);
    }
  };

  const headerButtons =
    mode === "view" ? (
      <Button
        label="Edit"
        leftIconName="edit"
        borderless
        onClick={() => setMode("edit")}
        size="md"
      />
    ) : (
      <div className={sprinkles({ display: "flex", gap: "sm" })}>
        <Button
          label="Cancel"
          borderless
          size="md"
          onClick={() => {
            setMode("view");
            setErrors([]);
            setCredentialsErrorMessage("");
            setAccessKeyId("");
            setSecretAccessKey("");
            resetConfig();
          }}
        />
        <Button
          label={loading ? "Saving..." : "Save"}
          leftIconName="check"
          type="primary"
          onClick={handleEditMetadata}
          disabled={
            metadataDisabled ||
            credentialsDisabled ||
            (appState.isOnPrem && !awsServicePrincipalOption)
          }
          disabledTooltip={
            metadataDisabled
              ? "Metadata is incomplete"
              : credentialsDisabled
              ? "If you fill out credentials, you must fill out both fields"
              : ""
          }
          size="md"
        />
      </div>
    );

  let opalServicePrincipalOptions: [AWSServicePrincipalOption] = [
    AWSServicePrincipalOption.IamUserCredentials,
  ];
  if (hasOnPremRoleChaining) {
    opalServicePrincipalOptions.unshift(
      AWSServicePrincipalOption.Ec2InstanceRole,
      AWSServicePrincipalOption.PodRole
    );
  }

  const content = (
    <div
      className={
        hasV3
          ? styles.setupContainer
          : sprinkles({
              padding: "md",
              display: "flex",
              flexDirection: "column",
              gap: "xl",
            })
      }
    >
      {errors.length > 0 && (
        <>
          {errors.map((err) => (
            <Banner type="error" message={err} />
          ))}
        </>
      )}
      {credentialsErrorMessage && (
        <Banner type="error" message={credentialsErrorMessage} />
      )}
      {hasV3 && (
        <FormRow title="App Setup">
          {authState.user?.isAdmin && (
            <div
              className={sprinkles({
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
              })}
            >
              {mode === "view" ? (
                <ButtonV3
                  type="mainBorderless"
                  label="Edit Setup"
                  leftIconName="edit"
                  size="md"
                  onClick={() => setMode("edit")}
                />
              ) : (
                <ButtonV3
                  type="mainBorderless"
                  label="Save"
                  leftIconName="check"
                  size="md"
                  onClick={handleEditMetadata}
                />
              )}
            </div>
          )}
        </FormRow>
      )}
      {appState.isOnPrem && (
        <FormRow title="Opal Service Principal">
          <Select
            options={opalServicePrincipalOptions}
            value={awsServicePrincipalOption}
            getOptionLabel={(option) => option}
            onChange={(value) => setAwsServicePrincipalOption(value)}
            placeholder={"Select option"}
            disabled={!editMode}
          />
        </FormRow>
      )}
      {appState.isOnPrem &&
        awsServicePrincipalOption ==
          AWSServicePrincipalOption.IamUserCredentials && (
          <>
            <FormRow title="User access key ID">
              {mode == "edit" ? (
                <Input
                  onChange={setAccessKeyId}
                  placeholder="The access key ID associated with the AWS IAM user."
                  value={accessKeyId}
                />
              ) : (
                defaultVal
              )}
            </FormRow>
            <FormRow title="User secret access key">
              {mode == "edit" ? (
                <Input
                  onChange={setSecretAccessKey}
                  placeholder="The access key secret associated with the the AWS IAM user."
                  value={secretAccessKey}
                  type="password"
                />
              ) : (
                defaultVal
              )}
            </FormRow>
          </>
        )}
      <FormRow title="AWS Management or Delegated Administrator Account ID">
        {editMode ? (
          <Input
            onChange={setManagementAccountId}
            placeholder="Identifier of the AWS organization's management or delegated administrator account"
            value={managementAccountId}
          />
        ) : (
          managementAccountId
        )}
      </FormRow>
      {!hasOidcProvider && !oidcProviderLoading && (
        <Banner
          type="info"
          message={
            <>
              Opal uses OpenID Connect (OIDC) to authenticate your users when
              granting access to your AWS Organization. Please configure your
              OIDC provider in your{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/settings#aws-settings"
              >
                organization settings
              </a>{" "}
              to enable AWS Organizations management.
            </>
          }
        />
      )}
      <FormRow title="Enable AWS Organizations management">
        {oidcProviderLoading ? (
          <Loader size="sm" />
        ) : (
          <Switch
            disabled={!hasOidcProvider || !editMode}
            checked={hasOidcProvider && awsOrganizationEnabled}
            onChange={() => setAwsOrganizationEnabled(!awsOrganizationEnabled)}
            infoTooltip="Toggles management of IAM Roles, EC2 instances, EKS Clusters, and RDS Databases."
          />
        )}
      </FormRow>
      <FormRow title="Enable IAM Identity Center management">
        <Switch
          disabled={!editMode}
          checked={awsSsoEnabled}
          onChange={() => setAwsSsoEnabled(!awsSsoEnabled)}
          infoTooltip="Toggles management of AWS IAM Identity Center groups and permission sets in Opal."
        />
      </FormRow>
      {hasEDS && (
        <FormRow title="CloudTrail Events SQS Queue URL">
          {editMode ? (
            <Input
              onChange={setCloudTrailEventsSqsUrl}
              placeholder="URL of the CloudTrail notification queue"
              value={cloudtrailEventsSqsUrl}
            />
          ) : (
            cloudtrailEventsSqsUrl
          )}
        </FormRow>
      )}
      {awsSsoEnabled && (
        <>
          <FormRow title="AWS IAM Identity Center Region">
            {editMode ? (
              <Input
                onChange={setAwsRegion}
                placeholder="AWS Region String (i.e. us-east-2)"
                value={awsRegion}
              />
            ) : (
              awsRegion
            )}
          </FormRow>
          <FormRow title="AWS IAM Identity Center Instance ARN">
            {editMode ? (
              <Input
                onChange={setSsoInstanceArn}
                placeholder="ARN of the IAM Identity Center Instance"
                value={ssoInstanceArn}
              />
            ) : (
              ssoInstanceArn
            )}
          </FormRow>
          <FormRow title="AWS Identity Store ID">
            {editMode ? (
              <Input
                onChange={setIdentityStoreId}
                placeholder="ID of the AWS Identity Store instance"
                value={identityStoreId}
              />
            ) : (
              identityStoreId
            )}
          </FormRow>
          <FormRow title="AWS Access Portal URL">
            {editMode ? (
              <Input
                onChange={setAccessPortalUrl}
                placeholder="Access portal URL for the IAM Identity Center instance"
                value={accessPortalUrl}
              />
            ) : (
              accessPortalUrl
            )}
          </FormRow>
        </>
      )}
      <FormRow title="Your External ID">{orgId}</FormRow>
    </div>
  );

  return (
    <>
      {!hasV3 && <AppHeader editButtons={headerButtons} />}
      {hasV3 ? content : <ColumnContent>{content}</ColumnContent>}
    </>
  );
};

export default AwsConnectionMetadataSetup;
