import { useRiskScoreGraphDataQuery } from "api/generated/graphql";
import { ButtonV3, Skeleton } from "components/ui";
import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { RiskScoreRange } from "views/recommendations/RecommendationFilters";
import RiskScoreInformationModal from "views/recommendations/RiskScoreInformationModal";

import AccessOverTimeGraph from "./AccessOverTimeGraph";
import RiskScoreGraph from "./RiskScoreGraph";
import * as styles from "./ThreatCenter.css";

export enum RecommendationsGraphType {
  RiskScore = "riskScore",
  AccessOverTime = "accessOverTime",
}

export const getGraphHeaders = (
  graphType: RecommendationsGraphType
): { header: React.ReactNode; subheader: React.ReactNode } => {
  switch (graphType) {
    case RecommendationsGraphType.RiskScore:
      return {
        header: "Identity Security Posture",
        subheader: "Volume of resources by risk score",
      };
    case RecommendationsGraphType.AccessOverTime:
      return {
        header: "Identity Security Posture",
        subheader: (
          <>
            <span className={sprinkles({ color: "red700" })}>Unbound</span> vs{" "}
            <span className={sprinkles({ color: "green700" })}>Bound</span>{" "}
            direct access grants over the last 30 days
          </>
        ),
      };
  }
};

interface ThreatCenterProps {
  setRiskScoreFilter: (filter: RiskScoreRange | undefined) => void;
}

const ThreatCenter = ({ setRiskScoreFilter }: ThreatCenterProps) => {
  const { data, loading, error } = useRiskScoreGraphDataQuery();

  const [activeGraph, setActiveGraph] = useState(
    RecommendationsGraphType.RiskScore
  );

  const [
    showRiskScoreInformationModal,
    setShowRiskScoreInformationModal,
  ] = useState(false);

  const showJITGraph = useFeatureFlag(FeatureFlag.ShowJITGraph);

  if (loading) {
    return <Skeleton height="480px" width="100%" />;
  }

  if (!data || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const entitiesPerRiskScore = data.riskScoreGraphData.numEntitiesPerScore;
  const granularity = data.riskScoreGraphData.granularity;
  const overallRiskScore = data.riskScoreGraphData.overallRiskScore;

  return (
    <>
      <div className={styles.header}>Risk Center</div>
      <div className={styles.graphContainer}>
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
          })}
        >
          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "column",
            })}
          >
            <div
              className={sprinkles({
                display: "flex",
                gap: "sm",
                alignItems: "center",
              })}
            >
              <div
                className={sprinkles({
                  fontSize: "textLg",
                  fontWeight: "medium",
                })}
              >
                {getGraphHeaders(activeGraph).header}
              </div>
              {activeGraph === RecommendationsGraphType.RiskScore && (
                <PostureBadge riskScore={overallRiskScore} />
              )}
            </div>
            <div
              className={sprinkles({
                display: "flex",
                gap: "md",
                fontSize: "textMd",
                color: "gray700",
              })}
            >
              <div>{getGraphHeaders(activeGraph).subheader}</div>
              {activeGraph === RecommendationsGraphType.RiskScore &&
                granularity > 1 && <div>• = {granularity} resources</div>}
            </div>
          </div>
          <div
            className={sprinkles({
              display: "flex",
              gap: "xs",
              alignItems: "center",
            })}
          >
            {activeGraph == RecommendationsGraphType.RiskScore && (
              <ButtonV3
                size="sm"
                outline
                leftIconName={"help-circle"}
                type="mainBorderless"
                label="How are risk scores calculated?"
                onClick={() => setShowRiskScoreInformationModal(true)}
              />
            )}
            {showRiskScoreInformationModal && (
              <RiskScoreInformationModal
                onClose={() => setShowRiskScoreInformationModal(false)}
              />
            )}
            {showJITGraph && (
              <ButtonGroup
                buttons={[
                  {
                    label: "Risk Score",
                    onClick: () =>
                      setActiveGraph(RecommendationsGraphType.RiskScore),
                    selected:
                      activeGraph === RecommendationsGraphType.RiskScore,
                  },
                  {
                    label: "Access Over Time",
                    onClick: () =>
                      setActiveGraph(RecommendationsGraphType.AccessOverTime),
                    selected:
                      activeGraph === RecommendationsGraphType.AccessOverTime,
                  },
                ]}
              />
            )}
          </div>
        </div>
        {activeGraph == RecommendationsGraphType.AccessOverTime &&
        showJITGraph ? (
          <AccessOverTimeGraph />
        ) : (
          <RiskScoreGraph
            entitiesPerRiskScore={entitiesPerRiskScore}
            numEntitiesPerDot={data.riskScoreGraphData.granularity}
            handleBucketSelect={setRiskScoreFilter}
          />
        )}
      </div>
    </>
  );
};

const PostureBadge = ({ riskScore }: { riskScore: number }) => {
  let label = "Protected";
  let color: "green" | "yellow" | "red" | "orange" = "green";
  if (riskScore > 50) {
    label = "Critical";
    color = "red";
  } else if (riskScore > 30) {
    label = "Vulnerable";
    color = "orange";
  } else if (riskScore > 10) {
    label = "Strong";
    color = "yellow";
  }
  return <div className={styles.postureBadge({ color })}>{label}</div>;
};

export default ThreatCenter;
