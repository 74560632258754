import {
  EventFragment,
  PropagationTaskType,
  RequestFragment,
  RequestStatus,
} from "api/generated/graphql";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { PropagationStatusInfo } from "components/propagation/PropagationStatusModal";
import { CodeEditor, EntityIcon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { Link } from "react-router-dom";
import { RequestTicketPropagationBanner } from "views/requests/RequestTicketPropagationBanner";

import RequestEscalateManagerButton from "./actions/RequestEscalateManagerButton";
import RequestRemindReviewersButton from "./actions/RequestRemindReviewersButton";
import { RequestEventsTable } from "./RequestEventsTable";
import * as styles from "./RequestOverviewV3.css";
import { RequestReviewers } from "./RequestReviewers";
import RequestsCommentsButton from "./RequestsComments";

interface Props {
  request: RequestFragment;
  events: EventFragment[];
  loading: boolean;
  propagationStatusInfos?: PropagationStatusInfo[];
  propagationTaskType?: PropagationTaskType;
}

const RequestOverviewV3 = ({
  request,
  events,
  loading,
  propagationStatusInfos,
  propagationTaskType,
}: Props) => {
  const propagationTickets =
    propagationStatusInfos?.flatMap(
      (propagationStatusInfo) => propagationStatusInfo.propagationTickets ?? []
    ) ?? [];

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        {propagationTickets.length > 0 && (
          <RequestTicketPropagationBanner
            request={request}
            propagationTickets={propagationTickets}
            propagationTaskType={propagationTaskType}
          />
        )}
        <RequestedItems request={request} />
        {request.status === RequestStatus.Pending && (
          <>
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                marginTop: "xxl",
                marginBottom: "md",
              })}
            >
              <div className={styles.header}>Reviewers</div>
              <div className={sprinkles({ display: "flex", gap: "sm" })}>
                <RequestRemindReviewersButton request={request} />
                <RequestEscalateManagerButton request={request} />
              </div>
            </div>
            <RequestReviewers request={request} />
          </>
        )}
        {request.resourceProposal && (
          <div>
            <div className={styles.header}>Role policy permissions</div>
            <div className={styles.container}>
              <CodeEditor
                code={request.resourceProposal.metadata?.policyDocument}
              />
            </div>
          </div>
        )}
        {request.resourceProposal && (
          <div>
            <div className={styles.header}>Role trust policy</div>
            <div className={styles.container}>
              <CodeEditor
                code={
                  request.resourceProposal.metadata?.assumeRolePolicyDocument
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.rightContainer}>
        <RequestActivity request={request} events={events} loading={loading} />
      </div>
    </div>
  );
};

const RequestedItems = ({ request }: { request: RequestFragment }) => {
  const totalNumItems =
    request.requestedResources.length + request.requestedGroups.length;

  return (
    <div>
      <div className={styles.header}>
        {totalNumItems} Requested {pluralize("Item", totalNumItems)}
      </div>
      {request.requestedResources.map((requestedResource) => {
        const connectionType =
          requestedResource.resource?.connection?.connectionType;
        const resource = requestedResource.resource;
        if (!resource || !connectionType) {
          return null;
        }

        let resourceName = resource.name;
        if (resource.parentResource?.name) {
          resourceName = `${resource.parentResource.name} / ${resource.name}`;
        }

        return (
          <Link
            to={`/resources/${resource.id}`}
            key={
              resource.id + requestedResource.accessLevel.accessLevelRemoteId
            }
          >
            <div className={styles.requestItem}>
              <EntityIcon type={connectionType} iconStyle="rounded" />
              <div className={styles.requestItemLabel}>
                <div className={styles.requestItemName}>{resourceName}</div>
                <div className={styles.requestItemRole}>
                  {requestedResource.accessLevel.accessLevelName}
                </div>
              </div>
              <EntityIcon type={resource.resourceType} includeBrand={false} />
              {resourceTypeInfoByType[resource.resourceType].fullName}
            </div>
          </Link>
        );
      })}
      {request.requestedGroups.map((requestedGroup) => {
        const connectionType = requestedGroup.group?.connection?.connectionType;
        const group = requestedGroup.group;
        if (!group || !connectionType) {
          return null;
        }

        return (
          <Link
            key={group.id + requestedGroup.accessLevel?.accessLevelRemoteId}
            to={`/groups/${group.id}`}
          >
            <div className={styles.requestItem}>
              <EntityIcon type={connectionType} iconStyle="rounded" />
              <div className={styles.requestItemLabel}>
                <div className={styles.requestItemName}>{group.name}</div>
                <div className={styles.requestItemRole}>
                  {requestedGroup.accessLevel?.accessLevelName}
                </div>
              </div>
              <EntityIcon type={group.groupType} includeBrand={false} />
              {groupTypeInfoByType[group.groupType].name}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

const RequestActivity = ({ request, events, loading }: Props) => {
  return (
    <div>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          marginBottom: "md",
        })}
      >
        <div className={styles.header}>Activity</div>
        <RequestsCommentsButton request={request} />
      </div>
      <RequestEventsTable request={request} events={events} loading={loading} />
    </div>
  );
};

export default RequestOverviewV3;
