import { EntityType } from "api/generated/graphql";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { getResourceUrlNew } from "utils/common";
import { getUserAvatarIcon } from "views/users/utils";

import * as styles from "./Cell.css";

export type UserForUserCell = {
  avatarUrl: string;
  id: string;
  fullName: string;
  email: string;
};

interface Props {
  user: UserForUserCell;
  openInNewTab?: boolean;
}

const UserCell = ({ user, openInNewTab }: Props) => {
  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "sm",
      })}
    >
      <div className={sprinkles({ flexShrink: 0 })}>
        <Icon data={getUserAvatarIcon(user)} />
      </div>
      <div>
        <a
          className={styles.mainText}
          href={getResourceUrlNew({
            entityId: user.id,
            entityType: EntityType.User,
          })}
          target={openInNewTab ? "_blank" : ""}
          rel="noopener noreferrer"
        >
          {user.fullName}
        </a>
        <div className={styles.subText}>{user.email}</div>
      </div>
    </div>
  );
};

export default UserCell;
