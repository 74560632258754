import {
  BundleDetailFragment,
  useBundleQuery,
  useUpdateBundleMutation,
} from "api/generated/graphql";
import { BundleConfig } from "components/forms/common";
import { makeBundleConfig } from "components/forms/utils";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { useUnsavedChangesModal } from "components/modals/update/UnsavedChangesModal";
import { useToast } from "components/toast/Toast";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useState } from "react";
import { useParams } from "react-router";
import { logError } from "utils/logging";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import BundleEditForm from "./BundleEditForm";

const BundleEditView = () => {
  const { bundleId } = useParams<Record<string, string>>();

  const { data, error, loading } = useBundleQuery({
    variables: {
      input: {
        id: bundleId,
      },
    },
    skip: !bundleId,
  });
  let bundle: BundleDetailFragment | undefined;

  if (data?.bundle.__typename === "BundleResult") {
    bundle = data.bundle.bundle;
  } else if (data?.bundle.__typename === "BundleNotFoundError") {
    return <NotFoundPage entity="Bundle" />;
  }

  if (loading) {
    return <FullscreenSkeleton />;
  }

  if (!bundle || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  return <BundleEdit bundle={bundle} />;
};

interface Props {
  bundle: BundleDetailFragment;
}

const BundleEdit = ({ bundle: bundle }: Props) => {
  const [updateBundle, { loading: updateLoading }] = useUpdateBundleMutation();
  const { displaySuccessToast } = useToast();

  const [errors, setErrors] = useState<string[]>([]);
  const initialConfig = makeBundleConfig(bundle);

  const [config, setConfig] = useState<Partial<BundleConfig>>(initialConfig);

  const {
    handleClose,
    maybeRenderUnsavedChangesModal,
  } = useUnsavedChangesModal(`/bundles/${bundle.id}`);

  const handleSave = async () => {
    if (!config) {
      return;
    }
    if (!config.name || !config.name.trim().length) {
      setErrors(["A unique name is required."]);
      return;
    }
    try {
      setErrors([]);
      const { data } = await updateBundle({
        variables: {
          input: {
            id: bundle.id,
            name: config.name,
            description: config.description,
            adminOwnerId: config.adminOwner?.id ?? undefined,
            visibility: config.visibility ?? undefined,
            visibilityGroupsIds: config.visibilityGroupsIds,
          },
        },
        refetchQueries: ["Bundle"],
      });

      switch (data?.updateBundle.__typename) {
        case "UpdateBundleResult":
          setErrors([]);
          displaySuccessToast(`Successfully updated bundle`);
          handleClose(false);
          break;
      }
    } catch (err) {
      logError(err, "Failed to update bundle");
      setErrors(["Failed to update bundle"]);
    }
  };

  const title = (
    <>
      Edit:
      <div className={sprinkles({ display: "flex", alignItems: "center" })}>
        <Icon name="package" size="entityLg" iconStyle="rounded" />
      </div>
      {config.name}
    </>
  );

  const hasChanges = !_.isEqual(config, initialConfig);

  return (
    <FullscreenView
      title={title}
      onCancel={() => handleClose(hasChanges)}
      onPrimaryButtonClick={handleSave}
      primaryButtonDisabled={updateLoading || !hasChanges}
    >
      <BundleEditForm config={config} onChange={setConfig} errors={errors} />
      {maybeRenderUnsavedChangesModal()}
    </FullscreenView>
  );
};

export default BundleEditView;
