import { getModifiedErrorMessage } from "api/ApiContext";
import { EntityIdTupleNullable } from "api/common/common";
import {
  Maybe,
  ResourceAccessLevel,
  ResourceAccessLevelFragment,
  ResourcePreviewLargeFragment,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import UploadCSVButton, {
  CSVColumns,
} from "components/buttons/UploadCSVButton";
import ResourceRoleCheckbox from "components/checkboxes/ResourceRoleCheckbox";
import {
  DirectoryListingEntries,
  DirectoryListingEntryInfo,
} from "components/directory_listing/DirectoryListing";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import {
  ResourceIndividualRoleAutocompleteData,
  ResourceIndividualRoleCheckedData,
  Role,
  RoleData,
} from "components/modals/ResourceIndividualRoleModal";
import { SelectType } from "components/modals/update/SelectItemsModal";
import selectItemsModalStyles from "components/modals/update/SelectItemsModal.module.scss";
import { Input, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import { OPAL_IMPERSONATION_REMOTE_ID } from "utils/constants";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useAllUsersQuery } from "utils/hooks";
import { logError } from "utils/logging";
import { ExpirationValue } from "views/requests/utils";

type ResourceUsersUploadCSVButtonProps = {
  onChangeRolesByUserId: (
    accessLevelsByUserId: Record<string, ResourceAccessLevel[]>
  ) => void;
  onChangeUploadCsvUserIds: (uploadCsvUsers: Record<string, boolean>) => void;
  onChangeErrorMessage?: (errorMessage: string) => void;
  roles: ResourceAccessLevelFragment[];
};

/**
 * Button for uploading resource users as a CSV, and populating the CSV data into data structures
 * as per `setRolesByUserId` and `setUploadCsvUserIds`.
 */
const ResourceUsersUploadCSVButton = (
  props: ResourceUsersUploadCSVButtonProps
) => {
  const {
    onChangeRolesByUserId: setRolesByUserId,
    onChangeUploadCsvUserIds: setUploadCsvUserIds,
    onChangeErrorMessage: setErrorMessage,
    roles: roles,
  } = props;

  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [rolesByUserEmail, setRolesByUserEmail] = useState<
    Record<string, ResourceAccessLevel[]>
  >({});

  const { data, error } = useAllUsersQuery({
    skip: userEmails.length === 0,
    fetchPolicy: "no-cache",
    variables: {
      input: {
        userEmails: userEmails,
      },
    },
  });

  useEffect(() => {
    if (data) {
      const newRolesByUserId: Record<string, ResourceAccessLevel[]> = {};
      const newUploadCsvUserIds: Record<string, boolean> = {};
      data.users.users.forEach((user) => {
        newRolesByUserId[user.id] = rolesByUserEmail[user.email];
        newUploadCsvUserIds[user.id] = true;
      });
      setRolesByUserId(newRolesByUserId);
      setUploadCsvUserIds(newUploadCsvUserIds);
    } else if (error) {
      logError(error, `failed to list users`);
      if (setErrorMessage) {
        setErrorMessage(
          getModifiedErrorMessage(
            `Error: failed to list users from email`,
            error
          )
        );
      }
    }
  }, [
    data,
    error,
    rolesByUserEmail,
    setErrorMessage,
    setRolesByUserId,
    setUploadCsvUserIds,
  ]);

  return (
    <UploadCSVButton
      onChangeUserInfos={async (userInfos) => {
        const newMap: Record<string, ResourceAccessLevel[]> = {};
        userInfos.forEach((userInfo) => {
          newMap[userInfo.email] = [
            {
              accessLevelName: userInfo.role ?? "",
              accessLevelRemoteId: userInfo.role ?? "",
            },
          ];
        });
        setRolesByUserEmail(newMap);

        setUserEmails([
          ...userEmails,
          ...userInfos.map((userInfo) => userInfo.email),
        ]);
      }}
      requiredColumns={
        roles.length > 1
          ? [CSVColumns.Email, CSVColumns.Role]
          : [CSVColumns.Email]
      }
      onChangeErrorMessage={setErrorMessage}
      trackName="resource_users"
    />
  );
};

type SelectModalEntryInfo = DirectoryListingEntryInfo & {
  disabled?: boolean;
  tooltipText?: string;
};

type ResourceRoleSelectModalProps = {
  title: string;
  selectType: SelectType;
  entryInfos: SelectModalEntryInfo[];
  itemName: string;
  isModalOpen: boolean;
  errorMessage?: Maybe<string>;
  setErrorMessage?: (error: string) => void;
  submitDisabled?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading?: boolean;

  resource?: ResourcePreviewLargeFragment;
  existingDirectRolesByEntityId: Record<string, Role[]>;
  updatedRolesByEntityId: Record<string, Role[]>;
  setUpdatedRolesByEntityId: (newMap: Record<string, Role[]>) => void;
  updatedAccessDurationsByEntityId?: Record<string, ExpirationValue>;
  setUpdatedAccessDurationsByEntityId?: (
    newMap: Record<string, ExpirationValue>
  ) => void;
  searchQuery?: string;
  setSearchQuery: (searchQuery: string) => void;
};

/**
 * Modal that supports selecting items and displaying a dropdown when selected.
 * Bit of a misnomer, since this is used for all types of entities (e.g. groups, users, etc).
 */
const ResourceRoleSelectModal = (props: ResourceRoleSelectModalProps) => {
  const [checkedDataItems, setCheckedDataItems] = useState<
    ResourceIndividualRoleCheckedData[]
  >([]);

  const [uploadCsvUserIds, setUploadCsvUserIds] = useState<
    Record<string, boolean>
  >({});

  const hasShowItemUserUploader = useFeatureFlag(
    FeatureFlag.ShowItemUserUploader
  );

  const addRoleData = (selected: ResourceIndividualRoleCheckedData[]) => {
    setCheckedDataItems((roleData) => {
      return [...roleData, ...selected];
    });
  };

  const removeRoleData = (entityIdToRemove: string) =>
    setCheckedDataItems((roleData) => {
      return roleData.filter(
        (roleDataItem) => roleDataItem.entityId !== entityIdToRemove
      );
    });

  props.entryInfos.forEach((entryInfo) => {
    entryInfo.getRightSideElement = (entityId: EntityIdTupleNullable) => {
      const entityIdVal = entityId.entityId;
      if (!entityIdVal) {
        return null;
      }
      return (
        <ResourceRoleCheckbox
          resource={props.resource}
          entityId={entityIdVal}
          selectType={props.selectType}
          disabled={entryInfo.disabled}
          tooltipText={entryInfo.tooltipText}
          existingDirectRolesByEntityId={props.existingDirectRolesByEntityId}
          updatedRolelsByEntityId={props.updatedRolesByEntityId}
          setUpdatedRolesByEntityId={props.setUpdatedRolesByEntityId}
          addRoleData={addRoleData}
          removeRoleData={removeRoleData}
          checked={checkedDataItems.some((item) => {
            return item.entityId === entityIdVal;
          })}
          serviceType={props.resource?.serviceType}
          uploadCsvUserIds={uploadCsvUserIds}
        />
      );
    };
  });

  const numEntriesUpdated = Object.keys(props.updatedRolesByEntityId).length;

  const autocompleteData: ResourceIndividualRoleAutocompleteData = {
    existingDirectRolesByEntityId: props.existingDirectRolesByEntityId,
    updatedRolesByEntityId: props.updatedRolesByEntityId,
    setUpdatedRolesByEntityId: props.setUpdatedRolesByEntityId,
    updatedAccessDurationsByEntityId: props.updatedAccessDurationsByEntityId,
    setUpdatedAccessDurationsByEntityId:
      props.setUpdatedAccessDurationsByEntityId,
    serviceType: props.resource?.serviceType,
  };

  const roleData: RoleData = {
    autocompleteData: autocompleteData,
    checkedDataItems: checkedDataItems,
  };

  const { data, error } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.resource?.id ?? "",
      },
    },
    skip: !props.resource?.id,
  });

  let roles: ResourceAccessLevelFragment[] = [];
  if (data) {
    switch (data.accessLevels.__typename) {
      case "ResourceAccessLevelsResult": {
        roles.push(...data.accessLevels.accessLevels);
        break;
      }
      case "ResourceNotFoundError":
        logError(new Error(`Error: failed to list resource roles`));
        break;
    }
  } else if (error) {
    logError(error, `failed to list resource roles`);
  }

  const getPrimaryButtonLabel = () => {
    switch (props.selectType) {
      case SelectType.Add:
        return `Add ${pluralize(
          props.itemName,
          numEntriesUpdated,
          numEntriesUpdated > 0
        )}`;
      case SelectType.Remove:
        return `Remove ${pluralize(
          props.itemName,
          numEntriesUpdated,
          numEntriesUpdated > 0
        )}`;
    }
    // This modal currenlty only has Add and Remove select types, so we should
    // never get here
    return "Submit";
  };

  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title={props.title}
    >
      <Modal.Body>
        {hasShowItemUserUploader ? (
          <div
            className={sprinkles({
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "md",
            })}
          >
            <ResourceUsersUploadCSVButton
              onChangeRolesByUserId={props.setUpdatedRolesByEntityId}
              onChangeErrorMessage={props.setErrorMessage}
              onChangeUploadCsvUserIds={setUploadCsvUserIds}
              roles={roles}
            />
          </div>
        ) : null}
        <Input
          leftIconName="search"
          value={props.searchQuery}
          onChange={props.setSearchQuery}
          placeholder="Search"
        />
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}
        <div className={selectItemsModalStyles.modalFieldContainer}>
          {props.entryInfos.length > 0 ? (
            <DirectoryListingEntries
              entryInfos={props.entryInfos}
              roleData={roleData}
              isImpersonationResource={
                props.resource?.remoteId === OPAL_IMPERSONATION_REMOTE_ID
              }
            />
          ) : (
            <div className={selectItemsModalStyles.emptyState}>{`No ${pluralize(
              props.itemName,
              2,
              false
            )} to display.`}</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={props.onSubmit}
        primaryButtonLabel={getPrimaryButtonLabel()}
        primaryButtonDisabled={numEntriesUpdated === 0 || props.submitDisabled}
        primaryButtonLoading={props.loading}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={props.onClose}
      />
    </Modal>
  );
};

export default ResourceRoleSelectModal;
