import { ResourceAccessLevel } from "api/generated/graphql";
import { ButtonV3, FormGroup, Select } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import sprinkles from "css/sprinkles.css";
import { by } from "utils/search/sort";
import { SessionWithExpiration } from "views/connect_sessions/utils";

type Props = {
  selectedRole: ResourceAccessLevel | undefined;
  setSelectedRole: (role: ResourceAccessLevel) => void;
  roles: ResourceAccessLevel[];
  isImpersonationResource: boolean;
  onSubmit: (role: ResourceAccessLevel) => void;
  loading?: boolean;
  getCurrentSession: (remoteId: string) => SessionWithExpiration | null;
};

const ConnectRoleDropdown = (props: Props) => {
  const {
    roles,
    isImpersonationResource,
    selectedRole,
    setSelectedRole,
  } = props;

  const requiresRole =
    roles.length > 0 && roles.every((role) => role.accessLevelRemoteId !== "");

  const sortedOptions = roles.slice().sort(by((role) => role.accessLevelName));

  const getOptionLabel = (role: ResourceAccessLevel) =>
    props.getCurrentSession(role.accessLevelRemoteId)
      ? `${role.accessLevelName} (Active)`
      : role.accessLevelName;

  const isSelectedRoleConnected =
    selectedRole &&
    Boolean(props.getCurrentSession(selectedRole.accessLevelRemoteId));

  return (
    <>
      {isImpersonationResource && (
        <FormGroup
          label="Select a user to impersonate:"
          infoTooltip="Once a user is selected, you will see the Opal product from the same perspective as that user. Only read-only actions can be performed."
        >
          <Select
            options={sortedOptions}
            loading={props.loading}
            value={selectedRole ?? undefined}
            getOptionLabel={getOptionLabel}
            getIcon={(option) => ({
              type: "src",
              style: "rounded",
              icon: option.accessLevelMetadata?.avatarUrl || defaultAvatarURL,
              fallbackIcon: defaultAvatarURL,
            })}
            onChange={(role) => {
              if (role) {
                setSelectedRole(role);
              }
            }}
            getOptionSelected={(a, b) =>
              a.accessLevelName === b.accessLevelName
            }
          />
        </FormGroup>
      )}
      {!isImpersonationResource && requiresRole && (
        <FormGroup label="Role:">
          <Select
            options={sortedOptions}
            loading={props.loading}
            value={selectedRole}
            getOptionLabel={getOptionLabel}
            onChange={(role) => {
              if (role) {
                setSelectedRole(role);
              }
            }}
            getOptionSelected={(a, b) =>
              a.accessLevelName === b.accessLevelName
            }
            placeholder="Select a role"
          />
        </FormGroup>
      )}
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "center",
          marginTop: requiresRole ? undefined : "lg",
          marginBottom: "lg",
        })}
      >
        {isSelectedRoleConnected ? (
          <ButtonV3
            type="successSecondary"
            leftIconName="zap"
            label={isImpersonationResource ? "Impersonate" : "Connected"}
            onClick={() => props.onSubmit(selectedRole)}
            loading={props.loading}
            size="lg"
            disabled={
              props.loading ||
              roles.every(
                (role) =>
                  selectedRole.accessLevelRemoteId !== role.accessLevelRemoteId
              )
            }
          />
        ) : (
          <ButtonV3
            type="success"
            leftIconName="zap"
            label={isImpersonationResource ? "Impersonate" : "Connect"}
            onClick={() => selectedRole && props.onSubmit(selectedRole)}
            loading={props.loading}
            size="lg"
            disabled={
              props.loading ||
              roles.every(
                (role) =>
                  selectedRole?.accessLevelRemoteId !== role.accessLevelRemoteId
              )
            }
          />
        )}
      </div>
    </>
  );
};

export default ConnectRoleDropdown;
