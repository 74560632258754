import {
  AccessReviewItemStatus,
  AccessReviewMyConnectionV3Fragment,
  AccessReviewMyGroupV3Fragment,
  AccessReviewMyResourceV3Fragment,
  ConnectionType,
  EntityType,
  GroupType,
  ResourceType,
  useMyAccessReviewItemsV3Query,
} from "api/generated/graphql";
import ItemTypeLabel from "components/label/ItemTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { Icon, Input, ProgressBar } from "components/ui";
import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useDebouncedValue } from "utils/hooks";
import { useTransitionTo, useURLSearchParamAsEnum } from "utils/router/hooks";
import { filterSearchResults } from "utils/search/filter";
import { dropNothings } from "views/utils";

import * as styles from "./AccessReviewMyResourcesV3.css";
import { accessReviewStatusIsInProgress } from "./common/utils";

type ReviewItem =
  | AccessReviewMyResourceV3Fragment
  | AccessReviewMyGroupV3Fragment
  | AccessReviewMyConnectionV3Fragment;

interface ReviewItems {
  resources: AccessReviewMyResourceV3Fragment[];
  groups: AccessReviewMyGroupV3Fragment[];
  connections: AccessReviewMyConnectionV3Fragment[];
}

interface AccessReviewMyResourceRow {
  id: string;
  resource: string;
  resourceId: string;
  itemType?: ResourceType | GroupType | ConnectionType;
  entityType: EntityType;
  usersReviewed: number;
  nhisReviewed?: number;
  resourcesReviewed?: number;
  description: string;
  data: {
    userReviewsAssigned: number;
    nhiReviewsAssigned?: number;
    resourceReviewsAssigned?: number;
    accessReviewResource?: AccessReviewMyResourceV3Fragment;
    accessReviewGroup?: AccessReviewMyGroupV3Fragment;
    accessReviewConnection?: AccessReviewMyConnectionV3Fragment;
    linkTo?: string;
  };
}

enum AccessReviewStatusFilter {
  TODO = "todo",
  COMPLETED = "completed",
}

const AccessReviewMyResourcesV3 = ({
  accessReviewId,
}: {
  accessReviewId: string;
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 250);
  const transitionTo = useTransitionTo();
  const [statusFilter, setStatusFilter] = useURLSearchParamAsEnum(
    "reviewStatus",
    AccessReviewStatusFilter,
    AccessReviewStatusFilter.TODO
  );
  const {
    data: itemsData,
    loading: itemsLoading,
  } = useMyAccessReviewItemsV3Query({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        accessReviewId,
      },
    },
    skip: !accessReviewId,
  });
  let reviewItems: ReviewItems | undefined;
  if (
    itemsData?.myAccessReviewItems.__typename === "MyAccessReviewItemsResult"
  ) {
    reviewItems = itemsData.myAccessReviewItems;
  }

  const hasNHIs = useFeatureFlag(FeatureFlag.NonHumanIdentities);

  const ACCESS_REVIEW_RESOURCE_COLUMNS: Header<AccessReviewMyResourceRow>[] = dropNothings(
    [
      {
        id: "resource",
        label: "Name",
        sortable: true,
        customCellRenderer: (row) => {
          return (
            <div
              className={sprinkles({ width: "fit-content" })}
              onClick={(e) => e.stopPropagation()}
            >
              <ResourceLabel
                text={row.resource}
                pointerCursor={true}
                maxChars="auto"
                hideIcon
                entityId={row.resourceId}
                entityTypeNew={row.entityType}
              />
            </div>
          );
        },
      },
      {
        id: "itemType",
        label: "Type",
        sortable: true,
        customCellRenderer: (row) => {
          if (!row.itemType) {
            return <></>;
          }
          return <ItemTypeLabel itemType={row.itemType} />;
        },
      },
      {
        id: "usersReviewed",
        label: "Users Reviewed",
        sortable: true,
        customCellRenderer: (row) => {
          if (
            row.usersReviewed === undefined ||
            row.data.userReviewsAssigned === undefined ||
            row.data.userReviewsAssigned === 0
          ) {
            return (
              <div
                className={sprinkles({
                  color: "gray700",
                })}
              >
                No Users to review
              </div>
            );
          }

          const percent =
            (row.usersReviewed / row.data.userReviewsAssigned) * 100;
          return (
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "md",
              })}
            >
              <div className={styles.progressBar}>
                <ProgressBar percentProgress={percent} />
              </div>
              <div className={styles.usersReviewedLabel}>
                {`${row.usersReviewed}/${row.data.userReviewsAssigned} Users`}
              </div>
            </div>
          );
        },
      },
      hasNHIs
        ? {
            id: "nhisReviewed",
            label: "NHIs Reviewed",
            sortable: true,
            customCellRenderer: (row) => {
              if (
                row.nhisReviewed === undefined ||
                row.data.nhiReviewsAssigned === undefined ||
                row.data.nhiReviewsAssigned === 0
              ) {
                return (
                  <div
                    className={sprinkles({
                      color: "gray700",
                    })}
                  >
                    No Resources to review
                  </div>
                );
              }

              const percent =
                (row.nhisReviewed / row.data.nhiReviewsAssigned) * 100;
              return (
                <div
                  className={sprinkles({
                    display: "flex",
                    alignItems: "center",
                    gap: "md",
                  })}
                >
                  <div className={styles.progressBar}>
                    <ProgressBar percentProgress={percent} />
                  </div>
                  <div className={styles.usersReviewedLabel}>
                    {`${row.nhisReviewed}/${row.data.nhiReviewsAssigned} Resources`}
                  </div>
                </div>
              );
            },
          }
        : undefined,
      {
        id: "resourcesReviewed",
        label: "Resources Reviewed",
        sortable: true,
        customCellRenderer: (row) => {
          if (
            row.resourcesReviewed === undefined ||
            row.data.resourceReviewsAssigned === undefined ||
            row.data.resourceReviewsAssigned === 0
          ) {
            return (
              <div
                className={sprinkles({
                  color: "gray700",
                })}
              >
                No Resources to review
              </div>
            );
          }

          const percent =
            (row.resourcesReviewed / row.data.resourceReviewsAssigned) * 100;
          return (
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "md",
              })}
            >
              <div className={styles.progressBar}>
                <ProgressBar percentProgress={percent} />
              </div>
              <div className={styles.usersReviewedLabel}>
                {`${row.resourcesReviewed}/${row.data.resourceReviewsAssigned} Resources`}
              </div>
            </div>
          );
        },
      },
      {
        id: "description",
        label: "Description",
        sortable: false,
      },
    ]
  );

  const todoItems: ReviewItem[] = [];
  const completedItems: ReviewItem[] = [];
  reviewItems?.resources.forEach((resource) => {
    if (
      accessReviewStatusIsInProgress(
        resource.currentUserReviewerStatus?.itemStatus
      )
    ) {
      todoItems.push(resource);
    } else if (
      resource.currentUserReviewerStatus?.itemStatus ===
      AccessReviewItemStatus.Completed
    ) {
      completedItems.push(resource);
    }
  });
  reviewItems?.groups.forEach((group) => {
    if (
      accessReviewStatusIsInProgress(
        group.currentUserReviewerStatus?.itemStatus
      )
    ) {
      todoItems.push(group);
    } else if (
      group.currentUserReviewerStatus?.itemStatus ===
      AccessReviewItemStatus.Completed
    ) {
      completedItems.push(group);
    }
  });
  reviewItems?.connections.forEach((connection) => {
    if (
      accessReviewStatusIsInProgress(
        connection.currentUserReviewerStatus?.itemStatus
      )
    ) {
      todoItems.push(connection);
    } else if (
      connection.currentUserReviewerStatus?.itemStatus ===
      AccessReviewItemStatus.Completed
    ) {
      completedItems.push(connection);
    }
  });

  const showCompleted = statusFilter === AccessReviewStatusFilter.COMPLETED;

  const items = showCompleted ? completedItems : todoItems;
  let rows: AccessReviewMyResourceRow[] = items
    .map((item) => {
      let row: AccessReviewMyResourceRow | undefined = undefined;
      if ("resource" in item && item.resource) {
        row = {
          id: item.id,
          resourceId: item.resource.id,
          resource: item.resource.name,
          itemType: item.resource.resourceType,
          entityType: EntityType.Resource,
          usersReviewed: item.currentUserReviewerStatus?.numUsersReviewed ?? 0,
          nhisReviewed: hasNHIs
            ? item.currentUserReviewerStatus?.numNHIsReviewed ?? 0
            : undefined,
          resourcesReviewed: hasNHIs
            ? item.currentUserReviewerStatus?.numResourcesReviewed ?? 0
            : undefined,
          description: item.resource.description,
          data: {
            userReviewsAssigned:
              item.currentUserReviewerStatus?.numUsersAssigned ?? 0,
            nhiReviewsAssigned: hasNHIs
              ? item.currentUserReviewerStatus?.numNHIsAssigned ?? 0
              : undefined,
            resourceReviewsAssigned: hasNHIs
              ? item.currentUserReviewerStatus?.numResourcesAssigned ?? 0
              : undefined,
            linkTo: `/access-reviews/${accessReviewId}/r/${item.id}`,
          },
        };
      } else if ("group" in item && item.group) {
        row = {
          id: item.id,
          resource: item.group.name,
          resourceId: item.group.id,
          itemType: item.group.groupType,
          entityType: EntityType.Group,
          usersReviewed: item.currentUserReviewerStatus?.numUsersReviewed ?? 0,
          resourcesReviewed:
            item.currentUserReviewerStatus?.numResourcesReviewed ?? 0,
          description: item.group.description,
          data: {
            userReviewsAssigned:
              item.currentUserReviewerStatus?.numUsersAssigned ?? 0,
            resourceReviewsAssigned:
              item.currentUserReviewerStatus?.numResourcesAssigned ?? 0,
            linkTo: `/access-reviews/${accessReviewId}/g/${item.id}`,
          },
        };
      } else if ("connection" in item && item.connection) {
        row = {
          id: item.id,
          resource: item.connection.name,
          resourceId: item.connection.id,
          itemType: item.connection.connectionType,
          entityType: EntityType.Connection,
          usersReviewed: item.currentUserReviewerStatus?.numUsersReviewed ?? 0,
          description: item.connection.description,
          data: {
            userReviewsAssigned:
              item.currentUserReviewerStatus?.numUsersAssigned ?? 0,
            linkTo: `/access-reviews/${accessReviewId}/c/${item.id}`,
          },
        };
      }

      return row;
    })
    .filter((row): row is AccessReviewMyResourceRow => row !== undefined);

  rows = filterSearchResults(
    rows,
    debouncedSearchQuery,
    (row: AccessReviewMyResourceRow) => [row.resource]
  );

  return (
    <div
      className={sprinkles({
        height: "100%",
      })}
    >
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "md",
        })}
      >
        <div className={styles.searchInput}>
          <Input
            value={searchQuery}
            onChange={(value) => setSearchQuery(value)}
            placeholder="Filter"
            leftIconName="search"
            style="search"
            type="search"
          />
        </div>
        <ButtonGroup
          buttons={[
            {
              label: "Todo",
              onClick: () => {
                setStatusFilter(AccessReviewStatusFilter.TODO);
              },
              selected: statusFilter === AccessReviewStatusFilter.TODO,
            },
            {
              label: "Completed",
              onClick: () => {
                setStatusFilter(AccessReviewStatusFilter.COMPLETED);
              },
              selected: statusFilter === AccessReviewStatusFilter.COMPLETED,
            },
          ]}
        />
      </div>
      <TableHeader
        entityName={"Resource"}
        totalNumRows={rows.length}
        rightElement={
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "xs",
            })}
          >
            <Icon name="check-circle" color="green600" size="xs" />
            <span
              className={sprinkles({
                fontSize: "bodyLg",
                color: "gray700",
              })}
            >{`${completedItems.length}/${
              todoItems.length + completedItems.length
            }`}</span>
            <div className={styles.mainProgressBar}>
              <ProgressBar
                size="md"
                percentProgress={
                  (completedItems.length /
                    (todoItems.length + completedItems.length)) *
                  100
                }
              />
            </div>
          </div>
        }
      />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        loadingRows={itemsLoading && !rows.length}
        getRowId={(ru) => ru.id}
        columns={ACCESS_REVIEW_RESOURCE_COLUMNS}
        onRowClick={(row, event) => {
          if (!row.data.linkTo) {
            return;
          }
          transitionTo({ pathname: row.data.linkTo }, event);
        }}
        defaultSortBy="resource"
        defaultSortDirection="ASC"
      />
    </div>
  );
};

export default AccessReviewMyResourcesV3;
