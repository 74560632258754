import { Icon } from "components/ui";
import React from "react";

import * as styles from "./Input.css";

interface BaseInputProps {
  placeholder?: string;
  id?: string;
  name?: string;
  maxLength?: number;
  error?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  size?: "sm" | "md";
  style?: "facetMenu" | "search";
  autoComplete?: string;
  onEnter?: () => void;
}

interface TextInputProps extends BaseInputProps {
  type?: "text" | "password" | "email" | "search";
  value?: string;
  matchPattern?: string;
  onChange: (value: string) => void;
  leftIconName?: PropsFor<typeof Icon>["name"];
  rightText?: string;
}

interface NumberInputProps extends BaseInputProps {
  type: "number";
  value?: number;
  min?: number;
  max?: number;
  /**
   * For good typing experience, we allow the typed value to be blank (`undefined`)
   * Numbers are parsed and slightly forgiving (e.g. "5 a" => 5), but never currently shows end-users validation errors;
   * if a non-finite number is entered, `onChange` is called with `undefined`.
   */
  onChange: (value?: number) => void;
  leftIconName?: PropsFor<typeof Icon>["name"];
  rightText?: string;
}

interface TextAreaInputProps extends BaseInputProps {
  type: "textarea";
  onChange: (value: string) => void;
  value?: string;
  rows?: number;
}

type InputProps = TextInputProps | NumberInputProps | TextAreaInputProps;

const Input: React.FC<InputProps> = (props) => {
  let size = props.size;
  if (!size && props.type !== "textarea") {
    size = "md";
  }
  const baseInputProps = {
    autoFocus: props.autoFocus,
    id: props.id,
    name: props.name,
    autoComplete: props.autoComplete,
    className: styles.input({
      error: props.error,
      disabled: props.disabled,
      size,
      style: props.style,
    }),
    defaultValue: props.value,
    disabled: props.disabled,
    onChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (props.type === "number") {
        const parsedValue = parseFloat(e.currentTarget.value);
        if (!isFinite(parsedValue)) {
          props.onChange(undefined);
          return;
        }
        props.onChange(parsedValue);
      } else {
        props.onChange(e.currentTarget.value);
      }
    },
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    onBlur: () => {
      if (props.type === "number") {
        let newValue = props.value || 0;
        if (props.max !== undefined) {
          newValue = Math.min(props.max, newValue);
        }
        if (props.min !== undefined) {
          newValue = Math.max(props.min, newValue);
        }
        props.onChange(newValue);
      }
    },
    onKeyDown: (e: React.KeyboardEvent<Element>) => {
      if (e.key === "Enter" && "onEnter" in props && props.onEnter) {
        props.onEnter();
      }
    },
  };

  if (props.type === "textarea") {
    return <textarea {...baseInputProps} rows={props.rows} />;
  }

  if (
    ("leftIconName" in props && props.leftIconName) ||
    ("rightText" in props && props.rightText)
  ) {
    return (
      <div
        className={styles.input({
          size,
          withIconWrapper: true,
          disabled: props.disabled,
          error: props.error,
          style: props.style,
        })}
      >
        {props.leftIconName ? (
          <div className={styles.icon}>
            <Icon name={props.leftIconName} size="xs" />
          </div>
        ) : null}
        <input
          {...baseInputProps}
          type={props.type}
          className={styles.inputWithIcon({
            size,
          })}
        />
        {props.rightText ? (
          <div className={styles.rightText}>{props.rightText}</div>
        ) : null}
      </div>
    );
  }

  return <input {...baseInputProps} type={props.type} />;
};

export default Input;
