import {
  ConnectionMetadataOutput,
  GroupPreviewLargeFragment,
  GroupType,
  Maybe,
  RequestMessageCode,
  RequestPreviewLargeFragment,
  RequestStatus,
  ResourceAccessLevel,
  ResourcePreviewLargeFragment,
  ResourceType,
  ThirdPartyProvider,
} from "api/generated/graphql";
import { Banner } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import { IconData } from "components/ui/utils";
import moment from "moment";
import { thirdPartyProviderNameByThirdPartyProvider } from "utils/directory/third_party_providers";

export type RequestsType = "inbox" | "sent" | "admin";

export const NULLABLE_DURATION_INDEFINITE = -1;

export const getExtraParamsByConnectionMetadata = (
  connectionMetadata: ConnectionMetadataOutput | undefined
): string => {
  switch (connectionMetadata?.__typename) {
    case "GitHubConnectionMetadata":
      return `orgName=${connectionMetadata.orgName}`;
  }
  return "";
};

export function getRequestMessageFromCode(
  code: RequestMessageCode,
  isSelfRequest: boolean,
  opts?: {
    thirdPartyProvider?: ThirdPartyProvider;
    extraParams?: string;
  }
): React.ReactNode | undefined {
  switch (code) {
    case RequestMessageCode.RequireUserAuthToken: {
      if (!opts?.thirdPartyProvider) {
        // This should never happen, but just in case we forgot to pass in the third party provider
        return "You must authenticate your identity for this app to request access.";
      }
      const { thirdPartyProvider, extraParams } = opts;
      const thirdPartyProviderName = thirdPartyProviderNameByThirdPartyProvider[
        opts.thirdPartyProvider
      ]!;
      if (isSelfRequest)
        return (
          <>
            {`This resource requires you to link your ${thirdPartyProviderName} account to Opal before requesting access. You can link your ${thirdPartyProviderName} account `}
            <Banner.Link
              label={`here`}
              to={{
                pathname: "/user/settings",
                hash: "identities",
                search: `showIntegrationModal=${thirdPartyProvider}&${extraParams}`,
              }}
            />
            {`.`}
          </>
        );
      return `This resource requires the target user to link their ${thirdPartyProviderName} account to Opal before requesting access.`;
    }

    case RequestMessageCode.NestedGroupAccessNotAllowed:
      if (isSelfRequest)
        return "You cannot request access to this group because you already have nested access.";
      return "Cannot request access for the target user because they already have nested access.";
  }
}

interface CurrentUserAccessSummary {
  hasAccess: boolean;
  expiration?: moment.Moment;
}

export function getCurrentUserResourceAccessSummary(
  resource: ResourcePreviewLargeFragment,
  includeRoles: boolean,
  role?: ResourceAccessLevel
): CurrentUserAccessSummary {
  let hasAccess = false;
  let expiration: moment.Moment | undefined = undefined;

  if (resource.currentUserAccess.resourceUsers.length === 0) {
    return {
      hasAccess,
      expiration,
    };
  }

  if (includeRoles) {
    if (!role) {
      return {
        hasAccess,
        expiration,
      };
    }
    const currentRoleAccess = resource.currentUserAccess.resourceUsers.find(
      (resourceUser) =>
        resourceUser.accessLevel.accessLevelRemoteId ===
        role.accessLevelRemoteId
    );
    if (currentRoleAccess?.access?.directAccessPoint) {
      hasAccess = true;
      const currentAccessExpiration =
        currentRoleAccess.access.directAccessPoint.expiration;
      expiration = currentAccessExpiration
        ? moment(new Date(currentAccessExpiration))
        : undefined;
    }
  } else {
    hasAccess = Boolean(
      resource.currentUserAccess.resourceUsers[0].access?.directAccessPoint
    );
    const currentAccessExpiration =
      resource.currentUserAccess.resourceUsers[0].access?.directAccessPoint
        ?.expiration;
    expiration = currentAccessExpiration
      ? moment(new Date(currentAccessExpiration))
      : undefined;
  }

  return {
    hasAccess,
    expiration,
  };
}

export function getCurrentUserGroupAccessSummary(
  group: GroupPreviewLargeFragment,
  includeRoles: boolean,
  role?: ResourceAccessLevel
): CurrentUserAccessSummary {
  let hasAccess = false;
  let expiration: moment.Moment | undefined = undefined;

  if (!group.currentUserAccess.groupUser) {
    return {
      hasAccess,
    };
  }

  if (includeRoles) {
    if (!role) {
      return {
        hasAccess,
        expiration,
      };
    }
    const hasCurrentRoleAccess =
      group.currentUserAccess.groupUser.access?.directAccessPoint?.accessLevel
        ?.accessLevelRemoteId === role.accessLevelRemoteId;
    if (hasCurrentRoleAccess) {
      hasAccess = true;
      const currentAccessExpiration =
        group.currentUserAccess.groupUser.access?.directAccessPoint?.expiration;
      expiration = currentAccessExpiration
        ? moment(new Date(currentAccessExpiration))
        : undefined;
    }
  } else {
    hasAccess = Boolean(
      group.currentUserAccess.groupUser.access?.directAccessPoint
    );
    const currentAccessExpiration =
      group.currentUserAccess.groupUser.access?.directAccessPoint?.expiration;
    expiration = currentAccessExpiration
      ? moment(new Date(currentAccessExpiration))
      : undefined;
  }

  return {
    hasAccess,
    expiration,
  };
}

export enum ExpirationValue {
  OneHour = "1 hour",
  OneDay = "1 day",
  OneWeek = "1 week",
  OneMonth = "1 month",
  NinetyDays = "90 days",
  OneYear = "1 year",
  Indefinite = "Indefinite",
}

export enum AWSServicePrincipalOption {
  Ec2InstanceRole = "EC2 Instance Role",
  IamUserCredentials = "IAM User Credentials",
  PodRole = "IAM Role for Service Account",
}

export const expirationValueToDurationInMinutes = (
  expirationValue: ExpirationValue
) => {
  switch (expirationValue) {
    case ExpirationValue.OneHour:
      return moment.duration(60, "m");
    case ExpirationValue.OneDay:
      return moment.duration(60 * 24, "m");
    case ExpirationValue.OneWeek:
      return moment.duration(60 * 24 * 7, "m");
    case ExpirationValue.OneMonth:
      return moment.duration(60 * 24 * 30, "m");
    case ExpirationValue.NinetyDays:
      return moment.duration(60 * 24 * 90, "m");
    case ExpirationValue.OneYear:
      return moment.duration(60 * 24 * 365, "m");
    case ExpirationValue.Indefinite:
      return null;
    default:
      return null;
  }
};

export const expirationValueNullableToDurationInMinutes = (
  expirationValue?: ExpirationValue
) => {
  if (expirationValue == null) {
    return null;
  }
  switch (expirationValue) {
    case ExpirationValue.OneHour:
      return moment.duration(60, "m");
    case ExpirationValue.OneDay:
      return moment.duration(60 * 24, "m");
    case ExpirationValue.OneWeek:
      return moment.duration(60 * 24 * 7, "m");
    case ExpirationValue.OneMonth:
      return moment.duration(60 * 24 * 30, "m");
    case ExpirationValue.OneYear:
      return moment.duration(60 * 24 * 365, "m");
    case ExpirationValue.Indefinite:
      return NULLABLE_DURATION_INDEFINITE;
    default:
      return null;
  }
};

export const minutesToExpirationValue = (
  minutes: Maybe<number>
): ExpirationValue => {
  if (minutes === null) {
    return ExpirationValue.Indefinite;
  }

  switch (minutes) {
    case 60:
      return ExpirationValue.OneHour;
    case 60 * 24:
      return ExpirationValue.OneDay;
    case 60 * 24 * 7:
      return ExpirationValue.OneWeek;
    case 60 * 24 * 30:
      return ExpirationValue.OneMonth;
    case 60 * 24 * 90:
      return ExpirationValue.NinetyDays;
    case 60 * 24 * 365:
      return ExpirationValue.OneYear;
    default:
      return ExpirationValue.OneHour;
  }
};

export const minutesToExpirationValueNullable = (
  minutes?: number
): ExpirationValue | null => {
  if (minutes == null) {
    return null;
  }

  if (minutes === NULLABLE_DURATION_INDEFINITE) {
    return ExpirationValue.Indefinite;
  }

  return minutesToExpirationValue(minutes);
};

export function getDefaultRequestDurationMinutes(
  maxDuration?: number,
  recommendedDuration?: number
) {
  if (recommendedDuration == null) {
    return maxDuration;
  } else if (recommendedDuration === NULLABLE_DURATION_INDEFINITE) {
    return maxDuration;
  }
  return maxDuration
    ? Math.min(recommendedDuration, maxDuration)
    : recommendedDuration;
}

export const getDefaultRequestExpiration = (
  maxDuration?: number,
  recommendedDuration?: number
): ExpirationValue => {
  if (recommendedDuration == null) {
    return maxDuration
      ? minutesToExpirationValue(maxDuration)
      : ExpirationValue.OneHour;
  } else if (recommendedDuration === NULLABLE_DURATION_INDEFINITE) {
    return maxDuration
      ? minutesToExpirationValue(maxDuration)
      : ExpirationValue.Indefinite;
  }
  return maxDuration
    ? minutesToExpirationValue(Math.min(recommendedDuration, maxDuration))
    : minutesToExpirationValue(recommendedDuration);
};

export const getRequestedItemsIcon = (
  request: RequestPreviewLargeFragment
): IconData => {
  const entityTypes = new Set<ResourceType | GroupType>();
  request.requestedResources.forEach((requestedResource) => {
    if (requestedResource.resource?.resourceType) {
      entityTypes.add(requestedResource.resource.resourceType);
    }
  });
  request.requestedGroups.forEach((requestedGroup) => {
    if (requestedGroup.group?.groupType) {
      entityTypes.add(requestedGroup.group.groupType);
    }
  });

  if (entityTypes.size !== 1) {
    return {
      type: "src",
      icon: request.requester?.avatarUrl || defaultAvatarURL,
      fallbackIcon: defaultAvatarURL,
      style: "rounded",
    };
  }

  const entityType = entityTypes.values().next().value;
  return { type: "entity", entityType };
};

export const requestStatusToReadableString = (
  status: RequestStatus
): string => {
  switch (status) {
    case RequestStatus.Approved: {
      return "Approved";
    }
    case RequestStatus.Canceled: {
      return "Canceled";
    }
    case RequestStatus.Denied: {
      return "Denied";
    }
    case RequestStatus.Pending: {
      return "Pending";
    }
  }
};
