import {
  RecommendationsEntityKey,
  RecommendationsEntityType,
  RecommendationsSubscoreType,
  ResourceRoleKey,
  UserRoleKey,
  useSuggestedAccessToRemediateForEntityQuery,
} from "api/generated/graphql";
import { Icon, RadioGroup } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { SetStateAction, useEffect, useState } from "react";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";

import ThreatPill from "../ThreatPill";
import { ExtendedExpirationOptions, REVOKE } from "./RemediationColumns";
import * as styles from "./RemediationSuggestionSection.css";

interface Props {
  entityKey: RecommendationsEntityKey;
  setNewAccess: (value: SetStateAction<Record<string, number>>) => void;
  originalSuggestionsByRowId: Set<string>;
  setOriginalSuggestionsByRowId: (value: SetStateAction<Set<string>>) => void;
  editMode: boolean;
  type: RecommendationsSubscoreType;
}

const makeRowIdFromUser = (user: UserRoleKey) =>
  user.accessLevelRemoteId
    ? user.userId + ":" + user.accessLevelRemoteId
    : user.userId;

const makeRowIdFromResource = (resource: ResourceRoleKey) =>
  resource.accessLevelRemoteId
    ? resource.resourceId + ":" + resource.accessLevelRemoteId
    : resource.resourceId;
const RemediationSuggestionSection = (props: Props) => {
  const {
    entityKey,
    setNewAccess,
    originalSuggestionsByRowId,
    setOriginalSuggestionsByRowId,
    editMode,
    type,
  } = props;
  const [
    expiration,
    setExpiration,
  ] = useState<ExtendedExpirationOptions | null>(
    type == RecommendationsSubscoreType.SuggestionNotUsingAccess ||
      (type == RecommendationsSubscoreType.SuggestionUnusedAccess &&
        entityKey.entityType == RecommendationsEntityType.Group)
      ? "Revoke"
      : ExpirationValue.OneWeek
  );

  const { data } = useSuggestedAccessToRemediateForEntityQuery({
    variables: {
      entityKey: entityKey,
      type: type,
    },
  });

  const rowIds =
    data?.suggestedAccessToRemediateForEntity?.userRoleKeys?.map(
      makeRowIdFromUser
    ) ||
    data?.suggestedAccessToRemediateForEntity?.resourceRoleKeys?.map(
      makeRowIdFromResource
    );
  useEffect(() => {
    let expirationTime =
      expirationValueToDurationInMinutes(
        expiration as ExpirationValue
      )?.asMinutes() ?? 0;
    if (rowIds && rowIds.length > 0 && originalSuggestionsByRowId.size == 0) {
      const newSet = new Set<string>();
      rowIds.forEach((rowId) => newSet.add(rowId + ":" + expirationTime));
      setOriginalSuggestionsByRowId(newSet);
    }
  }, [
    setOriginalSuggestionsByRowId,
    expiration,
    rowIds,
    originalSuggestionsByRowId,
  ]);

  const distinctRowCount = rowIds?.reduce((acc, rowId) => {
    acc.add(rowId.split(":")[0]);
    return acc;
  }, new Set()).size;

  useEffect(() => {
    if (expiration === REVOKE) {
      setNewAccess((prev) => {
        const next = { ...prev };
        rowIds?.forEach((rowId) => {
          next[rowId] = 0;
        });
        return next;
      });
    } else {
      const durationMin = expirationValueToDurationInMinutes(
        expiration as ExpirationValue
      )?.asMinutes();
      if (typeof durationMin === "number") {
        setNewAccess((prev) => {
          const next = { ...prev };
          rowIds?.forEach((rowId) => {
            next[rowId] = durationMin;
          });
          return next;
        });
      }
    }
  }, [data, expiration, editMode, entityKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const pillCount =
    type === RecommendationsSubscoreType.SuggestionPerpetualAndUnusedAccess
      ? 2
      : 1;

  const targetRows =
    type === RecommendationsSubscoreType.SuggestionNotUsingAccess
      ? pluralize("resource", distinctRowCount)
      : pluralize("user", distinctRowCount);

  const actionDirection =
    type === RecommendationsSubscoreType.SuggestionNotUsingAccess
      ? "for"
      : "from";

  return (
    <>
      <div className={styles.card}>
        <div className={styles.title}>
          <Icon name="stars" color="blue500V3" />
          Opal recommends taking action on access grants {actionDirection}{" "}
          {distinctRowCount} {targetRows} with {pillCount == 2 ? "both " : ""}
          the following {pluralize("attribute", pillCount)} :
          {type ===
            RecommendationsSubscoreType.SuggestionPerpetualAndUnusedAccess && (
            <>
              <ThreatPill
                type={RecommendationsSubscoreType.UnusedAccess}
                inverse
              />
              <ThreatPill
                type={RecommendationsSubscoreType.PerpetualAccess}
                inverse
              />
            </>
          )}
          {type === RecommendationsSubscoreType.SuggestionUnmanagedAccess && (
            <>
              <ThreatPill
                type={RecommendationsSubscoreType.UnmanagedAccess}
                inverse
              />
            </>
          )}
          {type === RecommendationsSubscoreType.SuggestionUnusedAccess && (
            <>
              <ThreatPill
                type={RecommendationsSubscoreType.UnusedAccess}
                inverse
              />
            </>
          )}
          {type === RecommendationsSubscoreType.SuggestionNotUsingAccess && (
            <>
              <ThreatPill
                type={RecommendationsSubscoreType.UnusedAccess}
                inverse
              />
            </>
          )}
        </div>
        <div
          className={sprinkles({
            flexDirection: "row",
            display: "flex",
          })}
        >
          <RadioGroup
            options={
              type == RecommendationsSubscoreType.SuggestionNotUsingAccess ||
              (type == RecommendationsSubscoreType.SuggestionUnusedAccess &&
                entityKey.entityType == RecommendationsEntityType.Group)
                ? [
                    {
                      label: "Revoke access",
                      value: REVOKE,
                    },
                  ]
                : [
                    {
                      label: "Revoke access",
                      value: REVOKE,
                    },
                    {
                      label: "Convert to JIT: 7 Days",
                      value: ExpirationValue.OneWeek,
                    },
                  ]
            }
            onSelectValue={(option) => {
              setExpiration(option.value as ExtendedExpirationOptions);
            }}
            value={
              expiration === REVOKE
                ? {
                    label: "Revoke access",
                    value: REVOKE,
                  }
                : {
                    label: "Convert to JIT: 7 Days",
                    value: ExpirationValue.OneWeek,
                  }
            }
            inline
            getOptionKey={(option) => option.value}
            getOptionLabel={(option) => option.label}
          />
        </div>
      </div>
    </>
  );
};

export default RemediationSuggestionSection;
