import {
  ConnectionType,
  EntityType,
  GroupType,
  RequestsSortByField,
  RequestStatus,
  RequestTableFragment,
  RequestType as GQLRequestType,
  ResourceType,
  SortDirection,
  useRequestsTableQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { Icon, Label, Tooltip } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import Table, { Header } from "components/ui/table/Table";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import { ReactNode, useState } from "react";
import React from "react";
import { useLocation } from "react-router";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { getUserAvatarIcon } from "views/users/utils";

import * as styles from "./RequestsTable.css";
import { RequestsType } from "./utils";

const CREATED_AT_COL_ID = RequestsSortByField.CreatedAt;

type SortOption = {
  field: RequestsSortByField;
  direction: SortDirection;
};

type RequestedByUser = {
  id: string;
  fullName: string;
  avatarUrl: string;
};

type TargetUser = {
  id: string;
  type: string;
  fullName: string;
  avatarUrl: string;
  requestedByUser: RequestedByUser;
};

type TargetGroup = {
  id: string;
  type: string;
  name: string;
  iconUrl: string;
  requestedByUser: RequestedByUser;
};

type Target = TargetUser | TargetGroup;
const isTargetUser = (target: Target): target is TargetUser =>
  target.type === "user";

const SORT_OPTIONS: SortOption[] = [
  {
    field: CREATED_AT_COL_ID,
    direction: SortDirection.Desc,
  },
  {
    field: CREATED_AT_COL_ID,
    direction: SortDirection.Asc,
  },
];

interface RequestRow {
  id: string;
  status: RequestStatus;
  target: Target;
  items: Array<{
    id: string;
    name: string;
    entityType: EntityType.Group | EntityType.Resource;
    itemType: ResourceType | GroupType;
    appType: ConnectionType;
    icon?: IconData;
    role?: string;
  }>;
  apps?: undefined; // Placeholder for table column ID
  roles?: undefined; // Placeholder for table column ID
  reason: string;
  durationMinutes: number;
  [CREATED_AT_COL_ID]: string;
}

const REQUEST_COLUMNS: Header<RequestRow>[] = [
  {
    id: "status",
    label: "Status",
    sortable: false,
    width: 48,
    customCellRenderer: (row) => {
      let rightIcon: React.ReactNode;
      let tooltipContent: ReactNode;

      switch (row.status) {
        case RequestStatus.Pending:
          rightIcon = <span style={{ fontSize: "30px" }}>•</span>;
          tooltipContent = "Pending";
          break;
        case RequestStatus.Approved:
          rightIcon = <Icon name="thumbs-up" size="xs" />;
          tooltipContent = "Approved";
          break;
        case RequestStatus.Denied:
          rightIcon = <Icon name="thumbs-down" size="xs" />;
          tooltipContent = "Denied";
          break;
        case RequestStatus.Canceled:
          rightIcon = <Icon name="x-circle" size="xs" />;
          tooltipContent = "Canceled";
          break;
      }

      return (
        <div
          className={styles.statusIcon({
            status: row.status.toLowerCase() as
              | "pending"
              | "approved"
              | "denied"
              | "canceled",
          })}
        >
          <Tooltip tooltipText={tooltipContent} contentInline arrow>
            {rightIcon}
          </Tooltip>
        </div>
      );
    },
  },
  {
    id: "target",
    label: "Request for",
    sortable: false,
    customCellRenderer: (row) => {
      return renderRequestTarget(row.target);
    },
  },
  {
    id: "items",
    label: "Resources",
    sortable: false,
    customCellRenderer: (row) => {
      if (!row.items.length) {
        return <></>;
      }
      const item = row.items[0];
      let label = item.name;
      let tooltipContent: ReactNode = "";
      const groupType =
        item.entityType === EntityType.Group
          ? (item.itemType as GroupType)
          : undefined;
      const resourceType =
        item.entityType === EntityType.Resource
          ? (item.itemType as ResourceType)
          : undefined;
      if (row.items.length > 1) {
        label += `, ${row.items.length - 1} more`;
        tooltipContent = (
          <>
            {row.items.map((item, i) => (
              <span key={i} className={sprinkles({ display: "block" })}>
                {item.name}
              </span>
            ))}
          </>
        );
      }
      return (
        <Tooltip tooltipText={tooltipContent} contentInline arrow>
          <StopClickPropagation>
            <ResourceLabel
              text={label}
              entityTypeNew={item.entityType}
              entityId={item.id}
              groupType={groupType}
              resourceType={resourceType}
            />
          </StopClickPropagation>
        </Tooltip>
      );
    },
  },
  {
    id: "apps",
    label: "Apps",
    sortable: false,
    customCellRenderer: (row) => {
      if (!row.items.length) {
        return <></>;
      }
      const items = _.uniqBy(row.items, (item) => item.appType);
      const item = items[0];
      let label = getConnectionTypeInfo(item.appType)?.name ?? "Unknown";
      let tooltipContent: ReactNode = "";
      if (items.length > 1) {
        label += `, ${items.length - 1} more`;
        tooltipContent = (
          <>
            {items.map((item, i) => (
              <span key={i} className={sprinkles({ display: "block" })}>
                {getConnectionTypeInfo(item.appType)?.name ?? "Unknown"}
              </span>
            ))}
          </>
        );
      }
      return (
        <Tooltip tooltipText={tooltipContent} contentInline arrow>
          <Label
            label={label}
            iconSize="md"
            icon={{
              type: "entity",
              entityType: item.appType,
            }}
          />
        </Tooltip>
      );
    },
  },
  {
    id: "roles",
    label: "Roles",
    sortable: false,
    customCellRenderer: (row) => {
      if (!row.items.length) {
        return <></>;
      }
      const roles = row.items
        .filter((item): item is typeof item & { role: string } =>
          Boolean(item.role)
        )
        .map((item) => item.role);
      let label = roles?.[0] || "";
      let tooltipContent: ReactNode = "";
      if (roles.length > 1) {
        label = "Multiple Roles";
        tooltipContent = (
          <>
            {roles.map((role, i) => (
              <span key={i} className={sprinkles({ display: "block" })}>
                {role}
              </span>
            ))}
          </>
        );
      }
      return (
        <Tooltip tooltipText={tooltipContent} contentInline arrow>
          <Label label={label} truncateLength={25} />
        </Tooltip>
      );
    },
  },
  {
    id: "reason",
    label: "Reason",
    sortable: false,
    customCellRenderer: (row) => {
      return <div className={styles.reasonCell}>{row.reason}</div>;
    },
  },
  {
    id: "durationMinutes",
    label: "Duration",
    sortable: false,
    width: 80,
    customCellRenderer: (row) => {
      const duration = moment.duration(row.durationMinutes, "minutes");
      return <div> {duration.humanize()}</div>;
    },
  },
  {
    id: CREATED_AT_COL_ID,
    label: "Requested",
    sortable: true,
    width: 120,
    customCellRenderer: (row) => {
      return <div>{moment(row[CREATED_AT_COL_ID]).fromNow()}</div>;
    },
  },
];

const renderRequestTarget = (target: Target): JSX.Element => {
  let name = "";
  let url = "";
  let entityType = EntityType.User;
  let iconData: IconData;
  if (isTargetUser(target)) {
    name = target.fullName;
    url = target.avatarUrl;
    iconData = {
      type: "src",
      icon: url,
      style: "rounded",
      fallbackIcon: defaultAvatarURL,
    };
  } else {
    name = target.name;
    url = target.iconUrl;
    entityType = EntityType.Group;
    iconData = {
      type: "src",
      icon: url,
      style: "rounded",
      fallbackIcon: defaultAvatarURL,
    };
  }

  let tooltipContent: ReactNode = "";
  if (target.requestedByUser.fullName !== name) {
    tooltipContent = (
      <>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            marginBottom: "xxs",
          })}
        >
          <Icon size="sm" data={getUserAvatarIcon(target.requestedByUser)} />
          <div>{target.requestedByUser.fullName}</div>
          <i
            className={sprinkles({
              color: "gray200",
            })}
          >
            requested for
          </i>
        </div>
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          <Icon size="sm" data={iconData} />
          <div>{name}</div>
        </div>
      </>
    );
  }
  return (
    <Tooltip tooltipText={tooltipContent} contentInline arrow>
      <div className={sprinkles({ display: "flex", gap: "sm" })}>
        <StopClickPropagation>
          <ResourceLabel
            text={name}
            entityTypeNew={entityType}
            avatar={url}
            entityId={target.id}
          />
        </StopClickPropagation>
      </div>
    </Tooltip>
  );
};

interface Props {
  requestsType: RequestsType;
  searchQuery?: string;
  numTotalRequests?: number; // Used for table data lazy loading
  setDisplayedRequestCount?: (count: number) => void;
}

const REQUESTS_PER_PAGE = 50;

const getGQLRequestType = (
  requestType: RequestsType
): GQLRequestType | null => {
  switch (requestType) {
    case "inbox":
      return GQLRequestType.Incoming;
    case "sent":
      return GQLRequestType.Outgoing;
    case "admin":
      return null;
  }
};

/**
 * Helper component that prevents a click event from propagating to parent elements.
 * This is needed because this view has a click event for each row in the table, which hides
 * the effects of click events on content within a table cell.
 */
const StopClickPropagation = (props: { children: ReactNode }) => {
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {props.children}
    </div>
  );
};

const HIDE_COMPLETED_PARAM = "hideCompleted";

const RequestsTable = (props: Props) => {
  const { searchQuery } = props;
  const transitionTo = useTransitionTo();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const requestType = getGQLRequestType(props.requestsType);
  const hideCompleted =
    query.get(HIDE_COMPLETED_PARAM) === "true" ||
    requestType === GQLRequestType.Incoming;

  const [sortBy, setSortBy] = useState<SortOption | undefined>(SORT_OPTIONS[0]);

  const { data, error, loading, fetchMore } = useRequestsTableQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        requestType,
        maxNumEntries: REQUESTS_PER_PAGE,
        searchQuery: searchQuery?.length ? searchQuery : undefined,
        sortBy: sortBy,
        showPendingOnly: hideCompleted,
      },
    },
  });

  if (error) {
    logError(error, `failed to list requests`);
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const requests = data?.requests.requests;
  const cursor = data?.requests.cursor;

  const populateTargetData = (request: RequestTableFragment): Target => {
    const requestedByUser = {
      id: request.requester?.id ?? "",
      fullName: request.requester?.fullName ?? "",
      avatarUrl: request.requester?.avatarUrl ?? "",
    };

    if (request.targetUserId && !request.targetGroupId) {
      return {
        id: request.targetUser?.id ?? "",
        type: "user",
        fullName: request.targetUser?.fullName ?? "",
        avatarUrl: request.targetUser?.avatarUrl ?? "",
        requestedByUser: requestedByUser,
      };
    } else if (!request.targetUserId && request.targetGroupId) {
      return {
        id: request.targetGroup?.id ?? "",
        type: "group",
        name: request.targetGroup?.name ?? "",
        iconUrl: request.targetGroup?.iconUrl ?? "",
        requestedByUser: requestedByUser,
      };
    }

    // Return placeholder if nothing else
    return {
      id: request.targetUser?.id ?? "",
      type: "user",
      fullName: request.targetUser?.fullName ?? "",
      avatarUrl: request.targetUser?.avatarUrl ?? "",
      requestedByUser: requestedByUser,
    };
  };

  const rows: RequestRow[] =
    requests?.map((request) => {
      const items: RequestRow["items"] = [];
      request.requestedGroups.map((requestedGroup) => {
        items.push({
          id: requestedGroup.group?.id ?? "",
          name: requestedGroup.group?.name ?? "",
          entityType: EntityType.Group,
          itemType: requestedGroup.group?.groupType ?? GroupType.OpalGroup,
          appType:
            requestedGroup.group?.connection?.connectionType ??
            ConnectionType.Custom,
          role: requestedGroup.accessLevel?.accessLevelName,
        });
      });
      request.requestedResources.map((requestedResource) => {
        items.push({
          id: requestedResource.resource?.id ?? "",
          name: requestedResource.resource?.name ?? "",
          entityType: EntityType.Resource,
          itemType:
            requestedResource.resource?.resourceType ?? ResourceType.Custom,
          appType:
            requestedResource.resource?.connection?.connectionType ??
            ConnectionType.Custom,
          role: requestedResource.accessLevel?.accessLevelName,
        });
      });
      return {
        status: request.status,
        id: request.id,
        target: populateTargetData(request),
        items,
        reason: request.reason || "",
        durationMinutes: request.durationInMinutes || 0,
        [CREATED_AT_COL_ID]: request.createdAt,
      };
    }) || [];

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              requestType,
              cursor,
              maxNumEntries: REQUESTS_PER_PAGE,
              searchQuery: searchQuery?.length ? searchQuery : undefined,
              sortBy: sortBy,
              showPendingOnly: hideCompleted,
            },
          },
        });
      }
    : undefined;

  // Allow the parent view to update its displayed request count.
  // The loading check is required to prevent the parent view from flickering.
  if (data && !loading && props.setDisplayedRequestCount) {
    props.setDisplayedRequestCount(rows.length);
  }

  let totalNumRows = Math.max(props.numTotalRequests ?? 0, rows.length);
  // Ensure the emtpy table state is displayed when:
  // 1) Admin view has infinite scroll and no requests are loaded
  // 2) No search results are found
  if (
    ((props.numTotalRequests ?? 0) > 0 && rows.length === 0) ||
    ((searchQuery?.length ?? 0) > 0 && rows.length === 0)
  ) {
    totalNumRows = 0;
  }

  return (
    <>
      {loading ? (
        <ColumnListItemsSkeleton />
      ) : (
        <Table
          rows={rows}
          totalNumRows={totalNumRows}
          getRowId={(ru) => ru.id}
          columns={REQUEST_COLUMNS}
          onRowClick={(row, event) => {
            transitionTo(
              {
                pathname: `/requests/${props.requestsType}/${row.id}`,
              },
              event
            );
          }}
          onLoadMoreRows={loadMoreRows}
          manualSortDirection={
            sortBy && {
              sortBy: sortBy.field,
              sortDirection: sortBy.direction,
            }
          }
          handleManualSort={(sortBy, sortDirection) => {
            if (!sortDirection) {
              setSortBy(undefined);
              return;
            }
            const direction: SortDirection =
              sortDirection === "DESC" ? SortDirection.Desc : SortDirection.Asc;
            switch (sortBy) {
              case CREATED_AT_COL_ID:
                setSortBy({
                  field: CREATED_AT_COL_ID,
                  direction,
                });
                break;
            }
          }}
        />
      )}
    </>
  );
};

export default RequestsTable;
