import { Maybe, ServiceType } from "api/generated/graphql";
import adLogo from "assets/logos/active-directory-logo.png";
import awsLogo from "assets/logos/aws-logo.svg";
import awsSsoLogo from "assets/logos/aws-sso-logo.png";
import azureLogo from "assets/logos/azure-logo.svg";
import customLogo from "assets/logos/custom-logo.png";
import duoLogo from "assets/logos/duo-logo.svg";
import gcpLogo from "assets/logos/gcp-iam-logo.svg";
import gcpStorageLogo from "assets/logos/gcp-storage-logo.png";
import githubLogo from "assets/logos/github-logo.png";
import gitlabLogo from "assets/logos/gitlab-logo.svg";
import googleGroupLogo from "assets/logos/googlegroups-logo.png";
import googleWorkspaceLogo from "assets/logos/googleworkspace-logo.png";
import kubernetesLogo from "assets/logos/kubernetes-logo.png";
import ldapLogo from "assets/logos/ldap-logo.png";
import mariadbLogo from "assets/logos/mariadb-logo.png";
import mongoLogo from "assets/logos/mongodb-logo.png";
import mySqlLogo from "assets/logos/mysql-logo.png";
import oktaLogo from "assets/logos/okta-logo-side.png";
import opalLogo from "assets/logos/opal-logo-background.png";
import pagerdutyLogo from "assets/logos/pager-duty-logo.png";
import postgresLogo from "assets/logos/postgres-logo.svg";
import salesforceLogo from "assets/logos/salesforce-logo.png";
import sshLogo from "assets/logos/server-logo.png";
import snowflakeLogo from "assets/logos/snowflake-logo.svg";
import tailscaleLogo from "assets/logos/tailscale-logo.png";
import teleportLogo from "assets/logos/teleport-logo.png";
import unknownLogo from "assets/logos/unknown-logo.png";
import workdayLogo from "assets/logos/workday-logo.png";
import Label from "components/label/Label";
import sprinkles from "css/sprinkles.css";

export type ServiceTypeInfo = {
  name: string;
  icon: Maybe<string>;
};

export const serviceTypeInfoByType: Record<ServiceType, ServiceTypeInfo> = {
  [ServiceType.ActiveDirectory]: {
    name: "Active Directory",
    icon: adLogo,
  },
  [ServiceType.AwsIam]: {
    name: "Amazon IAM",
    icon: awsLogo,
  },
  [ServiceType.AwsSso]: {
    name: "AWS IAM Identity Center",
    icon: awsSsoLogo,
  },
  [ServiceType.Custom]: {
    name: "Custom",
    icon: customLogo,
  },
  [ServiceType.CustomConnector]: {
    name: "Custom Connector",
    icon: customLogo,
  },
  [ServiceType.Duo]: {
    name: "Duo",
    icon: duoLogo,
  },
  [ServiceType.GcpIam]: {
    name: "Google Cloud IAM",
    icon: gcpLogo,
  },
  [ServiceType.GcpStorage]: {
    name: "Google Cloud Storage",
    icon: gcpStorageLogo,
  },
  [ServiceType.GcpBigQuery]: {
    name: "Google Cloud BigQuery",
    icon: gcpLogo,
  },
  [ServiceType.GitHub]: {
    name: "GitHub",
    icon: githubLogo,
  },
  [ServiceType.GitLab]: {
    name: "GitLab",
    icon: gitlabLogo,
  },
  [ServiceType.GoogleGroups]: {
    name: "Google Groups",
    icon: googleGroupLogo,
  },
  [ServiceType.GoogleWorkspace]: {
    name: "Google Workspace",
    icon: googleWorkspaceLogo,
  },
  [ServiceType.Kubernetes]: {
    name: "Kubernetes",
    icon: kubernetesLogo,
  },
  [ServiceType.Ldap]: {
    name: "LDAP",
    icon: ldapLogo,
  },
  [ServiceType.Mongo]: {
    name: "MongoDB Database",
    icon: mongoLogo,
  },
  [ServiceType.MongoAtlas]: {
    name: "MongoDB Atlas Database",
    icon: mongoLogo,
  },
  [ServiceType.Mysql]: {
    name: "MySQL Database",
    icon: mySqlLogo,
  },
  [ServiceType.Mariadb]: {
    name: "MariaDB Database",
    icon: mariadbLogo,
  },
  [ServiceType.OktaDirectory]: {
    name: "Okta Directory",
    icon: oktaLogo,
  },
  [ServiceType.Opal]: {
    name: "Opal",
    icon: opalLogo,
  },
  [ServiceType.Pagerduty]: {
    name: "PagerDuty",
    icon: pagerdutyLogo,
  },
  [ServiceType.Postgres]: {
    name: "Postgres Database",
    icon: postgresLogo,
  },
  [ServiceType.Salesforce]: {
    name: "Salesforce",
    icon: salesforceLogo,
  },
  [ServiceType.Ssh]: {
    name: "SSH",
    icon: sshLogo,
  },
  [ServiceType.Tailscale]: {
    name: "Tailscale",
    icon: tailscaleLogo,
  },
  [ServiceType.Unknown]: {
    name: "Unknown",
    icon: unknownLogo,
  },
  [ServiceType.Workday]: {
    name: "Workday",
    icon: workdayLogo,
  },
  [ServiceType.Teleport]: {
    name: "Teleport",
    icon: teleportLogo,
  },
  [ServiceType.AzureAd]: {
    name: "Azure",
    icon: azureLogo,
  },
  [ServiceType.Snowflake]: {
    name: "Snowflake",
    icon: snowflakeLogo,
  },
};

export const getServiceTypeInfo = (serviceType?: Maybe<ServiceType>) => {
  return serviceType ? serviceTypeInfoByType[serviceType] : null;
};

type ServiceTypeLabelProps = {
  serviceType?: Maybe<ServiceType>;
  pointerCursor?: boolean;
};

export const ServiceTypeLabel = (props: ServiceTypeLabelProps) => {
  const serviceTypeInfo = getServiceTypeInfo(props.serviceType);

  return (
    <div className={sprinkles({ display: "flex" })}>
      <Label
        icon={serviceTypeInfo?.icon || undefined}
        text={serviceTypeInfo?.name || "--"}
        pointerCursor={props.pointerCursor}
      />
    </div>
  );
};

export default ServiceTypeLabel;
