import {
  EntityType,
  Maybe,
  SearchResultEntryFragment,
  useSearchQuery,
} from "api/generated/graphql";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { ItemTypeLabel, ResourceLabel } from "components/label/Label";
import { ErrorMessageLightLabel } from "components/label/MessageLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import { Divider } from "components/ui";
import { useContext } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { getResourceUrlNew } from "utils/common";
import SearchContext, {
  SearchContextActionType,
} from "views/search/SearchContext";

interface SearchTableRow {
  name: string;
  itemType: string;
}

const headers: Header<SearchTableRow>[] = [
  { id: "name", label: "Name" },
  { id: "itemType", label: "Item Type" },
];

export const Search = () => {
  const history = useHistory();
  const location = useLocation();
  const logEvent = useLogEvent();

  const { searchState, searchDispatch } = useContext(SearchContext);
  const searchParams = new URLSearchParams(location.search);
  const currentQuery = searchParams.get("q") || "";

  const { error } = useSearchQuery({
    skip: currentQuery.length <= 1,
    variables: {
      input: {
        query: currentQuery,
      },
    },
    onCompleted: (data) => {
      searchDispatch({
        type: SearchContextActionType.SearchQueryChange,
        payload: {
          searchResultEntries: data.search.entries,
        },
      });
    },
  });

  if (error) {
    return (
      <ColumnContainer>
        <Column isContent>
          <ColumnHeader
            title={`Search results for "${currentQuery}"`}
            icon={{ type: "name", icon: "search" }}
          />
          <Divider />
          <ErrorMessageLightLabel errorMessage="Error loading search results" />
        </Column>
      </ColumnContainer>
    );
  }

  const rows: CellRow<SearchTableRow>[] = searchState.searchResultEntries.map(
    (searchResult) => {
      const name = searchResult!.name;
      let subtext =
        searchResult?.annotationText ||
        getResourceTypeInfo(searchResult?.resourceType)?.name ||
        "";
      switch (searchResult?.objectId.entityType) {
        case EntityType.Group:
          subtext = getGroupTypeInfo(searchResult?.groupType)?.name || "";
          break;
      }
      const itemType = getSearchResultItemType(searchResult)!;
      return {
        id: searchResult!.objectId.entityId || "",
        data: {
          name: {
            value: name,
            element: (
              <ResourceLabel
                text={name}
                subText={subtext}
                entityTypeNew={searchResult!.objectId.entityType}
                resourceType={searchResult?.resourceType}
                avatar={searchResult?.avatarUrl ?? undefined}
                bold={true}
                iconLarge={true}
                pointerCursor={true}
              />
            ),
            clickHandler: () => {
              if (searchResult) {
                logEvent({
                  name: "search_result_click",
                  properties: {
                    entityID: searchResult.objectId.entityId,
                    entityType: searchResult.objectId.entityType,
                    entityName: name,
                  },
                });
                history.push(
                  getResourceUrlNew({
                    entityId: searchResult.objectId.entityId,
                    entityType: searchResult.objectId.entityType,
                  })
                );
              }
            },
          },
          itemType: {
            value: itemType,
            element: <ItemTypeLabel itemType={itemType} />,
          },
        },
      };
    }
  );

  const content = (
    <EmptyStateContentWrapper
      content={
        <MaterialTable<SearchTableRow>
          headers={headers}
          rows={rows}
          isUnsorted
          denseFormatting
        />
      }
      entityType={EntityType.SearchQuery}
      title={`No search results found`}
      subtitle={`Adjust your search query to populate the results table`}
      isEmpty={rows.length === 0}
      small={false}
    />
  );

  return (
    <ColumnContainer>
      <Column isContent>
        <ColumnHeader
          title={`Search results for "${currentQuery}"`}
          icon={{ type: "name", icon: "search" }}
        />
        <Divider />
        {content}
      </Column>
    </ColumnContainer>
  );
};

export default Search;

function getSearchResultItemType(
  searchResult?: Maybe<SearchResultEntryFragment>
) {
  const searchResultEntityType = searchResult?.objectId.entityType;
  let itemType = null;

  switch (searchResultEntityType) {
    case EntityType.Resource:
      itemType = getResourceTypeInfo(searchResult?.resourceType)?.name;
      break;
    case EntityType.Group:
      itemType = getGroupTypeInfo(searchResult?.groupType)?.name;
      break;
    case EntityType.Connection:
      itemType = "App";
      break;
    case EntityType.User:
      itemType = "User";
      break;
    default:
      itemType = "--";
  }

  return itemType;
}
