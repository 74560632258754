import { IdpConnectionType } from "api/generated/graphql";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { Banner, FormGroup, Input, Link } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import useLogEvent from "utils/analytics";
import { UploadServiceAccountJSONButton } from "views/connections/create/UploadServiceAccountJSONButton";

import { SetupContext, useResolvedState } from "../SetupContext";
import * as styles from "./IdpStep.css";

const Credentials = () => {
  const [{ connectionType }] = useResolvedState();

  let content;
  switch (connectionType) {
    case IdpConnectionType.Okta:
      content = <OktaCredentials />;
      break;
    case IdpConnectionType.Google:
      content = <GoogleCredentials />;
      break;
    case IdpConnectionType.AzureAd:
      content = <AzureCredentials />;
      break;
  }

  return (
    <div className={sprinkles({ display: "flex", flexDirection: "column" })}>
      <div className={styles.header}>Enter credentials</div>
      {content}
    </div>
  );
};

const OktaCredentials = () => {
  const [
    { credentials, credentialsError, connectionType },
  ] = useResolvedState();
  const { setCrendentials } = useContext(SetupContext);
  const logEvent = useLogEvent();

  if (connectionType !== IdpConnectionType.Okta) {
    return null;
  }

  const name = credentials?.name ?? "";
  const adminOwnerId = credentials?.adminOwnerId;
  const description = credentials?.description ?? "";
  const orgUrl = credentials?.orgUrl ?? "";
  const apiToken = credentials?.apiToken ?? "";

  return (
    <div className={styles.content}>
      <div className={styles.description}>
        <div className={sprinkles({ marginBottom: "lg" })}>
          Please provide the credentials for your Okta IdP below. You'll need to
          create a distinct Okta user to generate and own the API token. This
          enables customized permission scopes for Opal, maintaining their
          stability. Moreover, Okta will log access changes from Opal in the
          separate account's name.
        </div>
        {credentialsError && (
          <Banner type="error" message={credentialsError} marginBottom="lg" />
        )}
        <div className={styles.callout}>
          <div
            className={sprinkles({
              fontWeight: "semibold",
              marginBottom: "xs",
            })}
          >
            Complete the following tasks before proceeding:
          </div>
          <div>
            <Link
              url="https://help.okta.com/en-us/content/topics/users-groups-profiles/usgp-add-users.htm"
              external
              target="_blank"
              onClick={() => {
                logEvent({
                  name: "setup_link_click",
                  properties: {
                    url:
                      "https://help.okta.com/en-us/content/topics/users-groups-profiles/usgp-add-users.htm",
                  },
                });
              }}
            >
              Create an account for a new user in Okta
            </Link>
          </div>
          <div>
            <Link
              url="https://docs.opal.dev/docs/okta#step-1---configure-an-api-token-for-opal"
              external
              target="_blank"
              onClick={() => {
                logEvent({
                  name: "setup_link_click",
                  properties: {
                    url:
                      "https://docs.opal.dev/docs/okta#step-1---configure-an-api-token-for-opal",
                  },
                });
              }}
            >
              Create and configure an API token for Opal
            </Link>
          </div>
        </div>
        <div className={styles.form}>
          <FormGroup label="App name" required>
            <Input
              onChange={(val) => setCrendentials({ ...credentials, name: val })}
              placeholder="Identifiable name of the IdP that will show up in Opal."
              value={name}
            />
          </FormGroup>
          <FormGroup label="App admin" required>
            <OwnerDropdown
              selectedOwnerId={adminOwnerId}
              onSelectOwner={(owner) =>
                setCrendentials({ ...credentials, adminOwnerId: owner?.id })
              }
              placeholder="Select an owner to own this app."
            />
          </FormGroup>
          <FormGroup label="Description">
            <Input
              type="textarea"
              onChange={(val) =>
                setCrendentials({ ...credentials, description: val })
              }
              placeholder="A brief description of the account to further inform people requesting access to it."
              value={description}
            />
          </FormGroup>
          <FormGroup label="Organization hostname" required>
            <Input
              onChange={(val) =>
                setCrendentials({ ...credentials, orgUrl: val })
              }
              placeholder='The Okta organization hostname (e.g. "mydomain.okta.com")'
              value={orgUrl}
            />
          </FormGroup>
          <FormGroup label="API token" required>
            <Input
              onChange={(val) =>
                setCrendentials({ ...credentials, apiToken: val })
              }
              placeholder="The API token for the Okta account."
              value={apiToken}
              type="password"
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

const GoogleCredentials = () => {
  const [
    { credentials, credentialsError, connectionType },
  ] = useResolvedState();
  const { setCrendentials } = useContext(SetupContext);
  const logEvent = useLogEvent();

  if (connectionType !== IdpConnectionType.Google) {
    return null;
  }

  const name = credentials?.name ?? "";
  const adminOwnerId = credentials?.adminOwnerId;
  const description = credentials?.description ?? "";
  const adminEmail = credentials?.adminEmail ?? "";
  const customerId = credentials?.customerId ?? "";

  return (
    <div className={styles.content}>
      <div className={styles.description}>
        <div className={sprinkles({ marginBottom: "lg" })}>
          Please provide the credentials for your Google IdP below. You'll need
          to create a service account to manage your Google Workspace on your
          behalf. Once the service account is created, please input the user's
          credentials and some information about the connection below.
        </div>
        {credentialsError && (
          <Banner type="error" message={credentialsError} marginBottom="lg" />
        )}
        <div className={styles.callout}>
          <div
            className={sprinkles({
              fontWeight: "semibold",
              marginBottom: "xs",
            })}
          >
            Complete the following tasks before proceeding:
          </div>
          <div>
            <Link
              url="https://docs.opal.dev/docs/google-groups#step-1---configure-a-service-account-for-opal"
              external
              target="_blank"
              onClick={() => {
                logEvent({
                  name: "setup_link_click",
                  properties: {
                    url:
                      "https://docs.opal.dev/docs/google-groups#step-1---configure-a-service-account-for-opal",
                  },
                });
              }}
            >
              Create a service account in Google
            </Link>
          </div>
          <div>
            <Link
              url="https://console.developers.google.com/apis/api/admin.googleapis.com/overview"
              external
              target="_blank"
              onClick={() => {
                logEvent({
                  name: "setup_link_click",
                  properties: {
                    url:
                      "https://console.developers.google.com/apis/api/admin.googleapis.com/overview",
                  },
                });
              }}
            >
              Enable the Admin SDK API in Google
            </Link>
          </div>
          <div>
            <Link
              url="https://support.google.com/a/answer/10070793?hl=en"
              external
              target="_blank"
              onClick={() => {
                logEvent({
                  name: "setup_link_click",
                  properties: {
                    url: "https://support.google.com/a/answer/10070793?hl=en",
                  },
                });
              }}
            >
              Find your Google Workspace customer ID
            </Link>
          </div>
        </div>
        <div className={styles.form}>
          <FormGroup label="App name" required>
            <Input
              onChange={(val) => setCrendentials({ ...credentials, name: val })}
              placeholder="Identifiable name of the IdP that will show up in Opal."
              value={name}
            />
          </FormGroup>
          <FormGroup label="App admin" required>
            <OwnerDropdown
              selectedOwnerId={adminOwnerId}
              onSelectOwner={(owner) =>
                setCrendentials({ ...credentials, adminOwnerId: owner?.id })
              }
              placeholder="Select an owner to own this app."
            />
          </FormGroup>
          <FormGroup label="Description">
            <Input
              type="textarea"
              onChange={(val) =>
                setCrendentials({ ...credentials, description: val })
              }
              placeholder="A brief description of the account to further inform people requesting access to it."
              value={description}
            />
          </FormGroup>
          <FormGroup label="Google Workspace admin email" required>
            <Input
              onChange={(val) =>
                setCrendentials({ ...credentials, adminEmail: val })
              }
              placeholder="The email of the Google Workspace user to run queries on behalf of."
              value={adminEmail}
            />
          </FormGroup>
          <FormGroup label="Google Workspace customer ID" required>
            <Input
              onChange={(val) =>
                setCrendentials({ ...credentials, customerId: val })
              }
              placeholder="The customer ID corresponding to the Google Workspace."
              value={customerId}
            />
          </FormGroup>
          <FormGroup label="Service account credentials JSON" required>
            <UploadServiceAccountJSONButton
              setServiceAccountInfos={(info) =>
                setCrendentials({ ...credentials, serviceAccountJson: info })
              }
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

const AzureCredentials = () => {
  const [
    { credentials, credentialsError, connectionType },
  ] = useResolvedState();
  const { setCrendentials } = useContext(SetupContext);
  const logEvent = useLogEvent();

  if (connectionType !== IdpConnectionType.AzureAd) {
    return null;
  }

  const name = credentials?.name ?? "";
  const adminOwnerId = credentials?.adminOwnerId;
  const description = credentials?.description ?? "";
  const tenantId = credentials?.tenantId ?? "";
  const clientId = credentials?.clientId ?? "";
  const clientSecret = credentials?.clientSecret ?? "";

  return (
    <div className={styles.content}>
      <div className={styles.description}>
        <div className={sprinkles({ marginBottom: "lg" })}>
          Please provide the credentials for your Azure IdP below. You'll need
          to create an app registration to administer Azure on your behalf. Once
          the app registration is created, please input the user's credentials
          and some information about the connection below.
        </div>
        {credentialsError && (
          <Banner type="error" message={credentialsError} marginBottom="lg" />
        )}
        <div className={styles.callout}>
          <div
            className={sprinkles({
              fontWeight: "semibold",
              marginBottom: "xs",
            })}
          >
            Complete the following tasks before proceeding:
          </div>
          <div>
            <Link
              url="https://docs.opal.dev/docs/azure-ad"
              external
              target="_blank"
              onClick={() => {
                logEvent({
                  name: "setup_link_click",
                  properties: {
                    url: "https://docs.opal.dev/docs/azure-ad",
                  },
                });
              }}
            >
              Create an Azure app registration
            </Link>
          </div>
        </div>
        <div className={styles.form}>
          <FormGroup label="App name" required>
            <Input
              onChange={(val) => setCrendentials({ ...credentials, name: val })}
              placeholder="Identifiable name of the IdP that will show up in Opal."
              value={name}
            />
          </FormGroup>
          <FormGroup label="App admin" required>
            <OwnerDropdown
              selectedOwnerId={adminOwnerId}
              onSelectOwner={(owner) =>
                setCrendentials({ ...credentials, adminOwnerId: owner?.id })
              }
              placeholder="Select an owner to own this app."
            />
          </FormGroup>
          <FormGroup label="Description">
            <Input
              type="textarea"
              onChange={(val) =>
                setCrendentials({ ...credentials, description: val })
              }
              placeholder="A brief description of the account to further inform people requesting access to it."
              value={description}
            />
          </FormGroup>
          <FormGroup label="Tenant ID" required>
            <Input
              onChange={(val) =>
                setCrendentials({ ...credentials, tenantId: val })
              }
              value={tenantId}
              placeholder="a138396d-3790-4622-8405-36bf9283d298"
            />
          </FormGroup>
          <FormGroup label="Client ID" required>
            <Input
              onChange={(val) =>
                setCrendentials({ ...credentials, clientId: val })
              }
              value={clientId}
              placeholder="6e292892-aa75-22aa-9ade-ae66e6e753c8"
            />
          </FormGroup>
          <FormGroup label="Client Secret" required>
            <Input
              type="password"
              onChange={(val) =>
                setCrendentials({ ...credentials, clientSecret: val })
              }
              value={clientSecret}
              placeholder="1lQ8Q~4QLtsARzlkdLSdx_pYE22OGxmjitm6OdjU"
            />
          </FormGroup>
          <p>
            You will be redirected through Azure to approve the app
            registration.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Credentials;
