import { Button, Icon, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { FC } from "react";
import ViewSkeleton from "views/loading/ViewSkeleton";

import * as styles from "./FullscreenView.css";

type ButtonProps =
  | {
      primaryButtonLabel?: string;
      onPrimaryButtonClick?: () => void;
      primaryButtonDisabled?: boolean;
      primaryButtonLoading?: boolean;
      primaryButtonHidden?: boolean;
      onCancel: () => void;
      primaryAdjacentTip?: JSX.Element;
      onBack?: () => void;
    }
  | {
      buttons: React.ReactNode[];
      onCancel: () => void;
    };

type Props = ButtonProps & {
  title: JSX.Element | string;
  topSection?: JSX.Element;
  topSectionBorder?: boolean;
};

const FullscreenView: FC<Props> = (props) => {
  const { topSectionBorder = true } = props;
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.header}>{props.title}</div>
        <Icon name="x" size="sm" onClick={props.onCancel} />
      </div>
      {props.topSection && (
        <div className={styles.topSection({ border: topSectionBorder })}>
          {props.topSection}
        </div>
      )}
      <div className={styles.content}>{props.children}</div>
      <div className={styles.footer}>
        {"buttons" in props ? (
          <>
            <div />
            <div className={sprinkles({ display: "flex", gap: "lg" })}>
              {props.buttons}
            </div>
          </>
        ) : (
          <>
            <div>
              {props.onBack && (
                <Button
                  label="Back"
                  leftIconName="chevron-left"
                  size="lg"
                  onClick={props.onBack}
                  outline
                />
              )}
            </div>
            <div className={sprinkles({ display: "flex", gap: "lg" })}>
              {props.primaryAdjacentTip ? props.primaryAdjacentTip : null}
              <Button
                label="Cancel"
                outline
                size="lg"
                onClick={props.onCancel}
              />
              {!props.primaryButtonHidden && (
                <Button
                  label={props.primaryButtonLabel || "Save"}
                  type="primary"
                  size="lg"
                  onClick={props.onPrimaryButtonClick}
                  disabled={props.primaryButtonDisabled}
                  loading={props.primaryButtonLoading}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

type SidebarProps = {
  variants?: styles.LeftContentVariants;
};

export const FullscreenViewSidebar: FC<SidebarProps> = (props) => {
  return (
    <div className={styles.leftContent({ ...props?.variants })}>
      {props.children}
    </div>
  );
};

interface ContentProps {
  fullWidth?: boolean;
  center?: boolean;
}

export const FullscreenViewContent: FC<ContentProps> = (props) => {
  return (
    <div
      className={styles.rightContent({
        fullWidth: props.fullWidth,
        center: props.center,
      })}
    >
      {props.children}
    </div>
  );
};

export const FullscreenSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.header}>
          <Skeleton width="200px" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.rightContent({})}>
          <ViewSkeleton />
        </div>
      </div>
      <div className={styles.footer} />
    </div>
  );
};

export default Object.assign(FullscreenView, {
  Sidebar: FullscreenViewSidebar,
  Content: FullscreenViewContent,
});
