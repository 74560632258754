import { getModifiedErrorMessage } from "api/ApiContext";
import {
  Maybe,
  ResourceCustomAccessLevelFragment,
  ResourceType,
  ServiceType,
  useResourceCustomAccessLevelQuery,
  useUpdateResourceCustomAccessLevelMutation,
} from "api/generated/graphql";
import UpdateResourceCustomRoleModal from "components/modals/update/UpdateResourceCustomRoleModal";
import { useToast } from "components/toast/Toast";
import React, { useState } from "react";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";
import {
  getRoleDescriptionInfo,
  PolicyFormat,
} from "views/resources/viewer/rows/ResourceCustomRolesRow";

export type ResourceCustomRoleEditLabelProps = {
  resourceCustomRole: ResourceCustomAccessLevelFragment;
  serviceType: ServiceType;
  resourceType: ResourceType;
  resourceCustomRolesCount: number;
};

export const ResourceCustomRoleEditLabel = (
  props: ResourceCustomRoleEditLabelProps
) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <span
        style={{
          textDecoration: "underline",
          color: "blue",
          cursor: "pointer",
        }}
        onClick={async () => {
          setShowModal(!showModal);
        }}
      >
        {"Edit"}
      </span>
      {showModal && (
        <UpdateResourceCustomRoleModalWrapper
          showModal={showModal}
          setShowModal={setShowModal}
          key={props.resourceCustomRole.id}
          resourceCustomRole={props.resourceCustomRole}
          serviceType={props.serviceType}
          resourceType={props.resourceType}
          resourceCustomRolesCount={props.resourceCustomRolesCount}
        />
      )}
    </>
  );
};

type UpdateResourceCustomRoleModalWrapperProps = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  resourceCustomRole: ResourceCustomAccessLevelFragment;
  serviceType: ServiceType;
  resourceType: ResourceType;
  resourceCustomRolesCount: number;
};

const UpdateResourceCustomRoleModalWrapper = (
  props: UpdateResourceCustomRoleModalWrapperProps
) => {
  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();

  const [policy, setPolicy] = useState<Maybe<string>>(null);

  const [savedPolicy, setSavedPolicy] = useState<Maybe<string>>(null);

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const roleDescriptionInfo = getRoleDescriptionInfo(
    props.serviceType,
    props.resourceType
  );

  useResourceCustomAccessLevelQuery({
    variables: {
      input: {
        id: props.resourceCustomRole.id,
      },
    },
    onCompleted: (data) => {
      switch (data.resourceCustomAccessLevel.__typename) {
        case "ResourceCustomAccessLevelResult":
          if (data.resourceCustomAccessLevel.resourceCustomAccessLevel.policy) {
            let prettyPrintedPolicy: string;
            if (roleDescriptionInfo.policyFormat === PolicyFormat.JSON) {
              prettyPrintedPolicy = JSON.stringify(
                JSON.parse(
                  data.resourceCustomAccessLevel.resourceCustomAccessLevel
                    .policy
                ),
                null,
                2
              );
            } else {
              prettyPrintedPolicy =
                data.resourceCustomAccessLevel.resourceCustomAccessLevel.policy;
            }
            setPolicy(prettyPrintedPolicy);
            setSavedPolicy(prettyPrintedPolicy);
          }
          break;
        default:
          logError(new Error(`failed to retrieve resource custom role`));
      }
    },
    onError: (error) => {
      setErrorMessage(`Error: failed to get resource custom roles.`);
      logError(error, `failed to get resource custom role`);
    },
  });

  const [
    updateResourceCustomAccessLevelMutation,
    { loading: updateResourceCustomRoleLoading },
  ] = useUpdateResourceCustomAccessLevelMutation();

  const updateModalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
  };

  const updateModal = (
    <UpdateResourceCustomRoleModal
      key={"edit_role_modal"}
      isModalOpen={props.showModal}
      onChange={() => {}}
      onClose={() => {
        updateModalReset();
      }}
      onSubmit={async () => {
        const policyFormat = roleDescriptionInfo.policyFormat;
        if (!policy) {
          setErrorMessage(
            `Error: failed to parse updated policy. Please ensure that it's valid ${
              policyFormat === PolicyFormat.JSON ? "JSON" : "SQL"
            }.`
          );
          return;
        }

        let parsedCurrentPolicy = "";
        if (policyFormat === PolicyFormat.JSON) {
          try {
            parsedCurrentPolicy = JSON.stringify(JSON.parse(policy));
          } catch (error) {
            setErrorMessage(
              `Error: failed to parse updated policy. Please ensure that it's valid JSON.`
            );
            return;
          }
        } else {
          parsedCurrentPolicy = policy;
        }

        logEvent({
          name: "apps_edit_role",
          properties: {
            resourceType: props.resourceType,
          },
        });

        try {
          const { data } = await updateResourceCustomAccessLevelMutation({
            variables: {
              input: {
                resourceId: props.resourceCustomRole.resourceId,
                id: props.resourceCustomRole.id,
                policy: parsedCurrentPolicy,
              },
            },
            refetchQueries: ["ResourceCustomAccessLevels"],
          });
          switch (data?.updateResourceCustomAccessLevel.__typename) {
            case "UpdateResourceCustomAccessLevelResult":
              if (
                data.updateResourceCustomAccessLevel.resourceCustomAccessLevel
                  .policy
              ) {
                let prettyPrintedPolicy: string;
                if (roleDescriptionInfo.policyFormat === PolicyFormat.JSON) {
                  prettyPrintedPolicy = JSON.stringify(
                    JSON.parse(
                      data.updateResourceCustomAccessLevel
                        .resourceCustomAccessLevel.policy
                    ),
                    null,
                    2
                  );
                } else {
                  prettyPrintedPolicy =
                    data.updateResourceCustomAccessLevel
                      .resourceCustomAccessLevel.policy;
                }
                setPolicy(prettyPrintedPolicy);
                setSavedPolicy(prettyPrintedPolicy);
              }

              updateModalReset();

              displaySuccessToast("Success: resource custom role updated");
              break;
            case "ResourceCustomAccessLevelPriorityError":
              logError(new Error(data.updateResourceCustomAccessLevel.message));
              setErrorMessage(data.updateResourceCustomAccessLevel.message);
              break;
            default:
              logError(new Error(`failed to update resource custom role`));
              setErrorMessage("Error: failed to update resource custom role");
          }
        } catch (error) {
          logError(error, "failed to update resource custom role`");
          setErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to update resource custom role",
              error
            )
          );
        }
      }}
      value={props.resourceCustomRole.accessLevel.accessLevelName}
      errorMessage={errorMessage}
      loading={updateResourceCustomRoleLoading}
      setCurrentPolicy={setPolicy}
      currentPolicy={policy}
      savedPolicy={savedPolicy}
      roleDescriptionInfo={roleDescriptionInfo}
      resourceCustomRolesCount={props.resourceCustomRolesCount}
    />
  );

  return <>{props.showModal && updateModal}</>;
};

export default ResourceCustomRoleEditLabel;
