import { DataElement, DataElementList } from "components/ui";
import _ from "lodash";
import { UsersFilterData } from "views/users/UsersFilter";

export const UsersFilterViewer = ({
  filter,
  onSetFilter,
}: {
  filter: UsersFilterData;
  onSetFilter: (newFilter: UsersFilterData) => void;
}) => {
  return (
    <DataElementList>
      {filter.managerFilter && (
        <>
          {filter.managerFilter.managerUnset && (
            <DataElement
              label={"Manager: None set"}
              color="gray"
              leftIcon={{ name: "user-square" }}
              rightIcon={{
                name: "x",
              }}
              onClick={() => {
                const newFilter = { ...filter };
                if (newFilter.managerFilter) {
                  newFilter.managerFilter.managerUnset = undefined;
                }
                if (
                  !newFilter.managerFilter?.managerUnset &&
                  !newFilter.managerFilter?.managerId
                ) {
                  newFilter.managerFilter = undefined;
                }
                onSetFilter(newFilter);
              }}
            />
          )}
          {filter.managerFilter.managerId && (
            <DataElement
              label={"Manager: " + filter.hackyManagerFilterName ?? ""}
              color="gray"
              leftIcon={{ name: "user-square" }}
              rightIcon={{
                name: "x",
              }}
              onClick={() => {
                const newFilter = { ...filter };
                if (newFilter.managerFilter) {
                  newFilter.managerFilter.managerId = undefined;
                }
                if (
                  !newFilter.managerFilter?.managerUnset &&
                  !newFilter.managerFilter?.managerId
                ) {
                  newFilter.managerFilter = undefined;
                }
                onSetFilter(newFilter);
              }}
            />
          )}
        </>
      )}
      {filter.teamFilter && (
        <>
          {filter.teamFilter.teamUnset && (
            <DataElement
              label={"Team: None set"}
              color="gray"
              leftIcon={{ name: "users" }}
              rightIcon={{
                name: "x",
              }}
              onClick={() => {
                const newFilter = { ...filter };
                if (newFilter.teamFilter) {
                  newFilter.teamFilter.teamUnset = undefined;
                }
                if (
                  !newFilter.teamFilter?.teamUnset &&
                  !newFilter.teamFilter?.teams
                ) {
                  newFilter.teamFilter = undefined;
                }
                onSetFilter(newFilter);
              }}
            />
          )}
          {(filter.teamFilter.teams ?? []).map((teamFilter) => (
            <DataElement
              label={"Team: " + teamFilter}
              color="gray"
              leftIcon={{ name: "users" }}
              rightIcon={{
                name: "x",
              }}
              onClick={() => {
                const newFilter = { ...filter };
                if (newFilter.teamFilter) {
                  newFilter.teamFilter.teams = undefined;
                }
                if (
                  !newFilter.teamFilter?.teamUnset &&
                  !newFilter.teamFilter?.teams
                ) {
                  newFilter.teamFilter = undefined;
                }
                onSetFilter(newFilter);
              }}
            />
          ))}
        </>
      )}
      {filter.titleFilter && (
        <>
          {filter.titleFilter.titleUnset && (
            <DataElement
              label={"Title: None set"}
              color="gray"
              leftIcon={{ name: "role" }}
              rightIcon={{
                name: "x",
              }}
              onClick={() => {
                const newFilter = { ...filter };
                if (newFilter.titleFilter) {
                  newFilter.titleFilter.titleUnset = undefined;
                }
                if (
                  !newFilter.titleFilter?.titleUnset &&
                  !newFilter.titleFilter?.titles
                ) {
                  newFilter.titleFilter = undefined;
                }
                onSetFilter(newFilter);
              }}
            />
          )}
          {(filter.titleFilter.titles ?? []).map((titleFilter) => (
            <DataElement
              label={"Title: " + titleFilter}
              color="gray"
              leftIcon={{ name: "users" }}
              rightIcon={{
                name: "x",
              }}
              onClick={() => {
                const newFilter = { ...filter };
                if (newFilter.titleFilter) {
                  newFilter.titleFilter.titles = undefined;
                }
                if (
                  !newFilter.titleFilter?.titleUnset &&
                  !newFilter.titleFilter?.titles
                ) {
                  newFilter.titleFilter = undefined;
                }
                onSetFilter(newFilter);
              }}
            />
          ))}
        </>
      )}
      {filter.idpStatusFilter && (
        <>
          {filter.idpStatusFilter.statusUnset && (
            <DataElement
              label={"IDP Status: None set"}
              color="gray"
              leftIcon={{ name: "cloud-blank" }}
              rightIcon={{ name: "x" }}
              onClick={() => {
                const newFilter = { ...filter };
                if (newFilter.idpStatusFilter) {
                  newFilter.idpStatusFilter.statusUnset = undefined;
                }
                if (
                  !newFilter.idpStatusFilter?.statusUnset &&
                  !newFilter.idpStatusFilter?.statuses
                ) {
                  newFilter.idpStatusFilter = undefined;
                }
                onSetFilter(newFilter);
              }}
            />
          )}
          {(filter.idpStatusFilter.statuses ?? []).map((statusFilter) => (
            <DataElement
              label={"IDP Status: " + _.startCase(_.camelCase(statusFilter))}
              color="gray"
              leftIcon={{ name: "cloud-blank" }}
              rightIcon={{ name: "x" }}
              onClick={() => {
                const newFilter = { ...filter };
                if (newFilter.idpStatusFilter) {
                  newFilter.idpStatusFilter.statuses = undefined;
                }
                if (
                  !newFilter.idpStatusFilter?.statusUnset &&
                  !newFilter.idpStatusFilter?.statuses
                ) {
                  newFilter.idpStatusFilter = undefined;
                }
                onSetFilter(newFilter);
              }}
            />
          ))}
        </>
      )}
    </DataElementList>
  );
};
