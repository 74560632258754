import {
  CreateEventStreamInput,
  EntityType,
  PubSubPublishConnectionType,
  useCreateEventStreamMutation,
  useListEventStreamsQuery,
  WebhookPubsubPublishConnectionApiKeyLocation,
  WebhookPubsubPublishConnectionAuthType,
} from "api/generated/graphql";
import { EventStreamConnections } from "components/event_streaming/EventStreamConnections";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { ButtonV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { OrgSettingsSection } from "views/settings/OrgSettings";

import * as styles from "./EventStreaming.css";

export type EventStreamingProps = {
  title: string;
};

export const EventStreaming = (props: EventStreamingProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const hasEventStreaming = useFeatureFlag(FeatureFlag.EventStreaming);
  const canUseEventStreaming = hasV3 && hasEventStreaming;

  const { data, error, loading } = useListEventStreamsQuery({
    skip: !canUseEventStreaming,
    fetchPolicy: "cache-and-network",
  });

  if (!canUseEventStreaming) {
    return (
      <>
        <NotFoundPage />
      </>
    );
  }

  if (error) {
    logError(new Error(`failed to fetch event streams`));
    return (
      <>
        <UnexpectedErrorPage error={error} />
      </>
    );
  }

  const eventStreams = data?.listEventStreams.eventStreams ?? [];

  // Test component to be removed once we have creation UX built!
  const TestCreateEventStream = ({
    label,
    input,
  }: {
    label: string;
    input: CreateEventStreamInput;
  }) => {
    const [createEventStream] = useCreateEventStreamMutation({});

    return (
      <>
        <ButtonV3
          label={label}
          type="main"
          leftIconName="plus"
          onClick={() =>
            createEventStream({
              variables: {
                input: input,
              },
            })
          }
          size="sm"
        />
      </>
    );
  };

  return (
    <>
      {/* Section below will be removed once we have create built in the UX! */}
      <div>
        <div className={sprinkles({ marginBottom: "sm" })}>
          <TestCreateEventStream
            label="Test - DD"
            input={{
              name: "Datadog custom webhook",
              connectionType: PubSubPublishConnectionType.Webhook,
              metadata: {
                connectionType: PubSubPublishConnectionType.Webhook,
                webhook: {
                  url: "https://http-intake.logs.us5.datadoghq.com/api/v2/logs",
                },
              },
              credentials: {
                connectionType: PubSubPublishConnectionType.Webhook,
                webhook: {
                  authType: WebhookPubsubPublishConnectionAuthType.ApiKey,
                  apiKey: {
                    credentials: [
                      {
                        name: "DD-API-KEY",
                        value: "<API KEY>",
                        location:
                          WebhookPubsubPublishConnectionApiKeyLocation.Header,
                      },
                    ],
                  },
                },
              },
            }}
          />
        </div>

        <div className={sprinkles({ marginBottom: "sm" })}>
          <TestCreateEventStream
            label="Test - API Key"
            input={{
              name: "API Key custom webhook",
              connectionType: PubSubPublishConnectionType.Webhook,
              metadata: {
                connectionType: PubSubPublishConnectionType.Webhook,
                webhook: {
                  url:
                    "https://webhook.site/20d536dc-b95f-47d3-add3-5ac42d279fbc",
                },
              },
              credentials: {
                connectionType: PubSubPublishConnectionType.Webhook,
                webhook: {
                  authType: WebhookPubsubPublishConnectionAuthType.ApiKey,
                  apiKey: {
                    credentials: [
                      {
                        name: "test-key-1",
                        value: "test-value-1",
                        location:
                          WebhookPubsubPublishConnectionApiKeyLocation.Header,
                      },
                      {
                        name: "test-key-2",
                        value: "test-value-2",
                        location:
                          WebhookPubsubPublishConnectionApiKeyLocation.QueryParam,
                      },
                    ],
                  },
                },
              },
            }}
          />
        </div>

        <div className={sprinkles({ marginBottom: "sm" })}>
          <TestCreateEventStream
            label="Test - None"
            input={{
              name: "None custom webhook",
              connectionType: PubSubPublishConnectionType.Webhook,
              metadata: {
                connectionType: PubSubPublishConnectionType.Webhook,
                webhook: {
                  url:
                    "https://webhook.site/20d536dc-b95f-47d3-add3-5ac42d279fbc",
                },
              },
              credentials: {
                connectionType: PubSubPublishConnectionType.Webhook,
                webhook: {
                  authType: WebhookPubsubPublishConnectionAuthType.None,
                },
              },
            }}
          />
        </div>
      </div>
      {/* Section above will be removed once we have create built in the UX! */}
      <OrgSettingsSection title={props.title}>
        <EmptyStateContentWrapper
          entityType={EntityType.EventStreamConnection}
          title={"Event Streaming Connections"}
          subtitle={`Add connections to stream events to external systems`}
          isEmpty={!eventStreams || eventStreams.length == 0}
          onClickHandler={() => alert("DF-188")}
          buttonTitle={`Add Event Streaming Connection`}
          small={true}
          extraSmall={true}
          loading={loading}
          content={
            <div className={styles.container}>
              <EventStreamConnections eventStreams={eventStreams} />
            </div>
          }
        />
      </OrgSettingsSection>
    </>
  );
};
