import {
  EntityType,
  GroupType,
  OwnedGroupFragment,
  OwnedResourceFragment,
  ResourceType,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Icon, Label } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import React from "react";
import { getResourceUrlNew } from "utils/common";
import { useTransitionTo } from "utils/router/hooks";

interface OwnerResourcesTableRow {
  id: string;
  name: string;
  typename: "Resource" | "Group" | undefined;
  type: ResourceType | GroupType;
  admin: boolean;
  reviewer: boolean;
}

type OwnerResourcesTablePropsV3 = {
  ownedResources: OwnedResourceFragment[];
  ownedGroups: OwnedGroupFragment[];
};

export const OwnerResourcesTableV3 = (props: OwnerResourcesTablePropsV3) => {
  const transitionTo = useTransitionTo();

  const OWNER_RESOURCES_COLUMNS: Header<OwnerResourcesTableRow>[] = [
    { id: "name", label: "Name", sortable: true },
    {
      id: "type",
      label: "Type",
      sortable: true,
      customCellRenderer: (row) => {
        const formattedType =
          (row.typename === "Resource"
            ? getResourceTypeInfo(row.type as ResourceType)?.fullName
            : getGroupTypeInfo(row.type as GroupType)?.name) || "";
        return (
          <Label
            label={formattedType}
            icon={{ type: "entity", entityType: row.type }}
          />
        );
      },
    },
    {
      id: "admin",
      label: "Admin",
      sortable: false,
      customCellRenderer: (row) => {
        return (
          <span>
            {row.admin ? <Icon name="check-circle" size="sm" /> : "—"}
          </span>
        );
      },
    },
    {
      id: "reviewer",
      label: "Reviewer",
      sortable: false,
      customCellRenderer: (row) => {
        return (
          <span>
            {row.reviewer ? <Icon name="check-circle" size="sm" /> : "—"}
          </span>
        );
      },
    },
  ];

  const rows = [
    ...props.ownedResources.map<OwnerResourcesTableRow>((item) => {
      return {
        id: item.resource.id,
        name: item.resource.name,
        typename: item.resource.__typename,
        type: item.resource.resourceType,
        admin: item.isAdmin,
        reviewer: item.isReviewer,
      };
    }),
    ...props.ownedGroups.map<OwnerResourcesTableRow>((item) => {
      return {
        id: item.group.id,
        name: item.group.name,
        typename: item.group.__typename,
        type: item.group.groupType,
        admin: item.isAdmin,
        reviewer: item.isReviewer,
      };
    }),
  ];

  return (
    <Table
      rows={rows}
      totalNumRows={rows.length}
      getRowId={(row) => row.id}
      columns={OWNER_RESOURCES_COLUMNS}
      defaultSortBy="name"
      onRowClick={(row, event) => {
        transitionTo(
          {
            pathname: getResourceUrlNew({
              entityId: row.id,
              entityType:
                row.typename === "Resource"
                  ? EntityType.Resource
                  : EntityType.Group,
            }),
          },
          event
        );
      }}
    />
  );
};

export default OwnerResourcesTableV3;
