import { Column, ColumnContainer } from "components/column/Column";
import { Redirect, Route, Switch } from "react-router-dom";
import { UserExperience, useUserHasUserExperience } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import AppDetailColumnForItems from "views/apps/AppDetailColumnForItems";
import AppDetailContent from "views/apps/AppDetailContent";
import AppsListColumn from "views/apps/AppsListColumn";
import AWSAllAccountsColumn from "views/apps/AWSAllAccountsColumn";
import AWSCreateRoleColumn from "views/apps/AWSCreateRoleColumn";
import CreateAppBrowseColumn from "views/apps/CreateAppBrowseColumn";
import AppCatalog from "views/apps/enduser_exp/AppCatalog";
import GroupDetailColumn from "views/apps/GroupDetailColumn";
import Bundles from "views/bundles/Bundles";
import ConnectSessionView from "views/connect_sessions/ConnectSessionView";
import CreateConnection from "views/connections/CreateConnection";
import CreateGroupColumn from "views/groups/creation/CreateGroupColumn";
import CreateResourceColumn from "views/resources/creation/CreateResourceColumn";

import AppDetailView from "./AppDetailViewV3";
import BulkActionColumn from "./BulkActionColumn";
import BulkSelectBar from "./BulkSelectBar";
import { AppDetailView as EndUserAppDetailView } from "./enduser_exp/AppDetailView";
import GroupDetailView from "./GroupDetailViewV3";
import ResourceDetailColumn from "./ResourceDetailColumn";
import ResourceDetailView from "./ResourceDetailViewV3";

const Items = () => {
  return (
    <Switch>
      <Route
        path={"/apps/create/:serviceID"}
        component={CreateConnectionView}
      />
      <Route path={"/apps/browse"} component={BrowseServicesView} />
      <Route component={Browser} />
    </Switch>
  );
};

const Empty = () => <></>;

const Browser = () => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const hasEndUserExp = useFeatureFlag(FeatureFlag.EndUserExperience);
  const showEndUserExp =
    useUserHasUserExperience() === UserExperience.EndUserUX;

  return (
    <>
      <BulkSelectBar />
      <ColumnContainer>
        <Switch>
          <Route
            exact
            path="/apps"
            component={showEndUserExp ? AppCatalog : AppsListColumn}
          />
          <Route
            exact
            path={["/apps/:appId/all-accounts"]}
            component={AWSAllAccountsColumn}
          />
          {hasV3 && (
            <Switch>
              <Route
                exact
                path={["/apps/:appId"]}
                component={
                  showEndUserExp ? EndUserAppDetailView : AppDetailView
                }
              />
              <Route
                exact
                path={["/resources/:resourceId"]}
                component={ResourceDetailView}
              />
              <Route
                exact
                path={["/groups/:groupId"]}
                component={GroupDetailView}
              />
              {hasEndUserExp && (
                <Route
                  exact
                  path={"/apps/:appId/connect/:resourceId"}
                  component={ConnectSessionView}
                />
              )}
            </Switch>
          )}
          <Route
            exact
            path={[
              "/apps/:appId/create-role",
              "/apps/:appId/create-resource",
              "/apps/:appId/create-group",
              "/apps/okta/:oktaAppId",
              "/apps/okta/:oktaAppId/:appView",
              "/apps/:appId",
              "/apps/:appId/account/:accountId/:appView",
              "/apps/:appId/account/:accountId",
              "/apps/:appId/:appView",
              "/groups",
              "/groups/:groupId",
              "/groups/:groupId/:groupView",
              "/resources",
              "/resources/:resourceId",
              "/resources/:resourceId/:resourceView",
            ]}
            component={AppDetailColumnForItems}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/apps/:appId/create-role"
            component={AWSCreateRoleColumn}
          />
          <Route
            exact
            path="/apps/:appId/create-resource"
            component={CreateResourceColumn}
          />
          <Route
            exact
            path="/apps/:appId/create-group"
            component={CreateGroupColumn}
          />
          {!hasV3 && (
            <Route
              exact
              path={[
                "/apps/okta/:oktaAppId/:resourceView",
                "/apps/:appId/account/:accountId/:resourceView",
                "/resources/:resourceId",
                "/resources/:resourceId/:resourceView",
              ]}
              component={ResourceDetailColumn}
            />
          )}
          {!hasV3 && (
            <Route
              exact
              path={["/groups/:groupId/:groupView", "/groups/:groupId"]}
              component={GroupDetailColumn}
            />
          )}
          <Route
            exact
            path={[
              "/apps/okta/:resourceId",
              "/apps/:appId/all-accounts",
              "/apps/:appId/account/:accountId",
            ]}
            component={Empty}
          />
          {(!hasEndUserExp || !hasV3) && (
            <Route path={["/apps/:appId"]} component={AppDetailContent} />
          )}
          <Route path="/bundles" component={Bundles} />
          <Route exact path="/resources">
            <Redirect to="/apps" />
          </Route>
          <Route exact path="/groups">
            <Redirect to="/apps" />
          </Route>
        </Switch>
        <BulkActionColumn />
      </ColumnContainer>
    </>
  );
};

const BrowseServicesView = () => {
  return (
    <ColumnContainer>
      <CreateAppBrowseColumn />
    </ColumnContainer>
  );
};

const CreateConnectionView = () => {
  return (
    <ColumnContainer>
      <Column isContent>
        <CreateConnection />
      </Column>
    </ColumnContainer>
  );
};

export default Items;
