import {
  ActiveRequestConfigurationFragment,
  EntityType,
  GeneralSettingType,
  GroupAccessLevel,
  GroupAccessLevelFragment,
  GroupDropdownPreviewFragment,
  GroupFragment,
  GroupUserSource,
  IntegrationType,
  Maybe,
  OidcProviderType,
  RequestCustomMetadataInput,
  RequestedGroupInput,
  RequestedResourceInput,
  RequestFragment,
  RequestStatus,
  RequestTemplateCustomFieldType,
  ResourceAccessLevel,
  ResourceAccessLevelFragment,
  ResourceGroupFragment,
  ResourcePreviewLargeFragment,
  SupportTicketPreviewFragment,
  ThirdPartyProvider,
  useCreateRequestMutation,
  useGroupAccessLevelsQuery,
  useGroupActiveRequestConfigurationQuery,
  useInitOidcAuthFlowMutation,
  UserDropdownPreviewFragment,
  useResourceAccessLevelsQuery,
  useResourceActiveRequestConfigurationQuery,
  useThirdPartyIntegrationsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { opalConfetti } from "components/confetti/Confetti";
import { formatDuration } from "components/label/Label";
import { isDurationValueCustom } from "components/modals/EditDurationModal";
import { IdpMfaModal } from "components/modals/IdpMfaModal";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import RequestModalRoleDropdown from "components/modals/RequestModalRoleDropdown";
import RequestCustomFieldRow from "components/requests/RequestCustomFieldRow";
import RequestDurationPicker from "components/requests/RequestDurationPicker";
import TicketProviderDropdown from "components/tickets/TicketProviderDropdown";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Button,
  FormGroup,
  Input,
  Link,
  Modal,
  Switch,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { generateState } from "utils/auth/auth";
import {
  getResourceUrlNew,
  getSupportTicketThirdPartyProviders,
} from "utils/common";
import { entityHasOnlyOneRole } from "utils/directory/resources";
import {
  connectionRequiresThirdPartyProvider,
  thirdPartyProviderByConnectionType,
  thirdPartyProviderNameByThirdPartyProvider,
} from "utils/directory/third_party_providers";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import { MfaCustomParams } from "utils/mfa/mfa";
import { setOidcData } from "utils/oidc/oidc";
import { calculateMaxDurationInMinutes } from "views/Common";
import RequestForUserDropdown from "views/requests/RequestForUserDropdown";
import RequestModalMfaFiller from "views/requests/RequestModalMfaFiller";
import RequestTemplateParser, {
  REQUEST_TEMPLATE_ID_PARAM_KEY,
} from "views/requests/RequestTemplateParser";
import {
  expirationValueToDurationInMinutes,
  getCurrentUserGroupAccessSummary,
  getCurrentUserResourceAccessSummary,
  getDefaultRequestDurationMinutes,
  getDefaultRequestExpiration,
  getExtraParamsByConnectionMetadata,
  minutesToExpirationValue,
} from "views/requests/utils";
import OrgContext from "views/settings/OrgContext";
import { SupportTicketsField } from "views/support_tickets/SupportTickets";

import RequestForUserOrGroupDropdown from "../../views/requests/RequestForUserOrGroupDropdown";

type RequestModalProps = {
  isModalOpen: boolean;
  onClose: () => void;

  entity: ResourcePreviewLargeFragment | GroupFragment;
  entityType: EntityType;
  containingResourceGroups?: ResourceGroupFragment[];
  isImpersonationResource: boolean;
  isGlobalImpersonationResource: boolean;

  // After corinthian GA, we can remove this prop
  contentOnly?: boolean;
};

export const RequestModal = (props: RequestModalProps) => {
  const { id: entityID, name, serviceType, remoteId } = props.entity;
  const connection = props.entity.connection;
  const connectionType = props.entity.connection?.connectionType;
  const connectionMetadata = props.entity.connection?.metadata;

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const authState = useContext(AuthContext).authState;
  const { orgState } = useContext(OrgContext);
  const hasGroupProjects = useFeatureFlag(FeatureFlag.GroupProjects);

  const [initOidcAuthFlow] = useInitOidcAuthFlowMutation();

  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  // General hooks
  const history = useHistory();
  const location = useLocation();
  const { displaySuccessToast, displayErrorToast } = useToast();
  const logEvent = useLogEvent();

  useMountEffect(() => {
    logEvent({
      name: "request_modal_view",
      properties: {
        entityID,
        entityType: props.entityType,
        entityName: name,
      },
    });
  });

  const {
    data: thirdPartyUserIntegrationsData,
    error: thirdPartyUserIntegrationsError,
  } = useThirdPartyIntegrationsQuery({
    variables: {
      input: {
        integrationType: IntegrationType.User,
      },
    },
  });

  if (thirdPartyUserIntegrationsError) {
    logError(
      thirdPartyUserIntegrationsError,
      `failed to list third party integrations`
    );
  }

  const [targetUserOrGroup, setTargetUserOrGroup] = useState<
    UserDropdownPreviewFragment | GroupDropdownPreviewFragment | undefined
  >(authState.user?.user ? authState.user.user : undefined);
  const [selectedRole, setSelectedRole] = useState<
    ResourceAccessLevel | GroupAccessLevel
  >();
  const [customMetadata, setCustomMetadata] = useState<
    Record<string, RequestCustomMetadataInput>
  >({});

  const {
    data: resourceAccessLevelsData,
    error: resourceAccessLevelsError,
  } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: entityID,
        onlyRequestableTargetUser: targetUserOrGroup?.id,
      },
    },
    skip: props.entityType !== EntityType.Resource,
  });

  if (resourceAccessLevelsError) {
    logError(resourceAccessLevelsError, `failed to get resource access levels`);
  }

  let availableRoles:
    | ResourceAccessLevelFragment[]
    | GroupAccessLevelFragment[] = [];
  if (
    resourceAccessLevelsData?.accessLevels.__typename ===
    "ResourceAccessLevelsResult"
  ) {
    availableRoles = resourceAccessLevelsData.accessLevels.accessLevels ?? [];
  }

  const {
    data: groupAccessLevelsData,
    error: groupAccessLevelsError,
  } = useGroupAccessLevelsQuery({
    variables: {
      input: {
        groupId: entityID,
        onlyRequestableTargetUser: targetUserOrGroup?.id,
      },
    },
    skip: props.entityType !== EntityType.Group,
  });

  if (groupAccessLevelsError) {
    logError(groupAccessLevelsError, `failed to get group access levels`);
  }

  if (
    groupAccessLevelsData?.groupAccessLevels.__typename ===
    "GroupAccessLevelsResult"
  ) {
    availableRoles = groupAccessLevelsData.groupAccessLevels.accessLevels ?? [];
  }

  const {
    data: resourceRequestConfigData,
    error: resourceRequestConfigError,
    loading: resourceRequestConfigLoading,
  } = useResourceActiveRequestConfigurationQuery({
    variables: {
      resourceId: entityID,
      accessLevelRemoteId: selectedRole?.accessLevelRemoteId,
      targetUserId: targetUserOrGroup?.id,
    },
    fetchPolicy: "network-only",
    skip: props.entityType !== EntityType.Resource,
  });

  if (resourceRequestConfigError) {
    logError(resourceRequestConfigError, `failed to get request config`);
  }

  const {
    data: groupRequestConfigData,
    error: groupRequestConfigError,
    loading: groupRequestConfigLoading,
  } = useGroupActiveRequestConfigurationQuery({
    variables: {
      groupId: entityID,
      accessLevelRemoteId: selectedRole?.accessLevelRemoteId,
      targetUserId: targetUserOrGroup?.id,
    },
    fetchPolicy: "network-only",
    skip: props.entityType !== EntityType.Group,
  });

  if (groupRequestConfigError) {
    logError(groupRequestConfigError, `failed to get request config`);
  }

  let activeRequestConfiguration:
    | ActiveRequestConfigurationFragment
    | undefined;
  if (
    props.entityType === EntityType.Resource &&
    resourceRequestConfigData?.activeResourceRequestConfigurations[0]
      ?.requestConfiguration.__typename === "RequestConfiguration"
  ) {
    activeRequestConfiguration =
      resourceRequestConfigData?.activeResourceRequestConfigurations[0]
        ?.requestConfiguration ?? undefined;
  }
  if (
    props.entityType === EntityType.Group &&
    groupRequestConfigData?.activeGroupRequestConfigurations[0]
      ?.requestConfiguration.__typename === "RequestConfiguration"
  ) {
    activeRequestConfiguration =
      groupRequestConfigData?.activeGroupRequestConfigurations[0]
        ?.requestConfiguration ?? undefined;
  }
  const requestConfigLoading =
    resourceRequestConfigLoading || groupRequestConfigLoading;
  const customFields =
    activeRequestConfiguration?.requestTemplate?.customFields ?? [];

  const integrations = orgState.orgThirdPartyIntegrations;

  const userIntegrations =
    thirdPartyUserIntegrationsData?.thirdPartyIntegrations
      .thirdPartyIntegrations || [];

  let isThirdPartyProviderNotConnected: boolean = false;
  let thirdPartyProvider: ThirdPartyProvider | undefined;
  let thirdPartyProviderName: string | undefined = "";
  let extraParams: string = "";
  if (connection && connectionRequiresThirdPartyProvider(connection)) {
    thirdPartyProvider = thirdPartyProviderByConnectionType[
      connection.connectionType
    ]!;
    thirdPartyProviderName = thirdPartyProviderNameByThirdPartyProvider[
      thirdPartyProvider
    ]!;
    isThirdPartyProviderNotConnected = !userIntegrations.find(
      (integration) => integration.thirdPartyProvider === thirdPartyProvider
    );
    if (connectionMetadata)
      extraParams = getExtraParamsByConnectionMetadata(connectionMetadata);
  }

  // Get support ticket hooks
  const supportTicketThirdPartyProviders = getSupportTicketThirdPartyProviders(
    integrations
  );

  const [
    supportTicketThirdPartyProvider,
    setSupportTicketThirdPartyProvider,
  ] = useState<ThirdPartyProvider | undefined>(
    supportTicketThirdPartyProviders.length > 0
      ? supportTicketThirdPartyProviders[0]
      : undefined
  );

  const supportTicketBindingAllowed =
    supportTicketThirdPartyProviders &&
    supportTicketThirdPartyProviders.length !== 0;

  const [getTicketsErrorMessage, setGetTicketsErrorMessage] = useState<
    string | undefined
  >(undefined);

  const isSupportTicketRequiredGlobal =
    orgState.orgSettings?.generalSettings.some(
      (setting) => setting === GeneralSettingType.RequireSupportTicket
    ) || false;

  const [showSupportTickets, setShowSupportTickets] = useState(false);

  const handleSelectSupportTicketChange = (provider?: ThirdPartyProvider) => {
    setSupportTicketThirdPartyProvider(provider);
    setGetTicketsErrorMessage("");
    setSupportTicket(undefined);
  };

  const [supportTicket, setSupportTicket] = useState<
    SupportTicketPreviewFragment | undefined
  >();

  let maxDurationInMinutes: number | null | undefined;
  if (activeRequestConfiguration?.maxDurationInMinutes) {
    maxDurationInMinutes = activeRequestConfiguration?.maxDurationInMinutes;
  }
  let orgMaxDuration: number | null | undefined;
  if (props.entityType === EntityType.Resource) {
    orgMaxDuration = orgState.orgSettings?.maxResourceDurationInMinutes;
  } else if (props.entityType === EntityType.Group) {
    orgMaxDuration = orgState.orgSettings?.maxGroupDurationInMinutes;
  }
  maxDurationInMinutes = calculateMaxDurationInMinutes(
    orgMaxDuration,
    maxDurationInMinutes
  );

  const defaultRequestDuration = getDefaultRequestDurationMinutes(
    activeRequestConfiguration?.maxDurationInMinutes ?? undefined,
    activeRequestConfiguration?.recommendedDurationInMinutes ?? undefined
  );

  const defaultRequestExpiration = getDefaultRequestExpiration(
    maxDurationInMinutes ?? undefined,
    activeRequestConfiguration?.recommendedDurationInMinutes ?? undefined
  );

  // Create request hooks
  const [reason, setReason] = useState("");
  const [usingCustomDurationPicker, setUsingCustomDurationPicker] = useState(
    false
  );
  const [isIdpMfaModalOpen, setIsIdpMfaModalOpen] = useState(false);
  const [expiration, setExpiration] = useState(defaultRequestExpiration);
  const [customDuration, setCustomDuration] = useState(30);

  useEffect(() => {
    if (requestConfigLoading) return;
    setExpiration(defaultRequestExpiration);
    if (defaultRequestDuration) {
      const isCustomDuration = isDurationValueCustom(defaultRequestDuration);
      setUsingCustomDurationPicker(isCustomDuration);
      setCustomDuration(isCustomDuration ? defaultRequestDuration : 30);
    }
  }, [defaultRequestExpiration, defaultRequestDuration, requestConfigLoading]);

  const [createRequest, { loading }] = useCreateRequestMutation({
    refetchQueries: ["RequestsPendingStat", "RequestStats"],
  });

  const modalReset = () => {
    const queryParams = new URLSearchParams(location.search);
    // clear out request template ID parameter
    if (queryParams.has(REQUEST_TEMPLATE_ID_PARAM_KEY)) {
      queryParams.delete(REQUEST_TEMPLATE_ID_PARAM_KEY);
      history.replace({
        search: queryParams.toString(),
      });
    }

    props.onClose();
    setErrorMessage(null);
    setSelectedRole(undefined);
  };

  const overriddenShowSupportTickets =
    showSupportTickets ||
    isSupportTicketRequiredGlobal ||
    activeRequestConfiguration?.requireSupportTicket;

  let includeRoles = !entityHasOnlyOneRole(
    serviceType,
    connectionType,
    remoteId ?? ""
  );

  if (
    !props.isGlobalImpersonationResource &&
    availableRoles.length === 1 &&
    availableRoles[0].accessLevelRemoteId === ""
  ) {
    // in the event there is only the default role, do not show the dropdown
    includeRoles = false;
  }

  if (availableRoles.length === 0) {
    includeRoles = false;
  }

  const bannerMessageIntegrationNotConnected = (
    <>
      {`This resource requires you to link your ${thirdPartyProviderName} account to Opal before requesting access. You can link your ${thirdPartyProviderName} account `}
      <Banner.Link
        label={`here`}
        to={{
          hash: hasV3 ? `identities` : undefined,
          search: `showIntegrationModal=${thirdPartyProvider}&${extraParams}`,
          pathname: "/user/settings",
        }}
      />
      {`.`}
    </>
  );

  let currentUserHasAccess = false;
  let currentExpiration: moment.Moment | undefined = undefined;
  let canRequestForTargetUser = true;
  if (
    props.entityType === EntityType.Resource &&
    "resourceType" in props.entity
  ) {
    const result = getCurrentUserResourceAccessSummary(
      props.entity,
      includeRoles,
      (selectedRole as ResourceAccessLevel) ?? undefined
    );
    currentUserHasAccess = result.hasAccess;
    currentExpiration = result.expiration;
  } else if (
    props.entityType === EntityType.Group &&
    "groupType" in props.entity
  ) {
    const result = getCurrentUserGroupAccessSummary(
      props.entity,
      includeRoles,
      (selectedRole as ResourceAccessLevel) ?? undefined
    );
    currentUserHasAccess = result.hasAccess;
    currentExpiration = result.expiration;
    const groupTargetUser = props.entity.groupUsers.find(
      (gu) => gu.userId === targetUserOrGroup?.id
    );
    const groupTargetUserAccessLevels: Record<string, Array<string>> = {};
    props.entity.groupUsers.forEach((gu) => {
      if (groupTargetUserAccessLevels[gu.userId] == null) {
        groupTargetUserAccessLevels[gu.userId] = [];
      }
      const accessLevelRemoteId =
        gu.access?.directAccessPoint?.accessLevel?.accessLevelRemoteId;
      if (accessLevelRemoteId != null) {
        groupTargetUserAccessLevels[gu.userId].push(accessLevelRemoteId);
      }
    });
    // If the target user has nested access, then a request cannot be made for them
    // because access to the group is inherited from a parent group
    canRequestForTargetUser =
      groupTargetUser == null ||
      groupTargetUser.source !== GroupUserSource.RegularNested ||
      (groupTargetUser.source === GroupUserSource.RegularNested &&
        includeRoles &&
        !groupTargetUserAccessLevels[groupTargetUser.userId]?.includes(
          selectedRole?.accessLevelRemoteId ?? ""
        ));
  }

  const hasAccess =
    currentUserHasAccess && targetUserOrGroup?.id === authState.user?.user.id;

  const durationInMinutes = (usingCustomDurationPicker
    ? moment.duration(customDuration, "m")
    : expirationValueToDurationInMinutes(expiration)
  )?.asMinutes();

  const validateForm = () => {
    if (!props.entity.reasonOptional && reason === "") {
      return false;
    }

    if (includeRoles && !selectedRole) {
      return false;
    }

    if (!canRequestForTargetUser) {
      return false;
    }

    if (
      (isSupportTicketRequiredGlobal ||
        activeRequestConfiguration?.requireSupportTicket) &&
      !supportTicket
    ) {
      return false;
    }

    if (isThirdPartyProviderNotConnected) {
      return false;
    }

    for (let field of customFields) {
      if (field.required) {
        const metadata = customMetadata[field.name];
        if (metadata == null || metadata.metadataValue == null) {
          return false;
        }
        switch (field.type) {
          case RequestTemplateCustomFieldType.ShortText:
            if (!metadata.metadataValue.shortTextValue?.value) {
              return false;
            }
            break;
          case RequestTemplateCustomFieldType.LongText:
            if (!metadata.metadataValue.longTextValue?.value) {
              return false;
            }
            break;
          case RequestTemplateCustomFieldType.Boolean:
            if (!metadata.metadataValue.booleanValue?.value) {
              return false;
            }
            break;
          case RequestTemplateCustomFieldType.MultiChoice:
            if (!metadata.metadataValue.multiChoiceValue?.value) {
              return false;
            }
        }
      }
    }
    return true;
  };

  const handleClickRequest = async () => {
    logEvent({
      name: "request_modal_click_submit",
      properties: {
        entityID,
        entityType: props.entityType,
        entityName: name,
        reason,
        expiration,
        ticketBinded: Boolean(supportTicket),
      },
    });
    try {
      if (!targetUserOrGroup) {
        return;
      }

      if (durationInMinutes !== undefined && durationInMinutes <= 0) {
        setErrorMessage("Error: duration too short");
        return;
      }

      const requestedResources: RequestedResourceInput[] = [];
      const requestedGroups: RequestedGroupInput[] = [];

      if (props.entityType === EntityType.Resource) {
        requestedResources.push({
          resourceId: entityID,
          accessLevel: {
            accessLevelName: selectedRole?.accessLevelName || "",
            accessLevelRemoteId: selectedRole?.accessLevelRemoteId || "",
          },
        });
      } else {
        const requestedGroup: RequestedGroupInput = { groupId: entityID };
        if (availableRoles.length > 0) {
          requestedGroup.accessLevel = {
            accessLevelName: selectedRole?.accessLevelName || "",
            accessLevelRemoteId: selectedRole?.accessLevelRemoteId || "",
          };
        }
        requestedGroups.push(requestedGroup);
      }

      let customMetadataInput: RequestCustomMetadataInput[] = [];
      customFields.forEach((field) => {
        if (customMetadata[field.name]) {
          customMetadataInput.push(customMetadata[field.name]);
        } else {
          customMetadataInput.push({
            fieldName: field.name,
            fieldType: field.type,
            metadataValue: {},
          });
        }
      });

      const { data } = await createRequest({
        variables: {
          input: {
            targetUserId:
              targetUserOrGroup.__typename === "User"
                ? targetUserOrGroup.id
                : null,
            targetGroupId:
              targetUserOrGroup.__typename === "Group"
                ? targetUserOrGroup.id
                : null,
            requestedResources: requestedResources,
            requestedGroups: requestedGroups,
            reason: reason,
            durationInMinutes: durationInMinutes,
            supportTicket: supportTicket
              ? {
                  remoteId: supportTicket.remoteId,
                  url: supportTicket.url,
                  identifier: supportTicket.identifier,
                  thirdPartyProvider: supportTicket.thirdPartyProvider,
                }
              : null,
            customMetadata: customMetadataInput,
          },
        },
        refetchQueries: ["Requests"],
      });
      switch (data?.createRequest.__typename) {
        case "CreateRequestResult": {
          history.push(
            `${getResourceUrlNew({
              entityId: data.createRequest.request.id,
              entityType: EntityType.Request,
            })}?o=1`
          );
          modalReset();
          if (data.createRequest.request.status === RequestStatus.Approved) {
            displaySuccessToast("Success: request auto-approved");
            opalConfetti();
          } else {
            displaySuccessToast(
              <>
                <span>Success: request created. </span>
                <Link
                  url={getResourceUrlNew({
                    entityId: data.createRequest.request.id,
                    entityType: EntityType.Request,
                  })}
                >
                  View
                </Link>
              </>
            );
          }

          break;
        }
        case "RequestRequiresUserAuthTokenForConnectionError":
          if (data.createRequest.user && data.createRequest.connection) {
            setErrorMessage(
              `Error: user "${data.createRequest.user.fullName}" does not exist on the end system for the app ` +
                `"${data.createRequest.connection.name}". Please contact your Opal administrator to add your user to the end system.`
            );
          } else {
            setErrorMessage(`${data.createRequest.message}`);
          }
          break;
        case "ItemCannotBeRequestedError":
        case "RequestDurationTooLargeError":
        case "NoManagerSetForRequestingUserError":
          setErrorMessage(data.createRequest.message);
          break;
        case "NoReviewersSetForOwnerError":
        case "NoReviewersSetForResourceError":
        case "NoReviewersSetForGroupError":
          setErrorMessage("This request has no approvers set.");
          break;
        case "MfaInvalidError": {
          const useOktaMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOktaMfaForGatingOpalActions
          );
          const useOidcMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOidcMfaForGatingOpalActions
          );

          let redirectUrl = history.location.pathname;
          // Hack to redirect to the groups/resources page after the auth redirect
          if (history.location.pathname === "/browse") {
            if (props.entityType === EntityType.Resource) {
              redirectUrl = `/resources/${props.entity.id}`;
            } else {
              redirectUrl = `/groups/${props.entity.id}`;
            }
          }
          let mfaState = {
            requestReason: reason,
            requestDurationInMinutes: durationInMinutes,
            requestAccessLevel: selectedRole,
            requestTarget: targetUserOrGroup,
            requestSupportTicket: supportTicket,
            requestCustomFields: customMetadata,
          };
          if (useOidcMfa) {
            try {
              const state = generateState();
              const { data } = await initOidcAuthFlow({
                variables: {
                  input: {
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                  },
                },
              });
              switch (data?.initOidcAuthFlow.__typename) {
                case "InitOidcAuthFlowResult":
                  setOidcData({
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                    postAuthPath: redirectUrl,
                    mfaParams: mfaState,
                  });
                  window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                  break;
                case "OidcProviderNotFoundError":
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please contact your admin."
                  );
                  break;
                default:
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                  );
              }
            } catch (e) {
              logError(e, "Error: could not complete MFA");
            }
          } else if (useOktaMfa) {
            setIsIdpMfaModalOpen(true);
          } else {
            authState.authClient?.mfaFlow(redirectUrl, mfaState);
          }
          break;
        }
        case "GroupNestingNotAllowedError": {
          const fromGroupName =
            data.createRequest.fromGroup?.name ?? "Unknown group";
          const toGroupName =
            data.createRequest.toGroup?.name ?? "Unknown group";
          displayErrorToast(
            `Error: Group nesting not allowed. Cannot approve request from ${fromGroupName} to ${toGroupName},` +
              ` as it would create a circular dependency between groups. Contact your admin for assistance.`
          );
          break;
        }
        case "UserCannotRequestAccessForTargetGroupError":
          setErrorMessage(
            "Error: You do not have permission to request access on behalf of this group."
          );
          break;
        case "TargetUserHasNestedAccessError":
          setErrorMessage(`Error: target user already has nested access.`);
          break;
        default:
          logError(new Error(`failed to create request`));
          setErrorMessage("Error: failed to create request");
      }
    } catch (error) {
      logError(error, `failed to create request`);
      setErrorMessage("Error: failed to create request");
    }
  };

  // This is a hack to trigger the request creation on page load
  // when coming back from MFA flow. Before this, we had the `handleClickRequest`
  // written directly into `RequestModalMfaFiller` below, but that caused
  // data to be lost because the various state sets happen asynchronously,
  // and the mutation was called before the state was set.
  const [triggerClickRequest, setTriggerClickRequest] = useState(false);
  useEffect(() => {
    if (triggerClickRequest) {
      handleClickRequest();
    }
    // we don't want this to run every time handleClickRequest is updated -- only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerClickRequest]);

  const shouldShowServiceNow = useFeatureFlag(
    FeatureFlag.ServiceNowAccessAndPropagationTickets
  );

  const content = (
    <>
      {isThirdPartyProviderNotConnected && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Banner
            message={bannerMessageIntegrationNotConnected}
            type="warning"
          />
        </div>
      )}
      {hasAccess ? (
        <div
          className={sprinkles({
            marginBottom: "lg",
          })}
        >
          <Banner
            message={
              currentExpiration == null
                ? "You have indefinite access."
                : `Your current access will expire ${currentExpiration.fromNow()}`
            }
            type="success"
          />
        </div>
      ) : null}
      {!canRequestForTargetUser && (
        <div
          className={sprinkles({ marginBottom: "md" })}
          style={{ maxWidth: "400px" }}
        >
          <Banner
            message="Cannot request access for the target user because they already have nested access."
            type="warning"
          />
        </div>
      )}
      {targetUserOrGroup ? (
        hasGroupProjects ? (
          <FormGroup label="Request for" required>
            <RequestForUserOrGroupDropdown
              entities={[props.entity]}
              targetUserOrGroup={targetUserOrGroup}
              onChange={(newTarget) => setTargetUserOrGroup(newTarget)}
            />
          </FormGroup>
        ) : (
          <FormGroup label="Request for" required>
            <RequestForUserDropdown
              entities={[props.entity]}
              targetUser={targetUserOrGroup as UserDropdownPreviewFragment}
              onChange={(newTarget) => setTargetUserOrGroup(newTarget)}
            />
          </FormGroup>
        )
      ) : null}
      {includeRoles && !props.isGlobalImpersonationResource && (
        <FormGroup label="Role" required>
          <RequestModalRoleDropdown
            availableRoles={availableRoles}
            selectedRole={selectedRole}
            setSelectedRole={(role) => {
              setSelectedRole(role);
            }}
            isImpersonationResource={props.isImpersonationResource}
          />
        </FormGroup>
      )}
      {!props.entity.reasonOptional ? (
        <FormGroup label="Reason" required>
          <Input
            type="textarea"
            rows={4}
            placeholder={"I need access to this because..."}
            value={reason}
            onChange={setReason}
            data-test-id="Request Reason"
          />
        </FormGroup>
      ) : null}
      {customFields?.map((field) => (
        <RequestCustomFieldRow
          customField={field}
          metadataValue={customMetadata[field.name]?.metadataValue}
          onChange={(val) =>
            setCustomMetadata((prev) => ({
              ...prev,
              [field.name]: {
                fieldName: field.name,
                fieldType: field.type,
                metadataValue: val,
              },
            }))
          }
        />
      ))}

      <RequestDurationPicker
        expiration={expiration}
        setExpiration={setExpiration}
        customDuration={customDuration}
        setCustomDuration={setCustomDuration}
        usingCustomDurationPicker={usingCustomDurationPicker}
        setUsingCustomDurationPicker={setUsingCustomDurationPicker}
        hasAccess={hasAccess}
        maxDurationInMinutes={maxDurationInMinutes}
        recommendedDurationInMinutes={
          activeRequestConfiguration?.recommendedDurationInMinutes ?? undefined
        }
        maxValueReason={
          // Define warning messages for exceeding organization and resource maximum values
          `The requested duration exceeds the allowed limit of ${formatDuration(
            moment.duration(maxDurationInMinutes, "m")
          )} set by admins`
        }
      />

      {includeRoles && props.isGlobalImpersonationResource && (
        <>
          <FormGroup label="User Name">
            <Input
              placeholder="Enter the Name of the user to impersonate"
              value={selectedRole?.accessLevelName}
              onChange={(value) => {
                if (!selectedRole) {
                  setSelectedRole({
                    accessLevelName: value,
                    accessLevelRemoteId: "",
                  });
                } else {
                  setSelectedRole({
                    ...selectedRole,
                    accessLevelName: value,
                  });
                }
              }}
            />
          </FormGroup>
          <FormGroup label="User ID">
            <Input
              placeholder="Enter ID of user to impersonate"
              value={selectedRole?.accessLevelRemoteId}
              onChange={(value) => {
                if (!selectedRole) {
                  setSelectedRole({
                    accessLevelRemoteId: value?.trim(),
                    accessLevelName: "",
                  });
                } else {
                  setSelectedRole({
                    ...selectedRole,
                    accessLevelRemoteId: value?.trim(),
                  });
                }
              }}
            />
          </FormGroup>
        </>
      )}
      <div className={sprinkles({ marginBottom: "md" })}>
        <Switch
          checked={overriddenShowSupportTickets ?? false}
          onChange={() => {
            setGetTicketsErrorMessage("");
            setShowSupportTickets(!showSupportTickets);
          }}
          disabled={
            !supportTicketBindingAllowed ||
            isSupportTicketRequiredGlobal ||
            activeRequestConfiguration?.requireSupportTicket
          }
          label="Expire access when ticket is closed"
          infoTooltip={
            isSupportTicketRequiredGlobal ||
            activeRequestConfiguration?.requireSupportTicket
              ? isSupportTicketRequiredGlobal
                ? "Your organization requires all requests to be bound to an access ticket."
                : "Your organization requires this request to be bound to an access ticket."
              : "Enable this option to expire access when a linked ticket is closed."
          }
        />
      </div>
      {supportTicketBindingAllowed && overriddenShowSupportTickets && (
        <FormGroup label="Ticket Providers">
          <TicketProviderDropdown
            selectedTicketProvider={supportTicketThirdPartyProvider}
            onSelectTicketProvider={handleSelectSupportTicketChange}
            hiddenProviders={
              shouldShowServiceNow ? [] : [ThirdPartyProvider.ServiceNow]
            }
          />
        </FormGroup>
      )}
      {supportTicketBindingAllowed &&
        overriddenShowSupportTickets &&
        supportTicketThirdPartyProvider && (
          <SupportTicketsField
            thirdPartyProvider={supportTicketThirdPartyProvider}
            setGetTicketsErrorMessage={setGetTicketsErrorMessage}
            supportTicket={supportTicket}
            setSupportTicket={setSupportTicket}
          />
        )}
      {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      {getTicketsErrorMessage && (
        <ModalErrorMessage errorMessage={getTicketsErrorMessage} />
      )}
      <RequestTemplateParser
        callback={(request: RequestFragment) => {
          setReason(request.reason);
          if (request.requestedResources.length > 0) {
            setSelectedRole(request.requestedResources[0].accessLevel);
          } else if (
            request.requestedGroups.length > 0 &&
            request.requestedGroups[0].accessLevel
          ) {
            setSelectedRole(request.requestedGroups[0].accessLevel);
          }
          if (request.durationInMinutes) {
            setCustomDuration(request.durationInMinutes);
            setUsingCustomDurationPicker(
              isDurationValueCustom(request.durationInMinutes)
            );
          }
          if (request.supportTicket) {
            setSupportTicket(request.supportTicket);
            setSupportTicketThirdPartyProvider(
              request.supportTicket.thirdPartyProvider
            );
            setShowSupportTickets(true);
          }
        }}
      />
      <RequestModalMfaFiller
        callback={(requestModalParams: MfaCustomParams) => {
          setReason(requestModalParams.requestReason || "");
          if (requestModalParams.requestTarget) {
            setTargetUserOrGroup(requestModalParams.requestTarget);
          }
          if (requestModalParams.requestAccessLevel) {
            setSelectedRole(requestModalParams.requestAccessLevel);
          }
          const maybeRequestDurationInMinutes =
            requestModalParams.requestDurationInMinutes ?? null;
          setExpiration(
            minutesToExpirationValue(maybeRequestDurationInMinutes)
          );
          setUsingCustomDurationPicker(
            isDurationValueCustom(maybeRequestDurationInMinutes)
          );
          if (
            usingCustomDurationPicker &&
            requestModalParams.requestDurationInMinutes
          ) {
            setCustomDuration(requestModalParams.requestDurationInMinutes);
          }
          if (requestModalParams.requestSupportTicket) {
            setSupportTicket(requestModalParams.requestSupportTicket);
            setSupportTicketThirdPartyProvider(
              requestModalParams.requestSupportTicket.thirdPartyProvider
            );
            setShowSupportTickets(true);
          }
          if (requestModalParams.requestCustomFields) {
            setCustomMetadata(requestModalParams.requestCustomFields);
          }
          setTriggerClickRequest(true);
        }}
      />
    </>
  );

  if (props.contentOnly) {
    return (
      <>
        {content}
        {isIdpMfaModalOpen && (
          <IdpMfaModal
            onClose={() => setIsIdpMfaModalOpen(false)}
            onMfaSuccess={() => handleClickRequest()}
          />
        )}
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "flex-end",
          })}
        >
          <Button
            type="primary"
            label="Request"
            onClick={handleClickRequest}
            loading={loading}
            disabled={!validateForm()}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Modal
        isOpen={props.isModalOpen}
        onClose={() => {
          modalReset();
        }}
        title={`Request access to ${name}`}
      >
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Request"
          onPrimaryButtonClick={handleClickRequest}
          primaryButtonLoading={loading}
          primaryButtonDisabled={!validateForm()}
        />
      </Modal>
      {isIdpMfaModalOpen && (
        <IdpMfaModal
          onClose={() => setIsIdpMfaModalOpen(false)}
          onMfaSuccess={() => handleClickRequest()}
        />
      )}
    </>
  );
};

export default RequestModal;
