import { Badge, Icon, Loader, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import * as React from "react";

import * as styles from "./ButtonV3.css";

export interface Props {
  type?:
    | "default"
    | "warning"
    | "danger"
    | "main"
    | "mainSecondary"
    | "defaultSecondary"
    | "link"
    | "mainBorderless"
    | "defaultBorderless"
    | "dangerBorderless"
    | "success"
    | "successSecondary";
  size?: "xs" | "sm" | "md" | "lg";
  label?: string;
  sublabel?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  disabledTooltip?: string;
  loading?: boolean;
  leftIconName?: PropsFor<typeof Icon>["name"];
  rightIconName?: PropsFor<typeof Icon>["name"];
  badge?: PropsFor<typeof Badge>;
  flatEdge?: "left" | "right";
  submit?: boolean;
  outline?: boolean;
  fullWidth?: boolean;

  /* In order for the button to be a round, icon-only button:
     leftIconName must be set, label is not set, and round = true */
  round?: boolean;
}

const Button: React.FC<Props> = (props) => {
  const { type = "default" } = props;

  const isRound = Boolean(props.leftIconName && !props.label && props.round);
  const iconSize = props.size === "lg" ? "sm" : "xs";
  const button = (
    <button
      className={styles.button({
        type,
        outlineType: props.outline ? type : undefined,
        size: props.size || "md",
        disabled: props.disabled,
        isRound,
        flatEdge: props.flatEdge,
        withLabel: !!props.label,
        fullWidth: props.fullWidth,
      })}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.submit ? "submit" : undefined}
      data-testid={props.label}
    >
      {props.leftIconName && (
        <span
          className={styles.icon({
            withLabel: props.label !== undefined,
            side: "left",
            loading: props.loading,
            size: iconSize,
          })}
        >
          <Icon name={props.leftIconName} size={iconSize} />
        </span>
      )}

      <div
        className={styles.text({
          loading: props.loading,
          sublabel: !!props.sublabel,
        })}
      >
        <span className={sprinkles({ whiteSpace: "nowrap" })}>
          {props.label}
        </span>
        <span className={styles.sublabel}>{props.sublabel}</span>
      </div>
      {props.rightIconName && (
        <span
          className={styles.icon({
            withLabel: props.label !== undefined,
            side: "right",
            size: iconSize,
          })}
        >
          <Icon name={props.rightIconName} size={iconSize} />
        </span>
      )}
      {props.badge && (
        <span className={styles.badge}>
          <Badge {...props.badge} />
        </span>
      )}
      {props.loading && (
        <span className={styles.loadingSpinner}>
          <Loader size={props.size === "lg" ? "md" : "xs"} color="inherit" />
        </span>
      )}
    </button>
  );

  return props.disabled && props.disabledTooltip ? (
    <Tooltip tooltipText={props.disabledTooltip}>{button}</Tooltip>
  ) : (
    button
  );
};

export default Button;
