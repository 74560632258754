import {
  EntityType,
  GroupPreviewLargeFragment,
  GroupResourceFragment,
  Maybe,
  ResourceAccessLevelInput,
} from "api/generated/graphql";
import { getAccessPathsSortValue } from "components/label/AccessPointsLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { ResourceLabel } from "components/label/Label";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import { useContext } from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { getResourceSublabel } from "utils/resources";
import { PropagationType } from "utils/useRemediations";
import {
  getGroupResourceAccessPathsInfo,
  GroupResourceAccessPointsLabel,
} from "views/resources/GroupResourceAccessPointsLabel";

import { AppsContext } from "../apps/AppsContext";

interface GroupResourcesTableRow {
  name: string;
  role: string;
  accessPaths: string;
  connection: string;
  status: string;
}

type GroupResourcesTableProps = {
  group: GroupPreviewLargeFragment;
  groupResources: GroupResourceFragment[];
  numRowsPerPage?: number;
  denseFormatting?: boolean;
  showUnmanagedResources: boolean;
};

export const GroupResourcesTable = (props: GroupResourcesTableProps) => {
  const history = useHistory();

  const { toggleUnmanagedItem } = useContext(AppsContext);

  const headers: Header<GroupResourcesTableRow>[] = [
    { id: "name", label: "Name" },
    { id: "role", label: "Role" },
    {
      id: "accessPaths",
      label: "Source of Access",
    },
    { id: "connection", label: "App" },
    { id: "status", label: "Status" },
  ];

  const rows: CellRow<GroupResourcesTableRow>[] = [];
  for (const groupResource of props.groupResources) {
    const name = groupResource.resource?.name || groupResource.resourceId;
    const subText = groupResource.resource
      ? getResourceSublabel(groupResource.resource)
      : "--";
    const connection = groupResource.resource?.connection;
    const connectionId = connection?.id;

    const rowId = formatGroupResourceRowId(
      groupResource.resourceId,
      groupResource.accessLevel
    );

    if (!props.showUnmanagedResources && !groupResource.resource?.isManaged) {
      continue;
    }

    const accessPathsInfo = getGroupResourceAccessPathsInfo({
      group: props.group,
      resource: groupResource.resource,
      access: groupResource.access,
    });
    rows.push({
      id: rowId,
      data: {
        name: {
          value: name,
          element: (
            <ResourceLabel
              text={name}
              subText={subText}
              entityType={EntityTypeDeprecated.Resource}
              bold={true}
              icon={groupResource?.resource?.iconUrl}
              resourceType={groupResource.resource?.resourceType}
              iconLarge={true}
              pointerCursor={true}
              maxChars="auto"
              inactive={!groupResource.resource?.isManaged}
              tooltipText={
                !groupResource.resource?.isManaged
                  ? "This resource is currently not managed in Opal."
                  : null
              }
            />
          ),
          sortValue: groupResource.resource?.name.toLowerCase(),
          clickHandler: () => {
            if (groupResource.resource?.isManaged) {
              history.push(
                getResourceUrlNew({
                  entityId: groupResource?.resource?.id ?? null,
                  entityType: EntityType.Resource,
                })
              );
            } else {
              if (groupResource.resource) {
                toggleUnmanagedItem(groupResource.resource);
              }
            }
          },
        },
        role: {
          value: groupResource.accessLevel?.accessLevelName || "",
          element: (
            <div>{groupResource.accessLevel?.accessLevelName || "--"}</div>
          ),
        },
        accessPaths: {
          value: groupResource.groupId,
          sortValue: getAccessPathsSortValue(
            accessPathsInfo.hasDirectAccess,
            accessPathsInfo.accessPointRows.length
          ),
          element: (
            <GroupResourceAccessPointsLabel
              accessPathsInfo={accessPathsInfo}
              group={props.group}
              resource={groupResource.resource}
              role={groupResource.accessLevel}
              access={groupResource.access}
            />
          ),
        },
        connection: {
          sortValue: connection?.name || "",
          value: connectionId || "",
          element: (
            <ConnectionLabel
              text={connection?.name}
              connectionId={connectionId}
              connectionType={connection?.connectionType}
            />
          ),
        },
        status: {
          value: groupResource.resourceId,
          sortValue: groupResource.propagationStatus?.statusCode,
          element: (
            <PropagationStatusLabelWithModal
              propagationType={PropagationType.GroupResource}
              propagationStatus={groupResource.propagationStatus}
              isAccessReview={false}
              entityInfo={{
                group: props.group,
                resource: groupResource.resource,
                role: groupResource.accessLevel,
              }}
            />
          ),
        },
      },
    });
  }

  return (
    <MaterialTable<GroupResourcesTableRow>
      headers={headers}
      rows={rows}
      numRowsPerPage={props.numRowsPerPage}
      denseFormatting={props.denseFormatting}
      defaultSortOrder={"asc"}
    />
  );
};

const formatGroupResourceRowId = (
  resourceId: string,
  role?: Maybe<ResourceAccessLevelInput>
) => {
  return `${resourceId}|${role?.accessLevelName || ""}|${
    role?.accessLevelRemoteId || ""
  }`;
};

export default GroupResourcesTable;
