import {
  EntityType,
  GroupType,
  Maybe,
  ResourceType,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import AccessPointModal from "components/modals/AccessPointModal";
import { Label, Tooltip } from "components/ui";
import _ from "lodash";
import pluralize from "pluralize";
import React, { ReactElement, useContext, useState } from "react";
import { logError } from "utils/logging";

import { IconData } from "../ui/utils";

export const getAccessPathsSortValue = (
  hasDirectAccess: boolean,
  numAccessPaths: number
) => {
  if (hasDirectAccess) {
    return numAccessPaths;
  } else if (numAccessPaths) {
    // We want to always sort only-indirect access behind direct access,
    // so we add a large number in the case of only-indirect access.
    return 1000000 + numAccessPaths;
  }
  // Always sort no access last. Note that this should never happen, but we
  // give it a sort value just in case.
  return 1000000000;
};

export type EntityForAccessLabel =
  | UserForAccessLabel
  | ResourceForAccessLabel
  | GroupForAccessLabel;

export type UserForAccessLabel = {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
  __typename?: "User";
};

export type ResourceForAccessLabel = {
  id: string;
  name: string;
  resourceType: ResourceType;
  __typename?: "Resource";
};

export type GroupForAccessLabel = {
  id: string;
  name: string;
  __typename?: "Group";
  groupType: GroupType;
};

export const getNameForAccessLabelEntity = (
  entity?: Maybe<EntityForAccessLabel>
) => {
  if (!entity) {
    return "";
  }
  switch (entity.__typename) {
    case "User":
      return entity.fullName;
    case "Resource":
      return entity.name;
    case "Group":
      return entity.name;
    default:
      logError("unknown entity type", entity.__typename);
  }
};

export const getEntityTypeForAccessLabelEntity = (
  entity?: Maybe<EntityForAccessLabel>
) => {
  if (!entity) {
    return null;
  }
  switch (entity.__typename) {
    case "User":
      return EntityType.User;
    case "Resource":
      return EntityType.Resource;
    case "Group":
      return EntityType.Group;
    default:
      logError("unknown entity type", entity.__typename);
  }
};

type AccessPointsLabelProps = {
  subject: Maybe<EntityForAccessLabel> | undefined;
  object: Maybe<EntityForAccessLabel> | undefined;
  roleName?: string;
  hasDirectAccess?: boolean;
  numAccessPoints: number;
  numInheritedAccessPoints: number;
  numGroupAccessPoints: number;
  accessPathContent: ReactElement;
  isNotClickable?: boolean;
  useIndirectType?: boolean;
  icon?: IconData;
  onClick?: () => void;
};

export const AccessPointsLabel = (props: AccessPointsLabelProps) => {
  const { authState } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);

  if (props.numAccessPoints === 0) {
    return <div>{"--"}</div>;
  }

  let entity1NameAndAction = "Entity has";
  if (props.subject) {
    switch (props.subject.__typename) {
      case "User":
        if (authState.user?.user.id === props.subject?.id) {
          entity1NameAndAction = "You have";
        } else {
          entity1NameAndAction = `${props.subject?.firstName} has`;
        }
        break;
      case "Resource":
        entity1NameAndAction = `${props.subject?.name} has`;
        break;
      case "Group":
        entity1NameAndAction = `${props.subject?.name} has`;
        break;
    }
  }

  let entity2Name = "entity";
  if (props.object) {
    switch (props.object.__typename) {
      case "User":
        entity2Name = `${props.object.firstName} `;
        break;
      case "Resource":
        entity2Name = `${props.object.name} `;
        break;
      case "Group":
        entity2Name = `${props.object.name} `;
        break;
    }
  }

  let labelAccessDesc, tooltipAccessDesc: string;
  if (props.hasDirectAccess) {
    tooltipAccessDesc = `direct assignment`;
    if (props.numGroupAccessPoints > 0 && props.numInheritedAccessPoints > 0) {
      labelAccessDesc = `Direct + ${
        props.numInheritedAccessPoints + props.numGroupAccessPoints
      } More`;
      tooltipAccessDesc =
        tooltipAccessDesc +
        ` , ` +
        getInherited(props.useIndirectType!) +
        `${pluralize(
          " assignment",
          props.numInheritedAccessPoints,
          true
        )} and ${pluralize("group", props.numGroupAccessPoints, true)}`;
    } else if (props.numGroupAccessPoints > 0) {
      labelAccessDesc = `Direct + ${pluralize(
        "Group",
        props.numGroupAccessPoints,
        true
      )}`;
      tooltipAccessDesc =
        tooltipAccessDesc +
        ` and ${pluralize("group", props.numGroupAccessPoints, true)}`;
    } else if (props.numInheritedAccessPoints > 0) {
      labelAccessDesc =
        `Direct + ${props.numInheritedAccessPoints} ` +
        _.capitalize(getInherited(props.useIndirectType!));
      tooltipAccessDesc =
        tooltipAccessDesc +
        ` and ` +
        `${pluralize(
          getInherited(props.useIndirectType!) + " assignment",
          props.numInheritedAccessPoints,
          true
        )}`;
    } else {
      labelAccessDesc = `Direct`;
    }
  } else {
    let accessText = [];
    let accessTooltip = [];
    if (props.numInheritedAccessPoints > 0) {
      accessText.push(
        `${props.numInheritedAccessPoints} ` +
          _.capitalize(getInherited(props.useIndirectType!))
      );
      accessTooltip.push(
        pluralize(
          getInherited(props.useIndirectType!) + " assignment",
          props.numInheritedAccessPoints,
          true
        )
      );
    }
    if (props.numGroupAccessPoints > 0) {
      accessText.push(pluralize("Group", props.numGroupAccessPoints, true));
      accessTooltip.push(pluralize("group", props.numGroupAccessPoints, true));
    }
    labelAccessDesc = accessText.join(" + ");
    tooltipAccessDesc = accessTooltip.join(" and ");
  }

  const tooltipText = `${entity1NameAndAction} access to ${entity2Name} via ${tooltipAccessDesc}.`;

  let content = (
    <Label
      label={labelAccessDesc}
      icon={props.icon}
      onClick={() => {
        props.onClick && props.onClick();
        if (
          (props.numInheritedAccessPoints || props.numGroupAccessPoints > 0) &&
          !props.isNotClickable
        ) {
          setShowModal(true);
        }
      }}
    />
  );

  if (!showModal) {
    content = <Tooltip tooltipText={tooltipText}>{content}</Tooltip>;
  }

  return (
    <div>
      {content}
      {showModal && (
        <AccessPointModal
          subject={props.subject}
          object={props.object}
          roleName={props.roleName}
          accessPathContent={props.accessPathContent}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

const getInherited = (useIndirectType: boolean) => {
  if (useIndirectType) {
    return "indirect";
  }
  return "inherited";
};

export default AccessPointsLabel;
