import {
  AccessOption,
  ResourceType,
  useUserMenuQuery,
} from "api/generated/graphql";
import OpalLogo from "assets/logos/opal-security-logo.svg";
import AuthContext from "components/auth/AuthContext";
import { Avatar, Badge, ButtonV3, ContextMenu, Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import AppContext from "views/app/AppContext";
import {
  ACCESS_OPTION_URL_KEY,
  ITEM_TYPE_URL_KEY,
} from "views/apps/AppsContext";

import * as styles from "./LeftSidebarV3.css";
import useLeftSidebarSectionInfos, {
  LeftSidebarOptionInfo,
  LeftSidebarSectionInfo,
} from "./useLeftSidebarRoutesV3";

const LeftSidebar = () => {
  const history = useHistory();
  const leftSidebarSectionInfos = useLeftSidebarSectionInfos();
  const hasEndUserXP = useFeatureFlag(FeatureFlag.EndUserExperience);

  return (
    <div className={styles.container}>
      <nav className={styles.menu}>
        <button
          className={styles.logo}
          onClick={() => {
            history.push("/");
          }}
        >
          <img
            className={sprinkles({ overflow: "auto" })}
            src={OpalLogo}
            alt="Opal logo"
          />
        </button>
        {leftSidebarSectionInfos
          .filter((itemInfo) => {
            if ("options" in itemInfo) {
              return itemInfo.options.some((opt) => !opt.hidden);
            } else {
              return !itemInfo.hidden;
            }
          })
          .map((itemInfo, i) =>
            "options" in itemInfo ? (
              <LeftSidebarNavSection
                key={`${itemInfo.title}-${i}`}
                sectionInfo={itemInfo}
              />
            ) : (
              <LeftSidebarNavLink
                key={`${itemInfo.title}-${i}`}
                optionInfo={itemInfo}
              />
            )
          )}
      </nav>
      {hasEndUserXP && (
        // TODO: Remove feature flag and only use v3 feature flag
        <ButtonV3
          label="Make a request"
          leftIconName="raised-hand"
          type="main"
          onClick={() => history.push("/request-access")}
          fullWidth
        />
      )}
      <Footer />
    </div>
  );
};

const LeftSidebarNavLink = ({
  optionInfo,
}: {
  optionInfo: LeftSidebarOptionInfo;
}) => {
  const logEvent = useLogEvent();
  const location = useLocation();

  if (optionInfo.hidden) {
    return null;
  }

  const active = [
    optionInfo.route,
    ...(optionInfo.extraRoutes ?? []),
  ].some((route) => location.pathname.startsWith(route));
  const badgeCount = optionInfo?.badgeCount;

  return (
    <Link
      key={optionInfo.route}
      className={styles.menuItem({ active })}
      to={optionInfo.route}
      onClick={() =>
        logEvent({
          name: "left_sidebar_tab_click",
          properties: { tabName: optionInfo.title },
        })
      }
      title={optionInfo.title}
    >
      <div className={styles.menuItemLeft}>
        <Icon name={optionInfo.iconName} size="xs" />
        <div className={styles.menuItemTitle}>{optionInfo.title}</div>
      </div>
      {badgeCount && badgeCount > 0 ? (
        <Badge count={optionInfo.badgeCount ?? 0} color="white" size="sm" />
      ) : null}
    </Link>
  );
};

const LeftSidebarNavSection = ({
  sectionInfo,
}: {
  sectionInfo: LeftSidebarSectionInfo;
}) => {
  const logEvent = useLogEvent();
  const location = useLocation();
  const hasActiveOption = sectionInfo.options.some((optionInfo) => {
    let active = location.pathname.startsWith(optionInfo.route);
    optionInfo.extraRoutes?.forEach((route) => {
      if (location.pathname.startsWith(route)) {
        active = true;
      }
    });
    return active;
  });
  const [open, setOpen] = useState(hasActiveOption);

  const renderMenuItem = (optionInfo: LeftSidebarOptionInfo) => {
    if (optionInfo.hidden) {
      return null;
    }
    let active = location.pathname.startsWith(optionInfo.route);
    optionInfo.extraRoutes?.forEach((route) => {
      if (location.pathname.startsWith(route)) {
        active = true;
      }
    });
    return (
      <Link
        key={optionInfo.route}
        className={styles.menuItem({ active, nested: true })}
        to={optionInfo.route}
        onClick={() =>
          logEvent({
            name: "left_sidebar_tab_click",
            properties: { tabName: optionInfo.title },
          })
        }
        title={optionInfo.title}
      >
        <div className={styles.menuItemTitle}>{optionInfo.title}</div>
      </Link>
    );
  };

  const configActive = sectionInfo.options
    .map((optionInfo) => optionInfo.route)
    .some((route) => location.pathname.startsWith(route));

  return (
    <>
      <Link
        key={sectionInfo.title}
        className={styles.section}
        to={
          open || configActive
            ? location.pathname
            : sectionInfo.options[0].route
        }
        title={sectionInfo.title}
      >
        <div
          className={styles.menuItemLeft}
          onClick={() => {
            setOpen((prev) => !prev);
            logEvent({
              name: "left_sidebar_tab_click",
              properties: { tabName: sectionInfo.title },
            });
          }}
        >
          <Icon name={sectionInfo.iconName} size="xs" />
          <div className={styles.sectionTitle}>{sectionInfo.title}</div>
          <Icon name={open ? "chevron-down" : "chevron-right"} size="xs" />
        </div>
      </Link>
      {open &&
        sectionInfo.options.map((optionInfo) => renderMenuItem(optionInfo))}
    </>
  );
};

const Footer = () => {
  const menuItems = [
    {
      label: "Opal Docs",
      onClick: () => {
        window.open("https://docs.opal.dev/docs");
      },
    },
    {
      label: "Customer Support",
      onClick: () => {
        window.open("https://opaldev.zendesk.com/hc/en-us");
      },
    },
  ];

  return (
    <div>
      <ContextMenu
        block
        options={menuItems}
        renderButton={(onClick) => (
          <button
            className={styles.menuItem({ active: false })}
            onClick={onClick}
          >
            <div className={styles.menuItemTitle}>Help</div>
          </button>
        )}
      />
      <button
        className={styles.menuItem({ active: false })}
        onClick={() => {
          window.open("https://docs.opal.dev/changelog");
        }}
      >
        <div className={styles.menuItemLeft}>
          <div className={styles.menuItemTitle}>What's New</div>
        </div>
      </button>
      <div className={styles.divider} />
      <UserMenu />
    </div>
  );
};

const UserMenu = () => {
  const { authState } = useContext(AuthContext);
  const { appState } = useContext(AppContext);
  const history = useHistory();

  const { data } = useUserMenuQuery();

  const user = authState.user;
  const opalConnectionId =
    data?.connections.connections.length === 1
      ? data?.connections.connections[0].id
      : null;

  const renderUserMenu = (onClick: (event: React.MouseEvent) => void) => {
    return (
      <button className={styles.userMenu} onClick={onClick}>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            alignItems: "center",
          })}
        >
          <Avatar url={user?.user.avatarUrl} size="medium" />
          <div>
            <div className={styles.userName}>{user?.user.fullName}</div>
            <div className={styles.orgName}>{user?.user.organization.name}</div>
          </div>
        </div>
      </button>
    );
  };

  const menuItems: PropsFor<typeof ContextMenu>["options"] = [
    {
      label: "My Opal Roles",
      icon: { type: "name", icon: "role" },
      onClick: () =>
        history.push(
          `/apps/${opalConnectionId}?${ITEM_TYPE_URL_KEY}=${ResourceType.OpalRole}&${ACCESS_OPTION_URL_KEY}=${AccessOption.Mine}`
        ),
    },
    {
      label: "Account Settings",
      icon: { type: "name", icon: "user" },
      onClick: () => history.push("/user/settings"),
    },
    {
      label: "Log Out",
      icon: { type: "name", icon: "log-out" },
      onClick: () => history.push("/sign-out"),
    },
    {
      label: "",
      onClick: () => {},
      renderOption: () => {
        return (
          <div className={styles.version}>
            <a href="https://docs.opal.dev/changelog" target="_blank">
              {appState.version}
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <ContextMenu
      block
      options={menuItems}
      renderButton={renderUserMenu}
      offsetLeft={4}
      padding="lg"
    />
  );
};

export default LeftSidebar;
