import {
  EntityIdTupleFragment,
  EntityType,
  useSearchQuery,
} from "api/generated/graphql";
import { ErrorMessageLightLabel } from "components/label/MessageLabel";
import { KBarResults } from "kbar";
import React from "react";
import { useHistory } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { getResourceUrlNew } from "utils/common";
import useDebounce from "utils/search/useDebounce";
import SearchContext, {
  SearchContextActionType,
} from "views/search/SearchContext";

import ResultItem from "./ResultItem";
import styles from "./SearchResults.module.scss";
import { KBarItem, useSpotlightSearch } from "./utils";

const SEARCH_LIMIT = 5;

const getEntityUrl = (entity: EntityIdTupleFragment) => {
  if (entity.entityType === EntityType.SearchQuery) {
    if (entity.entityId) {
      const encodedSearchQuery = encodeURIComponent(entity.entityId);
      return `/search?q=${encodedSearchQuery}`;
    }
    return "/search";
  }
  return getResourceUrlNew(entity);
};

const SearchResults: React.FC<{}> = () => {
  const { searchQuery } = useSpotlightSearch();
  const debouncedSearchQuery = useDebounce(searchQuery);

  const history = useHistory();
  const logEvent = useLogEvent();

  const { searchDispatch } = React.useContext(SearchContext);

  const { error, data, loading } = useSearchQuery({
    skip: debouncedSearchQuery.length <= 1,
    variables: {
      input: {
        query: debouncedSearchQuery,
      },
    },
  });
  const searchResultEntries = data?.search.entries || [];

  React.useEffect(() => {
    if (!loading && data?.search) {
      logEvent({
        name: "k_bar_search_results_view",
        properties: {
          searchQuery: debouncedSearchQuery,
          numResults: searchResultEntries.length,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, loading, data?.search]);

  if (error) {
    return (
      <div className={styles.error}>
        <ErrorMessageLightLabel errorMessage="Error: search functionality is degraded" />
      </div>
    );
  }

  let items: KBarItem[] = searchResultEntries
    .slice(0, SEARCH_LIMIT)
    .map((entry) => ({
      type: "result",
      command: {
        perform: () => {
          logEvent({
            name: "k_bar_search_result_click",
            properties: {
              searchQuery,
              entityID: entry.objectId.entityId,
              entityName: entry.name,
              entityType: entry.objectId.entityType,
            },
          });
          history.push(getEntityUrl(entry.objectId));
        },
      },
      label: entry.name,
      entity: entry.objectId,
      avatarUrl: entry.avatarUrl || undefined,
      groupType: entry.groupType,
      connection: entry.connection || undefined,
      resourceType: entry.resourceType,
      annotationText: entry.annotationText || undefined,
    }));

  if (searchResultEntries.length > 0) {
    items.push({
      type: "see-all",
      command: {
        perform: () => {
          logEvent({
            name: "k_bar_see_all_results_click",
            properties: {
              searchQuery,
              numResults: searchResultEntries.length,
            },
          });
          const url = getEntityUrl({
            entityId: searchQuery,
            entityType: EntityType.SearchQuery,
          });
          searchDispatch({
            type: SearchContextActionType.SearchQueryChange,
            payload: {
              searchResultEntries,
            },
          });
          history.push(url);
        },
      },
    });
  } else if (!loading && debouncedSearchQuery.length > 1) {
    items.push({
      type: "no-results",
      command: {
        perform: () => {},
      },
    });
  }
  if (searchQuery.length <= 1) {
    items = [];
  }

  const renderResult = (resultData: { item: unknown; active: boolean }) => {
    // KBar typings aren't the best. Through inspection, the item here is KBarItem.
    const kbarResultItem: KBarItem = resultData.item as KBarItem;
    return (
      <ResultItem
        active={resultData.active}
        item={kbarResultItem}
        searchQuery={searchQuery}
      />
    );
  };

  return <KBarResults items={items} onRender={renderResult} />;
};

export default SearchResults;
