import { ConnectionType, GroupType, ResourceType } from "api/generated/graphql";
import { connectionTypeInfoByType } from "components/label/ConnectionTypeLabel";
import { Icon } from "components/ui";
import { brandIconSet } from "components/ui/entityicon/brandIconSet";
import { brandSquareIconSet } from "components/ui/entityicon/brandSquareIconSet";

import * as styles from "./EntityIcon.css";
import {
  getBrandIconNameFromConnectionType,
  getBrandSquareIconNameFromConnectionType,
} from "./utils";

interface EntityIconProps {
  type: ResourceType | GroupType | ConnectionType;
  /** default = md */
  size?: PropsFor<typeof Icon>["size"];
  separate?: boolean;
  iconStyle?: "default" | "rounded";
  includeBrand?: boolean;
  useDefaultColor?: boolean;
  // includeBrand must be true (the default) in order for only the brand icon to render
  onlyBrandIcon?: boolean;
}

export const entityIconMap = {
  account: "account",
  resource: "cube",
  role: "role",
  database: "database-2",
  dataflow: "dataflow",
  compute: "server-3",
  "gcp-organization": "buildings",
  "gcp-project": "gcp-project",
  "gitlab-project": "gitlab-project",
  "kubernetes-cluster": "kubernetes",
  "permission-set": "list",
  "azure-management-group": "management-group",
  "azure-resource-group": "resource-group",
  "azure-subscription": "key",
  profile: "user-square",
  repo: "git-merge",
  folder: "folder",
  bucket: "bucket",
  group: "users",
  table: "table",
  groupOnCall: "user-clock",
  serviceAccount: "service-account",
};

const EntityIcon: React.FC<EntityIconProps> = (props) => {
  const {
    includeBrand = true,
    useDefaultColor = true,
    onlyBrandIcon = false,
  } = props;

  if (Object.values(ConnectionType).includes(props.type as ConnectionType)) {
    const brand = getBrandIconNameFromConnectionType(
      props.type as ConnectionType
    );
    const BrandIconContent = brandIconSet[brand];

    if (props.iconStyle === "rounded") {
      return (
        <BrandIconContent
          className={styles.brandIconFull({
            size: props.size ?? "md",
          })}
        />
      );
    }
    let fullBrand = getBrandSquareIconNameFromConnectionType(
      props.type as ConnectionType
    );

    // If we haven't listed an icon above, attempt to fall-back to the old version.
    // XXX: remove after all "missings" fixed in getBrandIconNameFromConnectionType
    if (fullBrand === undefined) {
      const connectionTypeInfo =
        connectionTypeInfoByType[props.type as ConnectionType];
      if (connectionTypeInfo) {
        return <Icon externalIconUrl={connectionTypeInfo.icon} />;
      }
      return null;
    }

    const BrandsFullContent = brandSquareIconSet[fullBrand];
    return (
      <BrandsFullContent
        className={styles.brandIconFull({
          size: props.size ?? "md",
        })}
      />
    );
  }

  let entityType: keyof typeof entityIconMap = "resource";
  let brand: keyof typeof brandIconSet = "brand-selfhosted";

  switch (props.type) {
    case ResourceType.AwsAccount:
      entityType = "account";
      brand = "brand-aws";
      break;
    case ResourceType.AwsEc2Instance:
      entityType = "compute";
      brand = "brand-aws";
      break;
    case ResourceType.AwsEksCluster:
      entityType = "kubernetes-cluster";
      brand = "brand-aws";
      break;
    case ResourceType.AwsIamRole:
      entityType = "role";
      brand = "brand-aws";
      break;
    case ResourceType.AwsRdsMysqlInstance:
      entityType = "database";
      brand = "brand-aws";
      break;
    case ResourceType.AwsRdsMysqlCluster:
      entityType = "database";
      brand = "brand-aws";
      break;
    case ResourceType.AwsRdsPostgresInstance:
      entityType = "database";
      brand = "brand-aws";
      break;
    case ResourceType.AwsRdsPostgresCluster:
      entityType = "database";
      brand = "brand-aws";
      break;
    case ResourceType.AwsSsoPermissionSet:
      entityType = "role";
      brand = "brand-aws";
      break;
    case ResourceType.AzureManagementGroup:
      entityType = "azure-management-group";
      brand = "brand-azure";
      break;
    case ResourceType.AzureSubscription:
      entityType = "azure-subscription";
      brand = "brand-azure";
      break;
    case ResourceType.AzureResourceGroup:
      entityType = "azure-resource-group";
      brand = "brand-azure";
      break;
    case ResourceType.AzureVirtualMachine:
      entityType = "compute";
      brand = "brand-azure";
      break;
    case ResourceType.Custom:
      entityType = "resource";
      brand = "brand-customapp";
      break;
    case ResourceType.CustomConnector:
      entityType = "resource";
      brand = "brand-customconnection";
      break;
    case ResourceType.GcpOrganization:
      entityType = "gcp-organization";
      brand = "brand-gcp";
      break;
    case ResourceType.GcpBucket:
      entityType = "bucket";
      brand = "brand-gcp";
      break;
    case ResourceType.GcpBigQueryDataset:
      entityType = "database";
      brand = "brand-gcp";
      break;
    case ResourceType.GcpBigQueryTable:
      entityType = "table";
      brand = "brand-gcp";
      break;
    case ResourceType.GcpServiceAccount:
      entityType = "serviceAccount";
      brand = "brand-gcp";
      break;
    case ResourceType.GcpCloudSqlMysqlInstance:
      entityType = "database";
      brand = "brand-gcp";
      break;
    case ResourceType.GcpCloudSqlPostgresInstance:
      entityType = "database";
      brand = "brand-gcp";
      break;
    case ResourceType.GcpComputeInstance:
      entityType = "compute";
      brand = "brand-gcp";
      break;
    case ResourceType.GcpFolder:
      entityType = "folder";
      brand = "brand-gcp";
      break;
    case ResourceType.GcpGkeCluster:
      entityType = "kubernetes-cluster";
      brand = "brand-gcp";
      break;
    case ResourceType.GcpProject:
      entityType = "gcp-project";
      brand = "brand-gcp";
      break;
    case ResourceType.GitHubRepo:
      entityType = "repo";
      brand = "brand-github";
      break;
    case ResourceType.GitLabProject:
      entityType = "gitlab-project";
      brand = "brand-gitlab";
      break;
    case ResourceType.GoogleWorkspaceRole:
      entityType = "role";
      brand = "brand-google";
      break;
    case ResourceType.MariadbInstance:
      entityType = "database";
      brand = "brand-selfhosted";
      break;
    case ResourceType.PostgresInstance:
      entityType = "database";
      brand = "brand-selfhosted";
      break;
    case ResourceType.MongoAtlasInstance:
      entityType = "database";
      brand = "brand-mongo";
      break;
    case ResourceType.MongoInstance:
      entityType = "database";
      brand = "brand-selfhosted";
      break;
    case ResourceType.MysqlInstance:
      entityType = "database";
      brand = "brand-selfhosted";
      break;
    case ResourceType.OktaApp:
      // TODO: show brand icon only / custom url?
      entityType = "resource";
      brand = "brand-okta";
      break;
    case ResourceType.OktaRole:
      entityType = "role";
      brand = "brand-okta";
      break;
    case ResourceType.OpalRole:
      entityType = "role";
      brand = "brand-opal";
      break;
    case ResourceType.PagerdutyRole:
      entityType = "role";
      brand = "brand-pagerduty";
      break;
    case ResourceType.SalesforcePermissionSet:
      entityType = "permission-set";
      brand = "brand-salesforce";
      break;
    case ResourceType.SalesforceProfile:
      entityType = "profile";
      brand = "brand-salesforce";
      break;
    case ResourceType.SalesforceRole:
      entityType = "role";
      brand = "brand-salesforce";
      break;
    case ResourceType.SnowflakeDatabase:
      entityType = "database";
      brand = "brand-snowflake";
      break;
    case ResourceType.SnowflakeSchema:
      entityType = "dataflow";
      brand = "brand-snowflake";
      break;
    case ResourceType.SnowflakeTable:
      entityType = "table";
      brand = "brand-snowflake";
      break;
    case ResourceType.TailscaleSsh:
      entityType = "compute";
      brand = "brand-tailscale";
      break;
    case ResourceType.WorkdayRole:
      // TODO: brand icon for workday?
      entityType = "role";
      brand = "brand-workday";
      break;
    case GroupType.ActiveDirectoryGroup:
      entityType = "group";
      brand = "brand-active-directory";
      break;
    case GroupType.TailscaleGroup:
      entityType = "group";
      brand = "brand-tailscale";
      break;
    case GroupType.AwsSsoGroup:
      entityType = "group";
      brand = "brand-aws";
      break;
    case GroupType.DuoGroup:
      entityType = "group";
      brand = "brand-duo";
      break;
    case GroupType.GitHubTeam:
      entityType = "group";
      brand = "brand-github";
      break;
    case GroupType.GitLabGroup:
      entityType = "group";
      brand = "brand-gitlab";
      break;
    case GroupType.GoogleGroupsGroup:
      entityType = "group";
      brand = "brand-googlegroups";
      break;
    case GroupType.GoogleGroupsGkeGroup:
      entityType = "group";
      brand = "brand-googlegroups";
      break;
    case GroupType.LdapGroup:
      entityType = "group";
      brand = "brand-ldap";
      break;
    case GroupType.OktaGroup:
      entityType = "group";
      brand = "brand-okta";
      break;
    case GroupType.OpalGroup:
      entityType = "group";
      brand = "brand-opal";
      break;
    case GroupType.AzureAdMicrosoft_365Group:
      entityType = "group";
      brand = "brand-azure";
      break;
    case GroupType.AzureAdSecurityGroup:
      entityType = "group";
      brand = "brand-azure";
      break;
    case GroupType.SnowflakeRole:
      entityType = "role";
      brand = "brand-snowflake";
      break;
    case GroupType.ConnectorGroup:
      entityType = "group";
      brand = "brand-customconnection";
      break;
    case GroupType.WorkdayUserSecurityGroup:
      entityType = "group";
      brand = "brand-workday";
      break;
  }

  const getIconSize = () => {
    switch (props.size) {
      case "sm":
        return "entitySm";
      case "md":
        return "entityMd";
      case "lg":
        return "entityLg";
      case "xl":
        return "xl";
      case "xxl":
        return "xxl";
      default:
        return "entityMd";
    }
  };

  const iconName = entityIconMap[entityType];
  const BrandIconContent = brandIconSet[brand];

  if (!includeBrand) {
    return (
      <Icon
        color={useDefaultColor ? "gray800" : undefined}
        name={iconName as PropsFor<typeof Icon>["name"]}
        size={getIconSize()}
      />
    );
  }
  if (onlyBrandIcon) {
    return (
      <BrandIconContent
        className={styles.brandIconFull({
          size: props.size ?? "md",
        })}
      />
    );
  }
  return (
    <span className={styles.wrapper({ separate: props.separate })}>
      <Icon
        color={useDefaultColor ? "gray800" : undefined}
        name={iconName as PropsFor<typeof Icon>["name"]}
        size={getIconSize()}
      />
      <BrandIconContent
        className={styles.brandIcon({
          size: props.size ?? "md",
          separate: props.separate,
        })}
      />
    </span>
  );
};

export default EntityIcon;
