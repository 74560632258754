import {
  AccessReviewAction,
  AccessReviewGroupForDetailFragment,
  AccessReviewGroupResourceForDetailFragment,
  AccessReviewGroupResourceVisibilityPolicy,
  AccessReviewGroupUserFragment,
  AccessReviewItemStatus,
  AccessReviewType,
  EntityType,
  GroupAccessLevel,
  GroupResourceForContextFragment,
  Maybe,
  ReviewerUserStatus,
  ReviewGroupResourceAction,
  ReviewGroupUserAction,
  useAccessReviewGroupDetailsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Divider, Input, Tabs } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { filterSearchResults } from "utils/search/filter";
import { updateReviewStateForGroupUserRoleChange } from "views/access_reviews/AccessReviewGroupRoleCell";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import { calculateBatchAcceptRevokeState } from "./AccessReviewAcceptRevokeToggleButton";
import AccessReviewContext, {
  AccessReviewContextActionType,
  emptyPerformReviewState,
} from "./AccessReviewContext";
import * as styles from "./AccessReviewResource.css";
import DecisionButtons from "./common/DecisionButtons";
import GroupResourceSubRow from "./common/GroupResourceSubRow";
import GroupUserSubRow from "./common/GroupUserSubRow";
import NoteCell from "./common/NoteCell";
import StatusCell from "./common/StatusCell";
import SubmitAccessReviewButton from "./common/SubmitAccessReviewButtonV2";
import UserCell from "./common/UserCell";
import { UARSelectContext } from "./UARSelectContext";

interface GroupUserRow {
  id: string;
  user: string;
  role: string;
  updatedRole?: string;
  expires?: string;
  outcome?: string;
  note?: string;
  data: AccessReviewGroupUserFragment;
}

interface GroupResourceRow {
  id: string;
  resource: string;
  role: string;
  connection: string;
  outcome?: string;
  note?: string;
  data: AccessReviewGroupResourceForDetailFragment;
}

interface GroupResourceForContextRow {
  id: string;
  resource: string;
  role: string;
  data: GroupResourceForContextFragment;
}

const AccessReviewGroup = () => {
  const { accessReviewGroupId } = useParams<Record<string, string>>();

  const { data, error, loading } = useAccessReviewGroupDetailsQuery({
    variables: {
      input: {
        id: accessReviewGroupId,
      },
    },
    skip: !accessReviewGroupId,
  });

  if (loading) {
    return (
      <Column isContent maxWidth="lg">
        <ColumnContentSkeleton />
      </Column>
    );
  }

  let accessReviewGroup: AccessReviewGroupForDetailFragment | undefined;
  switch (data?.accessReviewGroup.__typename) {
    case "AccessReviewGroupResult":
      accessReviewGroup = data.accessReviewGroup.accessReviewGroup;
      break;
    case "AccessReviewGroupNotFoundError":
      return (
        <Column isContent maxWidth="lg">
          <NotFoundPage entity="Group" />
        </Column>
      );
  }

  if (error || !accessReviewGroup) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  return (
    <Column isContent maxWidth="lg">
      {accessReviewGroup.currentUserReviewerStatus?.itemStatus ===
      AccessReviewItemStatus.Completed ? (
        <AccessReviewGroupCompleted accessReviewGroup={accessReviewGroup} />
      ) : (
        <AccessReviewGroupTodo accessReviewGroup={accessReviewGroup} />
      )}
    </Column>
  );
};

interface CompletedProps {
  accessReviewGroup: AccessReviewGroupForDetailFragment;
}

const AccessReviewGroupCompleted = ({ accessReviewGroup }: CompletedProps) => {
  const { accessReviewId } = useParams<Record<string, string>>();
  const history = useHistory();

  const group = accessReviewGroup.group;

  if (!group) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  return (
    <>
      <ColumnHeader
        title={group.name}
        icon={{ type: "entity", entityType: group.groupType }}
        subtitle={`${group.connection?.name} / ${
          groupTypeInfoByType[group.groupType].name
        } ${group.description ? `(Description: ${group.description})` : ""}`}
        onClose={() =>
          history.push(`/access-reviews/${accessReviewId}/my-reviews`)
        }
      />
      <Divider />
      <div className={styles.tabsContainer}>
        <div className={styles.tabs}>
          <Tabs
            round
            tabInfos={[
              {
                title: "Users",
                onClick: () => history.push({ hash: "#users" }),
                isSelected: !location.hash || location.hash === "#users",
                icon: "users",
                badgeCount: accessReviewGroup.numGroupUsers,
              },
              {
                title: "Resources",
                onClick: () => history.push({ hash: "#resources" }),
                isSelected: location.hash === "#resources",
                icon: "cube",
                badgeCount: accessReviewGroup.numGroupResources,
              },
            ]}
          />
        </div>
      </div>
      {location.hash === "#resources" ? (
        <AccessReviewGroupResourcesCompleted
          accessReviewGroup={accessReviewGroup}
        />
      ) : (
        <AccessReviewGroupUsersCompleted
          accessReviewGroup={accessReviewGroup}
        />
      )}
    </>
  );
};

const AccessReviewGroupUsersCompleted = ({
  accessReviewGroup,
}: CompletedProps) => {
  const [searchQuery, setSearchQuery] = useState("");

  // Filter for search results
  const groupUsers = filterSearchResults(
    accessReviewGroup.groupUsers ?? [],
    searchQuery,
    (groupUser) => [groupUser.user?.fullName, groupUser.user?.email]
  );

  const rows: GroupUserRow[] = groupUsers.map((groupUser) => {
    return {
      id: groupUser.id,
      user: groupUser.user?.fullName || groupUser.userId,
      role: groupUser.accessLevel.accessLevelName || "",
      outcome: groupUser.statusAndOutcome.outcome,
      data: groupUser,
    };
  });

  const columns: Header<GroupUserRow>[] = [
    {
      id: "user",
      label: "User",
      customCellRenderer: (row) => {
        const user = row.data.user;
        if (!user) {
          return <></>;
        }
        return <UserCell user={user} />;
      },
    },
    {
      id: "outcome",
      label: "Status",
      sortable: false,
      width: 80,
      customCellRenderer: (row) => {
        const outcome = row.data.statusAndOutcome.outcome;
        const status = row.data.statusAndOutcome.status;
        if (!outcome || !status) {
          return <></>;
        }

        return <StatusCell outcome={outcome} status={status} />;
      },
    },
  ];

  const renderSubRow = (row: GroupUserRow) => {
    return <GroupUserSubRow groupUser={row.data} />;
  };

  return (
    <ColumnContent>
      <div className={styles.tableContainer}>
        <div className={styles.searchContainer}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search this table"
          />
        </div>
        <Table
          rows={rows}
          totalNumRows={rows.length}
          getRowId={(ru) => ru.id}
          columns={columns}
          renderSubRow={renderSubRow}
        />
      </div>
    </ColumnContent>
  );
};

const AccessReviewGroupResourcesCompleted = ({
  accessReviewGroup,
}: CompletedProps) => {
  const [searchQuery, setSearchQuery] = useState("");

  // Filter for search results
  const groupResources = filterSearchResults(
    accessReviewGroup.groupResources ?? [],
    searchQuery,
    (groupResource) => [
      groupResource.resource?.name,
      groupResource.resource?.connection?.name,
    ]
  );

  const rows: GroupResourceRow[] = groupResources.map((groupResource) => {
    return {
      id: groupResource.id,
      resource: groupResource.resource?.name || "--",
      role: groupResource.accessLevel.accessLevelName || "--",
      connection: groupResource.resource?.connection?.name || "--",
      outcome: groupResource.statusAndOutcome.outcome,
      data: groupResource,
    };
  });

  const columns: Header<GroupResourceRow>[] = [
    {
      id: "resource",
      label: "Resource",
      customCellRenderer: (row) => {
        const resource = row.data.resource;
        if (!resource) {
          return <></>;
        }
        return (
          <ResourceLabel
            text={resource.name}
            subText={getResourceTypeInfo(resource.resourceType)?.name || "--"}
            entityTypeNew={EntityType.Resource}
            resourceType={resource.resourceType}
            iconLarge
            bold
            icon={resource.iconUrl}
          />
        );
      },
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "outcome",
      label: "Status",
      sortable: false,
      width: 80,
      customCellRenderer: (row) => {
        const outcome = row.data.statusAndOutcome.outcome;
        const status = row.data.statusAndOutcome.status;
        if (!outcome || !status) {
          return <></>;
        }

        return <StatusCell outcome={outcome} status={status} />;
      },
    },
  ];

  const renderSubRow = (row: GroupResourceRow) => {
    return <GroupResourceSubRow groupResource={row.data} />;
  };

  return (
    <ColumnContent>
      <div className={styles.tableContainer}>
        <div className={styles.searchContainer}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search this table"
          />
        </div>
        <Table
          rows={rows}
          totalNumRows={rows.length}
          getRowId={(ru) => ru.id}
          columns={columns}
          renderSubRow={renderSubRow}
        />
      </div>
    </ColumnContent>
  );
};

interface TodoProps {
  accessReviewGroup: AccessReviewGroupForDetailFragment;
}

const AccessReviewGroupTodo = ({ accessReviewGroup }: TodoProps) => {
  const location = useLocation();
  const { accessReviewId, accessReviewGroupId } = useParams<
    Record<string, string>
  >();
  const history = useHistory();
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const { authState } = useContext(AuthContext);

  const group = accessReviewGroup.group;
  const performReviewState =
    accessReviewState.performReviewStateByUARGroupId[accessReviewGroupId];

  const showGroupVisibilityPolicies = useFeatureFlag(
    FeatureFlag.AccessReviewGroupResourceVisibilityPolicies
  );
  // Initialize review state if not already initialized
  useEffect(() => {
    if (
      !performReviewState ||
      !performReviewState.groupUserActions ||
      !performReviewState.groupResourceActions
    ) {
      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARGroupId: {
            ...accessReviewState.performReviewStateByUARGroupId,
            [accessReviewGroupId]: emptyPerformReviewState(),
          },
        },
      });
    }
  }, [
    accessReviewState,
    accessReviewGroupId,
    performReviewState,
    accessReviewDispatch,
  ]);

  if (!performReviewState) {
    return null;
  }

  if (!group) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const goBack = () => {
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARGroupId: {},
        performReviewStateByUARResourceId: {},
        performReviewStateByUARUserId: {},
        performReviewStateByUARConnectionId: {},
      },
    });
    history.push(`/access-reviews/${accessReviewId}/my-reviews`);
  };

  const groupResourcePolicy =
    accessReviewGroup?.accessReview?.groupResourceVisibilityPolicy;

  const subtitle =
    groupResourcePolicy === AccessReviewGroupResourceVisibilityPolicy.Strict
      ? "This access review was configured to not show other resources in this group"
      : groupResourcePolicy ===
        AccessReviewGroupResourceVisibilityPolicy.ViewVisibleAndAssigned
      ? "Below are resources in this group that you do not need to review, but may help with your assessment. There may be other resources in this group that you have not been granted visibility into."
      : "Below are resources in this group that you do not need to review, but may help with your assessment.";

  const selfReviewAllowed = accessReviewGroup?.accessReview?.selfReviewAllowed;
  const numIncompleteGroupUsers = (accessReviewGroup?.groupUsers ?? []).filter(
    (user) => {
      return user.reviewerUsers?.find((reviewer) => {
        return (
          reviewer.userId === authState.user?.user.id &&
          (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
          reviewer.status === ReviewerUserStatus.NotStarted
        );
      });
    }
  )?.length;
  const numIncompleteGroupResources = (
    accessReviewGroup?.groupResources ?? []
  ).filter((resource) => {
    return resource.reviewerUsers?.find((reviewer) => {
      return (
        reviewer.userId === authState.user?.user.id &&
        reviewer.status === ReviewerUserStatus.NotStarted
      );
    });
  })?.length;

  return (
    <>
      <ColumnHeader
        title={group.name}
        icon={{ type: "entity", entityType: group.groupType }}
        subtitle={`${group.connection?.name} / ${
          groupTypeInfoByType[group.groupType].name
        } ${group.description ? `(Description: ${group.description})` : ""}`}
        onClose={goBack}
        rightActions={
          <SubmitAccessReviewButton
            accessReviewId={accessReviewId}
            onCancel={goBack}
            entityName={group.name}
            performReviewState={performReviewState}
          />
        }
      />
      <Divider />
      <div
        className={
          location.hash === "#resources" && showGroupVisibilityPolicies
            ? styles.tabsContainerForScrollable
            : styles.tabsContainer
        }
      >
        <div className={styles.tabs}>
          <Tabs
            round
            tabInfos={[
              {
                title: "Users",
                onClick: () => history.push({ hash: "#users" }),
                isSelected: !location.hash || location.hash === "#users",
                icon: "users",
                badgeCount: numIncompleteGroupUsers,
              },
              {
                title: "Resources",
                onClick: () => history.push({ hash: "#resources" }),
                isSelected: location.hash === "#resources",
                icon: "cube",
                badgeCount: numIncompleteGroupResources,
              },
            ]}
          />
        </div>
      </div>
      {location.hash === "#resources" ? (
        <ColumnContent>
          <div
            className={sprinkles({
              marginX: "sm",
              marginTop: "md",
              fontWeight: "semibold",
            })}
          >
            Please review the following access:
          </div>
          <AccessReviewGroupResourcesTodoSection
            accessReviewGroup={accessReviewGroup}
          />
          {showGroupVisibilityPolicies && (
            <>
              <div
                className={sprinkles({
                  marginX: "sm",
                  marginTop: "md",
                  fontWeight: "semibold",
                })}
              >
                Other resources in this group:
              </div>
              <div
                className={sprinkles({
                  marginX: "sm",
                  marginBottom: "md",
                })}
              >
                {subtitle}
              </div>
              <AccessReviewOtherGroupResourcesTableSection
                accessReviewGroup={accessReviewGroup}
              />
            </>
          )}
        </ColumnContent>
      ) : (
        <>
          <div
            className={sprinkles({
              margin: "sm",
              marginTop: "md",
              fontWeight: "semibold",
            })}
          >
            Please review the following access:
          </div>
          <AccessReviewGroupUsersTodo accessReviewGroup={accessReviewGroup} />
        </>
      )}
    </>
  );
};

const AccessReviewGroupUsersTodo = ({ accessReviewGroup }: TodoProps) => {
  const { accessReviewId } = useParams<Record<string, string>>();
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const { accessReviewGroupId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);
  const { selectedGroupUserIds, setSelectedGroupUserIds } = useContext(
    UARSelectContext
  );

  const performReviewState =
    accessReviewState.performReviewStateByUARGroupId[accessReviewGroup.id];

  const [searchQuery, setSearchQuery] = useState("");

  const selfReviewAllowed = accessReviewGroup?.accessReview?.selfReviewAllowed;
  const group = accessReviewGroup.group;
  if (!group) {
    return null;
  }
  // Filter for search results and
  // only show incomplete group users current user needs to review
  const groupUsers = (accessReviewGroup?.groupUsers ?? []).filter((user) => {
    return user.reviewerUsers?.find((reviewer) => {
      return (
        reviewer.userId === authState.user?.user.id &&
        (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
        reviewer.status === ReviewerUserStatus.NotStarted
      );
    });
  });
  const filteredSearchGroupUsers = filterSearchResults(
    groupUsers,
    searchQuery,
    (groupUser) => [groupUser.user?.fullName, groupUser.user?.email]
  );

  const groupUserActionByUarGroupUserId: Record<
    string,
    ReviewGroupUserAction
  > = {};
  performReviewState?.groupUserActions?.forEach((groupUserAction) => {
    const id = groupUserAction.accessReviewGroupUserId;
    groupUserActionByUarGroupUserId[id] = groupUserAction;
  });

  const groupHasRoles = groupUsers.some((user) =>
    Boolean(user.accessLevel.accessLevelRemoteId)
  );

  const rows: GroupUserRow[] = filteredSearchGroupUsers.map((groupUser) => {
    const groupUserAction = groupUserActionByUarGroupUserId[groupUser.id];
    return {
      id: groupUser.id,
      user: groupUser.user?.fullName || groupUser.userId,
      role: groupUser.accessLevel.accessLevelName ?? "",
      updatedRole: groupUserAction?.updatedAccessLevel?.accessLevelName,
      outcome: groupUser.statusAndOutcome.outcome,
      note: groupUserAction?.note ?? undefined,
      data: groupUser,
    };
  });

  const bulkState = calculateBatchAcceptRevokeState(
    performReviewState.groupUserActions,
    groupUsers.length
  );

  const getGroupUserReviewState = (groupUserId: string) => {
    return performReviewState.groupUserActions.find(
      (gu) => gu.accessReviewGroupUserId === groupUserId
    )?.action;
  };

  const handleReviewAction = (
    state: AccessReviewAction,
    accessReviewGroupUserId: string
  ) => {
    const existingInfo = performReviewState.groupUserActions.find(
      (groupUserAction) =>
        groupUserAction.accessReviewGroupUserId === accessReviewGroupUserId
    );

    if (existingInfo) {
      existingInfo.action = state;
    } else {
      performReviewState.groupUserActions.push({
        accessReviewGroupUserId,
        action: state,
      });
    }
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARGroupId: {
          ...accessReviewState.performReviewStateByUARGroupId,
          [accessReviewGroup.id]: performReviewState,
        },
      },
    });
  };

  const handleBulkReviewAction = (state: AccessReviewAction) => {
    performReviewState.groupUserActions.forEach((groupUserAction) => {
      groupUserAction.action = state;
    });

    groupUsers.forEach((groupUser) => {
      const existingInfo = performReviewState.groupUserActions.find(
        (groupUserAction) =>
          groupUserAction.accessReviewGroupUserId === groupUser.id
      );

      if (!existingInfo) {
        performReviewState.groupUserActions.push({
          accessReviewGroupUserId: groupUser.id,
          action: state,
        });
      } else {
        delete existingInfo.updatedAccessLevel;
      }
    });

    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARGroupId: {
          ...accessReviewState.performReviewStateByUARGroupId,
          [accessReviewGroup.id]: performReviewState,
        },
      },
    });
  };
  const bulkDecisionHeader = (
    <DecisionButtons
      state={bulkState}
      onApproveClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Accept
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Accept
        )
      }
      onRevokeClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Revoke
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Revoke
        )
      }
      groupId={group?.id}
      onRoleSelect={(role) => {
        if (!performReviewState) {
          return;
        }

        let updatedPerformReviewState = {
          ...performReviewState,
        };
        groupUsers.forEach((groupUser) => {
          updatedPerformReviewState = updateReviewStateForGroupUserRoleChange(
            updatedPerformReviewState,
            groupUser,
            role as Maybe<GroupAccessLevel>
          );
        });

        accessReviewDispatch({
          type: AccessReviewContextActionType.AccessReviewItemUpdate,
          payload: {
            performReviewStateByUARGroupId: {
              ...accessReviewState.performReviewStateByUARGroupId,
              [accessReviewGroupId]: updatedPerformReviewState,
            },
          },
        });
      }}
      showRole={groupHasRoles}
      clearBackground
    />
  );

  const columns: Header<GroupUserRow>[] = [
    {
      id: "user",
      label: "User",
      width: groupHasRoles ? 200 : 400,
      customCellRenderer: (row) => {
        const user = row.data.user;
        if (!user) {
          return <></>;
        }
        return <UserCell user={user} />;
      },
    },
    {
      id: "expires",
      label: "Expires",
      width: groupHasRoles ? 160 : 200,
      customCellRenderer: (row) => {
        const expirationString =
          row.data.groupUser?.access?.latestExpiringAccessPoint?.expiration;
        if (!expirationString) {
          return <>Never</>;
        }
        const expires = moment(expirationString).fromNow();
        return <span>{expires}</span>;
      },
    },
  ];
  if (groupHasRoles) {
    columns.push({
      id: "role",
      label: "Role",
      customCellRenderer: (row) => {
        return (
          <span
            className={sprinkles({
              color: row.updatedRole ? "blue600" : "black",
            })}
          >
            {row.updatedRole ?? row.role}
          </span>
        );
      },
    });
  }
  columns.push(
    {
      id: "outcome",
      label: "",
      sortable: false,
      customHeader: rows.length > 0 ? bulkDecisionHeader : <></>,
      width: groupHasRoles ? 160 : 100,
      customCellRenderer: (row) => {
        const state = getGroupUserReviewState(row.id);
        return (
          <DecisionButtons
            state={state}
            onApproveClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Accept
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Accept,
                row.id
              )
            }
            onRevokeClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Revoke
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Revoke,
                row.id
              )
            }
            groupId={group?.id}
            onRoleSelect={(role) => {
              if (!performReviewState) {
                return;
              }
              const groupUser = row.data;

              const updatedPerformReviewState = updateReviewStateForGroupUserRoleChange(
                performReviewState,
                groupUser,
                role as Maybe<GroupAccessLevel>
              );

              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewItemUpdate,
                payload: {
                  performReviewStateByUARGroupId: {
                    ...accessReviewState.performReviewStateByUARGroupId,
                    [accessReviewGroupId]: updatedPerformReviewState,
                  },
                },
              });
            }}
            showRole={groupHasRoles}
          />
        );
      },
    },
    {
      id: "note",
      label: "",
      sortable: false,
      width: 40,
      customCellRenderer: (row) => (
        <NoteCell
          note={row.note}
          accessReviewID={accessReviewId}
          targetIDs={[row.id]}
          targetType={AccessReviewType.GroupUser}
          onNoteChange={(updatedNoteContent) => {
            const groupUser = row.data;
            if (!performReviewState) {
              return;
            }

            const existingInfo = performReviewState.groupUserActions.find(
              (groupUserAction) =>
                groupUserAction.accessReviewGroupUserId === groupUser.id
            );

            if (existingInfo) {
              existingInfo.note = updatedNoteContent;
            } else {
              performReviewState.groupUserActions.push({
                accessReviewGroupUserId: groupUser.id,
                action: AccessReviewAction.NoAction,
                note: updatedNoteContent,
              });
            }

            accessReviewDispatch({
              type: AccessReviewContextActionType.AccessReviewItemUpdate,
              payload: {
                performReviewStateByUARGroupId: {
                  ...accessReviewState.performReviewStateByUARGroupId,
                  [accessReviewGroup.id]: performReviewState,
                },
              },
            });
          }}
        />
      ),
    }
  );

  const renderSubRow = (row: GroupUserRow) => {
    return <GroupUserSubRow groupUser={row.data} />;
  };

  const selectedIds = selectedGroupUserIds[accessReviewGroup.id] ?? [];

  return (
    <ColumnContent>
      <div className={styles.tableContainer}>
        <div className={styles.searchContainer}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search this table"
          />
        </div>
        <Table
          rows={rows}
          totalNumRows={rows.length}
          getRowId={(row) => row.id}
          columns={columns}
          renderSubRow={renderSubRow}
          emptyState={{
            title: "No users to review",
          }}
          onCheckedRowsChange={(ids, checked) => {
            if (checked) {
              setSelectedGroupUserIds(
                accessReviewGroup.id,
                _.uniq([...ids, ...selectedIds])
              );
            } else {
              setSelectedGroupUserIds(
                accessReviewGroup.id,
                selectedIds.filter((id) => !ids.includes(id))
              );
            }
          }}
          checkedRowIds={new Set(selectedIds)}
          selectAllChecked={selectedIds.length === rows.length}
          onSelectAll={(checked) => {
            if (checked) {
              setSelectedGroupUserIds(
                accessReviewGroup.id,
                rows.map((row) => row.id)
              );
            } else {
              setSelectedGroupUserIds(accessReviewGroup.id, []);
            }
          }}
        />
      </div>
    </ColumnContent>
  );
};

const AccessReviewGroupResourcesTodoSection = ({
  accessReviewGroup,
}: TodoProps) => {
  const { accessReviewId } = useParams<Record<string, string>>();
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const { authState } = useContext(AuthContext);
  const { selectedGroupResourceIds, setSelectedGroupResourceIds } = useContext(
    UARSelectContext
  );

  const performReviewState =
    accessReviewState.performReviewStateByUARGroupId[accessReviewGroup.id];

  const [searchQuery, setSearchQuery] = useState("");

  const group = accessReviewGroup.group;
  if (!group) {
    return null;
  }
  // Filter for search results and
  // only show incomplete group resources current user needs to review
  const groupResources = (accessReviewGroup?.groupResources ?? []).filter(
    (resource) => {
      return resource.reviewerUsers?.find((reviewer) => {
        return (
          reviewer.userId === authState.user?.user.id &&
          reviewer.status === ReviewerUserStatus.NotStarted
        );
      });
    }
  );
  const filteredSearchGroupResources = filterSearchResults(
    groupResources,
    searchQuery,
    (groupResource) => [
      groupResource.resource?.name,
      groupResource.resource?.connection?.name,
    ]
  );

  const groupResourceActionByUarGroupResourceId: Record<
    string,
    ReviewGroupResourceAction
  > = {};
  performReviewState?.groupResourceActions?.forEach((groupResourceAction) => {
    const id = groupResourceAction.accessReviewGroupResourceId;
    groupResourceActionByUarGroupResourceId[id] = groupResourceAction;
  });

  const rows: GroupResourceRow[] = filteredSearchGroupResources.map(
    (groupResource) => {
      const groupResourceAction =
        groupResourceActionByUarGroupResourceId[groupResource.id];
      return {
        id: groupResource.id,
        resource: groupResource.resource?.name || "--",
        role: groupResource.accessLevel.accessLevelName || "--",
        connection: groupResource.resource?.connection?.name || "--",
        outcome: groupResource.statusAndOutcome.outcome,
        note: groupResourceAction?.note ?? undefined,
        data: groupResource,
      };
    }
  );

  const bulkState = calculateBatchAcceptRevokeState(
    performReviewState.groupResourceActions,
    groupResources.length
  );

  const getGroupResourceReviewState = (groupResourceId: string) => {
    return performReviewState.groupResourceActions.find(
      (gu) => gu.accessReviewGroupResourceId === groupResourceId
    )?.action;
  };

  const handleReviewAction = (
    state: AccessReviewAction,
    accessReviewGroupResourceId: string
  ) => {
    const existingInfo = performReviewState.groupResourceActions.find(
      (groupResourceAction) =>
        groupResourceAction.accessReviewGroupResourceId ===
        accessReviewGroupResourceId
    );

    if (existingInfo) {
      existingInfo.action = state;
    } else {
      performReviewState.groupResourceActions.push({
        accessReviewGroupResourceId,
        action: state,
      });
    }
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARGroupId: {
          ...accessReviewState.performReviewStateByUARGroupId,
          [accessReviewGroup.id]: performReviewState,
        },
      },
    });
  };

  const handleBulkReviewAction = (state: AccessReviewAction) => {
    performReviewState.groupResourceActions.forEach((groupResourceAction) => {
      groupResourceAction.action = state;
    });

    groupResources.forEach((groupResource) => {
      const existingInfo = performReviewState.groupResourceActions.find(
        (groupResourceAction) =>
          groupResourceAction.accessReviewGroupResourceId === groupResource.id
      );

      if (!existingInfo) {
        performReviewState.groupResourceActions.push({
          accessReviewGroupResourceId: groupResource.id,
          action: state,
        });
      }
    });

    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARGroupId: {
          ...accessReviewState.performReviewStateByUARGroupId,
          [accessReviewGroup.id]: performReviewState,
        },
      },
    });
  };
  const bulkDecisionHeader = (
    <DecisionButtons
      state={bulkState}
      onApproveClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Accept
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Accept
        )
      }
      onRevokeClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Revoke
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Revoke
        )
      }
      clearBackground
    />
  );

  const columns: Header<GroupResourceRow>[] = [
    {
      id: "resource",
      label: "Resource",
      width: 200,
      customCellRenderer: (row) => {
        const resource = row.data.resource;
        if (!resource) {
          return <>--</>;
        }
        return (
          <ResourceLabel
            text={resource.name}
            subText={getResourceTypeInfo(resource.resourceType)?.name || "--"}
            entityTypeNew={EntityType.Resource}
            resourceType={resource.resourceType}
            iconLarge
            bold
            icon={resource.iconUrl}
          />
        );
      },
    },
    {
      id: "role",
      label: "Role",
      width: 200,
    },
    {
      id: "outcome",
      label: "",
      sortable: false,
      customHeader: rows.length > 0 ? bulkDecisionHeader : <></>,
      width: 100,
      customCellRenderer: (row) => {
        const state = getGroupResourceReviewState(row.id);
        return (
          <DecisionButtons
            state={state}
            onApproveClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Accept
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Accept,
                row.id
              )
            }
            onRevokeClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Revoke
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Revoke,
                row.id
              )
            }
          />
        );
      },
    },
    {
      id: "note",
      label: "",
      sortable: false,
      width: 40,
      customCellRenderer: (row) => (
        <NoteCell
          note={row.note}
          accessReviewID={accessReviewId}
          targetIDs={[row.id]}
          targetType={AccessReviewType.GroupResource}
          onNoteChange={(updatedNoteContent) => {
            const groupResource = row.data;
            if (!performReviewState) {
              return;
            }

            const existingInfo = performReviewState.groupResourceActions.find(
              (groupResourceAction) =>
                groupResourceAction.accessReviewGroupResourceId ===
                groupResource.id
            );

            if (existingInfo) {
              existingInfo.note = updatedNoteContent;
            } else {
              performReviewState.groupResourceActions.push({
                accessReviewGroupResourceId: groupResource.id,
                action: AccessReviewAction.NoAction,
                note: updatedNoteContent,
              });
            }

            accessReviewDispatch({
              type: AccessReviewContextActionType.AccessReviewItemUpdate,
              payload: {
                performReviewStateByUARGroupId: {
                  ...accessReviewState.performReviewStateByUARGroupId,
                  [accessReviewGroup.id]: performReviewState,
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const renderSubRow = (row: GroupResourceRow) => {
    return <GroupResourceSubRow groupResource={row.data} />;
  };

  const selectedIds = selectedGroupResourceIds[accessReviewGroup.id] ?? [];

  return (
    <div className={styles.tableContainer}>
      <div className={styles.searchContainer}>
        <Input
          leftIconName="search"
          type="search"
          style="search"
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder="Search this table"
        />
      </div>
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={columns}
        renderSubRow={renderSubRow}
        emptyState={{
          title: "No resources to review",
        }}
        onCheckedRowsChange={(ids, checked) => {
          if (checked) {
            setSelectedGroupResourceIds(
              accessReviewGroup.id,
              _.uniq([...ids, ...selectedIds])
            );
          } else {
            setSelectedGroupResourceIds(
              accessReviewGroup.id,
              selectedIds.filter((id) => !ids.includes(id))
            );
          }
        }}
        checkedRowIds={new Set(selectedIds)}
        selectAllChecked={selectedIds.length === rows.length}
        onSelectAll={(checked) => {
          if (checked) {
            setSelectedGroupResourceIds(
              accessReviewGroup.id,
              rows.map((row) => row.id)
            );
          } else {
            setSelectedGroupResourceIds(accessReviewGroup.id, []);
          }
        }}
      />
    </div>
  );
};

const AccessReviewOtherGroupResourcesTableSection = ({
  accessReviewGroup,
}: TodoProps) => {
  // AllGroupResources helps UAR reviewers gain more context on a Group they are reviewing.
  // If the UAR uses the Moderate or Full Visibility group visibility resource policy,
  // this shows resources in the group that are not being reviewed by the reviewer.
  // This field, since it is a field to give context, shows the current state of the group,
  // while the UARGroupResources field has the state when the UAR was created.
  const [searchQuery, setSearchQuery] = useState("");
  const { authState } = useContext(AuthContext);

  const group = accessReviewGroup.group;
  if (!group) {
    return null;
  }
  const groupResourceIdsToReview = (
    accessReviewGroup?.groupResources ?? []
  ).reduce((acc, groupResource) => {
    const reviewer = groupResource.reviewerUsers?.find((reviewer) => {
      return (
        reviewer.userId === authState.user?.user.id &&
        reviewer.status === ReviewerUserStatus.NotStarted
      );
    });
    if (reviewer && groupResource?.resource?.id) {
      acc[groupResource?.resource?.id] = true;
    }
    return acc;
  }, {} as Record<string, boolean>);

  const groupResources =
    (accessReviewGroup?.allGroupResources ?? []).filter((resource) => {
      return !groupResourceIdsToReview[resource.resourceId];
    }) ?? [];

  const filteredSearchGroupResources = filterSearchResults(
    groupResources,
    searchQuery,
    (groupResource) => [
      groupResource.resource?.name,
      groupResource.resource?.connection?.name,
    ]
  );

  const rows: GroupResourceForContextRow[] = filteredSearchGroupResources.map(
    (groupResource) => {
      return {
        id: groupResource.groupId,
        resource: groupResource.resource?.name || "--",
        role: groupResource.accessLevel.accessLevelName || "--",
        data: groupResource,
      };
    }
  );

  const columns: Header<GroupResourceForContextRow>[] = [
    {
      id: "resource",
      label: "Resource",
      width: 200,
      customCellRenderer: (row) => {
        const resource = row.data.resource;
        if (!resource) {
          return <>--</>;
        }
        return (
          <ResourceLabel
            text={resource.name}
            subText={getResourceTypeInfo(resource.resourceType)?.name || "--"}
            entityTypeNew={EntityType.Resource}
            resourceType={resource.resourceType}
            iconLarge
            bold
            icon={resource.iconUrl}
          />
        );
      },
    },
    {
      id: "role",
      label: "Role",
      width: 200,
    },
  ];

  const isGroupResourcePolicyStrict =
    accessReviewGroup?.accessReview?.groupResourceVisibilityPolicy ===
    AccessReviewGroupResourceVisibilityPolicy.Strict;

  return (
    <>
      {!isGroupResourcePolicyStrict && (
        <div className={styles.tableContainer}>
          <div className={styles.searchContainer}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search this table"
            />
          </div>
          <Table
            rows={rows}
            totalNumRows={rows.length}
            getRowId={(row) => row.id}
            columns={columns}
            emptyState={{
              title: "No other resources to show",
            }}
          />
        </div>
      )}
    </>
  );
};

export default AccessReviewGroup;
