import AuthContext from "components/auth/AuthContext";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import { Divider } from "components/ui";
import DateRangePicker from "components/ui/date_picker/DateRangePicker";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useMountEffect } from "utils/hooks";

import MetricsFilter from "./filters/MetricsFilters";
import MetricsFilterV3 from "./filters/MetricsFiltersV3";
import TimeBucketPicker from "./filters/TimeBucketPicker";
import AccessDurationGranted from "./metrics/AccessDurationGranted";
import BreakGlassUsersCounts from "./metrics/BreakGlassUsersCount";
import BreakGlassUsersGroups from "./metrics/BreakGlassUsersGroups";
import ClosedRequestMetrics from "./metrics/ClosedRequestMetrics";
import { MetricsGraphRow } from "./metrics/MetricsGraphCard";
import MostRequestedGroups from "./metrics/MostRequestedGroups";
import MostRequestedResources from "./metrics/MostRequestedResources";
import RequestApprovalTime from "./metrics/RequestApprovalTime";
import RequestMetrics from "./metrics/RequestMetrics";
import {
  useMetricsFilterDispatch,
  useMetricsFilterState,
} from "./MetricsDashboardContext";

const MetricsDashboard = () => {
  const { authState } = useContext(AuthContext);
  const { dateRange } = useMetricsFilterState();
  const logEvent = useLogEvent();
  const filterDispatch = useMetricsFilterDispatch();
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  useMountEffect(() => {
    logEvent({
      name: "metrics_dashboard_view",
    });
  });

  return (
    <ColumnContainer>
      <Column isContent maxWidth="none">
        {hasV3 ? (
          <ColumnHeaderV3
            title="Dashboard"
            icon={{ type: "name", icon: "line-chart-up" }}
            includeDefaultActions
          />
        ) : (
          <ColumnHeader
            title="Dashboard"
            icon={{ type: "name", icon: "line-chart-up" }}
            subtitle={authState.user?.user.organization.name}
            rightActions={
              <div className={sprinkles({ display: "flex", gap: "sm" })}>
                <TimeBucketPicker />
                <DateRangePicker
                  selectedRange={{
                    from: dateRange.from,
                    // Always show highlighted date to end of range,
                    // even if end is today (which is undefined).
                    to: dateRange.to || new Date(),
                  }}
                  setSelectedRange={(dateRange) => {
                    if (dateRange) {
                      logEvent({
                        name: "metrics_dashboard_time_range_select",
                        properties: {
                          start: dateRange.from?.toISOString() ?? "",
                          end: dateRange.to?.toISOString() ?? "",
                        },
                      });
                      filterDispatch({
                        type: "SET_DATE_RANGE",
                        payload: {
                          dateRange,
                        },
                      });
                    }
                  }}
                  disableFutureDates
                  clearable={false}
                />
              </div>
            }
          />
        )}
        {!hasV3 && <Divider />}
        {hasV3 ? <MetricsFilterV3 /> : <MetricsFilter />}
        <ColumnContent>
          <RequestMetrics />
          <MetricsGraphRow>
            <RequestApprovalTime />
            <AccessDurationGranted />
          </MetricsGraphRow>
          <MetricsGraphRow>
            <MostRequestedResources />
            <MostRequestedGroups />
          </MetricsGraphRow>
          <MetricsGraphRow>
            <BreakGlassUsersCounts />
            <BreakGlassUsersGroups />
          </MetricsGraphRow>
          <MetricsGraphRow>
            <ClosedRequestMetrics />
          </MetricsGraphRow>
        </ColumnContent>
      </Column>
    </ColumnContainer>
  );
};

export default MetricsDashboard;
