import {
  EntityType,
  GroupAccessLevel,
  GroupPreviewLargeFragment,
  useBundleItemsQuery,
  useBundleQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import { Editor, EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { GroupTableCellResourceLabel } from "components/label/GroupTableCellResourceLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import MuiVirtualTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MuiVirtualTable";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { SortDirection } from "react-virtualized";
import { AuthorizedActionManage } from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import BundleAddGroupsModal from "./BundleAddGroupsModal";
import BundleContentHeader from "./BundleContentHeader";
import BundleRemoveGroupsModal from "./BundleRemoveGroupsModal";

const BundleGroupsColumn = () => {
  const { bundleId } = useParams<Record<string, string>>();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const {
    data: bundleData,
    error: bundleError,
    loading: bundleLoading,
  } = useBundleQuery({
    variables: {
      input: {
        id: bundleId,
      },
    },
  });

  const { data, error, loading } = useBundleItemsQuery({
    variables: {
      input: {
        bundleId,
      },
    },
    skip: !bundleId,
  });

  const allItems = data?.bundleItems.items ?? [];

  if (error || bundleError) {
    return null;
  }

  if (loading || bundleLoading) {
    return (
      <Column isContent maxWidth="lg">
        <ColumnContentSkeleton />
      </Column>
    );
  }

  const existingRolesByGroupId: Record<string, GroupAccessLevel[]> = {};
  const groupsWithRole: GroupWithAccessLevel[] = [];
  allItems.forEach((item) => {
    if (!item.group) {
      return;
    }
    if (item.group.id in existingRolesByGroupId) {
      existingRolesByGroupId[item.group.id].push({
        accessLevelRemoteId: item.accessLevelRemoteId,
        accessLevelName: item.accessLevelName,
      });
    } else {
      existingRolesByGroupId[item.group.id] = [
        {
          accessLevelRemoteId: item.accessLevelRemoteId,
          accessLevelName: item.accessLevelName,
        },
      ];
    }
    groupsWithRole.push({
      group: item.group,
      role: {
        accessLevelRemoteId: item.accessLevelRemoteId,
        accessLevelName: item.accessLevelName,
      },
    });
  });
  const numGroups = Object.keys(existingRolesByGroupId).length;

  const editor = (
    <Editor
      menuOptions={[
        {
          label: "Add",
          handler: () => setShowAddModal(true),
        },
        {
          label: "Remove",
          handler: () => setShowRemoveModal(true),
          hide: numGroups === 0,
        },
      ]}
    />
  );

  let canManage = false;
  if (bundleData?.bundle.__typename === "BundleResult") {
    canManage =
      bundleData.bundle.bundle.authorizedActions?.includes(
        AuthorizedActionManage
      ) ?? false;
  }

  if (!canManage) {
    return null;
  }

  return (
    <>
      <Column isContent maxWidth="lg">
        <BundleContentHeader canManage={canManage} />
        <EntityViewerRow
          title="Bundle groups"
          isTable
          editor={editor}
          content={
            <EmptyStateContentWrapper
              title="No groups in bundle"
              isEmpty={numGroups === 0}
              entityType={EntityType.Group}
              content={<BundleGroupsTable groups={groupsWithRole} />}
            />
          }
        />
      </Column>
      {showAddModal && (
        <BundleAddGroupsModal
          bundleId={bundleId}
          onClose={() => setShowAddModal(false)}
          existingRolesByGroupId={existingRolesByGroupId}
        />
      )}
      {showRemoveModal && (
        <BundleRemoveGroupsModal
          bundleId={bundleId}
          onClose={() => setShowRemoveModal(false)}
          bundleItems={allItems}
        />
      )}
    </>
  );
};

interface BundleGroupRow {
  name: string;
  connection: string;
  role: string;
}

interface GroupWithAccessLevel {
  group: GroupPreviewLargeFragment;
  role: GroupAccessLevel;
}

interface TableProps {
  groups: GroupWithAccessLevel[];
}

const BundleGroupsTable = (props: TableProps) => {
  const history = useHistory();

  const headers: Header<BundleGroupRow>[] = [
    {
      id: "name",
      label: "Name",
      width: 300,
      minWidth: 250,
    },
    {
      id: "role",
      label: "Role",
      width: 200,
      minWidth: 150,
    },
    {
      id: "connection",
      label: "App",
      width: 200,
      minWidth: 150,
    },
  ];

  const rows: CellRow<BundleGroupRow>[] = props.groups.map(
    ({ group, role }) => ({
      id: group.id,
      data: {
        name: {
          value: group.name,
          element: (
            <GroupTableCellResourceLabel
              groupName={group.name}
              groupType={group.groupType}
              isOnCall={group.isOnCallSynced}
            />
          ),
          clickHandler: () => {
            history.push(
              getResourceUrlNew({
                entityId: group.id,
                entityType: EntityType.Group,
              })
            );
          },
        },
        role: {
          value: role.accessLevelName,
          element: <span>{role.accessLevelName || "--"}</span>,
        },
        connection: {
          value: group.connection?.name ?? "",
          element: (
            <ConnectionLabel
              text={group.connection?.name ?? ""}
              connectionId={group.connectionId ?? ""}
              connectionType={group.connection?.connectionType}
            />
          ),
        },
      },
    })
  );
  return (
    <MuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy="name"
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded
    />
  );
};

export default BundleGroupsColumn;
