import { ColumnContainer } from "components/column/Column";
import React from "react";
import { Switch } from "react-router";
import { Route, useRouteMatch } from "react-router-dom";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import TagsColumn from "./TagsColumn";
import TagsColumnV3 from "./TagsColumnV3";

const Tags = () => {
  const match = useRouteMatch();

  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  if (hasV3Nav) {
    return (
      <Switch>
        <Route
          exact
          path={`${match.path}/:tagId`}
          component={TagsComponentV3}
        />
        <Route exact path={`${match.path}`} component={TagsComponentV3} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path={`${match.path}/:tagId`} component={TagsComponent} />
      <Route exact path={`${match.path}`} component={TagsComponent} />
    </Switch>
  );
};

const TagsComponentV3 = () => {
  return (
    <ColumnContainer>
      <TagsColumnV3 />
    </ColumnContainer>
  );
};

const TagsComponent = () => {
  return (
    <ColumnContainer>
      <TagsColumn />
    </ColumnContainer>
  );
};

export default Tags;
