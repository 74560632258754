import AuthContext from "components/auth/AuthContext";
import React from "react";
import { useContext } from "react";
import { Redirect } from "react-router";
import { Route, Switch } from "react-router-dom";
import { FeatureFlag, useFeatureFlagNoCast } from "utils/feature_flags";
import FullPageLoading from "views/loading/FullPageLoading";

const Homepage = () => {
  const hasV3Nav = useFeatureFlagNoCast(FeatureFlag.V3Nav);
  const hasRecommendations = useFeatureFlagNoCast(FeatureFlag.LPPMShowHomepage);
  const { authState } = useContext(AuthContext);

  if (hasV3Nav == undefined || hasRecommendations == undefined) {
    return <FullPageLoading></FullPageLoading>;
  }
  let redirectRoute: string =
    hasV3Nav &&
    hasRecommendations &&
    (authState.user?.isAdmin || authState.user?.isReadOnlyAdmin)
      ? "/home"
      : "/apps";
  return (
    <Switch>
      <Route exact path={`/`}>
        <Redirect to={redirectRoute} />
      </Route>
    </Switch>
  );
};

export default Homepage;
